import type lightPalette from 'common/lib/theme/lightPalette';
import type newPalette from 'common/lib/theme/newPalette';

/**
 * Map v1 palette to v2 palette.
 */
const v1PaletteMapping: {
  [key in keyof typeof lightPalette]?: keyof typeof newPalette;
} = {
  text: 'gray12',
  textLight: 'gray11',
  orange: 'orange9',
  orangeDark: 'orange10',
  orangeLight: 'orange8',
  orangeFocus: 'orange4',
  orangeBackground: 'orange3',
  orangeText: 'orange11',
  yellow: 'yellow9',
  yellowDark: 'yellow10',
  yellowLight: 'yellow8',
  yellowFocus: 'yellow4',
  yellowBackground: 'yellow3',
  yellowText: 'yellow11',
  blue: 'blue9',
  blueDark: 'blue10',
  blueLight: 'blue8',
  blueFocus: 'blue4',
  blueBackground: 'blue3',
  blueText: 'blue11',
  black: 'gray12',
  grayDark: 'gray10',
  gray: 'gray9',
  grayLight: 'gray7',
  grayFocus: 'gray5',
  grayBackground: 'gray3',
  grayLightBackground: 'gray2',
  white: 'gray1',
  red: 'red9',
  redDark: 'red10',
  redLight: 'red8',
  redFocus: 'red4',
  redBackground: 'red3',
  redText: 'red11',
  green: 'green9',
  greenDark: 'green10',
  greenLight: 'green8',
  greenFocus: 'green4',
  greenBackground: 'green3',
  greenText: 'green11',
  teal: 'sky9',
  purple: 'purple9',
  purpleBackground: 'purple3',
  lime: 'lime9',
  limeBackground: 'lime3',
  pink: 'pink9',
  pinkBackground: 'pink3',
  indigo: 'indigo9',
  indigoBackground: 'indigo3',
};

export const v1PaletteMappingDarkOverrides: {
  [key in keyof typeof lightPalette]?: keyof typeof newPalette;
} = {
  white: 'gray3',
  grayBackground: 'gray1',
};

export default v1PaletteMapping;
