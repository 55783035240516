import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { color } from 'common/lib/theme/dynamic';
import isV2Theme from 'common/lib/theme/isV2Theme';

export default styled.div`
  color: ${isV2Theme(color.text, ({ theme }) => rgba(theme.color.textWhite, 0.7))};
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize.small};

  padding: ${({ theme }) => theme.spacing.small};
  transition: ${({ theme }) => theme.transition.default};
  margin-bottom: ${({ theme }) => theme.spacing.xxsmall};
  border-radius: ${({ theme }) => theme.radius.small};

  :hover {
    ${isV2Theme(
      undefined,
      css`
        color: ${color.textWhite};
      `,
    )}
    background-color: ${isV2Theme(color.gray4, color.navyLight)};
  }
  :active {
    background-color: ${isV2Theme(color.gray5, color.navyDark)};
  }
`;
