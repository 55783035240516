import React from 'react';

import LinkAccountCheckDuplicatesModal from 'components/accounts/LinkAccountCheckDuplicatesModal';
import type {
  Props as LinkAccountDataProviderModalProps,
  LinkAccountSuccessMetadata,
} from 'components/accounts/LinkAccountDataProviderModal';
import LinkAccountSearchInstitutionsModal from 'components/accounts/LinkAccountSearchInstitutionsModal';
import Stack from 'components/utils/Stack';

import type { LinkInstitutionFields } from 'common/generated/graphQlTypes/LinkInstitutionFields';
import type { Web_GetTopInstitutionGroups_topInstitutionGroups } from 'common/generated/graphQlTypes/Web_GetTopInstitutionGroups';

type LinkInstitutionFlowProps = Omit<LinkAccountDataProviderModalProps, 'goBack' | 'next'> & {
  next: (metadata: LinkAccountSuccessMetadata) => void;
  goBack?: () => void;
};

/** Sub flow to link a specific institution. This can be used separately from LinkAccountFlow. */
export const LinkInstitutionFlow = ({
  next,
  goBack,
  ...initialProps
}: LinkInstitutionFlowProps) => (
  <Stack
    initial={{
      component: LinkAccountCheckDuplicatesModal,
      props: initialProps,
    }}
    onComplete={next}
    onPopTop={goBack}
  />
);

type Props = {
  /** If this is non-null it will skip the first "Select institution" step and jump straight to either Finicity Connect or Plaid Link */
  institution?: LinkInstitutionFields;
  allowManualAccount?: boolean;
  /** if this is non-null it will skip and show institutions of the selectedTopGroup only */
  selectedTopGroup?: Web_GetTopInstitutionGroups_topInstitutionGroups;
  onComplete: (metadata: LinkAccountSuccessMetadata) => void;
  onClose: () => void;
};

const LinkAccountFlow = ({
  institution,
  allowManualAccount,
  selectedTopGroup,
  onComplete,
  onClose,
}: Props) =>
  institution ? (
    <LinkInstitutionFlow institution={institution} next={onComplete} goBack={onClose} />
  ) : (
    <Stack
      initial={{
        component: LinkAccountSearchInstitutionsModal,
        props: { allowManualAccount, selectedTopGroup },
      }}
      onComplete={onComplete}
      onPopTop={onClose}
    />
  );

export default LinkAccountFlow;
