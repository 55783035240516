import { rgba } from 'polished';
import { isNotNil } from 'ramda-adjunct';
import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import Icon from 'components/lib/ui/Icon';
import type { AbstractButtonProps } from 'components/lib/ui/button/AbstractButton';
import AbstractButton from 'components/lib/ui/button/AbstractButton';

import boxShadow from 'common/lib/styles/boxShadow';
import { xor } from 'common/utils/Logic';
import type { ButtonSize } from 'lib/styles/buttonSizeMixin';

import type { Color } from 'types/Styles';

const ICON_SIZE: { [size in ButtonSize]: number } = {
  xsmall: 16,
  small: 16,
  medium: 20,
  large: 24,
};

export const BUTTON_SIZE: { [size in ButtonSize]: number } = {
  xsmall: 32,
  small: 36,
  medium: 40,
  large: 48,
};

const Root = styled(AbstractButton).attrs<{ active?: boolean }>(({ active, ...props }) => ({
  ...props,
  className: active ? 'active' : undefined,
}))<{ $color?: Color; hoverColor?: Color; $size: number; $darkBg: boolean; $bordered?: boolean }>`
  transition: ${({ theme }) => theme.transition.default};
  color: ${({ theme, $darkBg, $color }) =>
    $darkBg ? rgba(theme.color[$color ?? 'textWhite'], 0.7) : theme.color[$color ?? 'text']};
  background-color: transparent;
  padding: 0;
  height: ${({ $size }) => $size}px;
  width: ${({ $size }) => $size}px;
  position: relative;
  transform: scale(1); /* create stacking context */
  border-radius: ${({ theme }) => theme.radius.round};

  ::before {
    z-index: -1; /* stylelint-disable-line plugin/no-z-index */
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: ${({ theme }) => theme.radius.pill};
    background: ${({ theme, $darkBg }) => ($darkBg ? theme.color.navyLight : theme.color.white)};
    border: 1px solid
      ${({ theme, $darkBg }) => ($darkBg ? theme.color.navyDark : theme.color.grayLight)};
    ${boxShadow.small}
    transition: ${({ theme }) => theme.transition.default};
    opacity: 0;

    ${({ $bordered }) =>
      $bordered &&
      css`
        opacity: 1;
        box-shadow: none;
      `}
  }

  :hover,
  :focus {
    color: ${({ theme, $darkBg, $color }) =>
      $darkBg ? theme.color[$color ?? 'textWhite'] : theme.color[$color ?? 'text']};
    ::before {
      opacity: 1;
      ${boxShadow.small}
    }
  }
  :active,
  &.active {
    color: ${({ theme, $darkBg, $color }) =>
      $darkBg ? theme.color[$color ?? 'textWhite'] : theme.color[$color ?? 'text']};
    ::before {
      opacity: 1;
      background: ${({ theme, $darkBg }) =>
        $darkBg ? theme.color.navyDark : theme.color.grayBackground};
      ${boxShadow.inset}
    }
  }
`;

const StyledIcon = styled(Icon)<{ $size: number }>`
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
`;

type IconOnlyProps = {
  /** Icon name */
  icon: string;
  /** Icon color */
  color?: Color;
  /** Icon hover color */
  hoverColor?: Color;
  /** Icon size */
  iconSize?: number;
  /** Icon component to render instead of the default icon */
  iconComponent?: never;
};

type IconComponentOnlyProps = {
  iconComponent: React.ReactNode;
  icon?: never;
  color?: never;
  hoverColor?: never;
  iconSize?: never;
};

type Props = AbstractButtonProps &
  (IconOnlyProps | IconComponentOnlyProps) & {
    /** Always show circle border. Defaults to only show on hover. */
    bordered?: boolean;
    /** Adds 'active' class to simulate forcing :active */
    active?: boolean;
    /** For use on dark backgrounds. Will change the hover background/border color. */
    darkBg?: boolean;
    size?: ButtonSize;
  };

const IconButton = ({
  icon,
  color,
  size = 'small',
  bordered,
  active,
  darkBg = false,
  children,
  iconSize,
  iconComponent,
  ...props
}: Props) => {
  const renderIcon = useCallback(() => {
    if (!xor(isNotNil(icon), isNotNil(iconComponent))) {
      throw new Error('Must provide either icon or iconComponent');
    }

    if (iconComponent) {
      return iconComponent;
    }

    if (isNotNil(icon)) {
      return <StyledIcon name={icon} $size={iconSize ?? ICON_SIZE[size]} />;
    }

    return null;
  }, [icon, iconComponent, iconSize, size]);

  return (
    <Root
      {...props}
      $size={BUTTON_SIZE[size]}
      $darkBg={darkBg}
      $color={color}
      $bordered={bordered}
      active={active}
    >
      {renderIcon()}
      {children}
    </Root>
  );
};

export default IconButton;
