import { createSelector } from 'reselect';

import type { RootState } from 'state/types';

const getUiState = ({ ui }: RootState) => ui;

export const getIsSidebarExpanded = createSelector(
  getUiState,
  ({ sidebarExpanded }) => sidebarExpanded,
);

export const getIsSideDrawerVisible = createSelector(
  getUiState,
  ({ isSideDrawerVisible }) => isSideDrawerVisible,
);

export const getIsDemoHouseholdModalVisible = createSelector(
  getUiState,
  ({ isDemoHouseholdModalVisible }) => isDemoHouseholdModalVisible,
);

export const getIsGlobalSearchModalVisible = createSelector(
  getUiState,
  ({ isGlobalSearchModalVisible }) => isGlobalSearchModalVisible,
);
