import { gql } from '@apollo/client';

import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';

import { gql as newGql } from 'common/generated/gql';

export const UPDATE_MERCHANT_RECURRING = gql`
  mutation Common_RecurringUpdateMerchant($input: UpdateMerchantInput!) {
    updateMerchant(input: $input) {
      merchant {
        id
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export const UPDATE_LIABILITY_STATEMENT = newGql(/* GraphQL */ `
  mutation Common_RecurringUpdateLiabilityStatement(
    $creditReportLiabilityStatementId: ID!
    $userBillAmount: Decimal
    $revertToOriginal: Boolean
  ) {
    updateBillAmount(
      creditReportLiabilityStatementId: $creditReportLiabilityStatementId
      userBillAmount: $userBillAmount
      revertToOriginal: $revertToOriginal
    ) {
      creditReportLiabilityStatement {
        id
        billAmount
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

export const UPDATE_LIABILITY_STATEMENT_PAYMENT_STATUS = newGql(/* GraphQL */ `
  mutation Common_MarkRecurringLiabilityStatementAsPaid(
    $creditReportLiabilityStatementId: ID!
    $isPaid: Boolean!
  ) {
    updateBillPaid(
      creditReportLiabilityStatementId: $creditReportLiabilityStatementId
      isPaid: $isPaid
    ) {
      creditReportLiabilityStatement {
        id
        billAmount
        userMarkedPaidAt
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);
