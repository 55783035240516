import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import CurrencyInput from 'components/lib/form/CurrencyInput';
import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import CashFlowCurrency from 'components/lib/ui/currency/CashFlowCurrency';
import { OverlayTrigger } from 'components/lib/ui/popover';
import BudgetHistoricalChartPopover from 'components/lib/ui/popover/BudgetHistoricalChartPopover';
import { INPUT_HEIGHT_PX } from 'components/plan/PlanGrid';

import useKey from 'lib/hooks/useKey';

const INPUT_WIDTH_PX = 140;

const AmountInput = styled(CurrencyInput)<{ $height?: number }>`
  width: ${INPUT_WIDTH_PX}px;
  height: ${({ $height = INPUT_HEIGHT_PX }) => $height}px;
  text-align: right;
  background: none;
`;

const StyledCashFlowCurrency = styled(CashFlowCurrency)`
  padding-right: 13px; /* Compensate for the margin-right + border on the input */
`;

type Props = {
  itemId?: GraphQlUUID;
  value?: Maybe<number>;
  isGroup?: boolean;
  canEdit?: boolean;
  disabled?: boolean;
  height?: number;
  isIncome?: boolean;
  onChange?: (value: Maybe<number>) => void;
  onBlur?: () => void;
};

const OnboardingAmountCell = ({
  itemId,
  value,
  canEdit,
  isGroup,
  disabled,
  height,
  isIncome,
  onChange,
  onBlur: onBlurProp,
}: Props) => {
  const inputRef = useRef<HTMLElement>(null);
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    // Ensure that we always default to outside state when it changes
    if (localValue !== value) {
      setLocalValue(value);
    }
  }, [value]);

  const onClickChartAmount = useCallback(
    (amount: number) => {
      setLocalValue(amount);
      setTimeout(() => inputRef.current?.blur(), 0); // Need setTimeout so localValue updates before blur
      onChange?.(amount);
    },
    [inputRef, onChange],
  );

  const onBlur = useCallback(() => {
    if (localValue !== value) {
      onChange?.(localValue);
    }

    onBlurProp?.();
  }, [value, localValue]);

  const onSubmit = useCallback(() => {
    inputRef.current?.blur();
  }, [inputRef]);

  useKey('Escape', inputRef.current, { onKeyDown: onSubmit });
  useKey('Enter', inputRef.current, { onKeyDown: onSubmit });
  useKey('Tab', inputRef.current, { onKeyDown: onSubmit });

  if (!canEdit) {
    return <StyledCashFlowCurrency bold={!!isGroup} round type="income" value={localValue ?? 0} />;
  }

  return (
    <>
      <OverlayTrigger
        overlay={
          !!itemId && (
            <BudgetHistoricalChartPopover
              itemId={itemId}
              isCategoryGroup={isGroup}
              onClickBar={onClickChartAmount}
              isIncome={isIncome}
            />
          )
        }
        placement="bottom-end"
      >
        {({ open, close }) => (
          <AmountInput
            ref={inputRef}
            tabIndex={0}
            name="budgeted"
            selectOnFocus
            disabled={disabled}
            value={localValue ?? undefined}
            placeholder="$0"
            onChange={setLocalValue}
            onBlur={() => {
              onBlur();
              close();
            }}
            onFocus={() => {
              open();
            }}
            autoComplete="off"
            maskOptions={{ allowNegative: true }}
            {...sensitiveClassProps}
            bold={!!isGroup}
            $height={height}
          />
        )}
      </OverlayTrigger>
    </>
  );
};

export default OnboardingAmountCell;
