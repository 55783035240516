import { debounce } from 'lodash';
import type { DateTime } from 'luxon';
import * as R from 'ramda';
import { useMemo } from 'react';

import { GET_BUDGET_STATUS_QUERY } from 'common/lib/graphQl/budgets';
import { GET_JOINT_PLANNING_DATA } from 'common/lib/graphQl/planning';
import useUpdateBudgetItemMutation from 'common/lib/hooks/budget/useUpdateBudgetItemMutation';
import useUpdateFlexBudgetMutation from 'common/lib/hooks/budget/useUpdateFlexBudgetMutation';
import useUpdateGoalV2PlannedContribution from 'common/lib/hooks/goalsV2/useUpdateGoalV2PlannedContribution';
import useDebounce from 'common/lib/hooks/useDebounce';
import { useMockedQueryData } from 'common/lib/hooks/useMockableQuery';
import useQuery from 'common/lib/hooks/useQuery';
import type { PlanState } from 'lib/hooks/plan/usePlanState';
import { PlanTimeframe } from 'lib/hooks/plan/usePlanState';
import { usePlanningData } from 'lib/hooks/planning/usePlanningData';
import type { PlanSectionType } from 'lib/plan/Adapters';

import { BudgetVariability } from 'common/generated/graphql';

const REFETCH_PLANNING_DATA_DEBOUNCE_MS = 1000;

/**
 * This hook handles all of the data fetching for the Plan page.
 */
const usePlanQuery = (state: PlanState, debounceTime = REFETCH_PLANNING_DATA_DEBOUNCE_MS) => {
  const { columnDisplayInterval, timeframe, doesNotHaveAccounts } = state;

  const { fetchedDateRange, data, initialQueryVariables, refetch } = usePlanningData(
    columnDisplayInterval,
    timeframe !== PlanTimeframe.Yearly,
  );

  // Debounce refetch to prevent multiple requests from being made in quick succession
  const debounceRefetch = debounce(refetch, debounceTime);

  const onUpdateBudgetItemAmount = () => {
    debounceRefetch.cancel();
    debounceRefetch();
  };

  const { updateBudgetItemAmount } = useUpdateBudgetItemMutation({
    onCompleted: onUpdateBudgetItemAmount,
  });

  const { updateGoalV2PlannedContribution } = useUpdateGoalV2PlannedContribution({
    onCompleted: () => refetch(),
  });

  const { updateFlexBudgetMutation } = useUpdateFlexBudgetMutation({
    onCompleted: () => refetch(),
  });

  const updateCellValue = useMemo(
    () =>
      R.memoizeWith(
        (id, date) => `${id}-${date.toISODate()}`,
        (itemId: string, date: DateTime, sectionType: PlanSectionType, isGroup?: boolean) =>
          (amount: number, applyToFuture: boolean) => {
            if (sectionType === 'savings') {
              updateGoalV2PlannedContribution(date, itemId, amount);
            } else if (itemId === BudgetVariability.FLEXIBLE) {
              updateFlexBudgetMutation(date)(amount, applyToFuture);
            } else if (isGroup) {
              updateBudgetItemAmount(date, undefined, itemId)(amount, applyToFuture);
            } else {
              updateBudgetItemAmount(date, itemId)(amount, applyToFuture);
            }
          },
      ),
    [updateBudgetItemAmount, updateFlexBudgetMutation, updateGoalV2PlannedContribution],
  );

  const { data: budgetStatusData, refetch: refetchBudgetStatus } =
    useQuery(GET_BUDGET_STATUS_QUERY);

  const { budgetStatus } = budgetStatusData || {};
  const doesNotHaveBudget = budgetStatus?.hasBudget === false;
  const showMockData = doesNotHaveAccounts || doesNotHaveBudget;

  const [mockData] = useMockedQueryData(GET_JOINT_PLANNING_DATA, {
    variables: initialQueryVariables,
    skip: !showMockData,
  });

  // cache planning data for {debounceTime} until user stops changing it
  const debouncedData = useDebounce(showMockData ? mockData ?? undefined : data, debounceTime);

  return {
    data: debouncedData,
    doesNotHaveBudget,
    budgetStatus,
    refetchBudgetStatus,
    fetchedDateRange: showMockData ? columnDisplayInterval : fetchedDateRange,
    refetch,
    updateCellValue,
  };
};

export default usePlanQuery;
