import { gql } from '@apollo/client';
import * as RA from 'ramda-adjunct';
import React from 'react';
import styled from 'styled-components';

import ReconnectCredentialButton from 'components/accounts/ReconnectCredentialButton';
import Card from 'components/lib/ui/Card';
import FlexContainer from 'components/lib/ui/FlexContainer';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import InstitutionInfo from 'components/settings/institutions/InstitutionInfo';
import InstitutionMenu from 'components/settings/institutions/InstitutionMenu';

import type { Web_GetInstitutionSettingsQuery } from 'common/generated/graphql';

const Root = styled(Card)``;

const Header = styled(FlexContainer).attrs({ alignCenter: true })`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const ActionsWrapper = styled.div`
  margin-left: auto;
  display: flex;
`;

const RefreshButton = styled(PrimaryButton)`
  margin-left: ${({ theme }) => theme.spacing.default};
`;

type Props = {
  credential: Web_GetInstitutionSettingsQuery['credentials'][0];
  children?: React.ReactNode;
  className?: string;
  refetch: () => void;
};

const CredentialSettingsCard = ({ credential, children, className, refetch }: Props) => {
  const isDisconnected = RA.isNotNil(credential.disconnectedFromDataProviderAt);
  const isUpdateRequired = credential.updateRequired;
  return (
    <Root key={credential.id} className={className}>
      <Header>
        <InstitutionInfo credential={credential} />
        <ActionsWrapper>
          <InstitutionMenu credential={credential} refetch={refetch} />
          {isUpdateRequired && !isDisconnected && (
            <ReconnectCredentialButton
              institution={{
                name: credential?.institution.name,
                id: credential?.institution.id,
              }}
              credential={credential}
              renderContent={(onClick) => <RefreshButton onClick={onClick}>Update</RefreshButton>}
            />
          )}
        </ActionsWrapper>
      </Header>
      {children}
    </Root>
  );
};

CredentialSettingsCard.fragments = {
  CredentialSettingsCardFields: gql`
    fragment CredentialSettingsCardFields on Credential {
      id
      updateRequired
      disconnectedFromDataProviderAt
      ...InstitutionInfoFields

      institution {
        id
        name
        logo
        url
      }
    }
    ${InstitutionInfo.fragments.InstitutionInfoFields}
  `,
};

export default CredentialSettingsCard;
