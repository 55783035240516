import React, { useState } from 'react';
import { BarChart, Bar, XAxis, ReferenceLine, Cell } from 'recharts';

import GrabberReferenceLineLabel from 'components/lib/charts/GrabberReferenceLineLabel';

import { formatCurrencyMaybeThousands, formatCurrencyNoCents } from 'common/utils/Currency';
import useTheme from 'lib/hooks/useTheme';

const BAR_GAP_PX = 8;
const MARGIN_TOP_PX = 20;
const BAR_MIN_HEIGHT_PX = 6;
const GRABBER_WIDTH_PX = 52;
const GRABBER_HEIGHT_PX = 24;
const MARGIN_LEFT_PX = GRABBER_WIDTH_PX + 27;
const XAXIS_OFFSET_PX = 8;

// eslint-disable-next-line
type Props<YAxisT, XAxisT, DataT extends object> = {
  yAxisDataKey: YAxisT;
  xAxisDataKey: XAxisT;
  data: readonly DataT[];
  referenceLineY: number;

  width: number;
  height: number;
  grabberColor?: string;
  grabberTextColor?: string;
  barColor?: string;
  barHighlightColor?: string;

  onClickBar?: (data: DataT) => void;
  onClickGrabber?: () => void;
};

const BarChartWithGrabberLine = <
  YAxisT extends string,
  XAxisT extends string,
  DataT extends { [x in XAxisT]: string } & { [y in YAxisT]: number },
>(
  props: Props<YAxisT, XAxisT, DataT>,
) => {
  const theme = useTheme();

  const {
    xAxisDataKey,
    yAxisDataKey,
    data,
    referenceLineY,
    width,
    height,
    grabberColor = theme.color.orange,
    grabberTextColor = theme.color.white,
    barColor = theme.color.orangeFocus,
    barHighlightColor = theme.color.orange,
    onClickBar,
    onClickGrabber,
  } = props;

  const [hoveredBarIndex, setHoveredBarIndex] = useState<number | null>(null);

  return (
    <BarChart
      width={width}
      height={height}
      data={data}
      barCategoryGap={BAR_GAP_PX}
      margin={{
        top: MARGIN_TOP_PX,
        left: MARGIN_LEFT_PX,
        right: BAR_GAP_PX,
      }}
    >
      <XAxis
        dataKey={xAxisDataKey}
        tick={{ fontSize: theme.fontSize.xsmall, fill: theme.color.textLight }}
        axisLine={false}
        tickLine={false}
        dy={XAXIS_OFFSET_PX}
      />

      <Bar
        minPointSize={BAR_MIN_HEIGHT_PX}
        isAnimationActive={false}
        dataKey={yAxisDataKey}
        radius={[0, 0, 0, 0]}
        cursor="pointer"
        onMouseEnter={(_, index) => setHoveredBarIndex(index)}
        onMouseLeave={() => setHoveredBarIndex(null)}
        label={{
          formatter: (value) =>
            formatCurrencyMaybeThousands(value as DataT[typeof yAxisDataKey]).toUpperCase(),
          fontSize: theme.fontSize.xsmall,
          fontWeight: 'bold',
          fill: theme.color.text,
          position: 'top',
          offset: parseInt(theme.spacing.xsmall),
        }}
      >
        {data.map((item, index) => (
          <Cell
            key={`cell-${index}`}
            onClick={() => onClickBar?.(item)}
            fill={hoveredBarIndex === index ? barHighlightColor : barColor}
          />
        ))}
      </Bar>
      <ReferenceLine
        y={referenceLineY}
        position="start"
        stroke={0}
        label={(props) => (
          <GrabberReferenceLineLabel
            {...props}
            onClick={onClickGrabber}
            fill={grabberColor}
            textFill={grabberTextColor}
            grabberWidth={GRABBER_WIDTH_PX}
            grabberHeight={GRABBER_HEIGHT_PX}
            chartMarginLeft={MARGIN_LEFT_PX}
          >
            {formatCurrencyNoCents(referenceLineY)}
          </GrabberReferenceLineLabel>
        )}
      />
    </BarChart>
  );
};

export default BarChartWithGrabberLine;
