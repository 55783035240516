import { color, ifDark, ifTheme, withOpacity } from 'common/lib/theme/variables/types';

const SHADOW_COLOR = ifTheme({
  v1: color('black', 'trueBlack'),
  v2: color('gray12', 'trueBlack'),
});

const _color = {
  background: {
    base: ifTheme({
      v1: color('grayBackground'),
      v2: color('gray3', 'gray1'),
    }),
    card: ifTheme({
      v1: color('white'),
      v2: color('gray1', 'gray3'),
    }),
    sidebar: ifTheme({
      v1: color('navy'),
      v2: color('gray3', 'gray1'),
    }),
    header: ifTheme({
      v1: color('white'),
      v2: color('gray3', 'gray1'),
    }),
    fullscreenHeader: ifTheme({
      v1: color('white'),
      v2: color('gray1'),
    }),
    tooltip: ifTheme({
      v1: color('black', 'trueBlack'),
      v2: color('gray12', 'gray1'),
    }),
    modalOverlay: withOpacity(SHADOW_COLOR, ifDark(0.5, 0.4)),
  },
  border: {
    base: ifTheme({
      v1: color('grayBackground'),
      v2: color('gray3', 'gray5'),
    }),
  },
  shadow: {
    small: withOpacity(SHADOW_COLOR, 0.1),
    medium: withOpacity(SHADOW_COLOR, 0.08),
    inset: withOpacity(SHADOW_COLOR, 0.1),
    large: withOpacity(SHADOW_COLOR, 0.1),
    card: withOpacity(SHADOW_COLOR, ifDark(0.2, 0.04)),
  },
};

export default _color;
