// Declare standardized event titles to pass to Segment
export const APPLICATION_BACKGROUNDED = 'Application Backgrounded';
export const VISUAL_APPEARANCE_PREFERENCE_UPDATED = 'Visual Appearance Preference Updated';
export const TRANSACTION_ROW_SWIPED = 'Transaction Row Swiped';
export const DOWNLOAD_TRANSACTIONS_CSV_BUTTON_CLICKED = 'Download Transactions CSV Button Clicked';

export enum PLAID_EVENT_NAMES {
  CLOSE_OAUTH = 'Plaid Link Oauth Closed',
  ERROR = 'Plaid Link Errored',
  EXIT = 'Plaid Link Exited',
  FAIL_OAUTH = 'Plaid Link Oauth Failed',
  HANDOFF = 'Plaid Link Handed Off',
  OPEN = 'Plaid Link Opened',
  OPEN_OAUTH = 'Plaid Link Oauth Opened',
  SEARCH_INSTITUTION = 'Plaid Link Institutions Searched',
  SELECT_INSTITUTION = 'Plaid Link Institution Selected',
  SELECT_INSTITUTION_ERROR = 'Plaid Link Institution Selected Error',
  SUBMIT_CREDENTIALS = 'Plaid Link Credentials Submitted',
  SUBMIT_MFA = 'Plaid Link MFA Submitted',
  TRANSITION_VIEW = 'Plaid Link View Transitioned',
}

// Event names as we get them directly from Plaid
export enum PLAID_EVENT_NAMES_RAW {
  CLOSE_OAUTH = 'CLOSE_OAUTH',
  ERROR = 'ERROR',
  EXIT = 'EXIT',
  FAIL_OAUTH = 'FAIL_OAUTH',
  HANDOFF = 'HANDOFF',
  OPEN = 'OPEN',
  OPEN_OAUTH = 'OPEN_OAUTH',
  SEARCH_INSTITUTION = 'SEARCH_INSTITUTION',
  SELECT_INSTITUTION = 'SELECT_INSTITUTION',
  SUBMIT_CREDENTIALS = 'SUBMIT_CREDENTIALS',
  SUBMIT_MFA = 'SUBMIT_MFA',
  TRANSITION_VIEW = 'TRANSITION_VIEW',
}

export type PlaidEventName = keyof typeof PLAID_EVENT_NAMES;

export enum ConnectEventNames {
  ConnectInstitutionSelected = 'Connect Institution Selected',
  ConnectByDataProviderSelected = 'Connect By Data Provider Selected',
  FirstCredentialMobile = 'First Credential Mobile',
  FallbackDataProviderOffered = 'Fallback Data Provider Offered',
}

export enum NavigationEventNames {
  NavigationEventEnriched = 'Navigation Event Enriched',
}

export enum PurchaseEventNames {
  PurchaseOptionsLoaded = 'Purchase Options Loaded',
  PurchaseInitiated = 'Purchase Initiated',
  PurchaseCancelled = 'Purchase Cancelled',
  PurchaseErrored = 'Purchase Errored',
  PurchaseCompleted = 'Purchase Completed',
  // This is to help debug a disappearing "Purchase Completed" event on Singular
  // We have a hypothesis that one of the purchase args breaks the logging
  PurchaseCompletedNoArgs = 'Purchase Completed No Args',
  PurchasePollingTimeoutReached = 'Purchase Polling Timeout Reached',
  PurchaseRestored = 'Purchase Restored',
  PurchaseRestoreError = 'Purchase Restore Error',
  PurchaseUpsellAnnualButtonClicked = 'Purchase Upsell Annual Button Clicked',
}

export enum FinicityEventNames {
  Complete = 'Finicity Connect Complete',
  Opened = 'Finicity Connect Opened',
  Errored = 'Finicity Connect Errored',
  Event = 'Finicity Connect Event',
}

export enum CoinbaseEventNames {
  Opened = 'Coinbase Connect Opened',
}

export enum RequestReviewEventNames {
  Requested = 'App Review Requested',
  Responded = 'App Review Responded',
}

export enum GuestPassEventNames {
  Shared = 'Guest Pass Shared',
}

export enum ReferralsEventNames {
  Shared = 'Referral Code Shared',
  ButtonClicked = 'Referral Button Clicked',
}

export enum ReviewEventNames {
  Opened = 'Review Opened',
  Exited = 'Review Exited',
  DetailDrawerOpened = 'Review Detail Drawer Opened',
  Completed = 'Review Completed',
  ScreenViewed = 'Review Screen Viewed',
  StatsShared = 'Review Stats Shared',
  YearlyReviewDismissed = 'Yearly Review Dismissed',
}

export enum ReviewOpenSource {
  Dashboard = 'Dashboard',
  PushNotification = 'Push Notification',
  NotificationCenter = 'Notification Center',
}

export enum InvestmentsEventNames {
  AccountMenuInteracted = 'Investment Account Menu Interacted',
  AllocationChartViewed = 'Investment Allocation Chart Viewed',
  PerformanceChartDateRangeChange = 'Performance Chart Date Range Change',
}

export enum MXConnectEventNames {
  Loaded = 'MX Connect Loaded',
  CredentialsEntered = 'MX Connect Credentials Entered',
  InstitutionsSearched = 'MX Connect Institutions Searched',
  InstitutionSelected = 'MX Connect Institution Selected',
  MemberConnected = 'MX Connect Member Connected',
  MemberConnectedPrimaryAction = 'MX Connect Member Connected Primary Action',
  MemberDeleted = 'MX Connect Member Deleted',
  Error = 'MX Connect Error',
  MemberStatusUpdate = 'MX Connect Member Status Update',
  OauthRequested = 'MX Connect OAuth Requested',
  StepChange = 'MX Connect Step Change',
  MFASubmitted = 'MX Connect MFA Submitted',
  CredentialsUpdated = 'MX Connect Credentials Updated',
}

export enum OnboardingEventNames {
  SignedUpAndVerifiedMobile = 'User Signed Up And Verified Mobile',
  VerifiedMobile = 'User Verified Mobile',
}

export enum PremiumEventNames {
  PremiumPopoverViewed = 'Premium Popover Viewed',
  PremiumSkipped = 'Premium Skipped',
  PaymentScreenViewed = 'Payment Screen Viewed',
  PremiumScreenViewed = 'Premium Screen Viewed',
  PremiumExpiredScreenViewed = 'Premium Expired Screen Viewed',
}

export enum WidgetEventNames {
  IosWidgetAdded = 'iOS Widget Added',
  IosWidgetRemoved = 'iOS Widget Removed',
}

export enum CancelFlowEventNames {
  CancelFlowStarted = 'Cancel Flow Started',
  CancelReasonSelected = 'Cancel Reason Selected',
  CancelContactSupportOffered = 'Cancel Contact Support Offered',
  CancelContactSupportAccepted = 'Cancel Contact Support Accepted',
  CancelDiscountOffered = 'Cancel Discount Offered',
  CancelDiscountAccepted = 'Cancel Discount Accepted',
  CancelFlowCompleted = 'Cancel Flow Completed',
}

export enum RapidReviewEventNames {
  Opened = 'Rapid Review Opened',
  Completed = 'Rapid Review Completed',
  TransactionSkipped = 'Rapid Review Transaction Skipped',
  TransactionReviewed = 'Rapid Review Transaction Reviewed',
  Undo = 'Rapid Review Undo',
  BannerViewed = 'Rapid Review Banner Viewed',
}

export enum BillTrackingEventNames {
  SpinwheelAuthStarted = 'Spinwheel Auth Started',
  SpinwheelAuthFinished = 'Spinwheel Auth Finished',
  SpinwheelAuthErrored = 'Spinwheel Auth Errored',

  CreditReportMappingStarted = 'Credit Report Mapping Started',
}

export enum WhatsNewEventNames {
  WhatsNewClicked = 'Whats New Clicked',
  WhatsNewDismissed = 'Whats New Dismissed',
}

export enum AccountEventNames {
  CopyBalanceHistoryFormOpened = 'Copy Balance History Form Opened',
  DownloadBalancesCsvButtonClicked = 'Download Balances CSV Button Clicked',
  EditBalancesIndividualDatapointClicked = 'Edit Balances Individual Datapoint Clicked',
  EditBalancesButtonClicked = 'Edit Balances Button Clicked',
  EditBalancesFormOpened = 'Edit Balances Form Opened',
  EditBalancesSaved = 'Edit Balances Saved',
}

// General group for miscellaneous event names
export enum AnalyticsEventNames {
  PushNotificationOpened = 'Push Notification Opened',
  DemoStarted = 'Demo Started', // User clicked "Try a demo" in onboarding
  FeatureFlagImpression = 'Feature Flag Impression',
  PayWithWalletButtonViewed = 'Pay With Wallet Button Viewed',
  PayWithWalletCanceled = 'Pay With Wallet Canceled',
  PayWithWalletPaymentMethodSelected = 'Pay With Wallet Payment Method Selected',

  // Support
  SupportTicketCreated = 'Support Ticket Created',
  SupportTicketFormViewed = 'Support Ticket Form Opened',
  SupportChatModalViewed = 'Support Chat Modal Viewed',
  RecommendedResolutionsViewed = 'Recommended Resolutions Viewed',
  RecommendedResolutionSelected = 'Recommended Resolution Selected',
  RefundRequestCreated = 'Refund Request Created',
  RefundRequestFormOpened = 'Refund Request Form Opened',

  // Credential
  CredentialRefreshAllRequested = 'Credential Refresh All Requested',

  // Preferences
  MobileNavigationCustomized = 'Mobile Navigation Customized',
  LocalAuthenticationSettingUpdated = 'Local Authentication Setting Updated',

  // Used for FB events.
  AddPaymentInfo = 'AddPaymentInfo',
  CompleteRegistration = 'CompleteRegistration',

  // Assistant
  AssistantMicrophoneStarted = 'Assistant Microphone Started',
  AssistantMicrophoneCompleted = 'Assistant Microphone Completed',

  // Enrichment
  CustomMerchantEnrichmentCreated = 'Custom Merchant Enrichment Created',
  RecommendedMerchantEnrichmentSelected = 'Recommended Merchant Enrichment Selected',
  ExistingMerchantSelected = 'Existing Merchant Selected',

  // Recommended Categories
  RecommendedCategorySelected = 'Recommended Category Selected',
  NonRecommendedCategorySelected = 'Non Recommended Category Selected',
  CategoryCreatedWhileSelecting = 'Category Created While Selecting',
}

export type AnalyticsEvent =
  | PLAID_EVENT_NAMES
  | NavigationEventNames
  | PurchaseEventNames
  | FinicityEventNames
  | RequestReviewEventNames
  | GuestPassEventNames
  | ReferralsEventNames
  | ReviewEventNames
  | CoinbaseEventNames
  | InvestmentsEventNames
  | ConnectEventNames
  | MXConnectEventNames
  | OnboardingEventNames
  | PremiumEventNames
  | WidgetEventNames
  | CancelFlowEventNames
  | RapidReviewEventNames
  | BillTrackingEventNames
  | AccountEventNames
  | AnalyticsEventNames
  | WhatsNewEventNames
  | typeof APPLICATION_BACKGROUNDED
  | typeof VISUAL_APPEARANCE_PREFERENCE_UPDATED
  | typeof TRANSACTION_ROW_SWIPED
  | typeof DOWNLOAD_TRANSACTIONS_CSV_BUTTON_CLICKED;
