import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Confetti from 'components/lib/ui/Confetti';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Link from 'components/lib/ui/Link';
import Text from 'components/lib/ui/Text';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import { HELP_CENTER_GETTING_STARTED_WITH_BILL_SYNCING } from 'common/constants/externalUrls';
import routes from 'constants/routes';

export const IGNORE_ACCOUNT_VALUE = 'ignore';

const Root = styled(FlexContainer).attrs({ column: true, alignCenter: true })`
  max-width: 765px;
  width: 100%;
`;

const Title = styled(Text).attrs({ size: 'xlarge', weight: 'medium' })`
  margin-top: ${({ theme }) => theme.spacing.xxlarge};
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const FinishButton = styled(PrimaryButton)`
  width: 90%;
  margin: ${({ theme }) => theme.spacing.small} auto;
`;

const EducationContainer = styled.div`
  width: 80%;
  margin: 0 auto;
`;

const EducationTitle = styled(Text).attrs({ size: 'large', weight: 'medium' })`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.small};
  margin-top: ${({ theme }) => theme.spacing.xlarge};
`;

const EducationImage = styled.img`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.default};
  margin-bottom: ${({ theme }) => theme.spacing.xxxlarge};
  border-radius: ${({ theme }) => theme.radius.medium};
`;

const MapSpinwheelLiabilitiesSuccess = () => {
  const history = useHistory();

  return (
    <Root>
      <Confetti portal />

      <Title>Your Monarch accounts have been linked!</Title>
      <Text align="center">
        Below are a few more things to know. Check out our{' '}
        <Link href={HELP_CENTER_GETTING_STARTED_WITH_BILL_SYNCING} target="_blank">
          help article
        </Link>{' '}
        at any time to learn more about bill syncing.
      </Text>

      <EducationContainer>
        <EducationTitle>New accounts section</EducationTitle>
        <Text size="base">
          You&apos;ll see your synced bills and recurring merchants in their own sections.
        </Text>

        <EducationImage src="https://monarch-static-assets.s3.amazonaws.com/walkthrough/new_account_sections.png" />

        <EducationTitle>New notifications</EducationTitle>
        <Text>
          You&apos;ll get notifications when we receive a new statement balance and when a due date
          is approaching for your synced bills.
        </Text>

        <EducationImage src="https://monarch-static-assets.s3.amazonaws.com/walkthrough/bill_tracking_notifications.png" />

        <EducationTitle>Not seeing your upcoming bills?</EducationTitle>

        <Text>
          We may have already received next month&apos;s statement for bills that have already passed this month. These won&apos;t show up for this month, but can be found by viewing the the following months or in the all recurring tab.
        </Text>

        <EducationImage src="https://monarch-static-assets.s3.amazonaws.com/walkthrough/all_recurring_tab.png" />

        <EducationTitle>Mark as paid</EducationTitle>

        <Text>
          Once you&apos;ve paid a bill in your accounts section, simply mark it as paid by using the
          checkmark or click the three dots to clear it from upcoming.
        </Text>

        <EducationImage src="https://monarch-static-assets.s3.amazonaws.com/walkthrough/mark_as_paid.png" />

        <EducationTitle>Review duplicative recurring</EducationTitle>

        <Text>
          You may now have some recurring merchants that are now duplicative with synced bills. You
          can remove these from either your accounts or merchants section, whichever you prefer.
        </Text>

        <EducationImage src="https://monarch-static-assets.s3.amazonaws.com/walkthrough/bill_tracking_duplicatives.png" />

        <FinishButton size="large" onClick={() => history.push(routes.recurring())}>
          Finish
        </FinishButton>
      </EducationContainer>
    </Root>
  );
};

export default MapSpinwheelLiabilitiesSuccess;
