import { getRemainingAmount } from 'common/lib/budget/Amounts';
import type { PlanAmounts as PlanAmountsImport } from 'common/lib/planning';
import { PlanTimeframe } from 'lib/hooks/plan/usePlanState';

import type { CategoryGroupType } from 'common/generated/graphql';

export type PlanAmountType = 'budgeted' | 'actual' | 'remaining';

// Convenience export
export type PlanAmounts = PlanAmountsImport;

export const SAVINGS_PLAN_SECTION_TYPE = 'savings';

export type PlanSectionType = CategoryGroupType | typeof SAVINGS_PLAN_SECTION_TYPE;

/**
 * Convert between amount type and user visible string name.
 */
export const getDisplayNameForAmountType = (amountType: PlanAmountType) =>
  ({
    budgeted: 'Budget',
    actual: 'Actual',
    remaining: 'Remaining',
  })[amountType];

/**
 * Calculate remaining amount if needed, otherwise return amount matching key.
 */
export const getAmountForType = (amounts: PlanAmounts | undefined, amountType: PlanAmountType) => {
  if (amountType === 'remaining') {
    return getRemainingAmount({
      actual: amounts?.actual,
      available: amounts?.remaining,
      budgeted: amounts?.budgeted,
    });
  } else {
    return amounts?.[amountType] ?? undefined;
  }
};

export const getSectionHasBottomPadding = (
  timeframe: PlanTimeframe,
  unplannedCount: number,
  isCollapsed: boolean,
  groupLevelBudgetingEnabled: boolean | null | undefined,
) =>
  timeframe === PlanTimeframe.SingleMonth &&
  unplannedCount <= 0 &&
  !isCollapsed &&
  !groupLevelBudgetingEnabled;
