import React from 'react';
import type { NavigateAction } from 'react-big-calendar';
import styled from 'styled-components';

import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import IconButton from 'components/lib/ui/button/IconButton';

const Root = styled.div`
  padding: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.xlarge};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
`;

const StyledButton = styled(DefaultButton)`
  margin-right: ${({ theme }) => theme.spacing.default};
`;

const ArrowIcon = styled(IconButton)`
  width: 18px;
  height: 18px;
  padding: ${({ theme }) => theme.spacing.default};
  margin-left: ${({ theme }) => theme.spacing.xsmall};
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
`;

type Props = {
  label: string;
  onNavigate: (action: NavigateAction) => void;
};

const Toolbar = ({ label, onNavigate }: Props) => (
  <Root>
    <Text size="large" weight="medium">
      {label}
    </Text>
    <Controls>
      <StyledButton onClick={() => onNavigate('TODAY')}>Today</StyledButton>
      <ArrowIcon icon="arrow-left" onClick={() => onNavigate('PREV')} />
      <ArrowIcon icon="arrow-right" onClick={() => onNavigate('NEXT')} />
    </Controls>
  </Root>
);

export default Toolbar;
