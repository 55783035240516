import { gql } from '@apollo/client';

import InstitutionRow from 'components/institutions/InstitutionRow';

import useQuery from 'common/lib/hooks/useQuery';
import useSupportedDataProviders from 'lib/hooks/institutions/useSupportedDataProviders';

import type {
  Web_GetTopInstitutionGroupByType,
  Web_GetTopInstitutionGroupByTypeVariables,
} from 'common/generated/graphQlTypes/Web_GetTopInstitutionGroupByType';

const useGetInstitutionGroup = (groupType: string) => {
  const dataProviders = useSupportedDataProviders();

  const { data: institutionGroup } = useQuery<
    Web_GetTopInstitutionGroupByType,
    Web_GetTopInstitutionGroupByTypeVariables
  >(QUERY_GROUPS, {
    variables: {
      dataProviders,
      topInstitutionGroupType: groupType,
    },
  });

  return institutionGroup;
};

const QUERY_GROUPS = gql`
  query Web_GetTopInstitutionGroupByType(
    $dataProviders: [String]!
    $topInstitutionGroupType: String!
  ) {
    credentials {
      id
    }
    topInstitutionGroupByType(
      dataProviders: $dataProviders
      topInstitutionGroupType: $topInstitutionGroupType
    ) {
      type
      title
      shortTitle
      accountsConnectedCount
      institutions {
        id
        ...InstitutionRowFields
      }
      manualAccountShortcuts {
        title
        accountType {
          name
        }
        accountSubtype {
          name
        }
      }
    }
  }
  ${InstitutionRow.fragments.InstitutionRowFields}
`;

export default useGetInstitutionGroup;
