import { DateTime } from 'luxon';
import * as R from 'ramda';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import BarChartWithGrabberLine from 'components/lib/charts/BarChartWithGrabberLine';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';

import useAggregatesGraphQuery from 'common/lib/hooks/budget/useAggregatesGraphQuery';
import useTheme from 'lib/hooks/useTheme';

const roundAmount = (amount: number) => Math.max(0, Math.round(amount));

const LoadingContainer = styled.div<{ width: number; height: number }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  width: number;
  height: number;
  id: string;
  isIncome?: boolean;
  onClickAmount?: (amount: number) => void;
  isGroup?: boolean;
};

const HistoricalAmountsBarChart = ({
  width,
  height,
  id,
  isIncome = false,
  onClickAmount,
  isGroup = false,
}: Props) => {
  const theme = useTheme();

  const startDate = DateTime.local().minus({ months: 3 }).startOf('month');
  const endDate = DateTime.local().minus({ month: 1 }).endOf('month');
  const [data, { loading }] = useAggregatesGraphQuery(id, startDate, endDate, isGroup);
  const graphData = useMemo(
    () =>
      data.map(
        R.evolve({
          sum: isIncome ? R.identity : R.negate,
          month: (date) => DateTime.fromISO(date).toFormat('MMM'),
        }),
      ),
    [data, isIncome],
  );
  const average = useMemo(
    () =>
      graphData.map(({ sum }) => sum).reduce((acc, val) => acc + val, 0) / (graphData.length || 1),
    [graphData],
  );

  return loading && !graphData.length ? (
    <LoadingContainer width={width} height={height}>
      <LoadingSpinner />
    </LoadingContainer>
  ) : (
    <BarChartWithGrabberLine
      width={width}
      height={height}
      xAxisDataKey="month"
      yAxisDataKey="sum"
      data={graphData}
      referenceLineY={average}
      barColor={isIncome ? theme.color.greenFocus : theme.color.orangeFocus}
      barHighlightColor={isIncome ? theme.color.green : theme.color.orange}
      grabberColor={isIncome ? theme.color.green : theme.color.orange}
      grabberTextColor={theme.color.textWhite}
      onClickBar={({ sum }) => onClickAmount?.(roundAmount(sum))}
      onClickGrabber={() => onClickAmount?.(roundAmount(average))}
    />
  );
};

export default HistoricalAmountsBarChart;
