import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FeatureFlagGate from 'components/lib/higherOrder/FeatureFlagGate';
import Flex from 'components/lib/ui/Flex';
import LeftRightSwitcher from 'components/lib/ui/LeftRightSwitcher';
import ModalCard from 'components/lib/ui/ModalCard';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import BudgetSettingsForm from 'components/plan/BudgetSettingsForm';
import PlanHeaderTimeframeToggle from 'components/plan/PlanHeaderTimeframeToggle';

import { usePlanContext } from 'lib/contexts/PlanContext';
import { PlanTimeframe } from 'lib/hooks/plan/usePlanState';
import useModal from 'lib/hooks/useModal';

import routes from 'constants/routes';

const Root = styled(Flex).attrs({ alignCenter: true })``;

const Switcher = styled(LeftRightSwitcher)`
  margin: 0 ${({ theme }) => theme.spacing.default};
`;

const Divider = styled.div`
  height: 16px;
  width: 1px;
  background-color: ${({ theme }) => theme.color.gray};
  margin: 0 ${({ theme }) => theme.spacing.default};
`;

const SettingsButton = styled(DefaultButton)`
  display: flex;
  align-items: baseline;
`;

export type Props = {
  className?: string;
  timeframe: PlanTimeframe;
  onLeft: () => void;
  onRight: () => void;
  onClickToday: () => void;
  onChangeTimeframe: (timeframe: PlanTimeframe) => void;
};

const PlanHeaderControls = ({ onClickToday, timeframe, onChangeTimeframe, ...props }: Props) => {
  const { refetch } = usePlanContext();
  const [EditBudgetSettingsModal, { open: openEditSettings, close: closeEditSettings }] =
    useModal();

  const { push } = useHistory();

  return (
    <Root>
      <Switcher {...props} />
      <DefaultButton onClick={onClickToday}>Today</DefaultButton>
      <Divider />
      {timeframe !== PlanTimeframe.SingleMonth && (
        <>
          <PlanHeaderTimeframeToggle timeframe={timeframe} onChangeTimeframe={onChangeTimeframe} />
          <Divider />
        </>
      )}
      <SettingsButton onClick={openEditSettings}>
        <ButtonIcon name="settings" />
        <span>Settings</span>
      </SettingsButton>

      <EditBudgetSettingsModal>
        <ModalCard title="Budget Settings">
          <BudgetSettingsForm
            onDone={() => {
              refetch();
              closeEditSettings();
            }}
          />
        </ModalCard>
      </EditBudgetSettingsModal>

      <FeatureFlagGate name="fixed-flex-budgeting">
        <>
          <Divider />

          <SettingsButton onClick={() => push(routes.budget.onboarding())}>
            <span>Onboarding</span>
            <ButtonIcon name="link" />
          </SettingsButton>
        </>
      </FeatureFlagGate>
    </Root>
  );
};

export default PlanHeaderControls;
