import { gql } from '@apollo/client';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import type { InstitutionFields } from 'components/accounts/LinkAccountDataProviderModal';
import LinkAccountDataProviderModal from 'components/accounts/LinkAccountDataProviderModal';
import CredentialSettingsCard from 'components/institutions/CredentialSettingsCard';
import CardFooter from 'components/lib/ui/CardFooter';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import { useStackContext } from 'common/lib/contexts/StackContext';
import useQuery from 'common/lib/hooks/useQuery';
import { useModalContext } from 'lib/contexts/ModalContext';

import type {
  GetDuplicateCredentials,
  GetDuplicateCredentialsVariables,
} from 'common/generated/graphQlTypes/GetDuplicateCredentials';

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin: ${({ theme }) => theme.spacing.xxlarge};
`;

const CardBody = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
  padding-top: ${({ theme }) => theme.spacing.default};
`;

const StyledCredentialSettingsCard = styled(CredentialSettingsCard)`
  border: 1px solid ${({ theme }) => theme.color.grayLight};

  :not(:first-child) {
    margin-top: ${({ theme }) => theme.spacing.default};
  }
`;

const HeaderText = styled(Text).attrs({
  size: 'xxsmall',
  color: 'textLight',
  weight: 'bold',
})`
  text-transform: uppercase;
  letter-spacing: 0.08em;
`;

const FooterText = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xlarge};
`;

type Props = {
  institution?: InstitutionFields;
};

/**
 * When linking with Plaid, check for credentials for the same institution. If the user has some,
 * show a warning message before they proceed.
 */
const LinkAccountCheckDuplicatesModal = ({ institution }: Props) => {
  const { close } = useModalContext();

  const { replace, push, pop } = useStackContext();

  const skip = () => replace(LinkAccountDataProviderModal, { institution });
  const next = () => push(LinkAccountDataProviderModal, { institution });

  useEffect(() => {
    if (!institution?.id) {
      skip();
    }
  }, []);

  const { data, refetch } = useQuery<GetDuplicateCredentials, GetDuplicateCredentialsVariables>(
    QUERY,
    {
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          institutionId: institution?.id,
        },
      },
      skip: !institution?.id,
      onCompleted: ({ credentials }) => {
        if (!credentials.length) {
          skip();
        }
      },
    },
  );
  const { credentials = [] } = data ?? {};
  const hasPotentialDuplicates = credentials.length > 0;

  const { name: institutionName } = institution ?? {};
  const title = `Are you sure you want to connect ${institutionName} again?`;

  return hasPotentialDuplicates ? (
    <ModalCard
      title={title}
      description="Adding duplicate connections with the same login details can cause issues with our data
    providers. It is recommended to update your existing login details instead."
      hideBottomBorder
      onClickBackButton={pop}
    >
      <CardBody>
        <HeaderText>Existing Connections</HeaderText>
        {credentials.map((credential) => (
          <StyledCredentialSettingsCard
            key={credential.id}
            credential={credential}
            refetch={refetch}
          />
        ))}
        <FooterText>If you are adding a connection with new login details, continue.</FooterText>
      </CardBody>
      <CardFooter>
        <DefaultButton onClick={close}>Cancel</DefaultButton>
        <PrimaryButton onClick={next}>Continue</PrimaryButton>
      </CardFooter>
    </ModalCard>
  ) : (
    <FlexContainer center>
      <StyledLoadingSpinner />
    </FlexContainer>
  );
};

const QUERY = gql`
  query GetDuplicateCredentials($filters: CredentialFilters!) {
    credentials(filters: $filters) {
      id
      ...CredentialSettingsCardFields
    }
  }
  ${CredentialSettingsCard.fragments.CredentialSettingsCardFields}
`;

export default LinkAccountCheckDuplicatesModal;
