import * as R from 'ramda';

import useQuery from 'common/lib/hooks/useQuery';

import { gql } from 'common/generated';
import { RecurringStreamReviewStatus } from 'common/generated/graphql';

export const useRecurringStreams = ({
  includeLiabilities,
  skip,
}: {
  includeLiabilities: boolean;
  skip?: boolean;
}) => {
  const {
    data,
    isLoadingInitialData,
    refetch: refetchHouseholdHasRecurring,
  } = useQuery(GET_RECURRING_STREAMS, {
    variables: { includeLiabilities },
    skip,
  });
  const hasRecurringMerchants = (data?.recurringTransactionStreams ?? []).length > 0;

  const pendingStreams = R.filter(
    R.pathEq(['stream', 'reviewStatus'], RecurringStreamReviewStatus.PENDING),
    data?.recurringTransactionStreams ?? [],
  );

  const pendingStreamsCount = pendingStreams.length;

  return {
    hasRecurringMerchants,
    isLoading: isLoadingInitialData,
    refetchHouseholdHasRecurring,
    pendingStreams,
    pendingStreamsCount,
  };
};

export const GET_RECURRING_STREAMS = gql(/* GraphQL */ `
  query Common_GetRecurringStreams($includeLiabilities: Boolean) {
    recurringTransactionStreams(includePending: true, includeLiabilities: $includeLiabilities) {
      stream {
        id
        reviewStatus
        frequency
        amount
        baseDate
        dayOfTheMonth
        isApproximate
        name
        logoUrl
        merchant {
          id
        }
        creditReportLiabilityAccount {
          id
          account {
            id
          }

          lastStatement {
            id
            dueDate
          }
        }
      }
    }
  }
`);
