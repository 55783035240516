import React from 'react';
import styled from 'styled-components';

import InstitutionRow from 'components/institutions/InstitutionRow';
import Empty from 'components/lib/ui/Empty';
import PremiumFeatureLimitOverlayTrigger from 'components/premium/PremiumFeatureLimitOverlayTrigger';

import { ProductFeatureLimit } from 'common/constants/premium';

import type { InstitutionRowFields } from 'common/generated/graphQlTypes/InstitutionRowFields';

const StyledInstitutionRow = styled(InstitutionRow)`
  padding-left: ${({ theme }) => theme.spacing.xlarge};
  padding-right: ${({ theme }) => theme.spacing.xlarge};
`;

type Props = {
  institutions: InstitutionRowFields[];
  credentialCount: number;
  search: string;
  onSelectInstitution: (institution: InstitutionRowFields) => void;
  onClickMoreButton?: (institution: InstitutionRowFields) => void;
  emptyButton?: { title: string; onClick: () => void };
};

const InstitutionsList = ({
  institutions,
  credentialCount,
  search,
  onSelectInstitution,
  onClickMoreButton,
  emptyButton,
}: Props) => (
  <>
    {!institutions.length ? (
      <Empty title={`No institutions matching "${search}"`} button={emptyButton} />
    ) : (
      institutions.map((institution) => (
        <PremiumFeatureLimitOverlayTrigger
          key={institution.id}
          featureLimit={ProductFeatureLimit.CredentialLimit}
          currentValue={credentialCount}
          placement="bottom"
        >
          {({ meetsOrExceedsLimit }) => (
            <StyledInstitutionRow
              institution={institution}
              onClick={() => !meetsOrExceedsLimit && onSelectInstitution(institution)}
              onClickMoreButton={
                onClickMoreButton && (() => !meetsOrExceedsLimit && onClickMoreButton(institution))
              }
            />
          )}
        </PremiumFeatureLimitOverlayTrigger>
      ))
    )}
  </>
);

export default InstitutionsList;
