import { gql } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import AddManualAccountFlow from 'components/accounts/AddManualAccountFlow';
import LinkAccountCheckDuplicatesModal from 'components/accounts/LinkAccountCheckDuplicatesModal';
import LinkAccountInstitutionStatusModal from 'components/accounts/LinkAccountInstitutionStatusModal';
import LinkAccountSelectDataProviderModal from 'components/accounts/LinkAccountSelectDataProviderModal';
import RequestInstitutionModal from 'components/accounts/RequestInstitutionModal';
import SearchAndLinkAccountFromDataProviderModal from 'components/accounts/SearchAndLinkAccountFromDataProviderModal';
import InstitutionRow from 'components/institutions/InstitutionRow';
import InstitutionsList from 'components/institutions/InstitutionsList';
import TopInstitutionGroups from 'components/institutions/TopInstitutionGroups';
import TopInstitutionsGrid from 'components/institutions/TopInstitutionsGrid';
import Empty from 'components/lib/ui/Empty';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import ModalCard from 'components/lib/ui/ModalCard';
import Scroll from 'components/lib/ui/Scroll';
import SearchBar from 'components/lib/ui/SearchBar';
import TextButton from 'components/lib/ui/TextButton';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import { useStackContext } from 'common/lib/contexts/StackContext';
import useQuery from 'common/lib/hooks/useQuery';
import useSearchInstitutions from 'lib/hooks/institutions/useSearchInstitutions';
import useSupportedDataProviders from 'lib/hooks/institutions/useSupportedDataProviders';
import useDemoHousehold from 'lib/hooks/useDemoHousehold';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';

import type { InstitutionRowFields } from 'common/generated/graphQlTypes/InstitutionRowFields';
import type { LinkInstitutionFields } from 'common/generated/graphQlTypes/LinkInstitutionFields';
import type {
  Web_GetTopInstitutionGroups,
  Web_GetTopInstitutionGroupsVariables,
  Web_GetTopInstitutionGroups_topInstitutionGroups,
} from 'common/generated/graphQlTypes/Web_GetTopInstitutionGroups';
import { DataProviderLegacy } from 'common/generated/graphql';

const EMPTY_SEARCH_STATE = '';

const Container = styled(FlexContainer).attrs({ column: true, alignCenter: true })`
  padding: ${({ theme }) => theme.spacing.xlarge};
  padding-top: ${({ theme }) => theme.spacing.default};

  :last-child {
    border-top: 1px solid ${({ theme }) => theme.color.grayBackground};
  }
`;

const ButtonsContainer = styled(FlexContainer)`
  justify-content: stretch;
  gap: ${({ theme }) => theme.spacing.small};
  width: 100%;
`;

const StyledSearchBar = styled(SearchBar).attrs({ focusOnRender: true })`
  margin: ${({ theme }) => theme.spacing.xsmall} ${({ theme }) => theme.spacing.xlarge};
`;

const StyledScroll = styled(Scroll)`
  width: 100%;
  max-height: 50vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`;

const FullWidthDefaultButton = styled(DefaultButton)`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  margin-bottom: ${({ theme }) => theme.spacing.large};
  padding: 7.5px ${({ theme }) => theme.spacing.xsmall};
`;

const StyledTextButton = styled(TextButton)`
  width: 100%;
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

const OtherOptionsHeader = styled.p`
  margin: ${({ theme }) => theme.spacing.xlarge} 0 ${({ theme }) => theme.spacing.small};
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

type Props = {
  allowManualAccount?: boolean;
  selectedTopGroup?: Web_GetTopInstitutionGroups_topInstitutionGroups;
};

const LinkAccountSearchInstitutionsModal = ({
  allowManualAccount = true,
  selectedTopGroup,
}: Props) => {
  const { pop, push, onComplete } = useStackContext();

  const isConnectMXInstitutionsOn = useIsFeatureFlagOn('connect-mx-institutions');
  const isConnectFinicityInstitutionsOn = useIsFeatureFlagOn('connect-finicity-institutions');

  const next = (params: { institution?: LinkInstitutionFields }) => {
    const { institution } = params ?? {};

    if (institution?.linkFlowWarnBeforeConnecting) {
      push(LinkAccountInstitutionStatusModal, { institution });
    } else {
      push(LinkAccountCheckDuplicatesModal, params);
    }
  };

  const [search, setSearch] = useState(EMPTY_SEARCH_STATE);
  const [selectedGroup, setSelectedGroup] = useState<
    Web_GetTopInstitutionGroups_topInstitutionGroups | undefined
  >(selectedTopGroup);
  const [showRequestNewInstitution, setShowRequestNewInstitution] = useState<boolean>(false);

  const dataProviders = useSupportedDataProviders();

  const { data: topInstitutionGroupsData, isLoadingInitialData } = useQuery<
    Web_GetTopInstitutionGroups,
    Web_GetTopInstitutionGroupsVariables
  >(QUERY, {
    variables: {
      dataProviders,
    },
  });
  const { topInstitutionGroups = [], credentials = [] } = topInstitutionGroupsData ?? {};
  const credentialCount = credentials.length;

  const { debouncedSearch, searchInstitutions, isNetworkRequestInFlight } =
    useSearchInstitutions(search);

  const isEmptyResultsState =
    !!debouncedSearch && !isNetworkRequestInFlight && !searchInstitutions.length;

  const { blockActionForDemoHousehold } = useDemoHousehold();

  const onSelectInstitution = (institution: InstitutionRowFields) =>
    blockActionForDemoHousehold(() => next({ institution }));

  const pushManualAccountFlow = (defaults?: { type?: string; subtype?: string }) =>
    push(AddManualAccountFlow, {
      onBack: pop,
      onComplete,
      initialProps: defaults ?? {},
    });

  return (
    <Switch>
      <Case when={showRequestNewInstitution}>
        <RequestInstitutionModal
          allowManualAccount={allowManualAccount}
          initialInstitutionName={debouncedSearch}
          onClickAddManualAccount={() => {
            setShowRequestNewInstitution(false);
            pushManualAccountFlow();
          }}
          onBack={() => setShowRequestNewInstitution(false)}
          onSearchAgain={() => {
            setSearch(EMPTY_SEARCH_STATE);
            setShowRequestNewInstitution(false);
          }}
        />
      </Case>
      <Case default>
        <ModalCard
          title={selectedGroup?.title ?? 'Add account'}
          hideBottomBorder
          onClickBackButton={
            selectedGroup
              ? () => {
                  setSelectedGroup(undefined);
                  setSearch(EMPTY_SEARCH_STATE);
                }
              : undefined
          }
        >
          <StyledSearchBar
            name="search-institutions"
            placeholder="Search 13,000+ institutions"
            autoComplete="off"
            value={search}
            onChange={setSearch}
          />
          <Switch>
            <Case
              when={
                isLoadingInitialData || (isNetworkRequestInFlight && !searchInstitutions.length)
              }
            >
              <Container>
                <LoadingSpinner />
              </Container>
            </Case>
            <Case when={isEmptyResultsState}>
              <Container>
                <Empty title={`No match for "${debouncedSearch}"`} />
                <FullWidthDefaultButton onClick={() => pushManualAccountFlow()}>
                  Add manual account
                </FullWidthDefaultButton>
              </Container>
            </Case>
            <Case when={!!debouncedSearch}>
              <StyledScroll>
                <InstitutionsList
                  institutions={searchInstitutions}
                  credentialCount={credentialCount}
                  search={debouncedSearch}
                  onSelectInstitution={onSelectInstitution}
                  onClickMoreButton={(institution) =>
                    blockActionForDemoHousehold(() =>
                      push(LinkAccountSelectDataProviderModal, { institution }),
                    )
                  }
                  emptyButton={{
                    title: 'Add a manual account',
                    onClick: () => pushManualAccountFlow(),
                  }}
                />
              </StyledScroll>
            </Case>
            <Case when={!!selectedGroup}>
              <Container>
                <TopInstitutionsGrid
                  institutions={selectedGroup?.institutions ?? []}
                  onSelectInstitution={onSelectInstitution}
                  credentialCount={credentialCount}
                  manualAccountShortcuts={selectedGroup?.manualAccountShortcuts ?? []}
                  onSelectManualAccountShortcut={(manualAccountShortcut) =>
                    pushManualAccountFlow({
                      type: manualAccountShortcut.accountType.name,
                      subtype: manualAccountShortcut.accountSubtype?.name,
                    })
                  }
                />
              </Container>
            </Case>
            <Case default>
              <Container>
                <TopInstitutionGroups
                  onSelectGroup={setSelectedGroup}
                  topInstitutionGroups={topInstitutionGroups}
                />
              </Container>
            </Case>
          </Switch>

          <Container>
            {(isConnectMXInstitutionsOn || isConnectFinicityInstitutionsOn) && (
              <OtherOptionsHeader>
                Having issues with the options above? Try these alternatives
              </OtherOptionsHeader>
            )}
            <ButtonsContainer>
              {isConnectMXInstitutionsOn && (
                <FullWidthDefaultButton
                  onClick={() =>
                    push(SearchAndLinkAccountFromDataProviderModal, {
                      dataProvider: DataProviderLegacy.MX,
                    })
                  }
                >
                  MX
                </FullWidthDefaultButton>
              )}

              {isConnectFinicityInstitutionsOn && (
                <FullWidthDefaultButton
                  onClick={() =>
                    push(SearchAndLinkAccountFromDataProviderModal, {
                      dataProvider: DataProviderLegacy.FINICITY,
                    })
                  }
                >
                  Finicity
                </FullWidthDefaultButton>
              )}

              {allowManualAccount && !isEmptyResultsState && (
                <FullWidthDefaultButton onClick={() => pushManualAccountFlow()}>
                  Add manual account
                </FullWidthDefaultButton>
              )}
            </ButtonsContainer>

            <StyledTextButton onClick={() => setShowRequestNewInstitution(true)}>
              Request new connection
            </StyledTextButton>
          </Container>
        </ModalCard>
      </Case>
    </Switch>
  );
};

const QUERY = gql`
  query Web_GetTopInstitutionGroups($dataProviders: [String]!) {
    credentials {
      id
    }
    topInstitutionGroups(dataProviders: $dataProviders) {
      type
      title
      shortTitle
      accountsConnectedCount
      institutions {
        id
        ...InstitutionRowFields
      }
      manualAccountShortcuts {
        title
        accountType {
          name
        }
        accountSubtype {
          name
        }
      }
    }
  }
  ${InstitutionRow.fragments.InstitutionRowFields}
`;

export default LinkAccountSearchInstitutionsModal;
