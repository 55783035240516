import { gql } from 'common/generated';

export const BUDGET_ROLLOVER_PERIOD_FIELDS_FRAGMENT = gql(/* GraphQL */ `
  fragment BudgetRolloverPeriodFields on BudgetRolloverPeriod {
    id
    startMonth
    endMonth
    startingBalance
    targetAmount
    frequency
    type
  }
`);

export const BUDGET_CATEGORY_FIELDS_FRAGMENT = gql(/* GraphQL */ `
  fragment BudgetCategoryFields on Category {
    id
    name
    icon
    order
    budgetVariability
    excludeFromBudget
    isSystemCategory
    group {
      id
      type
      budgetVariability
      groupLevelBudgetingEnabled
    }
    rolloverPeriod {
      ...BudgetRolloverPeriodFields
    }
  }
`);

export const BUDGET_CATEGORY_GROUP_FIELDS_FRAGMENT = gql(/* GraphQL */ `
  fragment BudgetCategoryGroupFields on CategoryGroup {
    id
    name
    order
    type
    budgetVariability
    groupLevelBudgetingEnabled
    categories {
      ...BudgetCategoryFields
    }
    rolloverPeriod {
      id
      type
      startMonth
      endMonth
      startingBalance
      frequency
      targetAmount
    }
  }
`);

export const BUDGET_DATA_MONTHLY_AMOUNTS_FIELDS_FRAGMENT = gql(/* GraphQL */ `
  fragment BudgetDataMonthlyAmountsFields on BudgetMonthlyAmounts {
    month
    plannedCashFlowAmount
    plannedSetAsideAmount
    actualAmount
    remainingAmount
    previousMonthRolloverAmount
    rolloverType
    cumulativeActualAmount
    rolloverTargetAmount
  }
`);

export const BUDGET_DATA_TOTALS_BY_MONTH_FIELDS_FRAGMENT = gql(/* GraphQL */ `
  fragment BudgetDataTotalsByMonthFields on BudgetTotals {
    actualAmount
    plannedAmount
    previousMonthRolloverAmount
    remainingAmount
  }
`);

export const BUDGET_DATA_GOALS_V2_FIELDS_FRAGMENT = gql(/* GraphQL */ `
  fragment BudgetDataGoalsV2Fields on GoalV2 {
    id
    name
    archivedAt
    completedAt
    priority
    imageStorageProvider
    imageStorageProviderId
    plannedContributions(startMonth: $startDate, endMonth: $endDate) {
      id
      month
      amount
    }
    monthlyContributionSummaries(startMonth: $startDate, endMonth: $endDate) {
      month
      sum
    }
  }
`);

export const BUDGET_DATA_FIELDS_FRAGMENT = gql(/* GraphQL */ `
  fragment BudgetDataFields on BudgetData {
    monthlyAmountsByCategory {
      category {
        id
      }
      monthlyAmounts {
        ...BudgetDataMonthlyAmountsFields
      }
    }
    monthlyAmountsByCategoryGroup {
      categoryGroup {
        id
      }
      monthlyAmounts {
        ...BudgetDataMonthlyAmountsFields
      }
    }
    monthlyAmountsForFlexExpense {
      budgetVariability
      monthlyAmounts {
        ...BudgetDataMonthlyAmountsFields
      }
    }
    totalsByMonth {
      month
      totalIncome {
        ...BudgetDataTotalsByMonthFields
      }
      totalExpenses {
        ...BudgetDataTotalsByMonthFields
      }
      totalFixedExpenses {
        ...BudgetDataTotalsByMonthFields
      }
      totalNonMonthlyExpenses {
        ...BudgetDataTotalsByMonthFields
      }
      totalFlexibleExpenses {
        ...BudgetDataTotalsByMonthFields
      }
    }
  }
`);

export const BUDGET_STATUS_FIELDS_FRAGMENT = gql(/* GraphQL */ `
  fragment BudgetStatusFields on BudgetStatus {
    hasBudget
    hasTransactions
    willCreateBudgetFromEmptyDefaultCategories
  }
`);

export const BUDGET_DATA_QUERY = gql(/* GraphQL */ `
  query Common_BudgetDataQuery($startDate: Date!, $endDate: Date!) {
    budgetSystem
    budgetStatus {
      ...BudgetStatusFields
    }
    budgetData(startMonth: $startDate, endMonth: $startDate) {
      ...BudgetDataFields
    }
    categoryGroups {
      ...BudgetCategoryGroupFields
    }
    goalsV2 {
      ...BudgetDataGoalsV2Fields
    }
  }
`);

export const CREATE_BUDGET_MUTATION = gql(/* GraphQL */ `
  mutation Common_CreateBudgetForHousehold($input: CreateBudgetMutationInput!) {
    createBudget(input: $input) {
      errors {
        message
      }
    }
  }
`);

export const GET_BUDGET_STATUS_QUERY = gql(/* GraphQL */ `
  query Common_GetBudgetStatus {
    budgetStatus {
      ...BudgetStatusFields
    }
  }
`);

export const GET_BUDGET_SETTINGS_QUERY = gql(/* GraphQL */ `
  query Common_GetBudgetSettings {
    budgetSystem
    flexExpenseRolloverPeriod {
      id
      startMonth
      startingBalance
    }
  }
`);

export const UPDATE_BUDGET_SETTINGS_MUTATION = gql(/* GraphQL */ `
  mutation Common_UpdateBudgetSettings($input: UpdateBudgetSettingsMutationInput!) {
    updateBudgetSettings(input: $input) {
      budgetSystem
      budgetRolloverPeriod {
        id
        startMonth
      }
    }
  }
`);

export const UPDATE_CATEGORY_EXCLUDE_FROM_BUDGET = gql(/* GraphQL */ `
  mutation Common_UpdateCategoryExcludeFromBudget($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      category {
        id
        excludeFromBudget
      }
    }
  }
`);
