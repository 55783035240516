import queryString from 'query-string';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import OnboardingAccounts from 'components/routes/budget/OnboardingAccounts';
import OnboardingExpenses from 'components/routes/budget/OnboardingExpenses';
import OnboardingFlexExpenses from 'components/routes/budget/OnboardingFlexExpenses';
import OnboardingFlexIntroduction from 'components/routes/budget/OnboardingFlexIntroduction';
import OnboardingIncome from 'components/routes/budget/OnboardingIncome';
import OnboardingMethodChoice from 'components/routes/budget/OnboardingMethodChoice';
import OnboardingNonMonthly from 'components/routes/budget/OnboardingNonMonthly';
import OnboardingSelectVariabilities from 'components/routes/budget/OnboardingSelectVariabilities';
import OnboardingSuccess from 'components/routes/budget/OnboardingSuccess';

import useHasAccounts from 'common/lib/hooks/accounts/useHasAccounts';

import * as COPY from 'common/constants/copy';
import routes from 'constants/routes';

import { BudgetSystem, BudgetVariability } from 'common/generated/graphql';

enum OnboardingFlowStep {
  Accounts = 'Accounts',
  Income = 'Income',
  MethodChoice = 'MethodChoice',
  FlexIntroduction = 'FlexIntroduction',
  Variabilities = 'Variabilities',
  Expenses = 'Expenses',
  FixedExpenses = 'FixedExpenses',
  NonMonthlyExpenses = 'NonMonthlyExpenses',
  FlexibleExpenses = 'FlexibleExpenses',
  Success = 'Success',
}

const OnboardingFlow = () => {
  const history = useHistory();
  const [selectedBudgetSystem, setSelectedBudgetSystem] = useState<BudgetSystem>();
  const { hasAccounts } = useHasAccounts();

  const isFlexBudgeting = selectedBudgetSystem === BudgetSystem.FIXED_AND_FLEX;
  const stepParam = queryString.parse(window.location.search).step;
  const steps = Object.values(OnboardingFlowStep);
  const currentStep = getCurrentStep(stepParam, hasAccounts);
  const progress = (steps.indexOf(currentStep) + 1) / steps.length;

  const updateStep = (newStep: OnboardingFlowStep) => {
    const params = { step: newStep };

    const url = queryString.stringifyUrl({
      url: window.location.pathname,
      query: params,
    });

    history.push(url);
  };

  const onCancel = () => {
    history.push(routes.budget.path);
  };

  switch (currentStep) {
    case OnboardingFlowStep.Accounts: {
      return (
        <OnboardingAccounts
          onCancel={onCancel}
          onBack={onCancel}
          onNext={() => updateStep(OnboardingFlowStep.Income)}
          progress={progress}
        />
      );
    }
    case OnboardingFlowStep.Income: {
      return (
        <OnboardingIncome
          onCancel={onCancel}
          onBack={() => updateStep(OnboardingFlowStep.Accounts)}
          onNext={() => updateStep(OnboardingFlowStep.MethodChoice)}
          progress={progress}
        />
      );
    }
    case OnboardingFlowStep.MethodChoice: {
      return (
        <OnboardingMethodChoice
          onCancel={onCancel}
          onBack={() => updateStep(OnboardingFlowStep.Income)}
          onSelectFixFlexBudget={() => {
            setSelectedBudgetSystem(BudgetSystem.FIXED_AND_FLEX);
            updateStep(OnboardingFlowStep.FlexIntroduction);
          }}
          onSelectCategoryBudget={() => {
            setSelectedBudgetSystem(BudgetSystem.GROUPS_AND_CATEGORIES);
            updateStep(OnboardingFlowStep.Expenses);
          }}
          progress={progress}
        />
      );
    }
    case OnboardingFlowStep.Expenses: {
      return (
        <OnboardingExpenses
          onCancel={onCancel}
          onBack={() => updateStep(OnboardingFlowStep.MethodChoice)}
          onNext={() => updateStep(OnboardingFlowStep.Success)}
          progress={progress}
        />
      );
    }
    case OnboardingFlowStep.FlexIntroduction: {
      return (
        <OnboardingFlexIntroduction
          onCancel={onCancel}
          onBack={() => updateStep(OnboardingFlowStep.MethodChoice)}
          onNext={() => updateStep(OnboardingFlowStep.Variabilities)}
          progress={progress}
        />
      );
    }
    case OnboardingFlowStep.Variabilities: {
      return (
        <OnboardingSelectVariabilities
          onCancel={onCancel}
          onBack={() => updateStep(OnboardingFlowStep.FlexIntroduction)}
          onNext={() => updateStep(OnboardingFlowStep.FixedExpenses)}
          progress={progress}
        />
      );
    }
    case OnboardingFlowStep.FixedExpenses: {
      return (
        <OnboardingFlexExpenses
          onCancel={onCancel}
          onBack={() => updateStep(OnboardingFlowStep.Variabilities)}
          onNext={() => updateStep(OnboardingFlowStep.NonMonthlyExpenses)}
          progress={progress}
          title={COPY.BUDGET.ONBOARDING.FIXED_EXPENSES.TITLE}
          description={COPY.BUDGET.ONBOARDING.FIXED_EXPENSES.DESCRIPTION}
          budgetVariability={BudgetVariability.FIXED}
          pageName="Fixed Expenses"
        />
      );
    }
    case OnboardingFlowStep.NonMonthlyExpenses: {
      return (
        <OnboardingNonMonthly
          onCancel={onCancel}
          onBack={() => updateStep(OnboardingFlowStep.FixedExpenses)}
          onNext={() => updateStep(OnboardingFlowStep.FlexibleExpenses)}
          progress={progress}
          title={COPY.BUDGET.ONBOARDING.NON_MONTHLY_EXPENSES.TITLE}
          description={COPY.BUDGET.ONBOARDING.NON_MONTHLY_EXPENSES.DESCRIPTION}
          pageName="Non-Monthly Expenses"
        />
      );
    }
    case OnboardingFlowStep.FlexibleExpenses: {
      return (
        <OnboardingFlexExpenses
          onCancel={onCancel}
          onBack={() => updateStep(OnboardingFlowStep.NonMonthlyExpenses)}
          onNext={() => updateStep(OnboardingFlowStep.Success)}
          progress={progress}
          title={COPY.BUDGET.ONBOARDING.FLEXIBLE_EXPENSES.TITLE}
          description={COPY.BUDGET.ONBOARDING.FLEXIBLE_EXPENSES.DESCRIPTION}
          budgetVariability={BudgetVariability.FLEXIBLE}
          pageName="Flexible Expenses"
        />
      );
    }
    case OnboardingFlowStep.Success: {
      return (
        <OnboardingSuccess
          onBack={() =>
            updateStep(
              isFlexBudgeting ? OnboardingFlowStep.FlexibleExpenses : OnboardingFlowStep.Expenses,
            )
          }
          onCancel={onCancel}
          onNext={onCancel}
          progress={progress}
        />
      );
    }
  }
};

const getCurrentStep = (step: unknown, hasAccounts: boolean | undefined): OnboardingFlowStep => {
  if (isStepValid(step)) {
    return step as OnboardingFlowStep;
  }

  if (hasAccounts) {
    return OnboardingFlowStep.Income;
  }

  return OnboardingFlowStep.Accounts;
};

function isStepValid(step: unknown): step is OnboardingFlowStep {
  return typeof step === 'string' && step in OnboardingFlowStep;
}

export default OnboardingFlow;
