import * as React from 'react';
import styled, { css } from 'styled-components';

import Card from 'components/lib/ui/Card';
import CardTitle from 'components/lib/ui/CardTitle';
import DragDots from 'components/lib/ui/DragDots';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LinkArrow from 'components/lib/ui/LinkArrow';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import NavLink from 'components/lib/ui/link/NavLink';

type Props = {
  title: string;
  description?: React.ReactNode;
  headerRight?: React.ReactNode;
  children?: React.ReactNode;
  loading?: boolean;
  minHeightPx?: number;
  headerLink: string;
  showHeaderBorder?: boolean;
  hideHeader?: boolean;
  tooltip?: React.ReactNode;
  className?: string;
};

const Root = styled(({ minHeightPx, ...props }) => <Card {...props} />)<{ minHeightPx?: number }>`
  min-height: ${({ minHeightPx }) => minHeightPx ?? 0}px;
  position: relative;
`;

const Loading = styled(FlexContainer)`
  margin: ${({ theme }) => theme.spacing.xlarge};
`;

const Header = styled(FlexContainer)<{ showBorder: boolean }>`
  padding: ${({ theme }) => theme.spacing.large} ${({ theme }) => theme.spacing.large}
    ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.xlarge};

  ${({ showBorder, theme }) =>
    showBorder &&
    css`
      border-bottom: 1px solid ${theme.color.grayBackground};
    `}
`;

const HeaderClickable = styled(NavLink)<{ tooltip: React.ReactNode }>`
  ${({ tooltip }) =>
    !tooltip &&
    `
    flex: 1;
  `}
`;

const HeaderRight = styled(Flex)`
  flex: 1;
`;

const Title = styled(CardTitle)`
  display: flex;
  align-items: center;

  ${HeaderClickable}:hover & {
    color: ${({ theme }) => theme.color.blue};
  }
`;

export const Description = styled.div`
  font-size: 20px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 150%;
  margin-top: ${({ theme }) => theme.spacing.xxsmall};
`;

const StyledDragDots = styled(DragDots)`
  position: absolute;
  top: 26px;
  left: ${({ theme }) => theme.spacing.xsmall};
  cursor: move;
  transition: ${({ theme }) => theme.transition.default};
  opacity: 0;

  ${Root}:hover & {
    opacity: 1;
  }
`;

const DashboardWidget = ({
  title,
  description,
  headerRight,
  children,
  loading = false,
  minHeightPx,
  headerLink,
  showHeaderBorder = true,
  hideHeader = false,
  tooltip,
  className,
}: Props) => (
  <Root className={className} minHeightPx={minHeightPx}>
    {!hideHeader && (
      <Header showBorder={showHeaderBorder}>
        <StyledDragDots />
        <HeaderClickable to={headerLink} tooltip={tooltip}>
          <Title>
            {title} <LinkArrow hoverTarget={HeaderClickable} />
          </Title>
          {!!description && <Description>{description}</Description>}
        </HeaderClickable>
        {tooltip}
        {!!headerRight && <HeaderRight justifyEnd>{headerRight}</HeaderRight>}
      </Header>
    )}
    {loading ? (
      <Loading center>
        <LoadingSpinner />
      </Loading>
    ) : (
      children
    )}
  </Root>
);

export default DashboardWidget;
