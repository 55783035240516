import type { DateTime } from 'luxon';
import { pipe, propEq, reduce, toPairs, values } from 'ramda';
import { compact } from 'ramda-adjunct';

import type { SummaryDisplayBudgetType } from 'common/lib/budget/Adapters';
import type { PlanSectionType, GridAmounts, PlanSectionData} from 'lib/plan/Adapters';
import { RowType } from 'lib/plan/Adapters';

import { BudgetVariability, CategoryGroupType } from 'common/generated/graphql';
import { BUDGET_VARIABILITY_STACK_MAPPING, BUDGET_VARIABILITY_TO_TITLE_MAPPING } from 'common/lib/budget/constants';
import type { LeftToBudgetTooltipRow } from 'components/plan/onboarding/LeftToBudgetFooter';
import type { BudgetedRowData } from 'components/plan/onboarding/OnboardingBudgetCardRow';

export type AmountsMap = Record<BudgetVariability, number | undefined>;

export const getGroupsDataWithBudgetedAmounts = (
  gridDisplayData: PlanSectionData[],
  gridAmounts: GridAmounts,
  type: PlanSectionType,
  month: DateTime,
) => {
  const dataForType = gridDisplayData?.find(propEq('type', type));
  const monthString = month.toISODate();

  return (
    dataForType?.groups.map((group) => {
      const groupData = gridAmounts?.[type][group.id];
      const groupBudgetedAmount = groupData.aggregate[monthString]?.budgeted;

      return {
        ...group,
        budgeted: groupBudgetedAmount,
        rows: group.rows.map((row) => {
          const rowBudgetedAmount = groupData[row.id][monthString]?.budgeted;

          return {
            ...row,
            budgeted: rowBudgetedAmount,
          };
        }),
      };
    }) ?? []
  );
};

export const getFlexGroupDataWithBudgetedAmounts = (
  gridDisplayData: PlanSectionData[],
  gridAmounts: GridAmounts,
  budgetVariability: BudgetVariability,
  month: DateTime,
) => {
  const groups = getGroupsDataWithBudgetedAmounts(
    gridDisplayData,
    gridAmounts,
    CategoryGroupType.EXPENSE,
    month,
  );
  return groups.filter(propEq('id', budgetVariability));
};

export const getSummaryDataForType = (
  budgetSummaryData: SummaryDisplayBudgetType[],
  type: PlanSectionType,
) => budgetSummaryData.find(propEq('type', type));

export const getBudgetedAmountForType = (
  budgetSummaryData: SummaryDisplayBudgetType[],
  type: PlanSectionType,
) => getSummaryDataForType(budgetSummaryData, type)?.budgeted ?? 0;

export const getBudgetedAmountForVariability = (
  budgetSummaryData: SummaryDisplayBudgetType[],
  budgetVariability: BudgetVariability,
) => {
  const summary = getSummaryDataForType(budgetSummaryData, CategoryGroupType.EXPENSE);
  return summary?.budgetVariabilities?.find(propEq('type', budgetVariability))?.budgeted ?? 0;
};

export const getAccumulatedAmountsMap = (
  budgetSummaryData: SummaryDisplayBudgetType[],
  budgetVariability: BudgetVariability,
) => {
  const stackedVariabilities = BUDGET_VARIABILITY_STACK_MAPPING[budgetVariability];

  const totalByVariability: AmountsMap = {
    [BudgetVariability.FIXED]: undefined,
    [BudgetVariability.NON_MONTHLY]: undefined,
    [BudgetVariability.FLEXIBLE]: undefined,
  };

  stackedVariabilities.forEach((budgetVariability) => {
    totalByVariability[budgetVariability] = getBudgetedAmountForVariability(
      budgetSummaryData,
      budgetVariability,
    );
  });

  return totalByVariability;
};

export const getAccumulatedAmountForVariabilities = pipe(
  values<AmountsMap, BudgetVariability>,
  compact,
  reduce((total, amount) => total + amount, 0),
);

export const getBudgetVariabilityName = (budgetVariability: BudgetVariability) =>
  BUDGET_VARIABILITY_TO_TITLE_MAPPING[budgetVariability]

// Overload signatures
export function getTooltipRows(income: number, amountsMap: AmountsMap): LeftToBudgetTooltipRow[];
export function getTooltipRows(income: number, expenses: number): LeftToBudgetTooltipRow[];

export function getTooltipRows(income: number, expensesOrAmountsMap: number | AmountsMap): LeftToBudgetTooltipRow[] {
  const baseRow: LeftToBudgetTooltipRow = { title: 'Income', value: income, type: 'income', emphasis: true };

  if (typeof expensesOrAmountsMap === 'number') {
    return [
      baseRow,
      { title: 'Expenses', value: expensesOrAmountsMap },
    ];
  } else {
    return [
      baseRow,
      ...toPairs(expensesOrAmountsMap).map(([variability, value]) => ({
        title: `${getBudgetVariabilityName(variability as BudgetVariability)} expenses`,
        value,
        placeholder: 'Up next',
      })),
    ];
  }
}

/**
 * Determines if a row is a category group with group-level budgeting enabled.
 * If so, we don't want to show it since its categories will be shown instead.
 */
const isRowGroupWithGroupBudget = (row: BudgetedRowData) =>
  row.rowType === RowType.CategoryGroup && row.groupLevelBudgetingEnabled;

/**
 * Filters out rows that are either excluded from the budget or are category groups with group-level budgeting enabled.
 */
export const filterVisibleRows = (rows: BudgetedRowData[]) =>
  rows.filter((row) => !row.excludeFromBudget && !isRowGroupWithGroupBudget(row));