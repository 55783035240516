import type { Variants } from 'framer-motion';
import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import { TooltipV2 } from 'components/lib/ui/TooltipV2';
import PlanRowTitle from 'components/plan/PlanRowTitle';
import BudgetRowControls from 'components/plan/onboarding/BudgetRowControls';
import OnboardingAmountCell from 'components/plan/onboarding/OnboardingAmountCell';
import { CardRow, Left, Right } from 'components/plan/onboarding/OnboardingCard';

import { spacing } from 'common/lib/theme/dynamic';
import type { PlanRowData } from 'lib/plan/Adapters';

import { DEFAULT_MOTION_TRANSITION } from 'constants/animation';

const ROW_ANIMATION_VARIANTS: Variants = {
  enter: { height: 'auto', opacity: 1 },
  exit: { height: 0, opacity: 0 },
};

const TooltipWrapper = styled(FlexContainer)`
  padding: ${spacing.xsmall};
  max-width: 250px;
  line-height: 1.5;
`;

const StyledRowControls = styled(BudgetRowControls)``;

const StyledLeft = styled(Left).attrs({ $rightShadow: true })`
  &:hover ${StyledRowControls} {
    opacity: 1;
  }
`;

export type BudgetedRowData = PlanRowData & { budgeted?: number | null };

type Props = {
  rowData: BudgetedRowData;
  isIncome?: boolean;
  onEditCategory: () => void;
  onHideFromBudget: () => void;
  onDeleteCategory: () => void;
  onChange?: (value: Maybe<number>) => void;
};

const OnboardingBudgetCardRow = ({
  rowData,
  isIncome,
  onEditCategory,
  onHideFromBudget,
  onDeleteCategory,
  onChange,
}: Props) => {
  const { id, name, icon: rowIcon, budgeted, canBeBudgeted, groupLevelBudgetingEnabled } = rowData;

  return (
    <CardRow $withTopBorder>
      <StyledLeft>
        <FlexContainer justifyBetween full alignCenter>
          <PlanRowTitle icon={rowIcon}>
            <span>{name}</span>
          </PlanRowTitle>
          <StyledRowControls
            isSystemCategory={rowData.isSystemCategory}
            onEditCategory={onEditCategory}
            onHideFromBudget={onHideFromBudget}
            onDeleteCategory={onDeleteCategory}
          />
        </FlexContainer>
      </StyledLeft>
      <Right>
        {!groupLevelBudgetingEnabled ? (
          <OnboardingAmountCell
            canEdit={canBeBudgeted}
            itemId={id}
            value={budgeted ?? 0}
            onChange={onChange}
            isIncome={isIncome}
          />
        ) : (
          <TooltipV2
            side="right"
            content={
              <TooltipWrapper>
                This category is inside a group that has group-level budgeting enabled
              </TooltipWrapper>
            }
          >
            <div>
              <OnboardingAmountCell disabled canEdit itemId={id} value={budgeted ?? 0} />
            </div>
          </TooltipV2>
        )}
      </Right>
    </CardRow>
  );
};

/**
 * Utility component to wrap the children of the row to animate them in and out when the row is mounting/unmounting.
 *
 * This should be used directly inside an `AnimatePresence` component.
 */
export const OnboardingCardRowAnimatedWrapper = ({ children }: React.PropsWithChildren) => (
  <motion.div
    variants={ROW_ANIMATION_VARIANTS}
    initial="exit"
    animate="enter"
    exit="exit"
    transition={DEFAULT_MOTION_TRANSITION}
  >
    {children}
  </motion.div>
);

export default OnboardingBudgetCardRow;
