import type { DateTime } from 'luxon';
import * as R from 'ramda';

import type { BudgetMonthlyAmounts } from 'common/generated/graphql';

type MonthlyAmounts = Pick<
  BudgetMonthlyAmounts,
  'month' | 'plannedCashFlowAmount' | 'remainingAmount'
>;

export const getOptimisticMonthlyAmounts = <
  T extends {
    monthlyAmounts: MonthlyAmounts[];
  },
>(
  data: T,
  plannedCashFlowAmount: number,
  startDate: DateTime,
) =>
  R.evolve(
    {
      monthlyAmounts: R.map(
        R.when(
          ({ month }) => month === startDate.toISODate(),
          (amounts: MonthlyAmounts) => ({
            ...amounts,
            plannedCashFlowAmount,
            // optimistically set remaining based on the difference between the new planned amount & old planned amount
            remainingAmount:
              (amounts.remainingAmount ?? 0) +
              (plannedCashFlowAmount - (amounts.plannedCashFlowAmount ?? 0)),
          }),
        ),
      ),
    },
    data,
  );
