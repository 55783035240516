import { gql } from '@apollo/client';
import * as R from 'ramda';
import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import InstitutionRow from 'components/institutions/InstitutionRow';
import InstitutionsList from 'components/institutions/InstitutionsList';
import TopInstitutionsGrid from 'components/institutions/TopInstitutionsGrid';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import PageEmptyOverlayCard from 'components/lib/ui/PageEmptyOverlayCard';
import Scroll from 'components/lib/ui/Scroll';
import SearchBar from 'components/lib/ui/SearchBar';
import Text from 'components/lib/ui/Text';
import TextButton from 'components/lib/ui/TextButton';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import OnboardingConnectAccountSidebar from 'components/onboarding/OnboardingConnectAccountSidebar';
import { HEADER_HEIGHT } from 'components/routes/Header';

import useQuery from 'common/lib/hooks/useQuery';
import useSearchInstitutions from 'lib/hooks/institutions/useSearchInstitutions';
import useSupportedDataProviders from 'lib/hooks/institutions/useSupportedDataProviders';

import { HELP_CENTER_PRIVACY_SECURITY_URL } from 'common/constants/externalUrls';

import type { LinkInstitutionFields } from 'common/generated/graphQlTypes/LinkInstitutionFields';
import type {
  Web_OnboardingTopInstitutions,
  Web_OnboardingTopInstitutionsVariables,
  Web_OnboardingTopInstitutions_topInstitutionGroups_institutions,
} from 'common/generated/graphQlTypes/Web_OnboardingTopInstitutions';

const TOP_INSTITUTION_LIMIT = 9;

const Root = styled(PageEmptyOverlayCard)`
  align-items: stretch;
  padding: 0;
  --top: calc(${HEADER_HEIGHT} + ${({ theme }) => theme.spacing.xlarge});

  width: calc(100% - 2 * ${({ theme }) => theme.spacing.xxlarge});
  max-width: 920px;
  max-height: calc(100% - var(--top) - ${({ theme }) => theme.spacing.xlarge});
  margin: var(--top) auto 0 auto;
  top: 0;
  bottom: auto;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.breakPoints.lg}px) {
    flex-direction: row;
    --top: 219px;
  }
`;

const Left = styled(FlexContainer).attrs({ column: true })`
  flex: 1;
  overflow: hidden;
`;

const Header = styled(FlexContainer).attrs({ column: true })`
  padding: 19px ${({ theme }) => theme.spacing.large};
  padding-bottom: 0;
`;

const StyledSidebar = styled(OnboardingConnectAccountSidebar)`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.color.grayFocus};
  flex-shrink: 0;
  display: none;

  @media (min-width: ${({ theme }) => theme.breakPoints.lg}px) {
    width: 360px;
    border-top: none;
    border-left: 1px solid ${({ theme }) => theme.color.grayFocus};
    display: flex;
    flex-direction: column;
  }
`;

const Subtitle = styled(Text)`
  margin-top: ${({ theme }) => theme.spacing.xsmall};

  b {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }
`;

const StyledSearchBar = styled(SearchBar)`
  margin: ${({ theme }) => theme.spacing.xlarge} 0;
`;

const StyledScroll = styled(Scroll).attrs({ hideScrollBar: true })`
  display: flex;
  flex-direction: column;
`;

const TopInstitutionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  padding: 19px ${({ theme }) => theme.spacing.large};
  padding-top: 0;
`;

const SearchButton = styled(DefaultButton).attrs({ size: 'small' })`
  margin-top: ${({ theme }) => theme.spacing.xlarge};
`;

const FooterButton = styled(TextButton)`
  margin-top: ${({ theme }) => theme.spacing.xlarge};
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

type Props = {
  onSelectInstitution: (institution: LinkInstitutionFields) => void;
};

const NewOnboardingConnectAccountCard = ({ onSelectInstitution }: Props) => {
  const searchBarRef = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState('');
  const [isSearchBarFocused, setIsSearchBarFocused] = useState(false);

  const dataProviders = useSupportedDataProviders();

  const { data, isLoadingInitialData } = useQuery<
    Web_OnboardingTopInstitutions,
    Web_OnboardingTopInstitutionsVariables
  >(QUERY, {
    variables: {
      dataProviders,
    },
  });
  const { topInstitutionGroups = [], credentials = [] } = data ?? {};
  const credentialCount = credentials.length;
  const topInstitutions = useMemo(
    () =>
      R.pipe(
        R.map(R.prop('institutions')),
        R.flatten,
        R.take(TOP_INSTITUTION_LIMIT),
      )(topInstitutionGroups) as Web_OnboardingTopInstitutions_topInstitutionGroups_institutions[],
    [topInstitutionGroups],
  );

  const { debouncedSearch, searchInstitutions, isNetworkRequestInFlight } =
    useSearchInstitutions(search);

  return (
    <Root>
      <Left>
        <Header>
          <Text size="large" weight="medium">
            Connect an account
          </Text>
          <Subtitle>
            Connect your <b>banks</b>, <b>credit cards</b>, and <b>investments</b> all in one place.
          </Subtitle>
          <StyledSearchBar
            name="search-institutions"
            placeholder="Search 13,000+ institutions"
            autoComplete="off"
            value={search}
            onChange={setSearch}
            onFocus={() => setIsSearchBarFocused(true)}
            onBlur={() => setIsSearchBarFocused(false)}
            ref={searchBarRef}
          />
        </Header>
        <StyledScroll>
          <Switch>
            <Case
              when={
                isLoadingInitialData || (isNetworkRequestInFlight && !searchInstitutions.length)
              }
            >
              <FlexContainer center>
                <LoadingSpinner />
              </FlexContainer>
            </Case>
            <Case when={!!debouncedSearch}>
              <InstitutionsList
                institutions={searchInstitutions}
                search={debouncedSearch}
                credentialCount={credentialCount}
                onSelectInstitution={onSelectInstitution}
              />
            </Case>
            <Case default>
              <TopInstitutionsContainer>
                <TopInstitutionsGrid
                  institutions={topInstitutions}
                  credentialCount={credentialCount}
                  onSelectInstitution={onSelectInstitution}
                />
                {!isSearchBarFocused && (
                  <SearchButton onClick={() => searchBarRef.current?.focus()}>
                    Search for more institutions
                  </SearchButton>
                )}
                <FooterButton
                  onClick={() => window.open(HELP_CENTER_PRIVACY_SECURITY_URL, '_blank')}
                >
                  <ButtonIcon name="lock" size={16} />
                  Your data is private & secure
                </FooterButton>
              </TopInstitutionsContainer>
            </Case>
          </Switch>
        </StyledScroll>
      </Left>
      <StyledSidebar />
    </Root>
  );
};

const QUERY = gql`
  query Web_OnboardingTopInstitutions($dataProviders: [String]!) {
    credentials {
      id
    }
    topInstitutionGroups(dataProviders: $dataProviders) {
      institutions {
        id
        ...InstitutionRowFields
      }
    }
  }
  ${InstitutionRow.fragments.InstitutionRowFields}
`;

export default NewOnboardingConnectAccountCard;
