import { DateTime } from 'luxon';
import pluralize from 'pluralize';
import * as RA from 'ramda-adjunct';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { maskClassProps } from 'components/lib/higherOrder/withSensitiveData';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import SectionHeader from 'components/lib/ui/SectionHeader';
import Tag from 'components/lib/ui/Tag';
import Text from 'components/lib/ui/Text';
import Tooltip from 'components/lib/ui/Tooltip';
import RouteLink from 'components/lib/ui/link/RouteLink';
import RecurringReviewInfoCard from 'components/recurring/RecurringReviewInfoCard';

import { useLastStatements } from 'common/lib/hooks/recurring/useLastStatements';
import useQuery from 'common/lib/hooks/useQuery';
import type { RecurringStreamToReview } from 'common/lib/recurring/types';
import { isoDateToAbbreviatedMonthDayAndYear } from 'common/utils/date';
import formatTransactionAmount from 'common/utils/formatTransactionAmount';

import { RECURRING } from 'common/constants/copy';
import routes from 'constants/routes';

import { gql } from 'common/generated/gql';

const LOGO_SIZE_PX = 48;

const Root = styled.div`
  border: 1px solid ${({ theme }) => theme.color.grayFocus};
  border-radius: ${({ theme }) => theme.radius.medium};
  background: ${({ theme }) => theme.color.white};
`;

const Header = styled(FlexContainer).attrs({ justifyBetween: true, alignCenter: true })`
  padding: ${({ theme }) => theme.spacing.default};
`;

const StyledSectionHeader = styled(SectionHeader)`
  letter-spacing: 0.1em;
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  padding: ${({ theme }) => theme.spacing.xxsmall} ${({ theme }) => theme.spacing.default};
  text-transform: uppercase;
`;

const TransactionRow = styled(FlexContainer).attrs({ alignCenter: true, justifyBetween: true })`
  padding: ${({ theme }) => theme.spacing.default};
  border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
`;

const CategoryIcon = styled.div`
  font-size: 14px;
  margin-right: ${({ theme }) => theme.spacing.small};
  transform: translateY(-1px);
`;

const EmptyTransactionContainer = styled(FlexContainer).attrs({ center: true })`
  padding: ${({ theme }) => theme.spacing.xlarge} ${({ theme }) => theme.spacing.xsmall};
  border-top: 1px solid ${({ theme }) => theme.color.grayBackground};
`;

const EmptyTransactionText = styled(Text).attrs({ color: 'grayDark' })`
  width: 60%;
  display: block;
  text-align: center;
`;

const SpinnerContainer = styled(FlexContainer).attrs({ center: true })`
  margin: ${({ theme }) => theme.spacing.xlarge} 0;
`;

const Amount = styled(Text).attrs({ weight: 'medium', ...maskClassProps })<{
  $isIncome: boolean;
}>`
  color: ${({ $isIncome, theme }) => ($isIncome ? theme.color.green : theme.color.text)};
  margin-right: ${({ theme }) => theme.spacing.default};
  cursor: default;
`;

const RecurringIcon = styled(Icon).attrs({ name: 'calendar', size: 12 })`
  margin: 0 ${({ theme }) => theme.spacing.default};
  display: block;
  cursor: default;
  color: ${({ theme }) => theme.color.textLight};
`;

const TransactionLink = styled(RouteLink).attrs({ stealthy: true })`
  display: flex;
  flex: 1;
`;

type Props = {
  stream: RecurringStreamToReview['stream'];
};

const ReviewSummaryCard = ({ stream }: Props) => {
  const { merchant, creditReportLiabilityAccount, name, logoUrl } = stream;
  const { id } = merchant ?? creditReportLiabilityAccount ?? {};

  const { data, isLoadingInitialData: isLoadingTransactions } = useQuery(LAST_TRANSACTIONS_QUERY, {
    variables: {
      // @ts-ignore
      merchantId: merchant?.id,
      endDate: DateTime.local().toISODate(),
      isMerchant: !!merchant,
    },
    skip: !merchant,
  });

  const { statements, isLoadingStatements } = useLastStatements({
    creditReportLiabilityAccountId: creditReportLiabilityAccount?.id,
  });

  const isLoadingInitialData = isLoadingTransactions || isLoadingStatements;

  const transactions = useMemo(() => data?.allTransactions?.results ?? [], [data?.allTransactions]);
  const totalCount = data?.allTransactions?.totalCount;

  const content = useMemo(() => {
    if (isLoadingInitialData) {
      return (
        <SpinnerContainer>
          <LoadingSpinner />
        </SpinnerContainer>
      );
    } else if (RA.isNilOrEmpty(transactions) && RA.isNilOrEmpty(statements)) {
      return (
        <EmptyTransactionContainer>
          <EmptyTransactionText>
            There are no transactions yet for this {merchant ? 'merchant' : 'account'}.
          </EmptyTransactionText>
        </EmptyTransactionContainer>
      );
    } else {
      if (creditReportLiabilityAccount) {
        return (
          <>
            <StyledSectionHeader>{RECURRING.LIABILITY_RECENT_BALANCES_HEADER}</StyledSectionHeader>
            {statements.map(({ id, dueDate, billAmount }) => (
              <TransactionRow key={id}>
                <Text size="small">{isoDateToAbbreviatedMonthDayAndYear(dueDate)}</Text>
                <Amount $isIncome={!!billAmount && billAmount > 0} size="small">
                  {billAmount ? formatTransactionAmount(billAmount) : '-'}
                </Amount>
              </TransactionRow>
            ))}
          </>
        );
      }

      return (
        <>
          <StyledSectionHeader>Recent transactions</StyledSectionHeader>
          {transactions.map(({ id, date, amount, category, isRecurring }) => (
            <TransactionRow key={id}>
              <TransactionLink to={routes.transactions.details({ id })}>
                <CategoryIcon>{category?.icon}</CategoryIcon>
                <Text size="small">{isoDateToAbbreviatedMonthDayAndYear(date)}</Text>
              </TransactionLink>
              {!!isRecurring && (
                <Tooltip content="Recurring">
                  <span>
                    <RecurringIcon />
                  </span>
                </Tooltip>
              )}
              <Amount $isIncome={amount > 0} size="small">
                {formatTransactionAmount(amount)}
              </Amount>
            </TransactionRow>
          ))}
        </>
      );
    }
  }, [isLoadingInitialData, transactions, statements, stream?.id]);

  const tag = merchant ? 'MERCHANT' : 'ACCOUNT';

  return (
    <Root>
      <Header>
        <RecurringReviewInfoCard
          id={id}
          name={name}
          logoUrl={logoUrl}
          logoSize={LOGO_SIZE_PX}
          description={
            merchant
              ? `${totalCount} ${pluralize('transaction', totalCount)}`
              : 'Synced via credit report'
          }
        />
        <FlexContainer justifyEnd alignCenter>
          <Tooltip
            opacity={1}
            content={stream.isApproximate ? RECURRING.AMOUNT_ESTIMATE_TOOLTIP : undefined}
          >
            <Tag>{tag}</Tag>
          </Tooltip>
        </FlexContainer>
      </Header>
      {content}
    </Root>
  );
};

const LAST_TRANSACTIONS_QUERY = gql(`
  query Web_GetReviewedMerchantLastTransactions($merchantId: UUID!, $endDate: Date!, $isMerchant: Boolean!) {
    allTransactions(filters: { merchants: [$merchantId], endDate: $endDate }) @include(if: $isMerchant) {
      totalCount
      results(limit: 3) {
        id
        date
        amount
        isRecurring
        category {
          id
          name
          icon
        }
      }
    }
  }
`);

export default ReviewSummaryCard;
