import React from 'react';

import { useFormContext } from 'common/components/form/FormContext';
import LeftToBudgetFooter from 'components/plan/onboarding/LeftToBudgetFooter';
import type { FormValues } from 'components/routes/budget/OnboardingNonMonthly';

import type { AmountsMap } from 'lib/budget/onboardingAdapters';
import {
  getAccumulatedAmountForVariabilities,
  getTooltipRows,
} from 'lib/budget/onboardingAdapters';

type Props = {
  incomeAmount: number;
  accumulatedAmountsMap: AmountsMap;
};

const OnboardingNonMonthlyLeftToBudgetFooter = ({ accumulatedAmountsMap, incomeAmount }: Props) => {
  const { values } = useFormContext<FormValues>();
  const nonMonthlyTotal = Object.values(values).reduce(
    (acc, { monthlyBudget }) => acc + (monthlyBudget || 0),
    0,
  );
  const amountsMapWithUnsavedNonMonthlyTotal = {
    fixed: accumulatedAmountsMap.fixed,
    non_monthly: nonMonthlyTotal,
    flexible: accumulatedAmountsMap.flexible,
  };

  const accumulatedAmount = getAccumulatedAmountForVariabilities(
    amountsMapWithUnsavedNonMonthlyTotal,
  );
  const tooltipRows = getTooltipRows(incomeAmount, amountsMapWithUnsavedNonMonthlyTotal);

  return <LeftToBudgetFooter value={incomeAmount - accumulatedAmount} tooltipRows={tooltipRows} />;
};

export default OnboardingNonMonthlyLeftToBudgetFooter;
