import { DateTime } from 'luxon';
import { propOr } from 'ramda';
import { isNotNil } from 'ramda-adjunct';

import {
  getDefaultRolloverFrequency,
  getRolloverSuggestedBudgetAmount,
} from 'common/lib/budget/Rollovers';
import { DEFAULT_BUDGET_VARIABILITY_IF_UNSET } from 'common/lib/budget/constants';
import { ensureEnumValue } from 'common/utils/Enum';
import { getStartOfCurrentMonthISO } from 'common/utils/date';

import {
  BudgetRolloverPeriodType,
  ROLLOVER_APPLY_TO_FUTURE_MONTHS_FIELD_NAME,
} from 'common/constants/budget';

import type {
  BudgetVariability,
  CategoryFormFieldsFragment,
  CategoryGroupFieldsFragment,
  CreateCategoryGroupInput,
  CreateCategoryInput,
  Maybe,
  Mobile_GetCategoriesQuery,
  UpdateCategoryGroupInput,
  UpdateCategoryInput,
} from 'common/generated/graphql';
import { BudgetRolloverFrequency, CategoryGroupType } from 'common/generated/graphql';

const DEFAULT_CATEGORY_ICON = '❓';

type CategoryInputType<T> = T extends { id: string } ? UpdateCategoryInput : CreateCategoryInput;

// Add any additional fields that are needed for the create/edit
// category form, but not passed to the backend
type CreateEditCategoryAdditionalFields = {
  [ROLLOVER_APPLY_TO_FUTURE_MONTHS_FIELD_NAME]: boolean;
};

export type CreateEditCategoryFormValues<
  T extends Mobile_GetCategoriesQuery['categories'][number] | Partial<CategoryFormFieldsFragment>,
> = CategoryInputType<T> & CreateEditCategoryAdditionalFields;

export const getCategoryFormInitialValues = <
  T extends Mobile_GetCategoriesQuery['categories'][number] | CategoryFormFieldsFragment,
>(
  category?: Partial<T> | null,
): CreateEditCategoryFormValues<T> => {
  const budgetVariability: BudgetVariability = propOr(
    DEFAULT_BUDGET_VARIABILITY_IF_UNSET,
    'budgetVariability',
    category,
  );

  const baseValues = category
    ? {
        id: category.id,
        name: category.name ?? '',
        icon: category.icon ?? DEFAULT_CATEGORY_ICON,
        excludeFromBudget: category.excludeFromBudget ?? false,
        group: category.group?.id ?? '',
        type: category.group?.type ?? CategoryGroupType.INCOME,
        budgetVariability,
      }
    : {
        name: '',
        icon: DEFAULT_CATEGORY_ICON,
        type: CategoryGroupType.INCOME,
        group: '',
        color: undefined,
        orderBefore: undefined,
        budgetVariability,
        excludeFromBudget: false,
      };

  const { rolloverPeriod } = category ?? {};
  const rolloverValues = {
    rolloverEnabled: isNotNil(rolloverPeriod),
    rolloverStartMonth: rolloverPeriod?.startMonth ?? getStartOfCurrentMonthISO(),
    rolloverStartingBalance: rolloverPeriod?.startingBalance ?? 0,
    rolloverFrequency: rolloverPeriod?.frequency ?? getDefaultRolloverFrequency(budgetVariability),
    rolloverTargetAmount: rolloverPeriod?.targetAmount,
    rolloverType: rolloverPeriod?.type,
    rolloverFutureBudgetAllocation: undefined,
    [ROLLOVER_APPLY_TO_FUTURE_MONTHS_FIELD_NAME]: false,
  };

  return {
    ...baseValues,
    ...rolloverValues,
  } as CreateEditCategoryFormValues<T>;
};

export const getCategoryGroupFormInitialValues = <
  T extends Mobile_GetCategoriesQuery['categoryGroups'][number] | CategoryGroupFieldsFragment,
>(
  group?: Maybe<T>,
): CreateCategoryGroupInput | UpdateCategoryGroupInput => {
  const rolloverValues = {
    rolloverEnabled: isNotNil(group?.rolloverPeriod),
    rolloverStartMonth:
      group?.rolloverPeriod?.startMonth ?? DateTime.local().startOf('month').toISODate(),
    rolloverType: BudgetRolloverPeriodType.Monthly, // This is the only one we support for now
    rolloverStartingBalance: group?.rolloverPeriod?.startingBalance ?? 0,
  };

  return group
    ? {
        id: group.id,
        name: group.name,
        type: group.type,
        budgetVariability: group.budgetVariability,
        groupLevelBudgetingEnabled: group.groupLevelBudgetingEnabled,
        ...rolloverValues,
        icon: undefined,
        color: undefined,
      }
    : {
        name: '',
        type: CategoryGroupType.INCOME,
        orderBefore: undefined,
        groupLevelBudgetingEnabled: false,
        budgetVariability: undefined,
        ...rolloverValues,
        icon: undefined,
        color: undefined,
      };
};

export const maybeGetRolloverFutureBudgetAllocation = <
  T extends Mobile_GetCategoriesQuery['categories'][number] | Partial<CategoryFormFieldsFragment>,
>(
  values: CreateEditCategoryFormValues<T>,
): Maybe<number> => {
  const frequency = ensureEnumValue(BudgetRolloverFrequency, values.rolloverFrequency);

  if (
    values.rolloverEnabled &&
    values[ROLLOVER_APPLY_TO_FUTURE_MONTHS_FIELD_NAME] &&
    values.rolloverTargetAmount &&
    frequency
  ) {
    return getRolloverSuggestedBudgetAmount(frequency, values.rolloverTargetAmount);
  }

  return undefined;
};
