import { match } from 'path-to-regexp';
import { lighten } from 'polished';
import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import EmployeeMenu from 'components/employee/EmployeeMenu';
import Text from 'components/lib/ui/Text';
import { OverlayTrigger } from 'components/lib/ui/popover';

import { getIsEmployee } from 'selectors';

import routes from 'constants/routes';

const EXCLUDED_PATH_MATCHERS = [
  match(routes.assistant.path),
  match(routes.transactions.details.path),
];

const Root = styled.div`
  font-size: 13px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  pointer-events: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 164px;
  height: 32px;
  background-color: ${({ theme }) => theme.color.navy};
  border-radius: ${({ theme }) => theme.radius.medium} ${({ theme }) => theme.radius.medium} 0 0;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => lighten(0.1, theme.color.navy)};
  }
`;

const EmployeeMenuButton = () => {
  const isEmployee = useSelector(getIsEmployee);
  const history = useHistory();

  if (!isEmployee) {
    return null;
  }

  if (EXCLUDED_PATH_MATCHERS.some(R.applyTo(history.location.pathname))) {
    return null;
  }

  return (
    <OverlayTrigger placement="top-end" overlay={<EmployeeMenu />}>
      {({ toggleOpen, isOpen }) => (
        <Root onClick={toggleOpen}>
          <Text color="textWhite" size="small">
            Employee Menu
          </Text>
        </Root>
      )}
    </OverlayTrigger>
  );
};

export default EmployeeMenuButton;
