import { DateTime } from 'luxon';
import React from 'react';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import RotateAnimation from 'components/lib/animations/RotateAnimation';
import Banner from 'components/lib/ui/Banner';
import Card from 'components/lib/ui/Card';
import CardBody from 'components/lib/ui/CardBody';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import RelativeDate from 'components/lib/ui/RelativeDate';
import Summary, { SummaryPair } from 'components/lib/ui/Summary';
import Text from 'components/lib/ui/Text';

import {
  getInstitutionPlaidStatus,
  getStatusForDataProvider,
  evaluateInstitutionStatusTitle,
} from 'common/lib/accounts/Institution';
import useForceRefreshAccount from 'common/lib/hooks/accounts/useForceRefreshAccount';
import useDemoHousehold from 'lib/hooks/useDemoHousehold';

import type { AccountDetails_GetAccountQuery } from 'common/generated/graphql';
import { InstitutionStatus } from 'common/types/institutions';

type Props = {
  account: NonNullable<AccountDetails_GetAccountQuery['account']>;
  refetch: () => void;
};

const RefreshIcon = styled(Icon).attrs({ name: 'refresh-cw' })<{
  disabled?: boolean;
}>`
  color: ${({ theme, disabled }) => (disabled ? theme.color.textLight : theme.color.blue)};
  stroke-width: 3;
  height: 18px;
  width: 18px;
  cursor: ${({ disabled }) => (disabled ? 'inherit' : 'pointer')};
`;

const StyledBanner = styled(Banner)`
  font-size: ${({ theme }) => theme.fontSize.small};
  margin-top: ${({ theme }) => `-${theme.spacing.xsmall}`};
`;

const ConnectionStatusLabel = styled(Text)`
  white-space: nowrap;
`;

const ConnectionStatusCard = ({ account, refetch }: Props) => {
  const { blockActionForDemoHousehold } = useDemoHousehold();

  const { dataProvider } = account;
  const institution = account.institution ?? account.credential?.institution;
  const updateRequired = account.credential?.updateRequired ?? false;
  const { name: institutionName } = institution ?? {};
  const balanceStatus = getStatusForDataProvider(
    getInstitutionPlaidStatus(institution, 'balanceStatus'),
    dataProvider,
    account.syncDisabled,
  );
  const transactionsStatus = getStatusForDataProvider(
    getInstitutionPlaidStatus(institution, 'transactionsStatus'),
    dataProvider,
    account.syncDisabled,
  );

  const [hideRefreshStatusBanner, setHideRefreshStatusBanner] = React.useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState<boolean>(false);

  const [forceRefresh, { hasSyncOrRecentRefreshRequest, canBeForceRefreshed, error }] =
    useForceRefreshAccount(account.id, {
      onDidFinishRefresh: () => {
        refetch();
        setShowSuccessMessage(true);
      },
    });

  // Toggled via employee menu
  const institutionLabel = institutionName ? `${institutionName} accounts` : 'account';

  const showRefreshButton = ['plaid', 'finicity', 'zillow', 'mx', 'apple_finance_kit'].includes(
    dataProvider ?? '',
  );
  const showTransactionSyncingStatus = !['zillow'].includes(dataProvider ?? '');

  const statusTitle = evaluateInstitutionStatusTitle({
    transactionsStatus,
    balanceStatus,
    showTransactionSyncingStatus,
    updateRequired,
  });

  return (
    <>
      <Card
        title="Connection status"
        controls={
          !updateRequired &&
          !account.syncDisabled &&
          showRefreshButton && (
            <FlexContainer
              contentCenter
              onClick={() =>
                blockActionForDemoHousehold(() => {
                  setHideRefreshStatusBanner(!canBeForceRefreshed);
                  forceRefresh();
                })
              }
              id="forceRefreshBtn"
            >
              <RotateAnimation enabled={hasSyncOrRecentRefreshRequest}>
                <RefreshIcon disabled={hasSyncOrRecentRefreshRequest} />
              </RotateAnimation>
            </FlexContainer>
          )
        }
      >
        <CardBody>
          {!hideRefreshStatusBanner && (
            <Switch>
              <Case when={Boolean(account.syncDisabled)}>{null}</Case>
              <Case when={Boolean(error)}>
                <StyledBanner type="error">
                  An unexpected error occurred when trying to refresh {institutionLabel}. The
                  Monarch team has been notified and will get started on a fix.
                </StyledBanner>
              </Case>
              <Case when={hasSyncOrRecentRefreshRequest}>
                <StyledBanner type="info">
                  Monarch is syncing with your {institutionLabel} in the background. This can take
                  up to a minute to complete.
                </StyledBanner>
              </Case>
              <Case when={showSuccessMessage}>
                <StyledBanner type="success">
                  Success! Monarch has the most recent information from{' '}
                  {institutionName || 'this institution'}.
                </StyledBanner>
              </Case>
            </Switch>
          )}
          <Summary>
            <SummaryPair
              dim
              label={balanceStatus === InstitutionStatus.SyncDisabled ? 'Inactive' : 'Last update'}
            >
              {balanceStatus === InstitutionStatus.SyncDisabled ? (
                <>{DateTime.fromISO(account.deactivatedAt!).toLocaleString(DateTime.DATE_MED)}</>
              ) : (
                <RelativeDate date={account.displayLastUpdatedAt} />
              )}
            </SummaryPair>
            <SummaryPair dim label="Status">
              <FlexContainer alignCenter justifyEnd>
                <ConnectionStatusLabel>{statusTitle}</ConnectionStatusLabel>
              </FlexContainer>
            </SummaryPair>
          </Summary>
        </CardBody>
      </Card>
    </>
  );
};

export default ConnectionStatusCard;
