import { gql } from '@apollo/client';
import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';

import CategoriesTourOverlay from 'components/categories/CategoriesTourOverlay';
import CreateCategoryGroupModal from 'components/categories/CreateCategoryGroupModal';
import CreateCategoryModal from 'components/categories/CreateCategoryModal';
import EditCategoryGroupModal from 'components/categories/EditCategoryGroupModal';
import EditCategoryModal from 'components/categories/EditCategoryModal';
import ManageCategoryGroupCard from 'components/categories/ManageCategoryGroupCard';
import ManageCategoryTypeSection from 'components/categories/ManageCategoryTypeSection';
import DragDropContext from 'components/lib/dnd/DragDropContext';
import Banner from 'components/lib/ui/Banner';
import Icon from 'components/lib/ui/Icon';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Modal from 'components/lib/ui/Modal';

import { manageCategoriesAdapter } from 'common/lib/categories/Adapters';
import useQuery from 'common/lib/hooks/useQuery';
import { handleDropResult } from 'lib/categories/DragAndDrop';
import useUpdateCategoryGroupOrderMutation from 'lib/hooks/budget/useUpdateCategoryGroupOrderMutation';
import useUpdateCategoryOrderMutation from 'lib/hooks/budget/useUpdateCategoryOrderMutation';

import type { CreateCategoryInput, ManageGetCategoryGroupsQuery } from 'common/generated/graphql';
import { CategoryGroupType } from 'common/generated/graphql';

const TYPES = [CategoryGroupType.INCOME, CategoryGroupType.EXPENSE, CategoryGroupType.TRANSFER];

const Root = styled.div`
  padding: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.xlarge};
`;

const InfoIcon = styled(Icon).attrs({ name: 'info ' })`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  margin-right: ${({ theme }) => theme.spacing.default};
`;

const InfoBanner = styled(Banner).attrs({ type: 'info' })`
  font-size: ${({ theme }) => theme.fontSize.small};
  display: flex;
  align-items: center;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.xlarge};
  margin-top: ${({ theme }) => theme.spacing.default};
`;

/** Keep refs for each section (Income, Expenses, Transfer). When a group is created, scroll to the
 * bottom of that section (because it's created at the end of the section). */
const useScrollToNewlyCreatedGroup = (loading: boolean) => {
  const [sectionRefs] = useState<{ [type in CategoryGroupType]?: HTMLDivElement | null }>({});
  const registerSectionRef = (type: CategoryGroupType) => (ref: HTMLDivElement | null) => {
    sectionRefs[type] = ref;
  };

  const [scrollToSection, setScrollToSection] = useState<CategoryGroupType | null>(null);

  useEffect(() => {
    if (scrollToSection && !loading) {
      sectionRefs[scrollToSection]?.scrollIntoView(false);
      setScrollToSection(null);
    }
  }, [sectionRefs, scrollToSection, setScrollToSection, loading]);

  return [registerSectionRef, setScrollToSection] as const;
};

const ManageCategories = () => {
  const [creatingGroupType, setCreatingGroupType] = useState<CategoryGroupType | null>(null);
  const [creatingCategory, setCreatingCategory] = useState<Partial<CreateCategoryInput> | null>(
    null,
  );
  const [editingCategoryGroupId, setEditingCategoryGroupId] = useState<string | null>(null);
  const [editingCategoryId, setEditingCategoryId] = useState<string | null>(null);

  const { data, isLoadingInitialData, refetch } =
    useQuery<ManageGetCategoryGroupsQuery>(GET_GROUPS);
  const [groupsByType, categoriesByGroup] = useMemo(() => manageCategoriesAdapter(data), [data]);
  const [updateCategoryOrder] = useUpdateCategoryOrderMutation({
    onCompleted: () => refetch(),
  });
  const [updateCategoryGroupOrder] = useUpdateCategoryGroupOrderMutation({
    onCompleted: () => refetch(),
  });

  const [registerSectionRef, scrollToSection] = useScrollToNewlyCreatedGroup(isLoadingInitialData);

  return (
    <Root>
      <CategoriesTourOverlay />
      <InfoBanner>
        <InfoIcon />
        Changes you make to your groups and categories here will be applied all throughout Monarch.
        You should customize your category structure to fit your needs.
      </InfoBanner>
      {isLoadingInitialData ? (
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      ) : (
        <DragDropContext
          onDragEnd={handleDropResult(updateCategoryOrder, updateCategoryGroupOrder)}
        >
          {TYPES.map((type) => (
            <div key={type} ref={registerSectionRef(type)}>
              <ManageCategoryTypeSection
                type={type}
                onClickCreateGroup={() => setCreatingGroupType(type)}
              >
                {groupsByType[type]?.map((group) => (
                  <ManageCategoryGroupCard
                    key={group.id}
                    group={group}
                    onClickEditGroup={() => setEditingCategoryGroupId(group.id)}
                    onClickCreateCategory={() => setCreatingCategory({ group: group.id })}
                    onClickEditCategory={({ id }) => setEditingCategoryId(id)}
                    categories={categoriesByGroup[group.id]}
                  />
                ))}
              </ManageCategoryTypeSection>
            </div>
          ))}
        </DragDropContext>
      )}
      {creatingGroupType && (
        <Modal onClose={() => setCreatingGroupType(null)}>
          {({ close }) => (
            <CreateCategoryGroupModal
              categoryType={creatingGroupType}
              onDone={() => {
                close();
                refetch().then(() => {
                  scrollToSection(creatingGroupType);
                });
              }}
            />
          )}
        </Modal>
      )}
      {creatingCategory && (
        <Modal onClose={() => setCreatingCategory(null)}>
          {({ close }) => (
            <CreateCategoryModal
              initialValues={creatingCategory}
              onCreate={() => {
                close();
                refetch();
              }}
            />
          )}
        </Modal>
      )}
      {!!editingCategoryGroupId && (
        <Modal onClose={() => setEditingCategoryGroupId(null)}>
          {({ close }) => (
            <EditCategoryGroupModal
              categoryGroupId={editingCategoryGroupId}
              onDone={() => {
                close();
                refetch();
              }}
            />
          )}
        </Modal>
      )}
      {!!editingCategoryId && (
        <Modal onClose={() => setEditingCategoryId(null)}>
          {({ close }) => (
            <EditCategoryModal
              categoryId={editingCategoryId}
              onDone={() => {
                close();
                refetch();
              }}
            />
          )}
        </Modal>
      )}
    </Root>
  );
};

const GET_GROUPS = gql`
  query ManageGetCategoryGroups {
    categoryGroups {
      id
      name
      order
      type
    }
    categories(includeDisabledSystemCategories: true) {
      id
      name
      order
      icon
      isSystemCategory
      systemCategory
      isDisabled
      group {
        id
        type
        name
      }
    }
  }
`;

export default ManageCategories;
