import React, { useEffect } from 'react';
import styled from 'styled-components';

import SpinwheelDIM from 'components/lib/external/SpinwheelDIM';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Link from 'components/lib/ui/Link';
import Text from 'components/lib/ui/Text';
import AsyncButton from 'components/lib/ui/button/AsyncButton';
import { primaryButtonMixin } from 'components/lib/ui/button/PrimaryButton';
import SpinwheelConnectionFlow from 'components/spinwheel/SpinwheelConnectionFlow';

import useSpinwheel from 'common/lib/hooks/recurring/useSpinwheel';
import useModal from 'lib/hooks/useModal';

import {
  // SPINWHEEL_TERMS_OF_USE_URL,
  // SPINWHEEL_PRIVACY_POLICY,
  // MONARCH_TERMS_OF_USE_URL,
  // MONARCH_PRIVACY_POLICY,
  HELP_CENTER_CONNECTING_TO_SPINWHEEL,
} from 'common/constants/externalUrls';

import type { Common_GetSpinwheelCreditReportQuery } from 'common/generated/graphql';

const Root = styled(FlexContainer).attrs({ column: true, alignCenter: true })`
  text-align: center;
  max-width: 600px;
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacing.large};
`;

const Title = styled(Text).attrs({ size: 'xlarge', weight: 'medium' })`
  margin-top: ${({ theme }) => theme.spacing.xxlarge};
  margin-bottom: ${({ theme }) => theme.spacing.default};
  max-width: 475px;
`;

const TitleWrapper = styled(Flex).attrs({ column: true, alignCenter: true })`
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
`;

const Subtitle = styled(Text).attrs({ size: 'large', weight: 'medium' })`
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const SubtitleWrapper = styled(Flex).attrs({ column: true, alignCenter: true })`
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
  padding: 0 ${({ theme }) => theme.spacing.xxxxlarge};
`;

const StyledButton = styled(AsyncButton)`
  ${primaryButtonMixin};
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.xxlarge};
`;

type Props = {
  onClickNext: (
    creditReport:
      | Common_GetSpinwheelCreditReportQuery['creditReportLiabilityAccounts']
      | null
      | undefined,
  ) => void;
};

const MapSpinwheelLiabilitiesStart = ({ onClickNext }: Props) => {
  const [AddBillsModal, { open: openAddBillsModal, close: closeAddBillsModal }] = useModal();
  const [ConnectionModal, { open: openConnectionModal, close: closeConnectionModal }] = useModal();

  const {
    syncCreditReport,
    isSyncingCreditReport,
    creditReportLiabilityAccounts,
    isCreditReportSyncTimedOut,
    connectionStatus,
    resetTimeout,
  } = useSpinwheel();

  useEffect(() => {
    if (connectionStatus.hasSyncedCreditReport) {
      onClickNext(creditReportLiabilityAccounts);
    } else if (isCreditReportSyncTimedOut) {
      openConnectionModal();
    }
  }, [connectionStatus.hasSyncedCreditReport, isCreditReportSyncTimedOut]);

  const handleCloseConnectionModal = () => {
    resetTimeout();
    closeConnectionModal();
  };

  return (
    <>
      <Root>
        <TitleWrapper>
          <Title>The most reliable way to provide statement balances</Title>
          <Text>
            Our partner Spinwheel provides statement balances and due dates with information from
            your credit report for the best reliability. Your information is always safe and secure
            and there is no impact to your credit score.
          </Text>
        </TitleWrapper>

        <SubtitleWrapper>
          <Subtitle>Connect in seconds</Subtitle>

          <Text>
            Once you&apos;ve connected with Spinwheel, you&apos;ll be able to choose which credit
            cards and loans you want to track in Monarch.
          </Text>
        </SubtitleWrapper>

        <SubtitleWrapper>
          <Subtitle>Your data is kept safe</Subtitle>

          <Text>Both Spinwheel and Monarch use bank-level security to help protect your data.</Text>
        </SubtitleWrapper>

        <SubtitleWrapper>
          <Subtitle>You&apos;re in control</Subtitle>

          <Text>Disconnect from Spinwheel and delete your synced data at any time.</Text>
        </SubtitleWrapper>

        <Text>
          To learn more, check out our{' '}
          <Link href={HELP_CENTER_CONNECTING_TO_SPINWHEEL} target="_blank">
            help article
          </Link>
          .
        </Text>

        <StyledButton
          size="large"
          pending={isSyncingCreditReport}
          onClick={() => {
            if (connectionStatus.isDisconnected) {
              syncCreditReport();
              openConnectionModal();
            } else {
              openAddBillsModal();
            }
          }}
        >
          Connect with Spinwheel
        </StyledButton>

        {/* This has been commented due to the fact that our terms/privacy is not currently updated */}
        {/* we indent to uncomment this before GA launch and after okay from legal */}

        {/* <FooterText>
          By syncing your Spinwheel account, you agree to Spinwheel&apos;s{' '}
          <Link href={SPINWHEEL_TERMS_OF_USE_URL}>Terms of Use</Link> and{' '}
          <Link href={SPINWHEEL_PRIVACY_POLICY}>Privacy Policy</Link>. Any information synced from
          your Spinwheel account to your Monarch account will be processed in accordance with
          Monarch&apos;s updated <Link href={MONARCH_TERMS_OF_USE_URL}>Terms of Use</Link> and{' '}
          <Link href={MONARCH_PRIVACY_POLICY}>Privacy Policy</Link>.
        </FooterText> */}
      </Root>

      <AddBillsModal>
        <SpinwheelDIM
          onClose={closeAddBillsModal}
          onSuccess={() => {
            closeAddBillsModal();
            syncCreditReport();

            // Open to show the loading state in modal
            // while we wait for the credit report to sync
            openConnectionModal();
          }}
        />
      </AddBillsModal>

      <ConnectionModal onClose={handleCloseConnectionModal}>
        <SpinwheelConnectionFlow
          isLoading={isSyncingCreditReport}
          hasFraudError={connectionStatus.hasFraudError}
          hasGenericError={connectionStatus.hasGenericError}
          onComplete={handleCloseConnectionModal}
        />
      </ConnectionModal>
    </>
  );
};

export default MapSpinwheelLiabilitiesStart;
