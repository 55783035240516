import { rgba } from 'polished';
import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import PremiumBadge from 'components/premium/PremiumBadge';
import PremiumFeatureLimitOverlayTrigger from 'components/premium/PremiumFeatureLimitOverlayTrigger';
import PremiumFeatureOverlayTrigger from 'components/premium/PremiumFeatureOverlayTrigger';

import { ACCOUNT_TYPE_TO_ICON_MAP } from 'common/constants/accounts';
import { INSTITUTION_NAME_ASSOCIATED_FEATURE, ProductFeatureLimit } from 'common/constants/premium';

import type { TopInstitutionsQuery_topInstitutions } from 'common/generated/graphQlTypes/TopInstitutionsQuery';
import type { Web_GetTopInstitutionGroups_topInstitutionGroups_manualAccountShortcuts } from 'common/generated/graphQlTypes/Web_GetTopInstitutionGroups';

const Root = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: ${({ theme }) => theme.spacing.xlarge};
  row-gap: ${({ theme }) => theme.spacing.xlarge};
`;

const Item = styled(FlexContainer).attrs({ center: true, column: true })`
  position: relative;
  border-radius: ${({ theme }) => theme.radius.medium};
  height: 96px;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize.small};
  text-align: center;
  background: ${({ theme }) =>
    theme.uiTheme === 'dark' ? rgba(theme.color.textWhite, 0.8) : theme.color.grayBackground};
  color: ${({ theme }) => theme.color.black};
  transition: ${({ theme }) => theme.transition.default};
  padding: ${({ theme }) => theme.spacing.xsmall};

  :hover {
    background: ${({ theme }) =>
      theme.uiTheme === 'dark' ? rgba(theme.color.textWhite, 0.9) : theme.color.grayFocus};
  }
`;

const ShortcutIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

const pulse = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const Image = styled.img<{ isLoading: boolean }>`
  width: 70%;
  height: 60%;

  ${({ isLoading }) =>
    isLoading &&
    css`
      animation: 0.8s ease-in-out infinite ${pulse};
    `};
`;

const StyledPremiumBadge = styled(PremiumBadge)`
  margin-top: ${({ theme }) => theme.spacing.xxsmall};
`;

type Props = {
  institutions: TopInstitutionsQuery_topInstitutions[];
  onSelectInstitution: (institution: TopInstitutionsQuery_topInstitutions) => void;
  credentialCount: number;
  manualAccountShortcuts?: Web_GetTopInstitutionGroups_topInstitutionGroups_manualAccountShortcuts[];
  onSelectManualAccountShortcut?: (
    manualAccountShortcut: Web_GetTopInstitutionGroups_topInstitutionGroups_manualAccountShortcuts,
  ) => void;
  loadingInstitutionId?: string;
  className?: string;
};

const TopInstitutionsGrid = ({
  institutions,
  onSelectInstitution,
  credentialCount,
  manualAccountShortcuts,
  onSelectManualAccountShortcut,
  loadingInstitutionId,
  className,
}: Props) => (
  <Root className={className}>
    {institutions.map((institution) => (
      <PremiumFeatureLimitOverlayTrigger
        key={institution.id}
        featureLimit={ProductFeatureLimit.CredentialLimit}
        currentValue={credentialCount}
        placement="bottom-start"
      >
        {({ meetsOrExceedsLimit }) => (
          <PremiumFeatureOverlayTrigger
            feature={INSTITUTION_NAME_ASSOCIATED_FEATURE[institution.name]}
            placement="bottom-start"
          >
            {({ hasAccess }) => (
              <Item
                onClick={() =>
                  hasAccess && !meetsOrExceedsLimit && onSelectInstitution(institution)
                }
              >
                {institution.largeLogo?.url ? (
                  <Image
                    src={institution.largeLogo.url}
                    isLoading={loadingInstitutionId === institution.id}
                  />
                ) : (
                  <span>{institution.name}</span>
                )}
                {!hasAccess && <StyledPremiumBadge />}
              </Item>
            )}
          </PremiumFeatureOverlayTrigger>
        )}
      </PremiumFeatureLimitOverlayTrigger>
    ))}
    {manualAccountShortcuts?.map((manualAccountShortcut) => (
      <Item
        key={manualAccountShortcut.title}
        onClick={() => onSelectManualAccountShortcut?.(manualAccountShortcut)}
      >
        <FlexContainer column alignCenter>
          <ShortcutIcon name={ACCOUNT_TYPE_TO_ICON_MAP[manualAccountShortcut.accountType.name]} />
          <span>{manualAccountShortcut.title}</span>
        </FlexContainer>
      </Item>
    ))}
  </Root>
);

export default TopInstitutionsGrid;
