import * as R from 'ramda';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import LinkAccountFlow from 'components/accounts/LinkAccountFlow';
import CheckCircle from 'components/lib/ui/CheckCircle';
import CheckProgressBar from 'components/lib/ui/CheckProgressBar';
import DashboardWidget from 'components/lib/ui/DashboardWidget';
import FlexContainer from 'components/lib/ui/FlexContainer';
import TextButton from 'components/lib/ui/TextButton';

import useDashboardConfig from 'common/lib/hooks/dashboard/useDashboardConfig';
import useOnboardingChecklist, {
  ChecklistItemName,
} from 'common/lib/hooks/dashboard/useOnboardingChecklist';
import { DashboardWidgetName } from 'common/state/dashboard/types';
import useModal from 'lib/hooks/useModal';

import routes from 'constants/routes';

const Row = styled(FlexContainer).attrs({ alignCenter: true })<{ $checked: boolean }>`
  padding: ${({ theme }) => theme.spacing.large} ${({ theme }) => theme.spacing.xlarge};
  transition: ${({ theme }) => theme.transition.default};
  border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  user-select: none;

  ${({ $checked }) =>
    !$checked &&
    css`
      cursor: pointer;

      :hover {
        background: ${({ theme }) => theme.color.grayBackground};
      }
    `}

  :last-child {
    border-radius: 0 0 ${({ theme }) => theme.radius.medium} ${({ theme }) => theme.radius.medium};
    border-bottom: none;
  }
`;

const Emoji = styled.span`
  font-size: ${({ theme }) => theme.fontSize.large};
`;

const RowTitle = styled.span<{ $checked: boolean }>`
  margin: 0 ${({ theme }) => theme.spacing.default};
  flex: 1;

  ${({ $checked }) =>
    $checked &&
    css`
      text-decoration: line-through;
      color: ${({ theme }) => theme.color.textLight};
    `}
`;

const OrangeCheckCircle = styled(CheckCircle)`
  background: ${({ theme }) => theme.color.orange};
`;

const Circle = styled.div`
  background: ${({ theme }) => theme.color.grayFocus};
  height: 24px;
  width: 24px;
  border-radius: ${({ theme }) => theme.radius.round};
`;

const StyledProgressBar = styled(CheckProgressBar)`
  margin-top: ${({ theme }) => theme.spacing.default};
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

const Footer = styled(FlexContainer)`
  padding: ${({ theme }) => theme.spacing.large} 46px;
`;

const OnboardingDashboardWidget = () => {
  const history = useHistory();

  const { items, titleCopy, isLoadingInitialData, refetch, completedCount } =
    useOnboardingChecklist();

  const [dashboardConfig, updateDashboardConfig] = useDashboardConfig('web');

  const [LinkModal, { open: openLink, close: closeLink }] = useModal();

  const onClickRow = useCallback(
    (itemName: ChecklistItemName) => {
      switch (itemName) {
        case ChecklistItemName.AddAccount:
          openLink();
          break;
        case ChecklistItemName.CustomizeCategories:
          history.push(routes.settings.categories());
          break;
        case ChecklistItemName.CreateGoal:
          history.push(routes.goalsV2());
          break;
        case ChecklistItemName.CreatePlan:
          history.push(routes.budget());
          break;
      }
    },
    [history, openLink],
  );

  const disableWidget = useCallback(() => {
    updateDashboardConfig(
      R.mergeDeepLeft(
        {
          widgets: {
            [DashboardWidgetName.GETTING_STARTED]: {
              enabled: false,
            },
          },
        },
        dashboardConfig,
      ),
      true,
    );
  }, [updateDashboardConfig, dashboardConfig]);

  return (
    <>
      <DashboardWidget
        title="Getting Started"
        description={
          !isLoadingInitialData && (
            <FlexContainer column>
              <span>{titleCopy}</span>
              <StyledProgressBar
                progress={completedCount / items.length}
                stepCount={items.length}
              />
            </FlexContainer>
          )
        }
        headerLink="/accounts"
        loading={isLoadingInitialData}
      >
        {items.map(({ name, icon, title, completed }) => (
          <Row key={title} $checked={completed} onClick={() => onClickRow(name)}>
            <Emoji>{icon}</Emoji>
            <RowTitle $checked={completed}>{title}</RowTitle>
            {completed ? <OrangeCheckCircle /> : <Circle />}
          </Row>
        ))}
        <Footer>
          <TextButton onClick={disableWidget}>Hide this widget</TextButton>
        </Footer>
      </DashboardWidget>
      <LinkModal onClose={refetch}>
        <LinkAccountFlow onClose={closeLink} onComplete={closeLink} />
      </LinkModal>
    </>
  );
};

export default OnboardingDashboardWidget;
