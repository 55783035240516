// THIS FILE IS GENERATED, DO NOT MODIFY MANUALLY. ##checksum##: 1558564853

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * An enumeration.
 */
export enum AccountTypeGroup {
  asset = 'asset',
  liability = 'liability',
  other = 'other',
}

/**
 * An enumeration.
 */
export enum ActivityEventType {
  ACCOUNT_ERROR = 'ACCOUNT_ERROR',
  BUDGET_EXCEEDED = 'BUDGET_EXCEEDED',
  GOAL_BALANCES_CHECKIN = 'GOAL_BALANCES_CHECKIN',
  GOAL_CONTRIBUTION = 'GOAL_CONTRIBUTION',
  GOAL_MILESTONE = 'GOAL_MILESTONE',
  LARGE_TRANSACTION = 'LARGE_TRANSACTION',
  NEW_RECURRING_MERCHANTS_DETECTED = 'NEW_RECURRING_MERCHANTS_DETECTED',
  REFERRAL_SUBSCRIPTION = 'REFERRAL_SUBSCRIPTION',
  TRANSACTIONS_NEED_REVIEW = 'TRANSACTIONS_NEED_REVIEW',
  UNCATEGORIZED_TRANSACTIONS = 'UNCATEGORIZED_TRANSACTIONS',
  VENMO_TRANSACTION_DETECTED_ON_NON_VENMO_ACCOUNT = 'VENMO_TRANSACTION_DETECTED_ON_NON_VENMO_ACCOUNT',
}

/**
 * An enumeration.
 */
export enum BudgetTimeframeInput {
  month = 'month',
}

/**
 * An enumeration.
 */
export enum CancellationReason {
  connection_issues = 'connection_issues',
  missing_features = 'missing_features',
  not_enough_value = 'not_enough_value',
  other = 'other',
}

export enum CategoryGroupType {
  E = 'E',
  I = 'I',
  T = 'T',
  expense = 'expense',
  income = 'income',
  transfer = 'transfer',
}

/**
 * An enumeration.
 */
export enum ContactSupportReason {
  billing_something_else = 'billing_something_else',
  bug_report = 'bug_report',
  cancellation_and_refunds = 'cancellation_and_refunds',
  connection_something_else = 'connection_something_else',
  delete_account = 'delete_account',
  duplicate_accounts = 'duplicate_accounts',
  extended_and_new_trials = 'extended_and_new_trials',
  general_something_else = 'general_something_else',
  how_it_works = 'how_it_works',
  initial_connection_failure = 'initial_connection_failure',
  institution_keeps_disconnecting = 'institution_keeps_disconnecting',
  missing_accounts = 'missing_accounts',
  missing_delayed_trans = 'missing_delayed_trans',
  missing_or_dupe_accounts = 'missing_or_dupe_accounts',
  monarch_assistant = 'monarch_assistant',
  payment_issue = 'payment_issue',
  referrals_and_promotion_codes = 'referrals_and_promotion_codes',
  wrong_account_type = 'wrong_account_type',
}

/**
 * An enumeration.
 */
export enum ContactSupportTopic {
  billing = 'billing',
  connection_issue = 'connection_issue',
  general = 'general',
}

export enum DataProviderLegacy {
  APPLE_CARD = 'APPLE_CARD',
  APPLE_FINANCE_KIT = 'APPLE_FINANCE_KIT',
  COINBASE = 'COINBASE',
  FINICITY = 'FINICITY',
  MX = 'MX',
  PLAID = 'PLAID',
  ZILLOW = 'ZILLOW',
}

/**
 * An enumeration.
 */
export enum ErrorCode {
  CREDENTIAL_REFRESH_UNAVAILABLE = 'CREDENTIAL_REFRESH_UNAVAILABLE',
  DELETE_SNAPSHOTS_HISTORY_UNAVAILABLE = 'DELETE_SNAPSHOTS_HISTORY_UNAVAILABLE',
  DELETE_TRANSACTIONS_HISTORY_UNAVAILABLE = 'DELETE_TRANSACTIONS_HISTORY_UNAVAILABLE',
  DOWNGRADE_ACTIONS_REQUIRED = 'DOWNGRADE_ACTIONS_REQUIRED',
  EMAIL_UNVERIFIED_ON_PROVIDER = 'EMAIL_UNVERIFIED_ON_PROVIDER',
  HOUSEHOLD_INVITE_USER_ALREADY_EXISTS = 'HOUSEHOLD_INVITE_USER_ALREADY_EXISTS',
  HOUSEHOLD_SCHEDULED_FOR_DELETION = 'HOUSEHOLD_SCHEDULED_FOR_DELETION',
  SPONSOR_NOT_FOUND = 'SPONSOR_NOT_FOUND',
  SUBSCRIPTION_ENDED = 'SUBSCRIPTION_ENDED',
  TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
  USER_ALREADY_SIGNED_UP = 'USER_ALREADY_SIGNED_UP',
  USER_IS_ONBOARDING = 'USER_IS_ONBOARDING',
}

/**
 * An enumeration.
 */
export enum EventPriority {
  ERROR = 'ERROR',
  INFORMATIONAL = 'INFORMATIONAL',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
}

/**
 * An enumeration.
 */
export enum GoalContributionType {
  MANUAL = 'MANUAL',
  STARTING_BALANCE = 'STARTING_BALANCE',
}

export enum GoalGoalType {
  DOWN_PAYMENT = 'DOWN_PAYMENT',
  EMERGENCY_FUND = 'EMERGENCY_FUND',
  GENERAL_SAVINGS = 'GENERAL_SAVINGS',
  HOLIDAYS = 'HOLIDAYS',
  OTHER = 'OTHER',
  UPCOMING_TRIP = 'UPCOMING_TRIP',
}

/**
 * An enumeration.
 */
export enum InstitutionStatus {
  DEGRADED = 'DEGRADED',
  DOWN = 'DOWN',
  HEALTHY = 'HEALTHY',
}

/**
 * An enumeration.
 */
export enum ManualInvestmentsAccountTrackingMethod {
  balances = 'balances',
  holdings = 'holdings',
}

export enum MerchantOrdering {
  NAME = 'NAME',
  TRANSACTION_COUNT = 'TRANSACTION_COUNT',
}

/**
 * Keep it in sync with the Banner `type` prop.
 */
export enum MessageBlockAppearance {
  error = 'error',
  info = 'info',
  success = 'success',
  warning = 'warning',
}

/**
 * An enumeration.
 */
export enum PaymentPeriod {
  LIFETIME = 'LIFETIME',
  MONTHLY = 'MONTHLY',
  OTHER = 'OTHER',
  YEARLY = 'YEARLY',
}

/**
 * An enumeration.
 */
export enum Platform {
  android = 'android',
  ios = 'ios',
  web = 'web',
}

/**
 * An enumeration.
 */
export enum ProductFeature {
  apple_card = 'apple_card',
  attachments = 'attachments',
  coinbase = 'coinbase',
  custom_categories = 'custom_categories',
  customer_support = 'customer_support',
  download_csv = 'download_csv',
  investments = 'investments',
  rollovers = 'rollovers',
  tags = 'tags',
  weekly_review = 'weekly_review',
  zillow = 'zillow',
}

/**
 * An enumeration.
 */
export enum StripeCouponDuration {
  forever = 'forever',
  once = 'once',
  repeating = 'repeating',
}

export enum SubscriptionDetailsPaymentSource {
  APP_STORE = 'APP_STORE',
  PLAY_STORE = 'PLAY_STORE',
  PROMOTIONAL = 'PROMOTIONAL',
  STRIPE = 'STRIPE',
}

/**
 * An enumeration.
 */
export enum SubscriptionEntitlement {
  lite = 'lite',
  premium = 'premium',
}

export enum SystemCategory {
  UNCATEGORIZED = 'UNCATEGORIZED',
}

export enum TopInstitutionGroupType {
  assets = 'assets',
  banks_credit_cards = 'banks_credit_cards',
  investments_loans = 'investments_loans',
}

export enum TransactionOrdering {
  amount = 'amount',
  date = 'date',
}

/**
 * An enumeration.
 */
export enum TrialType {
  free_trial = 'free_trial',
  guest_pass = 'guest_pass',
  premium = 'premium',
  referrals = 'referrals',
}

export enum UserHouseholdRole {
  MEMBER = 'MEMBER',
  OWNER = 'OWNER',
}

export interface AccountFilters {
  accountType?: string | null;
  excludeAccountTypes?: string[] | null;
  includeManual?: boolean | null;
  includeHidden?: boolean | null;
  groups?: AccountTypeGroup[] | null;
  ids?: string[] | null;
}

export interface AmountCriteriaFieldV2Input {
  operator: string;
  isExpense: boolean;
  value?: number | null;
  valueRange?: DecimalRangeSerializerInput | null;
}

export interface AnswerProfileQuestionInput {
  questionnaireName: string;
  questionName: string;
  answer?: GraphQlJSONString | null;
  dontKnow?: boolean | null;
}

export interface ApplyStripeCancelDiscountOfferInput {
  reason: CancellationReason;
}

export interface ArchiveGoalInput {
  id: string;
}

export interface BulkTransactionDataParams {
  isAllSelected?: boolean | null;
  expectedAffectedTransactionCount?: number | null;
  selectedTransactionIds?: (string | null)[] | null;
  excludedTransactionIds?: (string | null)[] | null;
  filters?: TransactionFilterInput | null;
}

export interface CancelSponsorshipInput {
  id: string;
}

export interface CancelStripeSubscriptionMutationInput {
  reason: string;
  feedback?: string | null;
}

export interface ChangeStripeSubscriptionMutationInput {
  stripePriceId: string;
  stripePromoCode?: string | null;
}

export interface ConfirmTOTPDeviceInput {
  token: string;
}

export interface CopySnapshotsInput {
  fromAccountId: string;
  toAccountId: string;
  cutoverDate: GraphQlDate;
}

export interface CreateBudgetMutationInput {
  startDate?: GraphQlDate | null;
  timeframe?: BudgetTimeframeInput | null;
}

export interface CreateCategoryGroupInput {
  name: string;
  orderBefore?: GraphQlUUID | null;
  type: string;
  color?: string | null;
}

export interface CreateCategoryInput {
  name: string;
  icon: string;
  group: string;
  type?: string | null;
  rolloverEnabled?: boolean | null;
  rolloverType?: string | null;
  rolloverStartMonth?: GraphQlDate | null;
}

export interface CreateCoinbaseAccountInput {
  code: string;
  redirectUri: string;
}

export interface CreateGoalAccountAllocationInput {
  goalId: string;
  accountId: string;
  amount?: number | null;
  useEntireAccountBalance?: boolean | null;
}

export interface CreateGoalContributionsMutationInput {
  date: GraphQlDate;
  goal: string;
  amount: number;
  notes?: string | null;
}

export interface CreateGoalInput {
  name: string;
  objective: string;
  targetAmount?: number | null;
  startingAmount?: number | null;
  plannedMonthlyContribution?: number | null;
  plannedMonthlyPretaxContribution?: number | null;
  imageStorageProvider?: string | null;
  imageStorageProviderId?: string | null;
}

export interface CreateGoalMutationInput {
  name: string;
  icon: string;
  goalType: string;
  targetAmount: number;
  targetDate: GraphQlDate;
  plannedMonthlyContribution: number;
  plannedStartingBalance?: number | null;
  startingBalance?: number | null;
  balancesFromAdjustmentDate?: (number | null)[] | null;
}

export interface CreateGoalsInput {
  goals: CreateGoalInput[];
}

export interface CreateHouseholdAccessGrantInput {
  email: string;
}

export interface CreateInstitutionIssueReportMutationInput {
  credential: string;
  credentialId?: string | null;
  accountId?: string | null;
  issues?: (string | null)[] | null;
  additionalNotes?: string | null;
  grantSupportAccountAccess?: boolean | null;
}

export interface CreateManualAccountMutationInput {
  name?: string | null;
  currentBalance?: number | null;
  displayBalance?: number | null;
  includeInNetWorth?: boolean | null;
  type: string;
  subtype: string;
  dataProvider?: string | null;
  newSignConvention?: boolean | null;
}

export interface CreateManualHoldingInput {
  accountId?: string | null;
  securityId: string;
  quantity: number;
}

export interface CreateManualInvestmentsAccountInput {
  name: string;
  subtype: string;
  manualInvestmentsTrackingMethod?: ManualInvestmentsAccountTrackingMethod | null;
  initialHoldings?: (InitialHoldingInput | null)[] | null;
  initialBalance?: number | null;
}

export interface CreateOrUpdateGoalPlannedContributionInput {
  goalId: string;
  month: GraphQlDate;
  amount: number;
}

export interface CreateReviewCheckinInput {
  reviewStartDate: GraphQlDate;
  reviewType?: string | null;
}

export interface CreateSponsorshipInvitesInput {
  quantity: number;
}

export interface CreateStripeSubscriptionMutationInput {
  paymentMethodId?: string | null;
  stripePriceId: string;
  stripePromoCode?: string | null;
}

export interface CreateSyncedRealEstateAccountInput {
  name: string;
  currentBalance: number;
  includeInNetWorth: boolean;
  subtype: string;
  zpid: string;
}

export interface CreateTransactionMutationInput {
  amount: number;
  date: GraphQlDate;
  notes?: string | null;
  merchantName: string;
  categoryId: string;
  accountId: string;
}

export interface CreateTransactionRuleInput {
  merchantCriteria?: (MerchantCriterionInput | null)[] | null;
  merchantCriteriaUseOriginalStatement?: boolean | null;
  categoryIds?: (string | null)[] | null;
  accountIds?: (string | null)[] | null;
  amountCriteria?: AmountCriteriaFieldV2Input | null;
  setMerchantAction?: string | null;
  setCategoryAction?: string | null;
  linkGoalAction?: string | null;
  setHideFromReportsAction?: boolean | null;
  sendNotificationAction?: boolean | null;
  addTagsAction?: string[] | null;
  reviewStatusAction?: string | null;
  applyToExistingTransactions?: boolean | null;
}

export interface CreateTransactionTagInput {
  name: string;
  color: string;
}

export interface CredentialFilters {
  plaidInstitutionId?: string | null;
  institutionId?: string | null;
}

export interface DateRange {
  startDate?: GraphQlDate | null;
  endDate?: GraphQlDate | null;
}

export interface DecimalRangeSerializerInput {
  upper: number;
  lower: number;
}

export interface DeleteCredentialInput {
  credentialId: string;
}

export interface DeleteGoalAccountAllocationInput {
  goalId: string;
  accountId: string;
}

export interface DeleteGoalInput {
  id: string;
}

export interface DeleteHouseholdMutationInput {
  householdId: GraphQlUUID;
  reason?: string | null;
  feedback?: string | null;
}

export interface DeleteSyncedSnapshotsInput {
  beforeDate: GraphQlDate;
  expectedAffectedSnapshotsCount: number;
}

export interface DeleteSyncedTransactionsInput {
  beforeDate: GraphQlDate;
  expectedAffectedTransactionsCount: number;
}

export interface DeleteTransactionMutationInput {
  transactionId: GraphQlUUID;
}

export interface DeleteUserFromHouseholdMutationInput {
  userId: GraphQlUUID;
}

export interface DisconnectCredentialsFromDataProviderInput {
  credentialIds: string[];
}

export interface ForceRefreshAccountsInput {
  accountIds: string[];
}

export interface GoalContributionFilters {
  startDate?: GraphQlDate | null;
  endDate?: GraphQlDate | null;
  includeWithdrawals?: boolean | null;
}

export interface InitialHoldingInput {
  securityId: string;
  quantity: number;
}

export interface InviteUserToHouseholdMutationInput {
  email?: string | null;
}

export interface MarkAdviceItemTaskCompleteInput {
  adviceItemTaskId: string;
}

export interface MarkAdviceItemTaskIncompleteInput {
  adviceItemTaskId: string;
}

export interface MarkGoalCompleteInput {
  id: string;
}

export interface MarkGoalIncompleteInput {
  id: string;
}

export interface MerchantCriterionInput {
  operator: string;
  value: string;
}

export interface MoveMoneyMutationInput {
  startDate: GraphQlDate;
  timeframe: BudgetTimeframeInput;
  fromCategoryId: GraphQlUUID;
  toCategoryId: GraphQlUUID;
  amount: number;
}

export interface MoveTransactionsInput {
  isAllSelected?: boolean | null;
  expectedAffectedTransactionCount?: number | null;
  selectedTransactionIds?: (string | null)[] | null;
  excludedTransactionIds?: (string | null)[] | null;
  filters?: TransactionFilterInput | null;
  fromAccountId: string;
  toAccountId: string;
}

export interface ParseStatementInput {
  sessionKey: string;
  parserName: string;
  accountId?: string | null;
  accountNameMapping?: GraphQlJSONString | null;
}

export interface PortfolioInput {
  accountIds?: (string | null)[] | null;
  startDate?: GraphQlDate | null;
  endDate?: GraphQlDate | null;
  topMoversLimit?: number | null;
  includeHiddenHoldings?: boolean | null;
}

export interface PreviewCopySnapshotsInput {
  fromAccountId: GraphQlUUID;
  toAccountId: GraphQlUUID;
  cutoverDate: GraphQlDate;
}

export interface ReactivateStripeSubscriptionMutationInput {
  paymentMethodId?: string | null;
  stripePriceId: string;
  stripePromoCode?: string | null;
}

export interface RecurringFieldsInput {
  isRecurring: boolean;
  frequency?: string | null;
  baseDate?: GraphQlDate | null;
  amount?: number | null;
  isActive?: boolean | null;
}

export interface RecurringTransactionFilter {
  accounts?: string[] | null;
}

export interface RequestInstitutionInput {
  institutionName: string;
  website: string;
  description?: string | null;
}

export interface ResendInviteToHouseholdMutationInput {
  inviteId: string;
}

export interface ResetBudgetRolloverInput {
  categoryId: string;
  startMonth: GraphQlDate;
  startingBalance?: number | null;
}

export interface RevokeHouseholdAccessGrantInput {
  id: string;
}

export interface RevokeInviteToHouseholdMutationInput {
  inviteId: string;
}

export interface RevokeSponsorshipInviteInput {
  id: string;
}

export interface SecurityHistoricalPerformanceInput {
  securityIds: (string | null)[];
  startDate?: GraphQlDate | null;
  endDate?: GraphQlDate | null;
}

export interface SendMessageInput {
  threadId: string;
  content: string;
  fromRecommendedPrompt?: boolean | null;
}

export interface SetMerchantLogoInput {
  merchantId: string;
  cloudinaryPublicId?: string | null;
}

export interface SetTransactionTagsInput {
  transactionId: string;
  tagIds: string[];
}

export interface SetUserOnboardingFinancialGoalInput {
  onboardingFinancialGoal: GraphQlUUID;
  otherText?: string | null;
}

export interface SetUserProfilePictureInput {
  filename: string;
  extension: string;
  cloudinaryPublicId?: string | null;
}

export interface SubmitAssistantFeedbackInput {
  messageId: string;
  feedbackType: string;
  details?: (string | null)[] | null;
  comments?: string | null;
}

export interface TransactionAddAttachmentMutationInput {
  transactionId: GraphQlUUID;
  filename: string;
  publicId: string;
  extension: string;
  sizeBytes: number;
}

export interface TransactionFilterInput {
  search?: string | null;
  accounts?: GraphQlUUID[] | null;
  categories?: GraphQlUUID[] | null;
  goalId?: string | null;
  uploadedStatement?: string | null;
  systemCategories?: SystemCategory[] | null;
  isUncategorized?: boolean | null;
  merchants?: GraphQlUUID[] | null;
  householdMerchants?: string[] | null;
  categoryGroups?: GraphQlUUID[] | null;
  startDate?: GraphQlDate | null;
  endDate?: GraphQlDate | null;
  hideFromReports?: boolean | null;
  absAmountGte?: number | null;
  absAmountLte?: number | null;
  isSplit?: boolean | null;
  hasNotes?: boolean | null;
  isRecurring?: boolean | null;
  hasAttachments?: boolean | null;
  tags?: string[] | null;
  importedFromMint?: boolean | null;
  needsReview?: boolean | null;
  ids?: string[] | null;
}

export interface TransactionRulePreviewInput {
  merchantCriteria?: (MerchantCriterionInput | null)[] | null;
  merchantCriteriaUseOriginalStatement?: boolean | null;
  categoryIds?: (string | null)[] | null;
  accountIds?: (string | null)[] | null;
  amountCriteria?: AmountCriteriaFieldV2Input | null;
  setMerchantAction?: string | null;
  setCategoryAction?: string | null;
  linkGoalAction?: string | null;
  setHideFromReportsAction?: boolean | null;
  sendNotificationAction?: boolean | null;
  addTagsAction?: string[] | null;
  reviewStatusAction?: string | null;
  applyToExistingTransactions?: boolean | null;
}

export interface TransactionSplitInputData {
  id?: GraphQlUUID | null;
  amount?: number | null;
  categoryId?: GraphQlUUID | null;
  notes?: string | null;
  merchantName?: string | null;
  date?: GraphQlDate | null;
}

export interface TransactionUpdateParams {
  categoryId?: string | null;
  merchantName?: string | null;
  date?: GraphQlDate | null;
  notes?: string | null;
  hide?: boolean | null;
  tags?: string[] | null;
  reviewStatus?: string | null;
  goalId?: string | null;
  isRecurring?: boolean | null;
}

export interface UnarchiveGoalInput {
  id: string;
}

export interface UpdateAccountGroupOrderInput {
  accountGroupOrder: (string | null)[];
}

export interface UpdateAccountMutationInput {
  id: string;
  name?: string | null;
  currentBalance?: number | null;
  displayBalance?: number | null;
  includeInNetWorth?: boolean | null;
  includeInGoalBalance?: boolean | null;
  hideFromList?: boolean | null;
  hideTransactionsFromReports?: boolean | null;
  type?: string | null;
  subtype?: string | null;
  synced?: boolean | null;
  dataProvider?: string | null;
  dataProviderAccountId?: string | null;
  archivedAt?: GraphQlDate | null;
  deactivatedAt?: GraphQlDate | null;
  syncDisabled?: boolean | null;
  newSignConvention?: boolean | null;
}

export interface UpdateAccountOrderInput {
  id: string;
  destinationPosition: number;
}

export interface UpdateAccountsMutationInput {
  id: string;
  name?: string | null;
  currentBalance?: number | null;
  displayBalance?: number | null;
  includeInNetWorth?: boolean | null;
  includeInGoalBalance?: boolean | null;
  hideFromList?: boolean | null;
  hideTransactionsFromReports?: boolean | null;
  type?: string | null;
  subtype?: string | null;
  synced?: boolean | null;
  dataProvider?: string | null;
  dataProviderAccountId?: string | null;
  archivedAt?: GraphQlDate | null;
  deactivatedAt?: GraphQlDate | null;
  syncDisabled?: boolean | null;
  newSignConvention?: boolean | null;
}

export interface UpdateCategoryGroupInput {
  id: GraphQlUUID;
  name?: string | null;
  color?: string | null;
}

export interface UpdateCategoryInput {
  id: string;
  name?: string | null;
  icon?: string | null;
  group?: string | null;
  type?: string | null;
  rolloverEnabled?: boolean | null;
  rolloverType?: string | null;
  rolloverStartMonth?: GraphQlDate | null;
}

export interface UpdateCredentialMutationInput {
  id?: string | null;
  updateRequired?: boolean | null;
}

export interface UpdateGoalAccountAllocationInput {
  goalId: string;
  accountId: string;
  amount?: number | null;
  useEntireAccountBalance?: boolean | null;
}

export interface UpdateGoalContributionMutationInput {
  id?: string | null;
  date?: GraphQlDate | null;
  amount?: number | null;
  notes?: string | null;
}

export interface UpdateGoalInput {
  id: string;
  name?: string | null;
  targetAmount?: number | null;
  startingAmount?: number | null;
  plannedMonthlyContribution?: number | null;
  plannedMonthlyPretaxContribution?: number | null;
  imageStorageProvider?: string | null;
  imageStorageProviderId?: string | null;
}

export interface UpdateGoalMutationInput {
  id?: string | null;
  name?: string | null;
  icon?: string | null;
  goalType?: string | null;
  targetAmount?: number | null;
  targetDate?: GraphQlDate | null;
  plannedMonthlyContribution?: number | null;
  plannedStartingBalance?: number | null;
  startingBalance?: number | null;
  balancesFromAdjustmentDate?: (number | null)[] | null;
}

export interface UpdateGoalPrioritiesInput {
  goals: UpdateGoalPriorityInput[];
}

export interface UpdateGoalPriorityInput {
  id: string;
  priority: number;
}

export interface UpdateHoldingInput {
  id: string;
  quantity?: number | null;
  costBasis?: number | null;
}

export interface UpdateHouseholdPreferencesInput {
  newTransactionsNeedReview?: boolean | null;
  uncategorizedTransactionsNeedReview?: boolean | null;
  pendingTransactionsCanBeEdited?: boolean | null;
}

export interface UpdateMeInput {
  name?: string | null;
  birthday?: GraphQlDate | null;
  timezone?: string | null;
}

export interface UpdateMerchantInput {
  merchantId: string;
  name?: string | null;
  recurrence?: RecurringFieldsInput | null;
}

export interface UpdateMyHouseholdInput {
  name?: string | null;
  address?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
}

export interface UpdateNotificationPreferencesInput {
  id?: string | null;
  PUSH?: boolean | null;
  EMAIL?: boolean | null;
  additionalPreferences?: GraphQlGenericScalar | null;
}

export interface UpdateOrCreateBudgetItemMutationInput {
  startDate: GraphQlDate;
  timeframe: BudgetTimeframeInput;
  categoryId: string;
  amount?: number | null;
  applyToFuture?: boolean | null;
  defaultAmount?: number | null;
}

export interface UpdateOrCreateGoalPlannedContributionMutationInput {
  startDate: GraphQlDate;
  goalId: string;
  amount: number;
}

export interface UpdateOrCreateStripePaymentMethodMutationInput {
  paymentMethodId: string;
}

export interface UpdateReviewCheckinInput {
  id: string;
  lastPageIndexViewed?: number | null;
  completed?: boolean | null;
}

export interface UpdateSponsorInput {
  name?: string | null;
}

export interface UpdateSponsorshipInviteInput {
  id: string;
  label?: string | null;
  sentToEmail?: string | null;
}

export interface UpdateTransactionMutationInput {
  id?: string | null;
  name?: string | null;
  amount?: number | null;
  date?: GraphQlDate | null;
  hideFromReports?: boolean | null;
  notes?: string | null;
  category?: string | null;
  needsReview?: boolean | null;
  reviewed?: boolean | null;
  reviewStatus?: string | null;
  isRecurring?: boolean | null;
  goalId?: string | null;
}

export interface UpdateTransactionRuleInput {
  merchantCriteria?: (MerchantCriterionInput | null)[] | null;
  merchantCriteriaUseOriginalStatement?: boolean | null;
  categoryIds?: (string | null)[] | null;
  accountIds?: (string | null)[] | null;
  amountCriteria?: AmountCriteriaFieldV2Input | null;
  setMerchantAction?: string | null;
  setCategoryAction?: string | null;
  linkGoalAction?: string | null;
  setHideFromReportsAction?: boolean | null;
  sendNotificationAction?: boolean | null;
  addTagsAction?: string[] | null;
  reviewStatusAction?: string | null;
  applyToExistingTransactions?: boolean | null;
  id: string;
}

export interface UpdateTransactionSplitMutationInput {
  transactionId: GraphQlUUID;
  splitData: TransactionSplitInputData[];
}

export interface UpdateTransactionTagInput {
  id: string;
  name: string;
  color: string;
}

export interface UpdateUserProfileInput {
  collaboratesOnFinances?: boolean | null;
  collaboratesOnFinancesDetailed?: string | null;
  birthday?: GraphQlDate | null;
  hasSeenGoalsFeatureTour?: boolean | null;
  hasSeenCategoriesManagementTour?: boolean | null;
  hasSeenWeeklyReviewTour?: boolean | null;
  shouldPromptTransactionRuleCreation?: boolean | null;
  userReportedAttributionChannels?: (string | null)[] | null;
  skippedPremiumUpsell?: boolean | null;
  dismissedYearlyReview?: boolean | null;
  completedYearlyReview?: boolean | null;
  aiAssistantOptedIn?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
