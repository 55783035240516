import React from 'react';
import styled from 'styled-components';

import MarkCircle from 'components/brand/MarkCircle';
import InstitutionLogo from 'components/institutions/InstitutionLogo';
import Dot from 'components/lib/ui/Dot';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';

import useTheme from 'lib/hooks/useTheme';

import type { InstitutionStatus } from 'common/generated/graphQlTypes/globalTypes';

const LARGE_CIRCLE_SIZE_PX = 56;
const ICON_SIZE_PT = 24;

const Root = styled(FlexContainer).attrs({ alignCenter: true })``;

const DotContainer = styled(FlexContainer).attrs({ alignCenter: true, column: true })`
  margin: 0 ${({ theme }) => theme.spacing.default};
`;

const StyledInstitutionLogo = styled(InstitutionLogo)`
  width: ${LARGE_CIRCLE_SIZE_PX}px;
  height: ${LARGE_CIRCLE_SIZE_PX}px;
`;

const GrayIcon = styled(Icon)`
  color: ${({ theme }) => theme.color.gray};
`;

type Props = {
  institutionLogo?: string;
  status: InstitutionStatus;
  className?: string;
};

const InstitutionSyncGraphic = ({ institutionLogo, status, className }: Props) => {
  const theme = useTheme();

  return (
    <Root className={className}>
      <MarkCircle size={LARGE_CIRCLE_SIZE_PX} />
      <DotContainer>
        <GrayIcon name="arrow-left" size={ICON_SIZE_PT} />
        <GrayIcon name="arrow-right" size={ICON_SIZE_PT} />
      </DotContainer>
      {institutionLogo ? (
        <StyledInstitutionLogo logo={institutionLogo} />
      ) : (
        <Dot size={LARGE_CIRCLE_SIZE_PX} color={theme.color.grayLight} />
      )}
    </Root>
  );
};

export default InstitutionSyncGraphic;
