/* eslint-disable no-nested-ternary */
import * as Sentry from '@sentry/browser';
import { path } from 'ramda';
import { isNilOrEmpty } from 'ramda-adjunct';
import React, { Suspense, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import ErrorBoundary from 'common/components/higherOrder/ErrorBoundary';
import Card from 'components/lib/ui/Card';
import CardTitle from 'components/lib/ui/CardTitle';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import ReportsChartCardControls from 'components/reports/ReportsChartCardControls';
import ReportsChartCardEmpty from 'components/reports/ReportsChartCardEmpty';
import ReportsChartShareModal from 'components/reports/ReportsChartShareModal';

import { setChartTypeForTab, setViewModeForTab, setReportsSankeyGroupMode } from 'actions';
import type { AdaptedReportData } from 'common/lib/reports';
import { getDefaultChartColors } from 'common/lib/reports';
import type { ReportsEntityTypes, ShowAllEntitiesState } from 'common/lib/reports/types';
import { fontSize, fontWeight, spacing } from 'common/lib/theme/dynamic';
import { formatCurrency } from 'common/utils/Currency';
import type { SankeyGroupMode } from 'lib/cashFlow/sankey';
import { useDispatch } from 'lib/hooks';
import useModal from 'lib/hooks/useModal';
import useTheme from 'lib/hooks/useTheme';
import { makeChartDisplayDates } from 'lib/reports';
import { selectDisplayPropertiesForTab, selectReportsFilters } from 'state/reports/selectors';
import type { ReportsTab } from 'state/reports/types';
import { ReportsChart } from 'state/reports/types';
import type { RootState } from 'state/types';

import routes from 'constants/routes';

import type {
  ReportsGroupByTimeframe,
  ReportsSummaryFieldsFragment,
  TransactionFilterInput,
} from 'common/generated/graphql';
import type { ExtractTypename, ValueOf } from 'common/types/utility';

const ReportsPieChart = React.lazy(() => import('components/reports/charts/ReportsPieChart'));
const ReportsHorizontalBarChart = React.lazy(
  () => import('components/reports/charts/ReportsHorizontalBarChart'),
);
const ReportsBarChart = React.lazy(() => import('components/reports/charts/ReportsBarChart'));
const ReportsCashFlowBarChart = React.lazy(
  () => import('components/reports/charts/ReportsCashFlowBarChart'),
);
const ReportsSankey = React.lazy(() => import('components/reports/charts/ReportsSankey'));

const ERROR_BOUNDARY_MESSAGE_WIDTH_PX = 210;

const Title = styled(CardTitle)`
  display: flex;
  align-items: center;
`;

export const Subtitle = styled.span`
  font-size: ${fontSize.large};
  font-weight: ${fontWeight.medium};
  margin-top: ${spacing.xxsmall};
  line-height: 150%;
`;

const LoadingContainer = styled(FlexContainer).attrs({ center: true })`
  padding: ${spacing.xxxxlarge};
`;

type Props = {
  currentTab: ReportsTab;
  data: AdaptedReportData;
  isLoading: boolean;
  summary: ReportsSummaryFieldsFragment;
  timeframe: ReportsGroupByTimeframe;
  title?: string;
  total?: number;
} & ShowAllEntitiesState;

const ReportsChartCard = ({
  currentTab,
  data,
  isLoading,
  summary,
  timeframe,
  title,
  total,
  showAllEntities,
  onChangeShowAllEntities,
}: Props) => {
  const theme = useTheme();
  const colors = useMemo(() => getDefaultChartColors(theme.color), [theme.color]);
  const dispatch = useDispatch();

  const displayProperties = useSelector((state: RootState) =>
    selectDisplayPropertiesForTab(state, currentTab),
  );
  const { startDate, endDate, ...filters } = useSelector(selectReportsFilters);
  const [displayStartDate, displayEndDate] = makeChartDisplayDates(startDate, endDate);

  const isChartEmpty = isNilOrEmpty(data?.[0]);

  const onChangeViewMode = useCallback(
    (view: string) => dispatch(setViewModeForTab({ tab: currentTab, view })),
    [currentTab, dispatch],
  );

  const onChangeChartType = useCallback(
    (chartType: ReportsChart) => {
      dispatch(setChartTypeForTab({ tab: currentTab, chartType }));
    },
    [currentTab, dispatch],
  );

  const onChangeSankeyGroupMode = useCallback(
    (mode: SankeyGroupMode) => {
      if (currentTab !== 'sankey') {
        // If not on the Sankey tab, don't do anything
        return;
      }

      dispatch(setReportsSankeyGroupMode(mode));
    },
    [currentTab, dispatch],
  );

  const formatEntityUrl = useCallback(
    (id: string, type: ExtractTypename<ValueOf<ReportsEntityTypes>>) => {
      const queryParams: Partial<TransactionFilterInput> = {
        startDate,
        endDate,
        ...filters,
      };

      if (type === 'Merchant') {
        queryParams.merchants = [id];
      } else if (type === 'Category') {
        queryParams.categories = [id];
      } else if (type === 'CategoryGroup') {
        queryParams.categoryGroups = [id];
      }

      return routes.transactions({ queryParams });
    },
    [startDate, endDate, filters],
  );

  const memoizedChart = useMemo(() => {
    const { chartType } = displayProperties ?? {};

    switch (chartType) {
      case ReportsChart.PieChart:
        return (
          <ReportsPieChart
            colors={colors}
            data={data}
            pieChartTitle={total ? formatCurrency(total) : undefined}
            pieChartSubtitle="Total"
            formatEntityUrl={formatEntityUrl}
            summary={summary}
            showAllEntities={showAllEntities}
            onChangeShowAllEntities={onChangeShowAllEntities}
          />
        );
      case ReportsChart.HorizontalBarChart:
        return <ReportsHorizontalBarChart data={data} colors={colors} />;
      case ReportsChart.StackedBarChart:
      case ReportsChart.BarChart:
        return (
          <ReportsBarChart
            data={data}
            colors={colors}
            timeframe={timeframe}
            tab={currentTab}
            isStacked={chartType === ReportsChart.StackedBarChart}
          />
        );
      case ReportsChart.CashFlowChart:
      case ReportsChart.StackedCashFlowChart:
        return (
          <ReportsCashFlowBarChart
            data={data[0]}
            timeframe={timeframe}
            stacked={chartType === ReportsChart.StackedCashFlowChart}
          />
        );
      case ReportsChart.Sankey:
        return <ReportsSankey data={data[0]} summary={summary} />;
      default:
        return null;
    }
  }, [data, displayProperties, currentTab, showAllEntities, onChangeShowAllEntities]);

  const [ShareModalContainer, { open: openShareModal }] = useModal();
  const cardHeader = useMemo(
    () => (
      <FlexContainer column>
        {title && <Title>{title}</Title>}
        <Subtitle>
          {displayStartDate} - {displayEndDate}
        </Subtitle>
      </FlexContainer>
    ),
    [title, displayStartDate, displayEndDate],
  );

  return (
    <>
      <Card
        title={cardHeader}
        controls={
          <ReportsChartCardControls
            isChartEmpty={isChartEmpty}
            chartType={displayProperties?.chartType}
            viewMode={path(['viewMode'], displayProperties)}
            groupMode={path(['groupMode'], displayProperties)}
            onChangeSankeyGroupMode={onChangeSankeyGroupMode}
            onChangeViewMode={onChangeViewMode}
            onChangeChartType={onChangeChartType}
            onClickShare={openShareModal}
          />
        }
      >
        <ErrorBoundary
          onError={Sentry.captureException}
          errorComponent={
            <ReportsChartCardEmpty
              iconName="x"
              title="Something went wrong"
              width={ERROR_BOUNDARY_MESSAGE_WIDTH_PX}
            >
              <Text>
                An unexpected error occurred. We have been notified and will get started on a fix.
              </Text>
              <DefaultButton onClick={() => location.reload()} size="small">
                Refresh the page
              </DefaultButton>
            </ReportsChartCardEmpty>
          }
        >
          {isLoading ? (
            <LoadingContainer>
              <LoadingSpinner />
            </LoadingContainer>
          ) : isChartEmpty ? (
            <ReportsChartCardEmpty iconName="pie-chart" />
          ) : (
            <Suspense
              fallback={
                <LoadingContainer>
                  <LoadingSpinner />
                </LoadingContainer>
              }
            >
              {memoizedChart}
            </Suspense>
          )}
        </ErrorBoundary>
      </Card>
      <ShareModalContainer full>
        <ReportsChartShareModal
          chart={memoizedChart}
          header={cardHeader}
          initialValues={{ title }}
          displayProperties={displayProperties}
        />
      </ShareModalContainer>
    </>
  );
};

export default ReportsChartCard;
