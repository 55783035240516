import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';

const Root = styled.div``;

const Row = styled.div<{ spacing: number }>`
  display: flex;

  :not(:first-child) {
    margin-top: ${({ spacing }) => spacing}px;
  }
`;

const Dot = styled.div<{ size: number; spacing: number }>`
  background: ${({ theme }) => theme.color.grayDark};
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  border-radius: 100%;

  :not(:first-child) {
    margin-left: ${({ spacing }) => spacing}px;
  }
`;

type Props = {
  dotSpacing?: number;
  dotSize?: number;
  rows?: number;
  columns?: number;
  className?: string;
};

const DragDots = ({ dotSpacing = 2, dotSize = 2, rows = 4, columns = 2, className }: Props) => (
  <Root className={className}>
    {_.range(rows).map((row) => (
      <Row key={row} spacing={dotSpacing}>
        {_.range(columns).map((column) => (
          <Dot key={column} size={dotSize} spacing={dotSpacing} />
        ))}
      </Row>
    ))}
  </Root>
);

export default DragDots;
