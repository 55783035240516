import { useMemo } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { useLocation, matchPath } from 'react-router';

import { getDismissedMobileBrowserWarning } from 'selectors';

import routes from 'constants/routes';

const EXCLUDED_ROUTES = [
  routes.signup.path,
  routes.household.path,
  routes.login.path,
  routes.forgotPassword.path,
  routes.resetPassword.path,
  routes.authProviderLogin.path,
  routes.authConfirmation.path,
];

const useShouldShowMobileBrowserWarning = () => {
  const dismissedMobileBrowserWarning = useSelector(getDismissedMobileBrowserWarning);

  // We don't want to show the warning if a user is anywhere in the onboarding routes.
  const { pathname } = useLocation();
  const matchesExcludedRoute = useMemo(
    () => EXCLUDED_ROUTES.some((path) => matchPath(pathname, { path })),
    [pathname],
  );

  const showMobileBrowserWarning = useMemo(
    () => !dismissedMobileBrowserWarning && isMobileOnly && !matchesExcludedRoute,
    [dismissedMobileBrowserWarning, matchesExcludedRoute],
  );

  return showMobileBrowserWarning;
};

export default useShouldShowMobileBrowserWarning;
