import * as Sentry from '@sentry/browser';

import { getSegmentAnonymousIdFromCookie, identifyUserToSegment } from 'lib/analytics/segment';
import { identifyUserToSingular } from 'lib/analytics/singular';
import { identifyUserToUserLeap } from 'lib/analytics/userLeap';
import { store } from 'state/store';
import { getIsDemo, getSplitUserAttributes } from 'state/user/selectors';

import type { UserHouseholdRole } from 'common/generated/graphQlTypes/globalTypes';

const getAnonymousId = () => getSegmentAnonymousIdFromCookie();

const identifyUser = (id: string, email: string, householdRole?: UserHouseholdRole) => {
  const state = store.getState();
  const isDemo = getIsDemo(state);

  if (isDemo) {
    return;
  }

  Sentry.setUser({ id, email });
  identifyUserToSegment(id, email, householdRole);
  identifyUserToUserLeap(id, email);
  identifyUserToSingular(id);

  if (window.ttq) {
    window.ttq.identify({ email, external_id: id });
  }

  if (window.spdt) {
    window.spdt('alias', { id });
  }

  const splitAttributes = getSplitUserAttributes(state);
  if (window.clarity) {
    window.clarity('identify', id);
    // Send Split attributes to Clarity (is_employee, is_active, email, etc.)
    Promise.all(
      Object.entries(splitAttributes).map(([key, value]) => window.clarity('set', key, value)),
    ).catch((error) => Sentry.captureException(error));
  }
};

export const identifyAnonymousUser = () => {
  if (window.clarity) {
    const anonymousId = getAnonymousId();

    if (anonymousId) {
      window.clarity('set', 'anonymous_id', anonymousId);
    }
  }
};

export default identifyUser;
