import type { DateTime } from 'luxon';
import React, { useCallback, useMemo } from 'react';
import type { Column, TableState, SortByFn } from 'react-table';
import styled from 'styled-components';

import Text from 'components/lib/ui/Text';
import Table from 'components/lib/ui/table/Table';
import RecurringAccount from 'components/recurring/RecurringAccount';
import RecurringAmountCell from 'components/recurring/RecurringAmountCell';
import RecurringCategory from 'components/recurring/RecurringCategory';
import RecurringMenu from 'components/recurring/RecurringMenu';
import RecurringStream from 'components/recurring/RecurringStream';
import RecurringTableLoading from 'components/recurring/RecurringTableLoading';

import {
  getFrequencyLabel,
  formatNextPaymentDate,
  sortStreams,
  sortStreamsByCategory,
  sortStreamsByFrequency,
  GroupByValues,
} from 'common/lib/recurring';
import { parseCurrency } from 'common/utils/Currency';

import type { Web_GetAllRecurringTransactionItemsQuery } from 'common/generated/graphql';
import type { ElementOf } from 'common/types/utility';

export type AllRecurringTransactionStream = ElementOf<
  Web_GetAllRecurringTransactionItemsQuery,
  'recurringTransactionStreams'
>; // alias
type ColumnConfig = Column<AllRecurringTransactionStream>[];
type StreamCell = { row: { original: AllRecurringTransactionStream } };

const StyledTable: typeof Table = styled(Table)`
  margin-bottom: 0;

  th:nth-last-child(2) {
    text-align: right;
  }
`;

const StyledRecurringMenu = styled(RecurringMenu)`
  margin-right: -${({ theme }) => theme.spacing.large};
  margin-left: -${({ theme }) => theme.spacing.large};
`;

type Props = {
  streams: AllRecurringTransactionStream[];
  isLoading: boolean;
  groupBy?: GroupByValues;
  startDate: DateTime;
  title: string;
  isInitiallyExpanded?: boolean;
  hideColumns?: string[];
  refetch: () => void;
};

type SortByFnReified = SortByFn<AllRecurringTransactionStream>;

const skipSortIfGroup =
  (sorterFunc: SortByFnReified): SortByFnReified =>
  (row1, row2, columnId, desc) => {
    if (row1.isGrouped) {
      const { groupByVal: firstGroup } = row1;
      const { groupByVal: secondGroup } = row2;

      const factor = desc ? -1 : 1;

      return factor * firstGroup.localeCompare(secondGroup);
    }
    return sorterFunc(row1, row2, columnId, desc);
  };

const RecurringAllTable = ({
  streams,
  isLoading,
  startDate,
  groupBy,
  title,
  isInitiallyExpanded,
  hideColumns = [],
  refetch,
}: Props) => {
  // @ts-ignore issue with the accessors and the type of the data
  const columns: ColumnConfig = useMemo(
    () => [
      {
        id: 'merchant',
        disableSortBy: true,
        accessor: ({ stream }) => stream?.name, // for sorting
        Aggregated: ({ row }) =>
          row.groupByID === GroupByValues.Category ? (
            <RecurringCategory category={row.subRows[0].original.category} startDate={startDate} />
          ) : (
            <Text>{getFrequencyLabel(row.groupByVal)}</Text>
          ),
        Header: () => <Text size="large">{title}</Text>,
        Cell: ({ row }: StreamCell) => {
          const stream = row.original?.stream;
          return (
            <RecurringStream
              id={stream.merchant?.id ?? stream.creditReportLiabilityAccount?.account?.id ?? ''}
              name={stream.name}
              frequency={getFrequencyLabel(stream.frequency)}
              logoUrl={stream.logoUrl}
              startDate={startDate}
              isMerchant={!!stream.merchant}
            />
          );
        },
      },
      {
        id: 'nextPayment',
        Header: 'Next Payment',
        accessor: ({ nextForecastedTransaction }) => nextForecastedTransaction?.date,
        sortType: skipSortIfGroup((row1, row2) => {
          const firstValue = row1.values.nextPayment;
          const secondValue = row2.values.nextPayment;
          return firstValue.localeCompare(secondValue);
        }),
        Cell: ({ value }: { value: Maybe<string> }) => formatNextPaymentDate(value),
      },
      {
        id: 'category',
        Header: 'Category',
        Aggregated: () => null,
        accessor: ({ category }) => category?.name, // for sorting
        sortType: skipSortIfGroup((row1, row2) => {
          const firstValue = row1.values.category;
          const secondValue = row2.values.category;

          if (!firstValue || !secondValue) {
            // if one of the values is null, we want to sort it to the bottom
            return 1;
          }

          return firstValue.localeCompare(secondValue);
        }),
        Cell: ({
          row: {
            original: { category },
          },
        }: StreamCell) =>
          category ? <RecurringCategory category={category} startDate={startDate} /> : '-',
      },
      {
        id: 'frequency',
        Header: 'Frequency',
        accessor: ({ stream }) => stream?.frequency, // for sorting
      },
      {
        id: 'account',
        Header: 'Account',
        accessor: ({ account }) => account?.displayName, // for sorting
        disableSortBy: true,
        Aggregated: () => null,
        Cell: ({
          row: {
            original: { account },
          },
        }: StreamCell) => (account ? <RecurringAccount account={account} /> : '-'),
      },
      {
        id: 'amount',
        Header: 'Amount',
        accessor: ({ nextForecastedTransaction }) => nextForecastedTransaction?.amount ?? 0,
        Aggregated: () => null,
        Cell: ({
          value,
          row: {
            original: { stream },
          },
        }: StreamCell & {
          value: number;
        }) => <RecurringAmountCell isApproximate={stream.isApproximate} amount={value} />,
        sortType: skipSortIfGroup((row1, row2) => {
          const firstValue = parseCurrency(row1.values.amount);
          const secondValue = parseCurrency(row2.values.amount);
          return firstValue - secondValue;
        }),
      },
      {
        Header: () => null, // No header
        id: 'menu', // It needs an ID if no header
        Aggregated: () => null,
        Cell: ({ row: { original } }: { row: { original: AllRecurringTransactionStream } }) => (
          <StyledRecurringMenu
            merchantId={original.stream?.merchant?.id}
            name={original.stream.name}
            startDate={startDate}
            refetch={refetch}
            streamId={original.stream.id}
            accountId={original.stream?.creditReportLiabilityAccount?.account?.id}
          />
        ),
      },
    ],
    [startDate, title, refetch],
  );

  const columnNames = [
    'Merchant',
    'Next Payment',
    'Category',
    'Account',
    'Amount',
    '', // Menu
  ];

  const sortedStreams = useMemo(() => {
    switch (groupBy) {
      case GroupByValues.Category:
        return sortStreamsByCategory(streams);
      case GroupByValues.Frequency:
        return sortStreamsByFrequency(streams);
      default:
        return sortStreams(streams);
    }
  }, [streams, groupBy]);

  const useControlledState = useCallback(
    (state: TableState<AllRecurringTransactionStream>) => {
      const newState = {
        ...state,
        ...(groupBy
          ? { groupBy: [groupBy], hiddenColumns: ['frequency', ...hideColumns, groupBy] }
          : {}),
      };
      return newState;
    },
    [groupBy, hideColumns],
  );

  return !isLoading ? (
    <StyledTable
      collapsible
      defaultAlignment="left"
      showFooter={false}
      columns={columns}
      data={sortedStreams}
      initialState={{
        hiddenColumns: ['frequency', ...hideColumns],
      }}
      useControlledState={useControlledState}
      initiallyExpanded={isInitiallyExpanded}
    />
  ) : (
    <RecurringTableLoading columnNames={columnNames} />
  );
};

export default RecurringAllTable;
