import type { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import type { Column } from 'react-table';
import styled from 'styled-components';

import Text from 'components/lib/ui/Text';
import Table from 'components/lib/ui/table/Table';
import RecurringAccount from 'components/recurring/RecurringAccount';
import RecurringAmountCell from 'components/recurring/RecurringAmountCell';
import RecurringCategory from 'components/recurring/RecurringCategory';
import RecurringMenu from 'components/recurring/RecurringMenu';
import RecurringStream from 'components/recurring/RecurringStream';
import RecurringTableLoading from 'components/recurring/RecurringTableLoading';

import { getFrequencyLabel, sortItems } from 'common/lib/recurring';
import type { RecurringTransactionItem } from 'common/lib/recurring/types';
import { isoDateToMonthAbbreviationAndDay } from 'common/utils/date';

type ColumnConfig = Column<RecurringTransactionItem>[];
type ItemCell = { row: { original: RecurringTransactionItem } };

const StyledTable: typeof Table = styled(Table)`
  th:nth-last-child(2) {
    text-align: right;
  }
  margin-bottom: ${({ theme }) => theme.spacing.gutter};
`;

const StyledRecurringMenu = styled(RecurringMenu)`
  margin-right: -${({ theme }) => theme.spacing.large};
  margin-left: -${({ theme }) => theme.spacing.large};
`;

type Props = {
  title: string;
  items?: RecurringTransactionItem[];
  isLoading: boolean;
  startDate: DateTime;
  refetch: () => void;
};

const RecurringUpcomingTable = ({ title, items, isLoading, startDate, refetch }: Props) => {
  // @ts-ignore issue with the accessors and the type of the data
  const columns: ColumnConfig = useMemo(
    () => [
      {
        id: 'Merchant',
        Header: () => <Text size="large">{title}</Text>,
        disableSortBy: true,
        accessor: ({ stream }) => stream?.name, // for sorting
        Cell: ({
          row: {
            original: { stream },
          },
        }: ItemCell) => (
          <RecurringStream
            id={stream.merchant?.id ?? stream.creditReportLiabilityAccount?.account?.id ?? ''}
            name={stream?.name ?? ''}
            frequency={getFrequencyLabel(stream.frequency)}
            logoUrl={stream.logoUrl}
            startDate={startDate}
            isMerchant={!!stream.merchant}
          />
        ),
      },
      {
        Header: 'Due Date',
        accessor: ({ date }) => date,
        Cell: ({ value }: { value: string }) => isoDateToMonthAbbreviationAndDay(value),
      },
      {
        Header: 'Category',
        accessor: ({ category }) => category?.name, // for sorting
        Cell: ({
          row: {
            original: { category },
          },
        }: ItemCell) =>
          category ? <RecurringCategory category={category} startDate={startDate} /> : '-',
      },
      {
        Header: 'Account',
        accessor: ({ account }) => account?.displayName, // for sorting
        Cell: ({
          row: {
            original: { account },
          },
        }: ItemCell) => (account ? <RecurringAccount account={account} /> : '-'),
      },
      {
        Header: 'Amount',
        accessor: ({ amount }) => amount ?? 0,
        Cell: ({
          value,
          row: {
            original: { isPast, stream },
          },
        }: { value: number } & ItemCell) => (
          <RecurringAmountCell
            amount={value}
            isPast={isPast}
            isApproximate={stream.isApproximate}
          />
        ),
      },
      {
        id: 'menu', // It needs an ID if no header
        Cell: ({ row: { original } }: ItemCell) => (
          <StyledRecurringMenu
            merchantId={original.stream?.merchant?.id}
            name={original.stream.name}
            startDate={startDate}
            refetch={refetch}
            streamId={original.stream.id}
          />
        ),
      },
    ],
    [startDate, title, refetch],
  );

  const columnNames = [
    'Merchant',
    'Due Date',
    'Category',
    'Account',
    'Amount',
    '', // Menu
  ];
  const sortedItems = useMemo(() => (items ? sortItems(items) : []), [items]);

  return !isLoading ? (
    <StyledTable
      collapsible
      defaultAlignment="left"
      showFooter={false}
      columns={columns}
      data={sortedItems}
    />
  ) : (
    <RecurringTableLoading columnNames={columnNames} />
  );
};

export default RecurringUpcomingTable;
