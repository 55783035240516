import pluralize from 'pluralize';
import * as R from 'ramda';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import LinkAccountFlow from 'components/accounts/LinkAccountFlow';
import FeatureOnboardingPage from 'components/lib/layouts/FeatureOnboardingPage';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Modal from 'components/lib/ui/Modal';
import Text, { TextWithLineBreaks } from 'components/lib/ui/Text';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import OnboardingAccountGroup from 'components/plan/onboarding/OnboardingAccountGroup';

import useQuery from 'common/lib/hooks/useQuery';
import useToggle from 'common/lib/hooks/useToggle';
import { spacing } from 'common/lib/theme/dynamic';

import { AccountTypeName } from 'common/constants/accounts';
import * as COPY from 'common/constants/copy';

import { gql } from 'common/generated/gql';
import type { Web_BudgetOnboardingGetAccountsQuery } from 'common/generated/graphql';
import type { ElementOf } from 'common/types/utility';

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin-top: ${spacing.xlarge};
`;

const OtherAccounts = styled.div`
  margin-top: ${spacing.xxlarge};
`;

const Button = styled(DefaultButton).attrs({ size: 'medium' })`
  margin-bottom: ${spacing.large};
  align-self: center;
`;

const StyledFlexContainer = styled(FlexContainer).attrs({
  full: true,
  column: true,
  gap: 'small',
})`
  width: 600px;
`;

type AccountTypeSummary = ElementOf<Web_BudgetOnboardingGetAccountsQuery, 'accountTypeSummaries'>;

type Account = ElementOf<AccountTypeSummary, 'accounts'>;

type Props = {
  onNext: () => void;
  onBack: () => void;
  onCancel: () => void;
  progress: number;
};

const OnboardingAccounts = ({ onNext, onBack, onCancel, progress }: Props) => {
  const { data, isLoadingInitialData, refetch } = useQuery(ACCOUNTS_QUERY);

  const visibleAccountIds = useMemo(
    () =>
      // Returns a map of account IDs to true if the account is visible so we have a O(1) lookup
      data?.visibleAccounts.reduce(
        (acc, account) => {
          acc[account.id] = true;
          return acc;
        },
        {} as Record<string, boolean>,
      ),
    [data?.visibleAccounts],
  );
  const isVisible = useCallback(
    (account: Account) => visibleAccountIds?.[account.id],
    [visibleAccountIds],
  );

  const [isModalVisible, { setOn: openModal, setOff: closeModal }] = useToggle(false);

  const { accountTypeSummaries = [] } = data ?? {};

  const getAccountTypeData = useCallback(
    (accountType: AccountTypeName) => {
      const summary = accountTypeSummaries.find(ofType(accountType));
      const accounts = sortByBalance(summary?.accounts ?? []).filter(isVisible);
      return { summary, accounts };
    },
    [accountTypeSummaries, isVisible],
  );

  const { creditCardData, cashData } = useMemo(
    () => ({
      creditCardData: getAccountTypeData(AccountTypeName.CREDIT),
      cashData: getAccountTypeData(AccountTypeName.DEPOSITORY),
    }),
    [getAccountTypeData],
  );

  const { summary: creditCardSummary, accounts: creditCardAccounts } = creditCardData;
  const { summary: cashSummary, accounts: cashAccounts } = cashData;

  const closeAddAccountModal = () => {
    refetch();
    closeModal();
  };

  const accountsCount = accountTypeSummaries.reduce((acc, item) => acc + item.accounts.length, 0);
  const otherAccountsCount = accountsCount - cashAccounts.length - creditCardAccounts.length;
  const hasNoAccounts = R.isEmpty(cashAccounts) && R.isEmpty(creditCardAccounts);

  return (
    <FeatureOnboardingPage
      pageName="Accounts"
      progress={progress}
      title={COPY.BUDGET.ONBOARDING.ACCOUNTS.TITLE}
      description={<TextWithLineBreaks text={COPY.BUDGET.ONBOARDING.ACCOUNTS.DESCRIPTION} />}
      onClickBack={onBack}
      onClickNext={onNext}
      onClickCancel={onCancel}
      descriptionMaxWidth={680}
      hideNextButton={hasNoAccounts}
    >
      <FlexContainer center column>
        {isLoadingInitialData ? (
          <StyledLoadingSpinner />
        ) : (
          <StyledFlexContainer>
            <Button onClick={openModal}>
              <ButtonIcon name="plus-circle" />
              Add account
            </Button>
            <OnboardingAccountGroup
              title="Credit cards"
              totalBalance={creditCardSummary?.totalDisplayBalance ?? 0}
              accounts={creditCardAccounts}
              emptyText="You haven't added any credit card accounts yet"
            />
            <OnboardingAccountGroup
              title="Cash"
              totalBalance={cashSummary?.totalDisplayBalance ?? 0}
              accounts={cashAccounts}
              emptyText="You haven't added any cash accounts yet"
            />
          </StyledFlexContainer>
        )}
        {otherAccountsCount > 0 && (
          <OtherAccounts>
            <Text color="textLight">{getOtherAccountsLegend(otherAccountsCount)}</Text>
          </OtherAccounts>
        )}
      </FlexContainer>
      {isModalVisible && (
        <Modal onClose={closeAddAccountModal}>
          {({ close }) => <LinkAccountFlow allowManualAccount onClose={close} onComplete={close} />}
        </Modal>
      )}
    </FeatureOnboardingPage>
  );
};

const ofType = R.pathEq(['type', 'name']);

const sortByBalance = R.sort<Account>(R.descend(R.prop('displayBalance')));

const getOtherAccountsLegend = (count: number) =>
  `${count} other ${pluralize('account', count)} ${pluralize('has', count)} also been added`;

const ACCOUNTS_QUERY = gql(/* GraphQL */ `
  query Web_BudgetOnboardingGetAccounts {
    visibleAccounts: accounts(filters: { includeHidden: false }) {
      id
    }

    accountTypeSummaries {
      isAsset
      totalDisplayBalance
      type {
        name
      }
      accounts {
        id
        displayName
        displayBalance
        icon
        logoUrl
      }
    }
  }
`);

export default OnboardingAccounts;
