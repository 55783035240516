import React from 'react';
import styled from 'styled-components';

import EmptyCreateCard from 'components/lib/ui/EmptyCreateCard';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import * as COPY from 'constants/copy';

const SecondaryButton = styled(DefaultButton).attrs({ size: 'medium' })`
  margin-left: ${({ theme }) => theme.spacing.default};
`;

type Props = {
  isLoading?: boolean;
  onClickPrimary: () => void;
  onClickSecondary: () => void;
};

const RecurringEmptyCard = ({ isLoading, onClickPrimary, onClickSecondary }: Props) => (
  <EmptyCreateCard
    icon="calendar"
    title={COPY.RECURRING.EMPTY.TITLE}
    button={{
      text: 'Get started',
      onClick: onClickPrimary,
      loading: isLoading,
    }}
    defaultButton={<SecondaryButton onClick={onClickSecondary}>Add manually</SecondaryButton>}
  />
);

export default RecurringEmptyCard;
