import { useMutation } from '@apollo/client';
import * as R from 'ramda';
import React, { useState, useCallback } from 'react';

import DeleteOrDisableCategoryConfirmation from 'components/categories/DeleteOrDisableCategoryConfirmation';
import Modal from 'components/lib/ui/Modal';

import { updateCachedCategory } from 'common/lib/categories/graphQl';
import { PLAN_DATA_QUERY_NAME } from 'common/lib/graphQl/planning';

import { CACHE_KEYS } from 'common/constants/cache';

import { gql } from 'common/generated/gql';
import type { PayloadErrorFields } from 'common/generated/graphQlTypes/PayloadErrorFields';

type Options = {
  onError?: (errorMessage: string | null) => void;
  onDone?: () => void;
};

const useDeleteCategory = (options?: Options) => {
  const { onError, onDone } = options ?? {};

  const [confirmingDeleteCategoryId, setConfirmingDeleteCategoryId] = useState<string>();

  const [disableOrDeleteCategory, { loading: loadingDisableOrDeleteCategory }] = useMutation(
    DELETE_CATEGORY,
    {
      update: (cache) => {
        cache.evict({ fieldName: CACHE_KEYS.CATEGORIES });
        cache.evict({ fieldName: CACHE_KEYS.CATEGORY_GROUPS });
      },
      refetchQueries: [PLAN_DATA_QUERY_NAME],
    },
  );

  const deleteCategoryWithConfirmation = (categoryId: string) => {
    setConfirmingDeleteCategoryId(categoryId);
  };

  const onConfirmDelete = async (categoryId: string, moveToCategoryId?: string) => {
    if (!confirmingDeleteCategoryId) {
      return;
    }
    const { data } = await disableOrDeleteCategory({
      variables: { id: categoryId, moveToCategoryId },
      update: updateCachedCategory(categoryId, { isDisabled: () => true }),
    });
    const errors: PayloadErrorFields | undefined = R.path(['deleteCategory', 'errors'], data);
    if (errors) {
      onError?.(errors.message);
    } else {
      onDone?.();
    }

    setConfirmingDeleteCategoryId(undefined);
  };

  const DeleteCategoryConfirmationModal = useCallback(
    () =>
      confirmingDeleteCategoryId ? (
        <Modal onClose={() => setConfirmingDeleteCategoryId(undefined)}>
          {({ close }) => (
            <DeleteOrDisableCategoryConfirmation
              categoryId={confirmingDeleteCategoryId}
              onCancel={close}
              disableOrDeleteCategory={onConfirmDelete}
            />
          )}
        </Modal>
      ) : null,
    [confirmingDeleteCategoryId],
  );

  return [
    DeleteCategoryConfirmationModal,
    { deleteCategoryWithConfirmation, confirmingDeleteCategoryId, loadingDisableOrDeleteCategory },
  ] as const;
};

const DELETE_CATEGORY = gql(`
  mutation Web_DeleteCategory($id: UUID!, $moveToCategoryId: UUID) {
    deleteCategory(id: $id, moveToCategoryId: $moveToCategoryId) {
      errors {
        ...PayloadErrorFields
      }
      deleted
    }
  }
`);

export default useDeleteCategory;
