import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { ButtonGroup } from 'components/lib/ui/ButtonGroup';
import DividerLine from 'components/lib/ui/DividerLine';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import type { SankeyGroupMode } from 'lib/cashFlow/sankey';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import type { SankeyDisplayProperties, TabDisplayProperties } from 'state/reports/types';
import { ReportsChart } from 'state/reports/types';

const VIEW_MODE_TO_LABEL: Record<NonNullable<TabDisplayProperties['viewMode']>, string> = {
  totalAmounts: 'Total amounts',
  changeOverTime: 'Change over time',
};

const Root = styled(FlexContainer).attrs({ gap: 'small', alignStretch: true })`
  height: 36px;
`;

const Rotate = styled.div`
  transform: rotate(90deg);
`;

const ButtonGroupIcon = styled(Icon).attrs({ size: 16 })``;

type Props = {
  isChartEmpty: boolean;
  chartType: ReportsChart | undefined;
  viewMode?: TabDisplayProperties['viewMode'];
  groupMode?: SankeyDisplayProperties['groupMode'];
  onChangeViewMode: (viewMode: string) => void;
  onChangeChartType: (chartType: ReportsChart) => void;
  onChangeSankeyGroupMode: (mode: SankeyGroupMode) => void;
  onClickShare: () => void;
};

const ReportsChartCardControls = ({
  isChartEmpty,
  chartType,
  viewMode,
  groupMode,
  onChangeViewMode,
  onChangeChartType,
  onChangeSankeyGroupMode,
  onClickShare,
}: Props) => {
  const isSharingActive = useIsFeatureFlagOn('reports-chart-share');
  const isChartTypeOneOf = useCallback(
    (types: ReportsChart[]) => chartType && types.includes(chartType),
    [chartType],
  );

  const sectionsToRender = useMemo(
    () =>
      [
        // View mode buttons
        viewMode && (
          <ButtonGroup>
            {Object.entries(VIEW_MODE_TO_LABEL).map(([value, label]) => (
              <DefaultButton
                key={value}
                onClick={() => onChangeViewMode?.(value)}
                active={value === viewMode}
              >
                {label}
              </DefaultButton>
            ))}
          </ButtonGroup>
        ),
        // Spending/income reports chart type buttons (pie chart, bar chart)
        isChartTypeOneOf([ReportsChart.PieChart, ReportsChart.HorizontalBarChart]) && (
          <ButtonGroup>
            <DefaultButton
              onClick={() => onChangeChartType(ReportsChart.PieChart)}
              active={chartType === ReportsChart.PieChart}
            >
              <ButtonGroupIcon name="pie-chart" />
            </DefaultButton>
            <DefaultButton
              onClick={() => onChangeChartType(ReportsChart.HorizontalBarChart)}
              active={chartType === ReportsChart.HorizontalBarChart}
            >
              <Rotate>
                <ButtonGroupIcon name="bar-chart" />
              </Rotate>
            </DefaultButton>
          </ButtonGroup>
        ),
        // Time-based reports chart type buttons (bar chart, stacked bar chart)
        isChartTypeOneOf([ReportsChart.StackedBarChart, ReportsChart.BarChart]) && (
          <ButtonGroup>
            <DefaultButton
              onClick={() => onChangeChartType(ReportsChart.BarChart)}
              active={chartType === ReportsChart.BarChart}
            >
              <ButtonGroupIcon name="bar-chart" />
            </DefaultButton>
            <DefaultButton
              onClick={() => onChangeChartType(ReportsChart.StackedBarChart)}
              active={chartType === ReportsChart.StackedBarChart}
            >
              <ButtonGroupIcon name="bar-chart-2" />
            </DefaultButton>
          </ButtonGroup>
        ),
        // Cash flow reports chart type buttons (bar chart, stacked bar chart)
        isChartTypeOneOf([ReportsChart.CashFlowChart, ReportsChart.StackedCashFlowChart]) && (
          <ButtonGroup>
            <DefaultButton
              onClick={() => onChangeChartType(ReportsChart.CashFlowChart)}
              active={chartType === ReportsChart.CashFlowChart}
            >
              <ButtonGroupIcon name="bar-chart" />
            </DefaultButton>
            <DefaultButton
              onClick={() => onChangeChartType(ReportsChart.StackedCashFlowChart)}
              active={chartType === ReportsChart.StackedCashFlowChart}
            >
              <ButtonGroupIcon name="bar-chart-2" />
            </DefaultButton>
          </ButtonGroup>
        ),
        // Sankey chart group mode buttons
        isChartTypeOneOf([ReportsChart.Sankey]) && (
          <ButtonGroup>
            <DefaultButton
              onClick={() => onChangeSankeyGroupMode('category')}
              active={groupMode === 'category'}
            >
              Categories
            </DefaultButton>
            <DefaultButton
              onClick={() => onChangeSankeyGroupMode('group')}
              active={groupMode === 'group'}
            >
              Groups
            </DefaultButton>
            <DefaultButton
              onClick={() => onChangeSankeyGroupMode('both')}
              active={groupMode === 'both'}
            >
              Both
            </DefaultButton>
          </ButtonGroup>
        ),
        // Share button
        isSharingActive && !isChartEmpty && (
          <DefaultButton onClick={onClickShare}>
            <ButtonIcon name="share" />
            <span>Share</span>
          </DefaultButton>
        ),
      ].filter(React.isValidElement),
    [
      viewMode,
      isChartTypeOneOf,
      chartType,
      groupMode,
      onChangeViewMode,
      onChangeChartType,
      onChangeSankeyGroupMode,
      isSharingActive,
      onClickShare,
      isChartEmpty,
    ],
  );

  return (
    <Root>
      {sectionsToRender.map((section, index) => (
        <React.Fragment key={index}>
          {section}
          {index < sectionsToRender.length - 1 && <DividerLine />}
        </React.Fragment>
      ))}
    </Root>
  );
};

export default ReportsChartCardControls;
