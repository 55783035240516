import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Page from 'components/lib/ui/Page';
import SetupContentHeader from 'components/lib/ui/SetupContentHeader';
import SetupHeader from 'components/lib/ui/SetupHeader';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import variables from 'common/lib/theme/variables';

const StyledPage = styled(Page)`
  height: 100vh;
  background-color: ${({ theme }) =>
    // eslint-disable-next-line no-nested-ternary
    theme.version === 2
      ? variables.color.background.base({ theme })
      : theme.uiTheme === 'dark'
        ? theme.color.grayBackground
        : theme.color.white};
`;

const NextButton = styled(PrimaryButton)`
  width: 100%;
  max-width: 438px;
  margin-top: ${({ theme }) => theme.spacing.xxxlarge};
  margin-bottom: ${({ theme }) => theme.spacing.xxxlarge};
`;

type Props = {
  pageName: string;
  title: string;
  headerMaxWidth?: number;
  description: React.ReactNode;
  descriptionMaxWidth?: number;
  progress: number;
  onClickBack: () => void;
  onClickNext?: () => void;
  onClickCancel?: () => void;
  children?: React.ReactNode;
  hideNextButton?: boolean;
  nextButtonText?: string;
  header?: React.ReactNode;
};

const FeatureOnboardingPage: React.FC<Props> = ({
  pageName,
  title,
  headerMaxWidth,
  description,
  descriptionMaxWidth,
  progress,
  onClickBack,
  onClickNext,
  onClickCancel,
  nextButtonText,
  hideNextButton,
  children,
  header,
}) => (
  <StyledPage
    name={pageName}
    header={
      <SetupHeader onClickBack={onClickBack} progress={progress} onClickCancel={onClickCancel} />
    }
  >
    <FlexContainer center column>
      <SetupContentHeader
        header={header}
        title={title}
        description={description}
        descriptionMaxWidth={descriptionMaxWidth}
        maxWidth={headerMaxWidth}
      />
      {children}
      {!hideNextButton && (
        <NextButton size="large" onClick={onClickNext}>
          {nextButtonText || 'Continue'}
        </NextButton>
      )}
    </FlexContainer>
  </StyledPage>
);

export default FeatureOnboardingPage;
