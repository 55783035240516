import React, { useState, useContext } from 'react';
import type { DragDropContextProps } from 'react-beautiful-dnd';
import { DragDropContext as BeautifulDragDropContext } from 'react-beautiful-dnd';

type CustomContextType = {
  draggingId: string | undefined;
};

const CustomContext = React.createContext<CustomContextType>(undefined as any);

export const useDragDropContext = () => {
  const context = useContext(CustomContext);
  if (!context) {
    throw new Error(
      'useDragDropContext used outside of DragDropContext. Ensure you have a <DragDropContext> component higher in the tree.',
    );
  }
  return context;
};

type Props = DragDropContextProps & {
  children?: React.ReactNode;
};

/**
 * This is a custom wrapper around react-beautiful-dnd's DragDropContext. It is used to provide additional
 * properties in the context.
 *
 * See Draggable.tsx for examples
 */
const DragDropContext = ({ children, ...props }: Props) => {
  const [draggingId, setDraggingId] = useState<string | undefined>(undefined);

  return (
    <BeautifulDragDropContext
      {...props}
      onBeforeCapture={(a) => setDraggingId(a.draggableId)}
      onDragEnd={(result, responder) => {
        setDraggingId(undefined);
        props.onDragEnd(result, responder);
      }}
    >
      <CustomContext.Provider value={{ draggingId }}>{children}</CustomContext.Provider>
    </BeautifulDragDropContext>
  );
};

export default DragDropContext;
