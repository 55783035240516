import { useMutation } from '@apollo/client';
import * as R from 'ramda';
import React, { useMemo } from 'react';
import { FiInfo } from 'react-icons/fi';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import CreateEditCategoryFormFields from 'components/categories/CreateEditCategoryFormFields';
import DisableRolloverConfirmation from 'components/categories/DisableRolloverConfirmation';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import CardBody from 'components/lib/ui/CardBody';
import CardFooter from 'components/lib/ui/CardFooter';
import Flex from 'components/lib/ui/Flex';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import ModalCard from 'components/lib/ui/ModalCard';
import Tooltip from 'components/lib/ui/Tooltip';
import AsyncButton from 'components/lib/ui/button/AsyncButton';
import { defaultButtonMixin } from 'components/lib/ui/button/DefaultButton';

import {
  getCategoryFormInitialValues,
  maybeGetRolloverFutureBudgetAllocation,
} from 'common/lib/categories/form';
import { updateCachedCategory } from 'common/lib/categories/graphQl';
import useQuery from 'common/lib/hooks/useQuery';
import useDeleteCategory from 'lib/hooks/category/useDeleteCategory';
import { errorToast } from 'lib/ui/toast';

import { ROLLOVER_APPLY_TO_FUTURE_MONTHS_FIELD_NAME } from 'common/constants/budget';
import { CACHE_KEYS } from 'common/constants/cache';

import { gql } from 'common/generated/gql';
import type { PayloadErrorFields } from 'common/generated/graphQlTypes/PayloadErrorFields';

const ENABLE_SYSTEM_CATEGORY =
  'Activate a system category to restore it and automatically have Monarch categorize transactions related to it.';
const DISABLE_SYSTEM_CATEGORY =
  'Deactivate will remove this category everywhere in Monarch. Any transactions related to this category will be marked as uncategorized unless custom rules are setup that categorize them.';
const MODAL_TITLE = 'Edit Category';

const EnableDisableButton = styled(AsyncButton)`
  ${defaultButtonMixin}
`;

const InfoTooltipWrapper = styled(Flex)`
  padding: 0 ${({ theme }) => theme.spacing.xsmall};
  color: ${({ theme }) => theme.color.textLight};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin: 0px auto;
`;

const FormDefaultButtonContainer = styled.div`
  display: flex;
`;

// Too keep the save button right aligned when there's no left button
const ButtonPlaceholder = styled.div``;

type Props = {
  categoryId: string;
  onDone: () => void;
};

const EditCategoryModal = ({ categoryId, onDone }: Props) => {
  const { data, isLoadingInitialData } = useQuery(GET_CATEGORY, {
    variables: { id: categoryId },
  });
  const { category } = data ?? {};

  const [
    DeleteCategoryConfirmationModal,
    { deleteCategoryWithConfirmation, loadingDisableOrDeleteCategory },
  ] = useDeleteCategory({
    onError: (errorMessage: string | null) => {
      errorToast(errorMessage);
    },
    onDone,
  });

  const [updateCategory] = useMutation(UPDATE_CATEGORY, {
    update: (cache) => {
      cache.evict({ fieldName: CACHE_KEYS.CATEGORIES });
      cache.evict({ fieldName: CACHE_KEYS.CATEGORY_GROUPS });
    },
  });
  const [restoreCategory, { loading: loadingRestoreCategory }] = useMutation(RESTORE_CATEGORY);

  // TODO may want to get the "protected" attribute from the backend instead
  const systemCategory = category?.systemCategory;
  const showDisableOrDeleteButton =
    systemCategory !== 'uncategorized' && systemCategory !== 'balance_adjustments';

  const onActivateCategory = async () => {
    if (!category) {
      return;
    }
    const { data } = await restoreCategory({
      variables: { id: category?.id ?? '' },
      update: updateCachedCategory(category?.id, { isDisabled: () => false }),
    });
    const errors: PayloadErrorFields | undefined = R.path(['restoreCategory', 'errors'], data);
    if (errors) {
      errorToast(errors.message);
    } else {
      onDone?.();
    }
  };

  const initialValues = useMemo(
    () => (category ? getCategoryFormInitialValues(category) : undefined),
    [category],
  );

  if (isLoadingInitialData) {
    return (
      <ModalCard title={MODAL_TITLE}>
        <CardBody>
          <StyledLoadingSpinner />
        </CardBody>
      </ModalCard>
    );
  } else if (!category) {
    return (
      <ModalCard title={MODAL_TITLE}>
        <CardBody>Category not found</CardBody>
      </ModalCard>
    );
  }

  return (
    <ModalCard title={MODAL_TITLE}>
      <Form
        initialValues={initialValues}
        onSubmit={async (values) => {
          let mutationInput = R.omit([ROLLOVER_APPLY_TO_FUTURE_MONTHS_FIELD_NAME], values);
          const rolloverFutureBudgetAllocation = maybeGetRolloverFutureBudgetAllocation(values);

          if (rolloverFutureBudgetAllocation) {
            mutationInput = {
              ...mutationInput,
              rolloverFutureBudgetAllocation,
            };
          }

          await updateCategory({
            variables: {
              input: {
                ...mutationInput,
                // TODO: needed due to a hotfix (api#6097)
                applyRolloverBudgetToFutureMonths: undefined,
              },
            },
          });
        }}
        onSubmitSuccess={onDone}
      >
        <CardBody>
          <CreateEditCategoryFormFields
            categoryType={category?.group.type}
            systemCategoryDisplayName={category?.systemCategoryDisplayName}
            isSystemCategory={category?.isSystemCategory}
          />
        </CardBody>
        <CardFooter justifyBetween>
          <FormDefaultButtonContainer>
            <Switch>
              <Case when={category?.isDisabled}>
                <EnableDisableButton pending={loadingRestoreCategory} onClick={onActivateCategory}>
                  Activate
                </EnableDisableButton>
                <Tooltip content={ENABLE_SYSTEM_CATEGORY}>
                  <InfoTooltipWrapper center>
                    <FiInfo />
                  </InfoTooltipWrapper>
                </Tooltip>
              </Case>
              <Case when={showDisableOrDeleteButton}>
                <EnableDisableButton
                  pending={loadingDisableOrDeleteCategory}
                  onClick={() => deleteCategoryWithConfirmation(category.id)}
                >
                  {category?.isSystemCategory ? 'Disable' : 'Delete'}
                </EnableDisableButton>
                {category?.isSystemCategory && (
                  <Tooltip content={DISABLE_SYSTEM_CATEGORY}>
                    <InfoTooltipWrapper center>
                      <FiInfo />
                    </InfoTooltipWrapper>
                  </Tooltip>
                )}
              </Case>
              <Case default>
                <ButtonPlaceholder />
              </Case>
            </Switch>
          </FormDefaultButtonContainer>
          <DisableRolloverConfirmation
            rolloverEnabledInitially={initialValues?.rolloverEnabled ?? false}
          >
            {({ onSave }) => (
              <FormSubmitButton type="button" size="small" onClick={onSave}>
                Save
              </FormSubmitButton>
            )}
          </DisableRolloverConfirmation>
        </CardFooter>
      </Form>
      <DeleteCategoryConfirmationModal />
    </ModalCard>
  );
};

const GET_CATEGORY = gql(`
  query Web_GetEditCategory($id: UUID!) {
    category(id: $id) {
      id
      ...CategoryFormFields
    }
  }
`);

const UPDATE_CATEGORY = gql(`
  mutation Web_UpdateCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      errors {
        ...PayloadErrorFields
      }
      category {
        id
        ...CategoryFormFields
      }
    }
  }
`);

const RESTORE_CATEGORY = gql(`
  mutation Web_RestoreCategory($id: UUID!) {
    restoreCategory(id: $id) {
      errors {
        ...PayloadErrorFields
      }
      category {
        id
        ...CategoryFormFields
      }
    }
  }
`);

export default EditCategoryModal;
