import type { DataProviderLegacy } from 'common/generated/graphQlTypes/globalTypes';

export enum InstitutionStatus {
  Healthy = 'HEALTHY',
  Degraded = 'DEGRADED',
  Down = 'DOWN',
  SyncDisabled = 'SYNC_DISABLED',
}

export type InstitutionDataProvider = {
  dataProvider: DataProviderLegacy;
  dataProviderInstitutionId?: string | null;
};
