import { useApolloClient, useMutation } from '@apollo/client';
import type { DateTime } from 'luxon';
import { useCallback } from 'react';

import { getOptimisticMonthlyAmounts } from 'common/lib/budget/OptimisticAdapter';

import { gql } from 'common/generated/gql';
import type { MutationHookOptionsFromDocument } from 'common/types/graphql';

type Options = MutationHookOptionsFromDocument<typeof UPDATE_FLEX_BUDGET_MUTATION>;

const useUpdateFlexBudgetMutation = (options: Options, optimisticCacheUpdate = true) => {
  const client = useApolloClient();
  const [performMutation, { loading }] = useMutation(UPDATE_FLEX_BUDGET_MUTATION, options);

  // TODO: This doesn't work on mobile. Figure out why and fix it
  const optimisticUpdateCacheRollovers = useCallback(
    (startDate: DateTime, amount: number) => {
      const cacheId = `BudgetFlexMonthlyAmounts:{"budgetVariability":"flexible"}`;

      const data = client.readFragment({
        id: cacheId,
        fragment: BUDGET_FLEX_MONTHLY_AMOUNTS_FRAGMENT,
      });

      if (data) {
        const writeData = getOptimisticMonthlyAmounts<typeof data>(data, amount, startDate);
        client.writeFragment({
          id: cacheId,
          fragment: BUDGET_FLEX_MONTHLY_AMOUNTS_FRAGMENT,
          data: writeData,
        });
      }
    },
    [client],
  );

  const updateFlexBudgetItemAmount =
    (startDate: DateTime) => async (amount: number, applyToFuture?: boolean) => {
      if (optimisticCacheUpdate) {
        optimisticUpdateCacheRollovers(startDate, amount);
      }

      await performMutation({
        variables: {
          // @ts-expect-error: TODO: change graphql codegen to use optionals for nullable fields
          input: {
            startDate: startDate.toISODate(),
            amount,
            applyToFuture,
          },
        },
      });
    };

  return {
    loading,
    updateFlexBudgetMutation: updateFlexBudgetItemAmount,
  };
};

const UPDATE_FLEX_BUDGET_MUTATION = gql(/* GraphQL */ `
  mutation Common_UpdateFlexBudgetMutation($input: UpdateOrCreateFlexBudgetItemMutationInput!) {
    updateOrCreateFlexBudgetItem(input: $input) {
      budgetItem {
        id
        budgetAmount
      }
    }
  }
`);

const BUDGET_FLEX_MONTHLY_AMOUNTS_FRAGMENT = gql(/* GraphQL */ `
  fragment OptimisticUpdateFlexMonthlyAmounts on BudgetFlexMonthlyAmounts {
    budgetVariability
    monthlyAmounts {
      month
      plannedCashFlowAmount
      actualAmount
      remainingAmount
      previousMonthRolloverAmount
      rolloverType
    }
  }
`);

export default useUpdateFlexBudgetMutation;
