import * as RA from 'ramda-adjunct';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import NetWorthChartLabelContentLoader from 'components/accounts/NetWorthChartLabelContentLoader';
import BigDateValueTooltip from 'components/lib/charts/BigDateValueTooltip';
import type { TooltipPayload } from 'components/lib/charts/ChartTooltip';
import SimpleLineChart from 'components/lib/charts/SimpleLineChart';

import type { DateRange, SnapshotEntry } from 'common/lib/accounts/accountCharts';
import {
  DATE_RANGES,
  getSnapshotDataForSnapshotGraph,
  getSnapshotDataForTimePeriod,
} from 'common/lib/accounts/accountCharts';
import { getComparativeDataFromStartOfPeriodToDate } from 'common/lib/accounts/netWorthCharts';
import { formatCurrencyThousandsDecimal } from 'common/utils/Currency';
import {
  isoDateToAbbreviatedMonthAndYear,
  isoDateToMonthAbbreviationAndDay,
} from 'common/utils/date';
import useTheme from 'lib/hooks/useTheme';

import type { Web_GetAggregateSnapshotsQuery } from 'common/generated/graphql';

const CHART_HEIGHT_PX = 275;
const DEFAULT_X_AXIS_HEIGHT = 26;
const LEFT_MARGIN = 10;

const LineChartContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.default};
`;

type Props = {
  title: string;
  aggregateSnapshotsData: Web_GetAggregateSnapshotsQuery['aggregateSnapshots'];
  isAggreateSnapShotsNetworkRequestInFlight?: boolean;
  selectedAccountType: string | null;
  selectedDateRange: DateRange;
  isAsset?: boolean;
  width: number;
  yDataKey: keyof Omit<SnapshotEntry, 'date'>;
};

const NetWorthPerformanceChart = ({
  title,
  aggregateSnapshotsData,
  isAggreateSnapShotsNetworkRequestInFlight,
  isAsset,
  selectedAccountType,
  selectedDateRange,
  yDataKey,
  width,
}: Props) => {
  const theme = useTheme();

  const { snapshots: aggregateSnapshots } = useMemo(
    () => getSnapshotDataForSnapshotGraph(aggregateSnapshotsData, isAsset ?? false),
    [isAsset, aggregateSnapshotsData],
  );

  const [loadedAccountTypes, setLoadedAccountTypes] = useState<Set<string | null>>(
    new Set([selectedAccountType]),
  );

  const [loadedDateRanges, setLoadedDateRanges] = useState<Set<string | null>>(
    new Set([selectedDateRange.display]),
  );

  const isLoadingLabelForLineChart =
    isAggreateSnapShotsNetworkRequestInFlight &&
    (!loadedAccountTypes.has(selectedAccountType) ||
      !loadedDateRanges.has(selectedDateRange.display));

  const lineChartData = useMemo(
    () =>
      aggregateSnapshots.length
        ? getSnapshotDataForTimePeriod(
            selectedDateRange?.duration ?? DATE_RANGES[0].duration,
            aggregateSnapshots,
          ).data
        : [],
    [selectedDateRange, aggregateSnapshots],
  );

  const dateRangeGreaterThanYear =
    selectedDateRange.display === '1Y' || selectedDateRange.display === 'ALL';

  return (
    <LineChartContainer>
      <SimpleLineChart
        height={CHART_HEIGHT_PX}
        margin={{
          left: LEFT_MARGIN,
        }}
        lineColor={theme.color.text}
        width={width}
        data={lineChartData as any}
        domain={['dataMin', 'dataMax']}
        onAnimationEnd={() => {
          setLoadedAccountTypes((prev) => prev.add(selectedAccountType));
          setLoadedDateRanges((prev) => prev.add(selectedDateRange.display));
        }}
        xAxisProps={
          isLoadingLabelForLineChart
            ? {
                // @ts-ignore - Recharts clones the element and adds the props to the clone
                tick: <NetWorthChartLabelContentLoader />,
              }
            : {
                height: DEFAULT_X_AXIS_HEIGHT,
              }
        }
        tooltipComponent={({ active, payload }) => (
          <TooltipComponent
            active={active}
            // @ts-ignore - The date type is incorrect
            payload={payload}
            yDataKey={yDataKey}
            lineChartData={lineChartData}
            title={title}
            isAsset={isAsset}
          />
        )}
        yDataKey={yDataKey}
        xDataKey="date"
        formatYAxis={formatCurrencyThousandsDecimal}
        formatXAxis={
          dateRangeGreaterThanYear
            ? isoDateToAbbreviatedMonthAndYear
            : isoDateToMonthAbbreviationAndDay
        }
        smoothGradient
      />
    </LineChartContainer>
  );
};

type TooltipComponentProps = {
  active: boolean;
  payload: TooltipPayload<Partial<SnapshotEntry>>[] | undefined | null;
  yDataKey: keyof Omit<SnapshotEntry, 'date'>;
  lineChartData: SnapshotEntry[];
  title: string;
  isAsset?: boolean;
};

const TooltipComponent = ({
  active,
  payload,
  yDataKey,
  lineChartData,
  title,
  isAsset,
}: TooltipComponentProps) => {
  const entry = payload?.[0]?.payload;
  const date = entry?.date;
  const value = entry?.[yDataKey];

  const comparisonData = useMemo(
    () => (date ? getComparativeDataFromStartOfPeriodToDate(lineChartData, date, yDataKey) : null),
    [date, lineChartData, yDataKey],
  );

  return RA.isNotNil(date) && RA.isNotNil(value) && RA.isNotNil(comparisonData) ? (
    <BigDateValueTooltip
      active={active}
      value={value}
      date={date}
      label={title}
      isAsset={isAsset}
      comparisonData={comparisonData}
    />
  ) : null;
};

export default NetWorthPerformanceChart;
