import React from 'react';
import styled from 'styled-components';

import Mark from 'components/brand/Mark';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import Tooltip from 'components/lib/ui/Tooltip';
import IconButton from 'components/lib/ui/button/IconButton';
import NavLink from 'components/lib/ui/link/NavLink';
import NotificationCenterButton from 'components/notifications/NotificationCenterButton';

import { color } from 'common/lib/theme/dynamic';
import isV2Theme from 'common/lib/theme/isV2Theme';
import useIsV2Theme from 'lib/hooks/useIsV2Theme';

import routes from 'constants/routes';

const MARK_SIZE_PX = 24;
const MARK_SIZE_PX_V2 = 28;

const HeaderLink = styled(NavLink)`
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: ${isV2Theme('-2px', '0')};
`;

const SearchTooltipText = styled(Text).attrs({ weight: 'medium' })`
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const SearchTooltipContent = styled.div`
  display: flex;
  align-items: center;
  padding: 6px ${({ theme }) => theme.spacing.xsmall};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.xxsmall};
  border-radius: ${({ theme }) => theme.radius.small};
  background: ${isV2Theme(color.gray11, color.navy)};
  margin-left: ${({ theme }) => theme.spacing.xxxsmall};
  min-width: 20px;
  height: 20px;
`;

type Props = {
  onOpenSearch?: () => Promise<void> | void;
  isMacOs?: boolean;
};

const SideBarDefaultHeader = ({ isMacOs = false, onOpenSearch }: Props) => {
  const isV2Theme = useIsV2Theme();

  return (
    <>
      <HeaderLink to={routes.dashboard()}>
        <Mark size={isV2Theme ? MARK_SIZE_PX_V2 : MARK_SIZE_PX} />
      </HeaderLink>
      <Tooltip
        portal
        content={
          <SearchTooltipContent>
            <SearchTooltipText>Search</SearchTooltipText>
            <IconWrapper>
              {isMacOs ? <Icon size={12} name="command" /> : <kbd>ctrl</kbd>}
            </IconWrapper>
            <IconWrapper>K</IconWrapper>
          </SearchTooltipContent>
        }
        place="bottom"
      >
        <IconButton icon="search" size="small" darkBg={!isV2Theme} onClick={onOpenSearch} />
      </Tooltip>
      <NotificationCenterButton />
      <IconButton
        icon="settings"
        size="small"
        linkTo={routes.settings.profile()}
        // @ts-ignore - activeClassName is passed through to NavLink
        activeClassName="jon-was-here"
        darkBg={!isV2Theme}
      />
    </>
  );
};

export default SideBarDefaultHeader;
