import * as R from 'ramda';
import { createReducer } from 'typesafe-actions';

import {
  toggleSidebarExpanded,
  windowDidResize,
  routeChanged,
  setIsSideDrawerVisible,
  setDemoHouseholdModalVisible,
  setGlobalSearchModalVisible,
} from 'actions';

import { SIDEBAR_BREAKPOINT_PX } from 'constants/breakpoints';

import type WindowSize from 'types/WindowSize';

// Note that this state is NOT persisted across page loads.
// If you want UI state that persists, use persistentUi
const INITIAL_UI_STATE: UiState = {
  /* This is undefined when the window hasn't loaded yet. It will no longer be undefined when
    its default is set in window.onload */
  sidebarExpanded: undefined,
  isSideDrawerVisible: false,
  window: {
    width: 0,
    height: 0,
  },
  isDemoHouseholdModalVisible: false,
  isGlobalSearchModalVisible: false,
};

export type UiState = {
  sidebarExpanded?: boolean;
  isSideDrawerVisible: boolean;
  window: WindowSize;
  isDemoHouseholdModalVisible: boolean;
  isGlobalSearchModalVisible: boolean;
};

const ui = createReducer<UiState>(INITIAL_UI_STATE)
  .handleAction(toggleSidebarExpanded, (state) => ({
    ...state,
    sidebarExpanded: !state.sidebarExpanded,
  }))
  .handleAction(setIsSideDrawerVisible, (state, { payload: isSideDrawerVisible }) => ({
    ...state,
    isSideDrawerVisible,
  }))
  .handleAction(windowDidResize, (state, { payload: { currentSize, previousSize } }) => {
    // If window is resized to smaller width than SIDEBAR_BREAKPOINT_PX and sidebar is expanded, then close sidebar
    // If window is resized to wider than SIDEBAR_BREAKPOINT_PX and sidebar is closed, then open sidebar
    // We do this in JS rather than using a CSS media query because of the state we keep in redux
    if (
      currentSize.width < SIDEBAR_BREAKPOINT_PX &&
      (previousSize?.width ?? SIDEBAR_BREAKPOINT_PX) >= SIDEBAR_BREAKPOINT_PX
    ) {
      return { ...state, window: currentSize, sidebarExpanded: false };
    }
    if (
      (previousSize?.width ?? 0) < SIDEBAR_BREAKPOINT_PX &&
      currentSize.width >= SIDEBAR_BREAKPOINT_PX
    ) {
      return { ...state, window: currentSize, sidebarExpanded: true };
    }

    return { ...state, window: currentSize };
  })
  .handleAction(routeChanged, (state, _action) => {
    const { window } = state;

    return R.evolve(
      {
        sidebarExpanded: (previous) => {
          // Close sidebar after clicking on route when on mobile device
          if (window.width < SIDEBAR_BREAKPOINT_PX && previous !== undefined) {
            return false;
          }
          return previous;
        },
      },
      state,
    );
  })
  .handleAction(setDemoHouseholdModalVisible, (state, { payload: visible }) => ({
    ...state,
    isDemoHouseholdModalVisible: visible,
  }))
  .handleAction(setGlobalSearchModalVisible, (state, { payload: visible }) => ({
    ...state,
    isGlobalSearchModalVisible: visible,
  }));

export default ui;
