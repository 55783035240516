import { createReducer } from 'typesafe-actions';
import type { ActionType } from 'typesafe-actions';

import * as actions from 'common/state/accounts/actions';

const { setHasAddedAccounts } = actions;

type AccountsAction = ActionType<typeof actions>;

export const accountsPersistConfig = {
  key: 'accounts',
  // adds storage when using it, since it's different between web and mobile
};

const INITIAL_STATE: AccountsState = {
  hasAddedAccounts: undefined,
};

export type AccountsState = {
  hasAddedAccounts?: boolean;
};

const accountsReducer = createReducer<AccountsState, AccountsAction>(INITIAL_STATE).handleAction(
  setHasAddedAccounts,
  (state, { payload }) => ({
    ...state,
    hasAddedAccounts: payload,
  }),
);

export default accountsReducer;
