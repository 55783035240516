import type { StyleMixin } from 'common/types/Styles';

/**
 * This mixin is for creating rounded corners that don't allow content to show below the rounded part.
 *
 * It sets the element's background color to the background color of the page, and then uses
 * a pseudo element to draw the real background color with rounded corners.
 */
const fakeCornersMixin: StyleMixin<{
  topLeft?: boolean;
  bottomLeft?: boolean;
  topRight?: boolean;
  bottomRight?: boolean;
  radius?: string;
  backgroundColor?: string;
  fillColor?: string;
}> = ({
  theme,
  topLeft,
  bottomLeft,
  topRight,
  bottomRight,
  radius = theme.radius.medium,
  backgroundColor = theme.color.grayBackground,
  fillColor = theme.color.white,
}) => `
  background: ${backgroundColor};
  ::before {
    /* Put this below the actual content */
    z-index: -1; /* stylelint-disable-line plugin/no-z-index */
    content: '';
    background: ${fillColor};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-top-left-radius: ${topLeft ? radius : 0};
    border-bottom-left-radius: ${bottomLeft ? radius : 0};
    border-top-right-radius: ${topRight ? radius : 0};
    border-bottom-right-radius: ${bottomRight ? radius : 0};
  }
`;

export default fakeCornersMixin;
