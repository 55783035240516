import ButtonIcon from 'components/lib/ui/button/ButtonIcon';

import type { ButtonSize } from 'lib/styles/buttonSizeMixin';
import { DEFAULT_BUTTON_SIZE } from 'lib/styles/buttonSizeMixin';

import type { StyleMixin } from 'common/types/Styles';

const ICON_SIZE: { [size in ButtonSize]: number } = {
  xsmall: 12,
  small: 12,
  medium: 16,
  large: 20,
};

const buttonIconMixin: StyleMixin<{ size?: ButtonSize }> = ({ size = DEFAULT_BUTTON_SIZE }) => `
  ${ButtonIcon} {
    width: ${ICON_SIZE[size]}px;
    height: ${ICON_SIZE[size]}px;
  }
`;

export default buttonIconMixin;
