import React, { useEffect } from 'react';
import styled from 'styled-components';

import LinkAccountFlow from 'components/accounts/LinkAccountFlow';
import TopInstitutionsGrid from 'components/institutions/TopInstitutionsGrid';
import FlexContainer from 'components/lib/ui/FlexContainer';
import SearchBar from 'components/lib/ui/SearchBar';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import OnboardingTitle from 'components/onboarding/OnboardingTitle';

import useGetInstitutionGroup from 'lib/hooks/institutions/useGetInstitutionGroup';
import useIsFeatureFlagOff from 'lib/hooks/useIsFeatureFlagOff';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import useModal from 'lib/hooks/useModal';

import * as COPY from 'common/constants/copy';

import type { LinkInstitutionFields } from 'common/generated/graphQlTypes/LinkInstitutionFields';
import type { Web_GetTopInstitutionGroups_topInstitutionGroups } from 'common/generated/graphQlTypes/Web_GetTopInstitutionGroups';
import { TopInstitutionGroupType } from 'common/generated/graphQlTypes/globalTypes';

const PageTitle = styled(OnboardingTitle)`
  font-size: ${({ theme }) => theme.fontSize.xlarge};
  margin: ${({ theme }) => theme.spacing.xxxxlarge} 0 ${({ theme }) => theme.spacing.medium};
`;

const PageSubtitle = styled.p`
  margin: 0 0 ${({ theme }) => theme.spacing.xxxlarge};
  font-size: ${({ theme }) => theme.fontSize.base};
  color: ${({ theme }) => theme.color.text};
  font-weight: ${({ theme }) => theme.fontWeight.book};
`;

const IntroContainer = styled(FlexContainer).attrs({ column: true })`
  width: 100%;
  max-width: 558px;
  margin: auto;
`;

const PageContainer = styled.div`
  width: 100%;
`;

const TopInstitutionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.large};
  padding-top: 0;
`;

const StyledSearchBar = styled(SearchBar)`
  margin: 0 ${({ theme }) => theme.spacing.large} ${({ theme }) => theme.spacing.xlarge};
`;

const SkipButton = styled(DefaultButton).attrs({
  size: 'medium',
})`
  margin-top: ${({ theme }) => theme.spacing.xxxlarge};
  width: 100%;
`;

type Props = {
  next: () => void;
  skipToComplete: () => void;
};

const DEFAULT_SELECTED_GROUP_TYPE = TopInstitutionGroupType.banks_credit_cards;

const OnboardingConnectSpendingAccount = ({ next, skipToComplete }: Props) => {
  const EXPERIMENT = 'ab-test-connect-single-account-before-paywall';

  /*
    I am checking if it is on and off because I want to avoid control.
    If it is off it will skip the page and I don't want to blink any content.
  */
  const isAbTestConnectSingleAccountBeforePaywallOn = useIsFeatureFlagOn(EXPERIMENT, {
    trackImpression: true,
  });

  const isAbTestConnectSingleAccountBeforePaywallOff = useIsFeatureFlagOff(EXPERIMENT);

  /*
    skips the page when the ab-test-connect-accounts-before-paywall feature flag is on.
    This feature flag is used to let users connect accounts before the paywall. When that happens, we should skip this page.
  */
  useEffect(() => {
    if (isAbTestConnectSingleAccountBeforePaywallOn) {
      skipToComplete();
    }
  }, [isAbTestConnectSingleAccountBeforePaywallOn]);

  const { credentials, topInstitutionGroupByType } =
    useGetInstitutionGroup(DEFAULT_SELECTED_GROUP_TYPE) ?? {};

  const [LinkModal, { open: openLink, close: closeLink, context }] = useModal<{
    institution?: LinkInstitutionFields;
    selectedTopGroup?: Web_GetTopInstitutionGroups_topInstitutionGroups;
  }>();

  return (
    <PageContainer>
      {isAbTestConnectSingleAccountBeforePaywallOff && (
        <>
          <IntroContainer>
            <PageTitle>{COPY.ONBOARDING.SPENDING_INSIGHTS_CONNECT_ACCOUNT.TITLE}</PageTitle>
            <PageSubtitle>
              {COPY.ONBOARDING.SPENDING_INSIGHTS_CONNECT_ACCOUNT.SUBTITLE}
            </PageSubtitle>

            <StyledSearchBar
              name="search-institutions"
              placeholder="Search 13,000+ institutions"
              autoComplete="off"
              focusOnRender={false}
              onFocus={() => {
                openLink({ selectedTopGroup: topInstitutionGroupByType ?? undefined });
              }}
            />

            <TopInstitutionsContainer>
              <TopInstitutionsGrid
                institutions={topInstitutionGroupByType?.institutions ?? []}
                credentialCount={credentials?.length ?? 0}
                onSelectInstitution={(institution) => openLink({ institution })}
              />

              <SkipButton onClick={next}>
                {COPY.ONBOARDING.SPENDING_INSIGHTS_CONNECT_ACCOUNT.SKIP_BUTTON}
              </SkipButton>
            </TopInstitutionsContainer>
          </IntroContainer>

          <LinkModal>
            <LinkAccountFlow
              allowManualAccount={false}
              selectedTopGroup={context?.selectedTopGroup}
              institution={context?.institution}
              onClose={closeLink}
              onComplete={() => next()}
            />
          </LinkModal>
        </>
      )}
    </PageContainer>
  );
};

export default OnboardingConnectSpendingAccount;
