import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import { TooltipV2 } from 'components/lib/ui/TooltipV2';

import { color, spacing, transition } from 'common/lib/theme/dynamic';

const RecalculateAmountRoot = styled.div`
  position: relative;
`;

const RecalculateButtonContainer = styled(FlexContainer)`
  width: 32px;
`;

const RecalculateButton = styled(Icon).attrs({ name: 'rotate-cw' })`
  position: absolute;
  left: ${spacing.xsmall};
  top: 50%;
  transform: translateY(-50%);
  color: ${color.blue};
  cursor: pointer;
  display: block;
  transition: ${transition.fast};

  &:hover {
    transform: translateY(-50%) rotate(18deg);
  }

  &:active {
    color: ${color.blueDark};
  }
`;

const TooltipContent = styled.span`
  display: inline-block;
  padding: 0 ${spacing.xsmall};
`;

const RecalculateAmountWrapper = ({
  children,
  visible,
  onClick,
}: React.PropsWithChildren<{ visible: boolean; onClick: () => void }>) => (
  <RecalculateAmountRoot>
    {visible && (
      <TooltipV2 content={<TooltipContent>Recalculate</TooltipContent>} sideOffset={2}>
        <RecalculateButtonContainer>
          <RecalculateButton onClick={onClick} />
        </RecalculateButtonContainer>
      </TooltipV2>
    )}
    {children}
  </RecalculateAmountRoot>
);

export default RecalculateAmountWrapper;
