import { useCallback, useState } from 'react';

import useEventListener from 'lib/hooks/useEventListener';

type UseKeyOptions = {
  onKeyDown?: (e: KeyboardEvent) => void;
  onKeyUp?: (e: KeyboardEvent) => void;
};

/**
 * Hook to listen to keyboard events.
 *
 * const isPressed = useKey('Escape', {
 *    onKeyDown: event => console.log('Escape key pressed down')
 * })
 */
const useKey = (key: string, element: HTMLElement | null | undefined, options?: UseKeyOptions) => {
  const [isPressed, setIsPressed] = useState(false);

  const onDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === key) {
        setIsPressed(true);
        options?.onKeyDown?.(event);
      }
    },
    [setIsPressed, key, options],
  );

  const onUp = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === key) {
        setIsPressed(false);
        options?.onKeyUp?.(event);
      }
    },
    [setIsPressed, key, options],
  );

  useEventListener(element, 'keydown', onDown);
  useEventListener(element, 'keyup', onUp);

  return isPressed;
};

export default useKey;
