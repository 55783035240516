/**
 * Helper function meant to be used in styled-components to make conditional
 * styles easier to read (avoid ternaries). It uses the version number on theme
 * to determine which value to return.
 *
 * Example usage:
 *
 * const Root = styled.div`
 *  background-color: ${isV2Theme(color.gray3, color.grayBackground)};
 * `;
 */
const isV2Theme =
  <ValOnT, ValOffT, StyledArgs extends { theme: { version: number } }>(
    valOn: ValOnT,
    valOff?: ValOffT,
  ) =>
  ({ theme: { version } }: StyledArgs) =>
    version === 2 ? valOn : valOff;

export default isV2Theme;
