import { createSelector } from 'reselect';

import type { EmployeeState } from 'common/state/employee/reducer';

export type RootStateEmployee = {
  employee: EmployeeState;
};

export const getEmployeeState = ({ employee }: RootStateEmployee) => employee;
export const getIsDemoMode = createSelector(getEmployeeState, ({ demoMode }) => demoMode);
export const getForceEmptyState = createSelector(
  getEmployeeState,
  ({ forceEmptyState }) => forceEmptyState,
);
export const getShowGoalsTour = createSelector(
  getEmployeeState,
  ({ showGoalsTour }) => showGoalsTour,
);
export const getForceRefreshEnabledStatus = createSelector(
  getEmployeeState,
  ({ forceRefreshEnabledStatus }) => forceRefreshEnabledStatus,
);
