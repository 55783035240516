import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import {
  UPDATE_LIABILITY_STATEMENT,
  UPDATE_LIABILITY_STATEMENT_PAYMENT_STATUS,
} from 'common/lib/graphQl/recurring';

type UpdateBillAmountProps = {
  creditReportLiabilityStatementId: string;
  userBillAmount?: number;
  revertToOriginal?: boolean;
  onSuccess?: () => void;
  onError?: () => void;
};

type UpdatePaymentStatusProps = {
  creditReportLiabilityStatementId: string;
  isPaid: boolean;
  onSuccess?: () => void;
  onError?: () => void;
  refetchQueries?: string[];
};

const useCreditReportLiabilityStatementsOperations = () => {
  const [performUpdateLiabilityStatementAmount] = useMutation(UPDATE_LIABILITY_STATEMENT);
  const [performUpdateLiabilityStatementPaymentStatus] = useMutation(
    UPDATE_LIABILITY_STATEMENT_PAYMENT_STATUS,
  );

  const updateLiabilityStatementPaymentStatus = useCallback(
    async ({
      creditReportLiabilityStatementId,
      isPaid,
      onSuccess,
      onError,
      refetchQueries,
    }: UpdatePaymentStatusProps) => {
      const response = await performUpdateLiabilityStatementPaymentStatus({
        variables: {
          creditReportLiabilityStatementId,
          isPaid,
        },
        refetchQueries,
      });

      if (response.data?.updateBillPaid?.errors) {
        onError?.();
      } else {
        onSuccess?.();
      }

      return response;
    },
    [performUpdateLiabilityStatementPaymentStatus],
  );

  const _updateLiabilityUserBillAmountRequest = useCallback(
    async ({
      creditReportLiabilityStatementId,
      userBillAmount,
      revertToOriginal,
      onSuccess,
      onError,
    }: UpdateBillAmountProps) => {
      const response = await performUpdateLiabilityStatementAmount({
        variables: {
          creditReportLiabilityStatementId,
          userBillAmount,
          revertToOriginal,
        },
      });

      if (response.data?.updateBillAmount?.errors) {
        onError?.();
      } else {
        onSuccess?.();
      }

      return response;
    },
    [performUpdateLiabilityStatementAmount],
  );

  const updateLiabilityUserBillAmount = useCallback(
    async ({
      creditReportLiabilityStatementId,
      userBillAmount,
      onSuccess,
      onError,
    }: UpdateBillAmountProps) =>
      _updateLiabilityUserBillAmountRequest({
        creditReportLiabilityStatementId,
        userBillAmount,
        onSuccess,
        onError,
      }),
    [_updateLiabilityUserBillAmountRequest],
  );

  const revertLiabilityBillAmountToOriginal = useCallback(
    async ({ creditReportLiabilityStatementId, onSuccess, onError }: UpdateBillAmountProps) =>
      _updateLiabilityUserBillAmountRequest({
        creditReportLiabilityStatementId,
        revertToOriginal: true,
        onSuccess,
        onError,
      }),
    [_updateLiabilityUserBillAmountRequest],
  );

  return {
    updateLiabilityUserBillAmount,
    revertLiabilityBillAmountToOriginal,
    updateLiabilityStatementPaymentStatus,
  };
};

export default useCreditReportLiabilityStatementsOperations;
