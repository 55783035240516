import React from 'react';
import styled from 'styled-components';

import FeatureOnboardingPage from 'components/lib/layouts/FeatureOnboardingPage';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import FooterButton from 'components/plan/onboarding/FooterButton';
import LeftToBudgetFooter from 'components/plan/onboarding/LeftToBudgetFooter';
import OnboardingBudgetList from 'components/plan/onboarding/OnboardingBudgetList';
import OnboardingGroupFooter from 'components/plan/onboarding/OnboardingGroupFooter';

import { spacing } from 'common/lib/theme/dynamic';
import { ONBOARDING_AMOUNT_INPUT_DEBOUNCE_TIME } from 'lib/budget/componentUtils';
import {
  getBudgetedAmountForType,
  getGroupsDataWithBudgetedAmounts,
  getTooltipRows,
} from 'lib/budget/onboardingAdapters';
import usePlanAdapter from 'lib/hooks/plan/usePlanAdapter';
import usePlanQuery from 'lib/hooks/plan/usePlanQuery';
import usePlanState from 'lib/hooks/plan/usePlanState';

import * as COPY from 'common/constants/copy';

import { CategoryGroupType } from 'common/generated/graphql';

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin-top: ${spacing.xlarge};
`;

const Container = styled(FlexContainer).attrs({ center: true, column: true })`
  margin-bottom: 200px;
`;

type Props = {
  onBack: () => void;
  onCancel: () => void;
  onNext: () => void;
  progress: number;
};

const OnboardingExpenses = ({ onBack, onCancel, onNext, progress }: Props) => {
  const [state] = usePlanState();
  const { data, fetchedDateRange, updateCellValue } = usePlanQuery(
    state,
    ONBOARDING_AMOUNT_INPUT_DEBOUNCE_TIME,
  );
  const { isLoadingInitialData, gridDisplayData, gridAmounts, budgetSummaryData } = usePlanAdapter(
    data,
    state,
    fetchedDateRange,
  );

  // gridDisplayData has the presentational info that we need, but gridAmounts has the budgeted amounts.
  const expenseDataWithAmounts = getGroupsDataWithBudgetedAmounts(
    gridDisplayData,
    gridAmounts,
    CategoryGroupType.EXPENSE,
    state.thisMonth,
  );

  const incomeAmount = getBudgetedAmountForType(budgetSummaryData, CategoryGroupType.INCOME);
  const expensesAmount = getBudgetedAmountForType(budgetSummaryData, CategoryGroupType.EXPENSE);
  const tooltipRows = getTooltipRows(incomeAmount, expensesAmount);
  const leftToBudgetOrSave = incomeAmount - expensesAmount;

  return (
    <FeatureOnboardingPage
      pageName="Expenses"
      title={COPY.BUDGET.ONBOARDING.EXPENSES.TITLE}
      description={COPY.BUDGET.ONBOARDING.EXPENSES.DESCRIPTION}
      descriptionMaxWidth={600}
      progress={progress}
      onClickBack={onBack}
      onClickCancel={onCancel}
      hideNextButton
    >
      <Container>
        {isLoadingInitialData ? (
          <StyledLoadingSpinner />
        ) : (
          <FlexContainer column gap="small">
            <OnboardingBudgetList
              data={expenseDataWithAmounts}
              thisMonth={state.thisMonth}
              updateBudgetedValue={updateCellValue}
              groupType={CategoryGroupType.EXPENSE}
              footer={
                <OnboardingGroupFooter title="Total budgeted expenses" value={expensesAmount} />
              }
            />
            <LeftToBudgetFooter value={leftToBudgetOrSave} tooltipRows={tooltipRows} />
          </FlexContainer>
        )}
      </Container>
      <FooterButton onClickNext={onNext} />
    </FeatureOnboardingPage>
  );
};

export default OnboardingExpenses;
