import { useMutation } from '@apollo/client';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

import {
  GET_BUDGET_SETTINGS_QUERY,
  UPDATE_BUDGET_SETTINGS_MUTATION,
} from 'common/lib/graphQl/budgets';
import useQuery from 'common/lib/hooks/useQuery';

import type { PayloadErrorFields } from 'common/generated/graphQlTypes/PayloadErrorFields';
import type { BudgetSystem } from 'common/generated/graphql';

/* 
  Allows fetching and updating the budget system.
  It also fetches values for the rollover period because the mutation requires all values.
*/

type UpdateOptions = {
  onError?: (errorMessage: string | null) => void;
  onDone?: () => void;
};

const useBudgetSystem = () => {
  const { data, isLoadingInitialData: loading, error } = useQuery(GET_BUDGET_SETTINGS_QUERY);

  const [updateBudgetSettings, { loading: updateLoading }] = useMutation(
    UPDATE_BUDGET_SETTINGS_MUTATION,
  );

  const performMutation = async (
    selectedBudgetSystem: BudgetSystem,
    { onError, onDone }: UpdateOptions,
  ) => {
    const { data: updateData } = await updateBudgetSettings({
      variables: {
        input: {
          budgetSystem: selectedBudgetSystem,
          // Just pass the current values since the mutation requires all of them.
          // TODO: We should refactor this to accept a partial update.
          rolloverEnabled: RA.isNotNil(data?.flexExpenseRolloverPeriod),
          rolloverStartMonth: data?.flexExpenseRolloverPeriod?.startMonth,
          rolloverStartingBalance: data?.flexExpenseRolloverPeriod?.startingBalance,
        },
      },
    });

    const errors: PayloadErrorFields | undefined = R.path(
      ['updateCategoryGroup', 'errors'],
      updateData,
    );
    if (errors) {
      onError?.(errors.message);
    } else {
      onDone?.();
    }
  };

  return {
    loading,
    data,
    error,
    updateBudgetSystem: performMutation,
    updateLoading,
  };
};

export default useBudgetSystem;
