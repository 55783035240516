import * as React from 'react';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import FeatureFlagGate from 'components/lib/higherOrder/FeatureFlagGate';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import ModalCard from 'components/lib/ui/ModalCard';
import ModalCardButton from 'components/lib/ui/ModalCardButton';
import Tooltip from 'components/lib/ui/Tooltip';

import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';

const TOOLTIP_MAX_WIDTH_PX = 194;

const MODAL_WIDTH_PX = 832;

const ModalCardRoot = styled(ModalCard)<{ $large: boolean }>`
  width: ${({ $large }) => ($large ? `${MODAL_WIDTH_PX}px` : 'auto')};
  margin: auto;
`;

const Root = styled(FlexContainer)`
  gap: ${({ theme }) => theme.spacing.xlarge};
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const TooltipChildren = styled(FlexContainer)`
  flex: 1;
`;

type Props = {
  searchDisabledTooltip: Maybe<string>;
  onClickSearch: () => void;
  onClickAddManual: () => void;
  onClickAddBills: () => void;
  hasAlreadySyncedWithSpinwheel: boolean;
};

const AddRecurringModalCard = ({
  searchDisabledTooltip,
  onClickSearch,
  onClickAddManual,
  onClickAddBills,
  hasAlreadySyncedWithSpinwheel,
}: Props) => {
  const isBillTrackingEnabled = useIsFeatureFlagOn('load-liabilities');

  return (
    <ModalCardRoot title="Add recurring" $large={isBillTrackingEnabled}>
      <Root>
        <FeatureFlagGate name="load-liabilities">
          <TooltipChildren>
            <ModalCardButton
              icon={<Icon name="refresh" />}
              text={
                hasAlreadySyncedWithSpinwheel
                  ? 'Manage your liability accounts'
                  : 'Sync your liability accounts'
              }
              onClick={onClickAddBills}
              isNew
            />
          </TooltipChildren>
        </FeatureFlagGate>

        <Switch>
          <Case when={isBillTrackingEnabled}>
            <TooltipChildren>
              <ModalCardButton
                icon={<Icon name="plus-circle" />}
                text="Add a recurring merchant manually"
                onClick={onClickAddManual}
              />
            </TooltipChildren>

            <Tooltip
              content={searchDisabledTooltip}
              maxWidth={TOOLTIP_MAX_WIDTH_PX}
              place="top"
              opacity={1}
            >
              <TooltipChildren>
                <ModalCardButton
                  icon={<Icon name="search" />}
                  text="Find recurring merchants in your accounts"
                  onClick={!searchDisabledTooltip ? onClickSearch : undefined}
                  disabled={!!searchDisabledTooltip}
                />
              </TooltipChildren>
            </Tooltip>
          </Case>

          <Case default>
            <Tooltip
              content={searchDisabledTooltip}
              maxWidth={TOOLTIP_MAX_WIDTH_PX}
              place="top"
              opacity={1}
            >
              <TooltipChildren>
                <ModalCardButton
                  icon={<Icon name="refresh" />}
                  text="Automatically detect recurring transactions"
                  onClick={!searchDisabledTooltip ? onClickSearch : undefined}
                  disabled={!!searchDisabledTooltip}
                />
              </TooltipChildren>
            </Tooltip>

            <TooltipChildren>
              <ModalCardButton
                icon={<Icon name="plus-circle" />}
                text="Add a recurring transaction manually"
                onClick={onClickAddManual}
              />
            </TooltipChildren>
          </Case>
        </Switch>
      </Root>
    </ModalCardRoot>
  );
};

export default AddRecurringModalCard;
