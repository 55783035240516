import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import TabNavLink from 'components/lib/ui/link/TabNavLink';

import routes from 'constants/routes';

const Root = styled(FlexContainer).attrs({ alignStretch: true })`
  margin-left: ${({ theme }) => theme.spacing.xxlarge};
`;

const StyledLink = styled(TabNavLink)`
  margin-right: ${({ theme }) => theme.spacing.xlarge};
  align-items: center;
  display: flex;
`;

const HeaderTabs = () => (
  <Root>
    <StyledLink to={routes.recurring.upcoming.path}>Upcoming</StyledLink>
    <StyledLink to={routes.recurring.all.path}>All merchants</StyledLink>
  </Root>
);

export default HeaderTabs;
