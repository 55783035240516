import { useMutation } from '@apollo/client';
import { propEq } from 'ramda';
import React from 'react';
import styled from 'styled-components';

import FeatureOnboardingPage from 'components/lib/layouts/FeatureOnboardingPage';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Link from 'components/lib/ui/Link';
import FooterButton from 'components/plan/onboarding/FooterButton';
import OnboardingCategoryVariabilityCard from 'components/plan/onboarding/OnboardingCategoryVariabilityCard';

import useEventCallback from 'common/lib/hooks/useEventCallback';
import { spacing } from 'common/lib/theme/dynamic';
import usePlanQuery from 'lib/hooks/plan/usePlanQuery';
import usePlanState from 'lib/hooks/plan/usePlanState';

import * as COPY from 'common/constants/copy';

import { gql } from 'common/generated';
import type { BudgetVariability } from 'common/generated/graphql';
import { CategoryGroupType } from 'common/generated/graphql';
import type { ExtractResponseFromDocument } from 'common/types/graphql';

// Given an optimistic update is performed, the data shouldn't be debounced
const DEBOUNCE_TIME = 0;

const Column = styled(FlexContainer).attrs({ column: true, full: true })`
  max-width: 600px;
  gap: ${spacing.small};
  padding-bottom: 200px;
`;

type Props = {
  progress: number;
  onBack: () => void;
  onCancel: () => void;
  onNext: () => void;
};

const OnboardingSelectVariabilities = ({ onBack, onCancel, onNext, progress }: Props) => {
  const [state] = usePlanState();
  const { data } = usePlanQuery(state, DEBOUNCE_TIME);
  const [updateCategoryBudgetVariability] = useMutation(UPDATE_CATEGORY_BUDGET_VARIABILITY);

  const groups = data?.categoryGroups.filter(propEq('type', CategoryGroupType.EXPENSE)) ?? [];

  const updateBudgetVariability = useEventCallback(
    (id: string, budgetVariability: BudgetVariability) => {
      updateCategoryBudgetVariability({
        // @ts-expect-error: TODO: change graphql codegen to use optionals for nullable fields
        variables: { input: { id, budgetVariability } },
        optimisticResponse: getOptimisticResponse(id, budgetVariability),
      });
    },
  );

  return (
    <FeatureOnboardingPage
      pageName="How do you want to budget?"
      title={COPY.BUDGET.ONBOARDING.SELECT_VARIABILITIES.TITLE}
      description={
        <>
          {COPY.BUDGET.ONBOARDING.SELECT_VARIABILITIES.DESCRIPTION}
          <br />
          <Link
            href="https://www.monarchmoney.com/blog/fixed-flex-spending-what-it-is-and-how-to-get-started"
            target="_blank"
            rel="noopener noreferrer"
          >
            Here
          </Link>{' '}
          is a reminder of what each of these mean.
        </>
      }
      hideNextButton
      progress={progress}
      onClickBack={onBack}
      onClickCancel={onCancel}
      headerMaxWidth={800}
    >
      <Column>
        {groups.map((group) => (
          <OnboardingCategoryVariabilityCard
            key={group.id}
            group={group}
            onChangeBudgetVariability={updateBudgetVariability}
          />
        ))}
      </Column>
      <FooterButton onClickNext={onNext} />
    </FeatureOnboardingPage>
  );
};

const getOptimisticResponse = (
  id: string,
  budgetVariability: BudgetVariability,
): ExtractResponseFromDocument<typeof UPDATE_CATEGORY_BUDGET_VARIABILITY> => ({
  __typename: 'Mutation',
  updateCategory: {
    __typename: 'UpdateCategoryPayload',
    category: {
      __typename: 'Category',
      id,
      budgetVariability,
    },
  },
});

const UPDATE_CATEGORY_BUDGET_VARIABILITY = gql(/* GraphQL */ `
  mutation Web_UpdateCategoryBudgetVariability($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      category {
        id
        budgetVariability
      }
    }
  }
`);

export default OnboardingSelectVariabilities;
