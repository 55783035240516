import * as RA from 'ramda-adjunct';
import * as Yup from 'yup';

import { AccountTypeName, ManualInvestmentAccountTrackingMode } from 'common/constants/accounts';

import type { Scalars } from 'common/generated/graphql';
import { DataProviderLegacy } from 'common/generated/graphql';

const REQUIRED_MESSAGE = 'This field is required';

export const editAccountValidationSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED_MESSAGE),
  subtype: Yup.string().nullable().required(REQUIRED_MESSAGE),
  hideInNetWorth: Yup.boolean().required(REQUIRED_MESSAGE),
  accountStatus: Yup.boolean().required(REQUIRED_MESSAGE),
  deactivatedAt: Yup.string()
    .nullable()
    .when('accountStatus', {
      is: (val) => isAccountInactive(val),
      then: Yup.string().required(REQUIRED_MESSAGE),
      otherwise: Yup.string().notRequired(),
    }),
});

export const isAccountInactive = (accountStatus: boolean) => accountStatus;

export type AccountWithFlags = {
  id: string;
  dataProvider?: string | null;
  type: {
    name: string;
  } | null;
  isManual: boolean;
  manualInvestmentsTrackingMethod?: string | null;
  credential?: {
    id: string;
  } | null;
  institution?: {
    id: string;
  } | null;
};

export const getDerivedAccountFlags = <T extends AccountWithFlags>(account: T) => {
  const isAppleCard = account.dataProvider === DataProviderLegacy.APPLE_CARD.toLowerCase();
  const isLoan = account.type?.name === AccountTypeName.LOAN;
  const isSynced = !account.isManual;
  const isInvestments = account.type?.name === AccountTypeName.BROKERAGE;
  const isTrackingBalances =
    account.manualInvestmentsTrackingMethod === ManualInvestmentAccountTrackingMode.BALANCES;
  const isTrackingHoldings =
    account.manualInvestmentsTrackingMethod === ManualInvestmentAccountTrackingMode.HOLDINGS;

  const areTransactionsSupported =
    !isInvestments || (isInvestments && !isSynced && isTrackingBalances);
  const areHoldingsSupported =
    (isInvestments && isSynced) || (isInvestments && !isSynced && isTrackingHoldings);
  const canAddTransaction = areTransactionsSupported && !isInvestments;
  const canReportIssue = !!account.credential && !!account.institution;

  return {
    areTransactionsSupported,
    areHoldingsSupported,
    canAddTransaction,
    canReportIssue,
    isAppleCard,
    isInvestments,
    isLoan,
    isSynced,
  };
};

export const isAccountHidden = <
  T extends { deactivatedAt: Scalars['Date'] | null; hideFromList: boolean },
>({
  deactivatedAt,
  hideFromList,
}: T) => hideFromList || RA.isNotNil(deactivatedAt);
