import { DateTime } from 'luxon';
import pluralize from 'pluralize';
import * as R from 'ramda';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import AccountBalanceGraph from 'components/accounts/AccountBalanceGraph';
import AccountDetailControls from 'components/accounts/AccountDetailControls';
import AccountDisconnectedBanner from 'components/accounts/AccountDisconnectedBanner';
import AccountEditModal from 'components/accounts/AccountEditModal';
import AccountLogo from 'components/accounts/AccountLogo';
import AccountSummary from 'components/accounts/AccountSummary';
import ClosedAccountCard from 'components/accounts/ClosedAccountCard';
import ConnectionStatusCard from 'components/accounts/ConnectionStatusCard';
import CopyBalanceHistoryModalCard from 'components/accounts/CopyBalanceHistoryModalCard';
import MoveTransactionsModal from 'components/accounts/MoveTransactionsModal';
import AddHoldingModal from 'components/holdings/AddHoldingModal';
import HoldingsListContainer from 'components/holdings/HoldingsListContainer';
import RouteModal from 'components/lib/routing/RouteModal';
import Banner from 'components/lib/ui/Banner';
import Card from 'components/lib/ui/Card';
import Controls from 'components/lib/ui/Controls';
import DropdownMenu, { DropdownMenuItem } from 'components/lib/ui/DropdownMenu';
import Empty, { Theme as EmptyTheme } from 'components/lib/ui/Empty';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Grid, { GridItem } from 'components/lib/ui/Grid';
import Icon from 'components/lib/ui/Icon';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Page from 'components/lib/ui/Page';
import Text from 'components/lib/ui/Text';
import Tooltip from 'components/lib/ui/Tooltip';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import RouteLink from 'components/lib/ui/link/RouteLink';
import { OverlayTrigger } from 'components/lib/ui/popover';
import NotFound from 'components/routes/NotFound';
import AddTransactionModal from 'components/transactions/AddTransactionModal';
import type { CustomEditButtonProps } from 'components/transactions/TransactionListHeader';
import TransactionsListContainer from 'components/transactions/TransactionsListContainer';

import {
  DATE_RANGES,
  getAvailableTimePeriodsForDateRanges,
} from 'common/lib/accounts/accountCharts';
import useHouseholdPreferences from 'common/lib/hooks/household/useHouseholdPreferences';
import type { BulkUpdateState } from 'common/lib/hooks/transactions/useBulkUpdateTransactionState';
import useQuery from 'common/lib/hooks/useQuery';
import { spacing } from 'common/lib/theme/dynamic';
import isV2Theme from 'common/lib/theme/isV2Theme';
import { track } from 'lib/analytics/segment';
import useEditBalancesModal from 'lib/hooks/accounts/useEditBalancesModal';
import useFilters from 'lib/hooks/transactions/useFilters';
import useAccountSelectOptions from 'lib/hooks/useAccountSelectOptions';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import useModal from 'lib/hooks/useModal';
import { convertFiltersToInput, DEFAULT_FILTERS } from 'lib/transactions/Filters';

import { AccountTypeName, ManualInvestmentAccountTrackingMode } from 'common/constants/accounts';
import { AccountEventNames } from 'common/constants/analytics';
import { ACCOUNTS } from 'common/constants/copy';
import { getNoTransactionCopy } from 'common/constants/noTransactions';
import routes from 'constants/routes';

import { gql } from 'common/generated/gql';
import type { AccountDetails_GetAccountQuery } from 'common/generated/graphql';
import type RouteProps from 'types/RouteProps';

const StyledAccountLogo = styled(AccountLogo)`
  width: 32px;
  height: 32px;
`;

const LoadingContainer = styled(Flex)`
  flex: 1;
  height: 100%;
`;

const LoadingSpinnerWithMargin = styled(LoadingSpinner)`
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const StyleGridItem = styled(GridItem)`
  grid-area: rec;
`;

const CardsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.gutter};
`;

const EditButton = styled(PrimaryButton)`
  position: relative;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xsmall};
`;

const ChevronDownIcon = styled(Icon).attrs({ name: 'chevron-down', size: 12 })`
  transform: translateY(1px);
`;

const CustomDropdownMenu = styled(DropdownMenu)`
  min-width: 200px;
`;

const InvestmentsBanner = styled.div`
  margin: ${({ theme }) => theme.spacing.xlarge} ${({ theme }) => theme.spacing.xlarge} 0;
  ${isV2Theme(css`
    margin-top: 0;
    margin-bottom: ${spacing.xlarge};
  `)}
`;

const HouseholdPreferencesLink = styled(RouteLink)`
  font-weight: ${({ theme }) => theme.fontWeight.book};
  text-decoration: underline;
`;

const StyledHoldingsListWrapper = styled.div`
  :empty {
    height: 0;
    margin: 0;
  }
  :not(:empty) {
    margin-bottom: 72px;
  }
`;

const StyledGrid = styled(Grid)`
  ${isV2Theme(css`
    padding-top: 0;
  `)}
`;

const StyledTransactionsListWrapper = styled.div``;

export type Props = RouteProps<typeof routes.accounts.accountDetails>;

const PAGE_NAME = 'Account Details';

const INVESTMENT_NO_HOLDING_COPY = {
  title: 'Holdings not found',
  subtitle: 'Search for an investment and enter the quantity you own to track the value.',
};

const getAvailableTimePeriods = getAvailableTimePeriodsForDateRanges(DATE_RANGES);

const AccountDetails = ({
  match: {
    params: { id },
  },
}: Props) => {
  const { householdPreferences } = useHouseholdPreferences();

  const { investmentTransactionsEnabled: investmentTransactionsPreferenceEnabled } =
    householdPreferences ?? {};

  const [filters] = useFilters();
  const history = useHistory();

  const cleanedFilters = convertFiltersToInput(filters);
  const [activeTimeFrameIndex, setActiveTimeFrameIndex] = useState<number>(0);

  const { data, refetch } = useQuery<AccountDetails_GetAccountQuery>(
    ACCOUNT_DETAILS_GET_ACCOUNT_QUERY,
    {
      variables: { id },
    },
  );

  const [
    MoveTransactionsModalContainer,
    {
      open: openMoveTransactionsModal,
      close: closeMoveTransactionsModal,
      context: transactionsCount,
    },
  ] = useModal<number>();
  const [EditBalancesModal, { open: openEditBalancesModal }] = useEditBalancesModal({
    accountId: id,
    accountName: data?.account?.displayName ?? '',
  });

  const onClickBalance = useCallback(
    (data: { date: string; balance: number }) => {
      openEditBalancesModal(data);
      track(AccountEventNames.EditBalancesIndividualDatapointClicked);
    },
    [openEditBalancesModal],
  );

  const [isLoadingAccounts, moveTransactionsAccountOptions] = useAccountSelectOptions({
    shouldGroupByType: false,
    excludeAccountId: id,
  });

  const [bulkUpdateState, setBulkUpdateState] = useState<Partial<BulkUpdateState>>();
  const renderTransactionListEditButton = useCallback(
    ({ openBulkUpdateForm, count }: CustomEditButtonProps) => (
      <OverlayTrigger
        placement="bottom-end"
        overlay={
          <CustomDropdownMenu>
            <DropdownMenuItem onClick={openBulkUpdateForm}>
              Edit {count} {pluralize('transaction', count)}
            </DropdownMenuItem>
            <Tooltip
              place="right"
              content={
                moveTransactionsAccountOptions.length <= 0
                  ? 'There are no other accounts you can move these transactions to. Transactions can move between synced, non-investment accounts.'
                  : undefined
              }
              maxWidth={220}
              hyphens={false}
              portal
            >
              <DropdownMenuItem
                onClick={() => openMoveTransactionsModal(count)}
                disabled={moveTransactionsAccountOptions.length <= 0}
              >
                Move to account
              </DropdownMenuItem>
            </Tooltip>
          </CustomDropdownMenu>
        }
      >
        {({ toggleOpen, isOpen }) => (
          <EditButton onClick={toggleOpen} active={isOpen} disabled={count === 0}>
            Edit {count > 0 ? count : ''} <ChevronDownIcon />
          </EditButton>
        )}
      </OverlayTrigger>
    ),
    [openMoveTransactionsModal, moveTransactionsAccountOptions],
  );

  const [CopyBalanceHistoryModal, { open: openCopyBalancesModal, close: closeCopyBalancesModal }] =
    useModal();
  const isCopyBalanceHistoryEnabled = useIsFeatureFlagOn('copy-balance-history');
  const isInvestmentTransactionsEnabled = useIsFeatureFlagOn('investment-transactions-enabled');

  const isRequestedAccount = data?.account?.id === id;
  // fix issue where page seems slow when coming from another account page
  if (!data || !isRequestedAccount) {
    return (
      <Page name={PAGE_NAME}>
        <LoadingContainer center column>
          <LoadingSpinnerWithMargin />
          Loading account details...
        </LoadingContainer>
      </Page>
    );
  }

  if (!data?.account) {
    return <NotFound />;
  }

  const { account } = data;
  const { displayBalance, isManual, isLiability } = account;
  const transactionFilters = { ...cleanedFilters, accounts: [data.account.id] };

  const isDisconnected =
    account.credential?.updateRequired || !!account.credential?.disconnectedFromDataProviderAt;

  const isDeactivated = !!account.deactivatedAt;

  const shouldShowAccountDisconnectedBanner = isDisconnected && !isDeactivated;

  const isVehicle = account.type.name === AccountTypeName.VEHICLE;
  const isRealEstate = account.type.name === AccountTypeName.REAL_ESTATE;
  const isInvestments = account.type.name === AccountTypeName.BROKERAGE;
  const isLoan = account.type.name === AccountTypeName.LOAN;
  const isSynced = !account.isManual;

  const isTrackingHoldings =
    account.manualInvestmentsTrackingMethod === ManualInvestmentAccountTrackingMode.HOLDINGS;
  const isTrackingBalances =
    account.manualInvestmentsTrackingMethod === ManualInvestmentAccountTrackingMode.BALANCES;

  const areTransactionsSupported =
    !isInvestments || (isInvestments && isInvestmentTransactionsEnabled);

  const canAddTransactions = !isInvestments || (isInvestments && isInvestmentTransactionsEnabled);

  const areHoldingsSupported =
    (isInvestments && isSynced) || (isInvestments && isManual && isTrackingHoldings);

  const isDefaultFiltersApplied = R.equals(filters, DEFAULT_FILTERS);

  const noTxnCopy = getNoTransactionCopy(
    isSynced,
    // TODO: Remove this check once we launch investment transactions
    !isInvestmentTransactionsEnabled && isInvestments,
    isLoan,
    isDefaultFiltersApplied,
    filters.search,
  );

  const snapshotBalances = data.snapshots.map(({ date, signedBalance }) => ({
    date,
    balance: isLiability ? -1 * signedBalance : signedBalance,
  }));

  const availableTimePeriods = getAvailableTimePeriods(snapshotBalances);
  const { duration: selectedDuration } = availableTimePeriods[activeTimeFrameIndex];

  const now = DateTime.local();

  const handleAddHoldingButtonClick = async () => {
    history.push(routes.accounts.accountDetails.addHolding({ id }));
  };

  const showInvestmentTransactionsBanner =
    !investmentTransactionsPreferenceEnabled && isInvestmentTransactionsEnabled && isInvestments;

  const showInvestmentTransactionsNotAvailableBanner =
    isInvestments && !isInvestmentTransactionsEnabled;

  return (
    <Page
      icon={<StyledAccountLogo logoUrl={account.logoUrl} icon={account.icon} />}
      name={PAGE_NAME}
      overrideTitle={account.displayName}
      controls={
        <AccountDetailControls account={account} openCopyBalancesModal={openCopyBalancesModal} />
      }
      titleIsSensitive
    >
      <Switch>
        <Case when={showInvestmentTransactionsBanner}>
          <InvestmentsBanner>
            <Banner type="info">
              <strong>Investment transactions now in beta!</strong> Visit your{' '}
              <HouseholdPreferencesLink to={routes.settings.preferences()}>
                household preferences
              </HouseholdPreferencesLink>{' '}
              page to learn more and enable this feature.
            </Banner>
          </InvestmentsBanner>
        </Case>
        <Case when={showInvestmentTransactionsNotAvailableBanner}>
          <InvestmentsBanner>
            <Banner type="info">
              <strong>{ACCOUNTS.COMING_SOON}</strong>{' '}
              {ACCOUNTS.INVESTMENT_TRANSACTIONS_NOT_AVAILABLE}
            </Banner>
          </InvestmentsBanner>
        </Case>
        <Case default>{null}</Case>
      </Switch>

      <StyledGrid template={`"chart chart" "rec sum" / 1fr 30%`} md={`"chart" "sum" "rec"`}>
        <GridItem area="chart">
          <Card>
            {shouldShowAccountDisconnectedBanner && <AccountDisconnectedBanner account={account} />}
            <AccountBalanceGraph
              snapshotData={snapshotBalances}
              selectedTimePeriodIndex={activeTimeFrameIndex}
              availableTimePeriods={availableTimePeriods}
              onSelectTimePeriod={async (index: number) => setActiveTimeFrameIndex(index)}
              isAsset={data.account.isAsset}
              accountTypeName={data.account.type.name}
              currentBalanceLabel="Current Balance"
              displayBalance={displayBalance ?? undefined}
              onClickBalance={onClickBalance}
            />
          </Card>
        </GridItem>

        <StyleGridItem area="rec">
          <FlexContainer column>
            <StyledHoldingsListWrapper>
              {areHoldingsSupported && (
                <HoldingsListContainer
                  hideHeader={false}
                  accountIds={[id]}
                  selectedTimePeriod={{
                    startDate: now.minus(selectedDuration).toISODate(),
                    endDate: now.toISODate(),
                  }}
                  onAddHoldingButtonClick={handleAddHoldingButtonClick}
                  emptyComponent={
                    <Empty
                      emptyTheme={EmptyTheme.NEW}
                      title={INVESTMENT_NO_HOLDING_COPY.title}
                      subtitle={INVESTMENT_NO_HOLDING_COPY.subtitle}
                      button={{
                        title: 'Add holding',
                        onClick: handleAddHoldingButtonClick,
                        isPrimary: true,
                      }}
                    />
                  }
                />
              )}
            </StyledHoldingsListWrapper>

            {areTransactionsSupported && (
              <StyledTransactionsListWrapper>
                <Card>
                  <TransactionsListContainer
                    showAccountColumn={false}
                    transactionFilters={transactionFilters}
                    overrideTitle={<Text weight="medium">Transactions</Text>}
                    // This is a temporary prop until we launch the button in every page,
                    // in which case we'll remove the prop and just use the button with the dropdown
                    renderCustomEditButton={renderTransactionListEditButton}
                    onBulkUpdateStateChange={setBulkUpdateState}
                    extraControls={
                      <Controls>
                        {canAddTransactions && (
                          <PrimaryButton
                            className="fs-add-manual-transaction"
                            linkTo={routes.accounts.accountDetails.addTransaction({ id })}
                          >
                            Add transaction
                          </PrimaryButton>
                        )}
                      </Controls>
                    }
                    emptyComponent={
                      <Empty
                        emptyTheme={EmptyTheme.NEW}
                        title={noTxnCopy.title}
                        subtitle={noTxnCopy.subtitle}
                        button={
                          canAddTransactions
                            ? {
                                title: 'Add transaction',
                                onClick: () =>
                                  history.push(
                                    routes.accounts.accountDetails.addTransaction({ id }),
                                  ),
                                isPrimary: true,
                              }
                            : undefined
                        }
                      />
                    }
                    allowBulkUpdate
                    hideHeaderIfNoContent
                  />
                </Card>
              </StyledTransactionsListWrapper>
            )}
          </FlexContainer>
        </StyleGridItem>

        <GridItem area="sum" sticky>
          <CardsColumn>
            <AccountSummary
              account={account}
              isManual={isManual}
              isVehicle={isVehicle}
              isRealEstate={isRealEstate}
              isInvestments={isInvestments}
              isTrackingManualHoldings={isTrackingHoldings}
              isTrackingManualBalances={isTrackingBalances}
              transactionCount={account.transactionsCount}
              holdingCount={account.holdingsCount}
            />
            {!isManual && !account.deactivatedAt && (
              <ConnectionStatusCard account={account} refetch={refetch} />
            )}
            {!!account.deactivatedAt && <ClosedAccountCard deactivatedAt={account.deactivatedAt} />}
          </CardsColumn>
        </GridItem>
      </StyledGrid>

      <RouteModal path={routes.accounts.accountDetails.edit({ id })}>
        {({ close }) => (
          <AccountEditModal
            account={account}
            onDone={() => {
              close();
              refetch();
            }}
            onClose={close}
          />
        )}
      </RouteModal>

      <RouteModal path={routes.accounts.accountDetails.addTransaction.path}>
        {({ close }) => (
          <AddTransactionModal
            accountId={id}
            onDone={() => {
              close();
              refetch();
            }}
            onCancel={close}
            isManual={isManual}
          />
        )}
      </RouteModal>
      <RouteModal path={routes.accounts.accountDetails.addHolding.path}>
        {({ close }) => (
          <AddHoldingModal
            accountId={id}
            onDone={() => {
              close();
              refetch();
            }}
            onAddMore={() => {
              history.push(routes.accounts.accountDetails.addHolding({ id }));
            }}
            onCancel={close}
          />
        )}
      </RouteModal>
      <MoveTransactionsModalContainer>
        <MoveTransactionsModal
          accountId={id}
          onClose={closeMoveTransactionsModal}
          bulkUpdateState={bulkUpdateState}
          accountOptions={moveTransactionsAccountOptions}
          isLoadingAccountOptions={isLoadingAccounts}
          filters={cleanedFilters}
          transactionsCount={transactionsCount}
        />
      </MoveTransactionsModalContainer>
      {isCopyBalanceHistoryEnabled && (
        <CopyBalanceHistoryModal>
          <CopyBalanceHistoryModalCard onClose={closeCopyBalancesModal} fromAccount={account} />
        </CopyBalanceHistoryModal>
      )}
      <EditBalancesModal />
    </Page>
  );
};

// Don't change the name of this query, it's used with refetchQueries in a couple places
export const ACCOUNT_DETAILS_GET_ACCOUNT_QUERY = gql(/* GraphQL */ `
  query AccountDetails_getAccount($id: UUID!, $filters: TransactionFilterInput) {
    account(id: $id) {
      id
      ...AccountFields
      ...EditAccountFormFields
      isLiability
      credential {
        id
        hasSyncOrRecentRefreshRequest
        canBeForceRefreshed
        disconnectedFromDataProviderAt
        dataProvider
        institution {
          id
          plaidInstitutionId
          url
          ...InstitutionStatusFields
        }
      }
      institution {
        id
        plaidInstitutionId
        url
        ...InstitutionStatusFields
      }
    }

    transactions: allTransactions(filters: $filters) {
      totalCount
      results(limit: 20) {
        id
        ...TransactionsListFields
      }
    }

    snapshots: snapshotsForAccount(accountId: $id) {
      date
      signedBalance
    }
  }
`);

export default AccountDetails;
