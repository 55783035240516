import type { ActionType } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';

import * as actions from 'common/state/employee/actions';

const { setDemoMode, forceEmptyState, setShowGoalsTour, forceRefreshEnabledStatus } = actions;

type EmployeeAction = ActionType<typeof actions>;

const INITIAL_STATE: EmployeeState = {
  demoMode: false,
  forceEmptyState: false,
  showGoalsTour: false,
  forceRefreshEnabledStatus: false,
};

export type EmployeeState = {
  demoMode: boolean;
  forceEmptyState: boolean;
  showGoalsTour: boolean;
  forceRefreshEnabledStatus: boolean;
};

const employeeReducer = createReducer<EmployeeState, EmployeeAction>(INITIAL_STATE)
  .handleAction(setDemoMode, (state, { payload: demoMode }) => ({ ...state, demoMode }))
  .handleAction(forceEmptyState, (state, { payload: forceEmptyState }) => ({
    ...state,
    forceEmptyState,
  }))
  .handleAction(setShowGoalsTour, (state, { payload: showGoalsTour }) => ({
    ...state,
    showGoalsTour,
  }))
  .handleAction(forceRefreshEnabledStatus, (state, { payload: forceRefreshEnabledStatus }) => ({
    ...state,
    forceRefreshEnabledStatus,
  }));

export default employeeReducer;
