import { createReducer } from 'typesafe-actions';

import {
  setDismissedMobileBrowserWarning,
  setPlaidLinkInfo,
  setUiThemePreference,
  setBulkSelectState,
  setTransactionsSummaryColumnVisible,
  setPlanDashboardWidgetViewIndex,
  setIsV2ThemeEnabled,
} from 'actions';

import type { UiThemePreference } from 'common/types';

export const DEFAULT_UI_THEME_PREFERENCE: UiThemePreference = 'light';

// Note that this state is persisted across page loads.
// You'll need to worry about backward compability (ie, if you add new state here, users who have
// loaded the app and have old persisted state may still not have this state).
const INITIAL_STATE: PersistentUiState = {
  // This will only be true when the user has seen and dismissed the warning.
  // Being false or undefined just means user hasn't dismissed, which could also be
  // because the user isn't on mobile device and never saw the prompt.
  dismissedMobileBrowserWarning: undefined,
  // Had to change the name from uiThemePreference because we changed the default value from "light" to "system",
  // and anyone who had already loaded the page would have had "light" persisted.
  themePreference: DEFAULT_UI_THEME_PREFERENCE,
  isV2ThemeEnabled: false,
  isBulkSelectActive: false,
  /** Controls the visibility of the summary column in the Transactions page. */
  isTransactionsSummaryColumnVisible: false,
  planDashboardWidgetViewIndex: 0,
};

export type PersistentUiState = {
  dismissedMobileBrowserWarning?: boolean;
  /** We need to persist this for Plaid Link Oauth */
  plaidLinkInfo?: {
    token: string;
    reconnectCredentialId?: string;
  };
  themePreference: UiThemePreference;
  isV2ThemeEnabled: boolean;
  isBulkSelectActive: boolean;
  isTransactionsSummaryColumnVisible: boolean;
  planDashboardWidgetViewIndex: number;
};

const persistentUi = createReducer<PersistentUiState>(INITIAL_STATE)
  .handleAction(setDismissedMobileBrowserWarning, (state) => ({
    ...state,
    dismissedMobileBrowserWarning: !state.dismissedMobileBrowserWarning,
  }))
  .handleAction(setPlaidLinkInfo, (state, { payload: plaidLinkInfo }) => ({
    ...state,
    plaidLinkInfo,
  }))
  .handleAction(setUiThemePreference, (state, { payload: themePreference }) => ({
    ...state,
    themePreference,
  }))
  .handleAction(setIsV2ThemeEnabled, (state, { payload: isV2ThemeEnabled }) => ({
    ...state,
    isV2ThemeEnabled,
  }))
  .handleAction(setBulkSelectState, (state, { payload: isBulkSelectActive }) => ({
    ...state,
    isBulkSelectActive,
  }))
  .handleAction(
    setTransactionsSummaryColumnVisible,
    (state, { payload: isTransactionsSummaryColumnVisible }) => ({
      ...state,
      isTransactionsSummaryColumnVisible,
    }),
  )
  .handleAction(
    setPlanDashboardWidgetViewIndex,
    (state, { payload: planDashboardWidgetViewIndex }) => ({
      ...state,
      planDashboardWidgetViewIndex,
    }),
  );

export default persistentUi;
