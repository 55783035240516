import * as React from 'react';
import styled from 'styled-components';

import Page from 'components/lib/ui/Page';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import { useQueryParam } from 'lib/hooks';

const Root = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const CLIENT_ID = '1ec6a65327fe5ee95b1d332fad19e1b9647516e38fbe0b32960f194d5ff7aa9f';
const REDIRECT_URL = 'https://app.monarchmoney.com/cryptopoc/redirect';
const SCOPES = [
  'wallet:accounts:read',
  'wallet:transactions:read',
  'wallet:deposits:read',
  'wallet:withdrawals:read',
];

const CryptoPoc = () => {
  const code = useQueryParam('code');
  const state = useQueryParam('state');

  return (
    <Page name="CryptoPOC">
      <Root>
        <PrimaryButton
          onClick={() => {
            window.location.href =
              `https://www.coinbase.com/oauth/authorize?response_type=code` +
              `&client_id=${CLIENT_ID}` +
              `&redirect_uri=${REDIRECT_URL}` +
              `&state=SECURE_RANDOM` +
              `&account=all` +
              `&scope=${SCOPES.join(',')}`;
          }}
        >
          Connect
        </PrimaryButton>
        <div>Code: {code}</div>
        <div>State: {state}</div>
      </Root>
    </Page>
  );
};

export default CryptoPoc;
