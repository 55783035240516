import { ApolloProvider } from '@apollo/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CloudinaryContext } from 'cloudinary-react';
import 'polyfills';
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Toaster } from 'sonner';

import SplitFactoryProvider from 'components/lib/external/SplitFactoryProvider';
import CustomToaster from 'components/lib/ui/DEPRECATED_Toaster';
import ThemeProvider from 'components/utils/ThemeProvider';

import { ContactSupportContextProvider } from 'lib/contexts/ContactSupportContext';
import getStringEnvVar from 'lib/getStringEnvVar';
import ApolloClient from 'lib/graphQl/ApolloClient';
import history from 'lib/history';
import GlobalStyle from 'lib/styles/GlobalStyle';
import { store, persistor } from 'state/store';

export const Providers = ({ children }: { children: React.ReactNode }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ApolloProvider client={ApolloClient}>
        <SplitFactoryProvider>
          <GoogleReCaptchaProvider
            useEnterprise
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY ?? ''}
          >
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_SIGN_IN_CLIENT_ID ?? ''}>
              <CloudinaryContext cloudName={getStringEnvVar('REACT_APP_CLOUDINARY_CLOUD_NAME')}>
                <ThemeProvider>
                  <>
                    <GlobalStyle />
                    <Router history={history}>
                      <ContactSupportContextProvider>{children}</ContactSupportContextProvider>
                    </Router>
                    <CustomToaster />
                    <Toaster />
                  </>
                </ThemeProvider>
              </CloudinaryContext>
            </GoogleOAuthProvider>
          </GoogleReCaptchaProvider>
        </SplitFactoryProvider>
      </ApolloProvider>
    </PersistGate>
  </Provider>
);
