import { DateTime } from 'luxon';
import pluralize from 'pluralize';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

import { formatCurrencyCentsOptional } from 'common/utils/Currency';
import { isoDateToMonthAndYear, isSameMonth, hasDatePassed } from 'common/utils/date';

import type { Frequency } from 'common/constants/recurringTransactions';
import {
  RECURRING_TRANSACTIONS_FREQUENCIES_INFO,
  RecurringTransactionStreamType,
} from 'common/constants/recurringTransactions';

type SortableRecurringItem = {
  date: string;
  isPast: boolean;
  stream: {
    name: string;
  };
};

type SortableRecurringStream = {
  nextForecastedTransaction: {
    date: string;
  };
  stream: {
    name: string;
  };
};

type FilterableRecurringItem = {
  isPast: boolean;
};

type ExtendedFilterableRecurringItem = {
  isPast: boolean;
  stream: {
    merchant:
      | {
          id: string;
        }
      | null
      | undefined;
    creditReportLiabilityAccount:
      | {
          id: string;
        }
      | null
      | undefined;
  };
  markedPaidAt: string | null | undefined;
};

type Option = {
  label: string;
  value: () => string;
};

export enum GroupByValues {
  Frequency = 'frequency',
  Category = 'category',
  Type = 'type',
}

export const getFrequencyOptions = () =>
  Object.entries(RECURRING_TRANSACTIONS_FREQUENCIES_INFO).map(([frequency, value]) => ({
    value: frequency,
    label: value,
  }));

export const getFrequencyLabel = <T extends string>(frequency: T) =>
  RECURRING_TRANSACTIONS_FREQUENCIES_INFO[frequency as Frequency];

export const getRecurringTooltipText = (frequency: Frequency | undefined) =>
  !frequency
    ? undefined
    : `Recurring ${RECURRING_TRANSACTIONS_FREQUENCIES_INFO[frequency]?.toLowerCase()}`;

export const DATE_RANGE_OPTIONS: Option[] = [
  { label: 'This month', value: () => DateTime.local().endOf('month').toISODate() },
  { label: 'This week', value: () => DateTime.local().endOf('week').toISODate() },
];

export const RECURRING_TYPE_OPTIONS = [
  { label: 'Expense', value: RecurringTransactionStreamType.Expense },
  { label: 'Income', value: RecurringTransactionStreamType.Income },
];

export const RECURRING_STATUS_OPTIONS = [
  { label: 'Active', value: true },
  { label: 'Canceled', value: false },
];

export const getWidgetDescription = (
  itemsAmount: number,
  selectedRangeOption?: Option,
  isBillTrackingEnabled?: boolean,
  remainingDueAmount?: number,
) => {
  if (isBillTrackingEnabled) {
    return `${formatCurrencyCentsOptional((remainingDueAmount ?? 0) * -1)} remaining due`;
  }

  return `${itemsAmount} upcoming ${selectedRangeOption?.label.toLocaleLowerCase()}`;
};

export const getMonthTitle = (date: DateTime) =>
  isSameMonth(date, DateTime.local()) ? 'This month' : isoDateToMonthAndYear(date.toISODate());

export const getMonthTitleWithPreposition = (date: DateTime) =>
  isSameMonth(date, DateTime.local())
    ? 'this month'
    : `in ${isoDateToMonthAndYear(date.toISODate())}`;

export const getSearchDisabledTooltipText = (
  startedAt: string | undefined | null,
  nextAvailableAt: string | undefined | null,
) => {
  if (!startedAt) {
    return null;
  }

  const nextSearchAt = nextAvailableAt ? DateTime.fromISO(nextAvailableAt) : DateTime.local();

  if (hasDatePassed(nextSearchAt)) {
    return null;
  }

  const elapsed = -getFormattedTooltipMinutes(DateTime.fromISO(startedAt));
  const nextAt = getFormattedTooltipMinutes(nextSearchAt);
  return `Last synced ${elapsed} ${pluralize(
    'minute',
    elapsed,
  )} ago. Please wait ${nextAt} ${pluralize('minute', nextAt)} before trying again.`;
};

const getFormattedTooltipMinutes = (date: DateTime) => Math.ceil(date.diffNow().as('minutes'));

export const sortItems = <TItem extends SortableRecurringItem>(items: TItem[]): TItem[] =>
  R.sortWith(
    [R.ascend(R.prop('isPast')), R.ascend(R.prop('date')), R.ascend(R.path(['stream', 'name']))],
    items,
  );

export const sortStreams = <TStream extends SortableRecurringStream>(streams: TStream[]) =>
  R.sortWith(
    [R.ascend(R.path(['nextForecastedTransaction', 'date'])), R.ascend(R.path(['stream', 'name']))],
    streams,
  );

export const sortStreamsByCategory = <TStream extends SortableRecurringStream>(
  streams: TStream[],
) => R.sort(R.ascend(R.path(['category', 'name'])), streams);

export const sortStreamsByFrequency = <TStream extends SortableRecurringStream>(
  streams: TStream[],
) => R.sort(R.ascend(R.path(['frequency'])), streams);

export const sortStreamsByGroupBy = <TStream extends SortableRecurringStream>(
  streams: TStream[],
  groupBy?: GroupByValues,
) => {
  switch (groupBy) {
    case GroupByValues.Category:
      return sortStreamsByCategory(streams);
    case GroupByValues.Frequency:
      return sortStreamsByFrequency(streams);
    default:
      return sortStreams(streams);
  }
};

export const formatNextPaymentDate = (date: string | undefined | null, showLateDaysAgo = false) => {
  if (R.isNil(date) || R.isEmpty(date)) {
    return null;
  }

  const nextPaymentDate = DateTime.fromISO(date);
  const nextPaymentDateFormatted = nextPaymentDate.toFormat('MMMM d, yyyy');

  const daysUntilNextPayment = Math.ceil(nextPaymentDate.diffNow().as('days'));

  const isLate = daysUntilNextPayment < 0;
  const daysDelta = Math.abs(daysUntilNextPayment);
  const daysUntilNextPaymentLabel = `${daysDelta} ${pluralize('day', daysDelta)}`;

  if (isLate) {
    if (showLateDaysAgo) {
      return `${nextPaymentDateFormatted} (${daysUntilNextPaymentLabel} ago)`;
    } else {
      return `${nextPaymentDateFormatted}`;
    }
  }

  return `${nextPaymentDateFormatted} (${daysUntilNextPaymentLabel})`;
};

export const splitUpcomingItems = <TItem extends FilterableRecurringItem>(items: TItem[]) => {
  const upcomingItems = items.filter((i) => !i.isPast);
  const pastItems = items.filter((i) => i.isPast);

  return { upcomingItems, pastItems };
};

export const splitUpcomingCompleteItems = <TItem extends ExtendedFilterableRecurringItem>(
  items: TItem[],
) => {
  const upcomingItems = items.filter((i) => {
    const isUpcomingMerchant = !!i.stream.merchant && !i.isPast;
    const isUpcomingAccount = !!i.stream.creditReportLiabilityAccount && !i.markedPaidAt;

    return isUpcomingMerchant || isUpcomingAccount;
  });

  const completeItems = items.filter((i) => {
    const isCompleteMerchant = !!i.stream.merchant && i.isPast;
    const isCompleteAccount = !!i.stream.creditReportLiabilityAccount && i.markedPaidAt;

    return isCompleteMerchant || isCompleteAccount;
  });

  return { upcomingItems, completeItems };
};

export const splitActiveCanceledItems = <T extends { stream: { isActive: boolean } }>(
  items: T[],
): { active: T[]; canceled: T[] } => {
  const { true: activeItems = [], false: canceledItems = [] } = RA.isNotNilOrEmpty(items)
    ? R.groupBy((item) => R.toString(item.stream.isActive), items)
    : {};
  return { active: activeItems, canceled: canceledItems };
};

export const getInitialType = (
  amount: number | undefined,
): NonNullable<RecurringTransactionStreamType> =>
  R.isNil(amount) || amount <= 0
    ? RecurringTransactionStreamType.Expense
    : RecurringTransactionStreamType.Income;

/** Transform the stream amount in accordance to the stream type (expense/income) */
export const getAmountWithSign = (
  originalAmount: number | undefined | null,
  type: RecurringTransactionStreamType,
) => {
  if (!originalAmount) {
    return 0;
  }

  if (!type) {
    return originalAmount;
  }

  const amount = Math.abs(originalAmount);
  return type === RecurringTransactionStreamType.Expense ? -amount : amount;
};
