import { gql } from '@apollo/client';
import * as R from 'ramda';
import React, { useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import GoalDashboardRow from 'components/goalsV2/GoalDashboardRow';
import DashboardWidget, { Description } from 'components/lib/ui/DashboardWidget';
import Empty from 'components/lib/ui/Empty';
import Flex from 'components/lib/ui/Flex';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import { filterOutArchivedGoals, sortGoalsByPriority } from 'common/lib/goalsV2/adapters';
import useQuery from 'common/lib/hooks/useQuery';

import { GOALS } from 'common/constants/copy';
import routes from 'constants/routes';

import type { Web_GoalsDashboardCardV2 } from 'common/generated/graphQlTypes/Web_GoalsDashboardCardV2';

const MAX_DISPLAY_COUNT = 3;

const StyledDashboardWidget = styled(DashboardWidget)`
  ${Description} {
    font-size: ${({ theme }) => theme.fontSize.large};
  }
`;

const Footer = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.color.grayBackground};
  padding: ${({ theme }) => theme.spacing.default};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

const FullWidthDefaultButton = styled(DefaultButton)`
  width: 100%;
`;

const GoalsDashboardWidget = () => {
  const { data, isLoadingInitialData } = useQuery<Web_GoalsDashboardCardV2>(QUERY);
  const { goalsV2: allGoals = [], goals: legacyGoals = [], migratedToGoalsV2 } = data ?? {};

  const { push } = useHistory();

  const displayGoals = useMemo(() => {
    let goals = filterOutArchivedGoals(allGoals);
    goals = sortGoalsByPriority(goals);

    return goals;
  }, [allGoals]);

  return (
    <StyledDashboardWidget
      title={GOALS.DASHBOARD_CARD.TITLE}
      description={GOALS.DASHBOARD_CARD.SUBTITLE}
      loading={isLoadingInitialData}
      headerLink={routes.goalsV2()}
    >
      <Switch>
        <Case when={!migratedToGoalsV2 && !!legacyGoals.length}>
          <Empty
            title={GOALS.DASHBOARD_CARD.MIGRATE_PROMPT.TITLE}
            subtitle={GOALS.DASHBOARD_CARD.MIGRATE_PROMPT.SUBTITLE}
            button={{
              title: GOALS.DASHBOARD_CARD.MIGRATE_PROMPT.BUTTON_TITLE,
              onClick: () => push(routes.goalsV2()),
            }}
          />
        </Case>
        <Case when={!displayGoals.length}>
          <Empty
            title={GOALS.DASHBOARD_CARD.EMPTY.TITLE}
            subtitle={GOALS.DASHBOARD_CARD.EMPTY.SUBTITLE}
            button={{
              title: GOALS.DASHBOARD_CARD.EMPTY.BUTTON_TITLE,
              onClick: () => push(routes.goalsV2()),
            }}
          />
        </Case>
        <Case default>
          {R.take(MAX_DISPLAY_COUNT, displayGoals).map((goal) => (
            <Link to={routes.goalsV2.goalDetail({ id: goal.id })} key={goal.id}>
              <GoalDashboardRow goal={goal} key={goal.id} />
            </Link>
          ))}
        </Case>
      </Switch>
      {!!allGoals.length && (
        <Footer center>
          <FullWidthDefaultButton linkTo={routes.goalsV2()}>
            {allGoals.length === displayGoals.length
              ? 'View all goals'
              : `View all ${allGoals.length} goals`}
          </FullWidthDefaultButton>
        </Footer>
      )}
    </StyledDashboardWidget>
  );
};

const QUERY = gql`
  query Web_GoalsDashboardCardV2 {
    migratedToGoalsV2
    goalsV2 {
      id
      priority
      ...Web_GoalDashboardRowFields
    }
    goals {
      id
    }
  }
  ${GoalDashboardRow.fragments.GoalDashboardRowFields}
`;

export default GoalsDashboardWidget;
