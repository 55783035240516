import React from 'react';
import styled from 'styled-components';

import { useDraggableContext } from 'components/lib/dnd/DraggableContext';
import Card from 'components/lib/ui/Card';
import CardTitle from 'components/lib/ui/CardTitle';
import DashboardWidgetAmountHeader from 'components/lib/ui/DashboardWidgetAmountHeader';
import DragDots from 'components/lib/ui/DragDots';
import FlexContainer from 'components/lib/ui/FlexContainer';

import { getCurrentTimeframeText } from 'common/lib/accounts/netWorthCharts';
import variables from 'common/lib/theme/variables';
import usePersistentFilter from 'lib/hooks/usePersistentFilter';

const Root = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing.gutter};
`;

const CustomCard = styled(Card).attrs({ hideHeader: true })<{
  $isDraggingThis: boolean;
  $isDragActive: boolean;
}>`
  --shadow-color: ${variables.color.shadow.card};

  transition: ${({ theme }) => theme.transition.default};
  opacity: ${({ $isDraggingThis, $isDragActive }) => ($isDragActive && !$isDraggingThis ? 0.7 : 1)};
  box-shadow: ${({ theme, $isDraggingThis }) =>
    [`0px 4px 8px var(--shadow-color)`, $isDraggingThis && `0px 0px 0px 2px ${theme.color.blue}`]
      .filter(Boolean)
      .join(', ')};
`;

const Dots = styled(DragDots)`
  margin-left: ${({ theme }) => theme.spacing.small};
  transition: ${({ theme }) => theme.transition.default};
  opacity: 0;
`;

const Header = styled(FlexContainer).attrs({ alignCenter: true, justifyStart: true })`
  gap: ${({ theme }) => theme.spacing.small};
  border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};

  &:hover ${Dots}, &:active ${Dots} {
    opacity: 1;
  }
`;

const Content = styled(FlexContainer).attrs({ column: true })`
  padding: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.xlarge}
    ${({ theme }) => theme.spacing.default} 0;
`;

const TotalAmount = styled(DashboardWidgetAmountHeader)`
  margin-top: ${({ theme }) => theme.spacing.xxxsmall};
`;

type Props = {
  draggable: boolean;
  isAsset: boolean;
  currentAmount: number;
  startAmount: number;
  title: string;
  isDraggingThis: boolean;
  isDragActive: boolean;
};

const AccountGroupCard = ({
  children,
  draggable,
  isAsset,
  title,
  currentAmount,
  startAmount,
  isDraggingThis,
  isDragActive,
}: React.PropsWithChildren<Props>) => {
  const { dragHandleProps } = useDraggableContext();
  const {
    activeFilters: { timeframe, dateRange, chartType },
  } = usePersistentFilter('accounts');
  return (
    <Root>
      <CustomCard $isDragActive={isDragActive} $isDraggingThis={isDraggingThis}>
        <Header {...dragHandleProps}>
          {draggable && <Dots />}
          <Content>
            <CardTitle>{title}</CardTitle>
            <TotalAmount
              currentAmount={currentAmount}
              startAmount={startAmount}
              timeframeText={getCurrentTimeframeText(timeframe, dateRange, chartType)}
              isAsset={isAsset}
            />
          </Content>
        </Header>
        {children}
      </CustomCard>
    </Root>
  );
};

export default AccountGroupCard;
