import React from 'react';
import styled from 'styled-components';

import HistoricalAmountsBarChart from 'components/budget/HistoricalAmountsBarChart';
import Checkbox from 'components/lib/form/Checkbox';
import EventPropagationBoundary from 'components/lib/higherOrder/EventPropagationBoundary';
import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import Card from 'components/lib/ui/Card';

const CHART_WIDTH_PX = 304;
const CHART_HEIGHT_PX = 126;

const StyledCard = styled(Card)`
  width: ${CHART_WIDTH_PX}px;
`;

const CardBody = styled.div`
  padding: ${({ theme }) => theme.spacing.default} 0;
  padding-bottom: ${({ theme }) => theme.spacing.small};
`;

const Footer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.grayBackground};
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.default};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.color.textLight};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledCheckbox = styled(Checkbox)`
  && {
    margin-bottom: 0;
  }
`;

export type Props = {
  itemId: GraphQlUUID;
  isIncome?: boolean;
  appliedToFuture: boolean;
  onClickChartAmount: (amount: number) => void;
  onCheckApplyToFuture: (applyToFuture: boolean) => void;
  isCategoryGroup?: boolean;
};

const EditAmountContextualModal = ({
  itemId,
  isIncome,
  onClickChartAmount,
  onCheckApplyToFuture,
  appliedToFuture,
  isCategoryGroup,
}: Props) => (
  <StyledCard>
    <EventPropagationBoundary onClick>
      <EventPropagationBoundary onMouseDown preventDefault>
        <CardBody {...sensitiveClassProps}>
          <HistoricalAmountsBarChart
            width={CHART_WIDTH_PX}
            height={CHART_HEIGHT_PX}
            id={itemId}
            isIncome={isIncome}
            onClickAmount={onClickChartAmount}
            isGroup={isCategoryGroup}
          />
        </CardBody>
        <Footer>
          <StyledCheckbox
            checked={appliedToFuture}
            onChange={({ target: { checked } }) => onCheckApplyToFuture(checked)}
          >
            Apply to all future months
          </StyledCheckbox>
        </Footer>
      </EventPropagationBoundary>
    </EventPropagationBoundary>
  </StyledCard>
);

export default EditAmountContextualModal;
