import { gql } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';

import ManualAccountLogo from 'components/accounts/ManualAccountLogo';
import InstitutionLogo from 'components/institutions/InstitutionLogo';
import Tooltip from 'components/lib/ui/Tooltip';

import {
  getInstitutionPlaidStatus,
  getStatusForDataProvider,
  evaluateInstitutionStatusColor,
} from 'common/lib/accounts/Institution';
import useTheme from 'lib/hooks/useTheme';

import type { Web_GetInstitutionSettingsQuery } from 'common/generated/graphql';

const ComboAvatar = styled.div`
  padding: ${({ theme }) => theme.spacing.xxsmall};
  padding-left: 0;
  position: relative;
`;

const StatusColor = styled.div<{ $color?: string }>`
  outline: 1px solid ${({ theme }) => theme.color.white};
  position: absolute;
  height: 10px;
  width: 10px;

  border-radius: ${({ theme }) => theme.radius.pill};
  background-color: ${({ $color }) => $color};
  right: 5px;
  bottom: 5px;
`;

type Props = {
  className?: string;
  size?: number;
  credential: Web_GetInstitutionSettingsQuery['credentials'][0];
  isDisconnected: boolean;
  isUpdateRequired: boolean;
};

const InstitutionLogoWithStatus = ({
  size,
  credential,
  isDisconnected,
  isUpdateRequired,
}: Props) => {
  const { institution } = credential;
  const theme = useTheme();

  const balanceStatus = getStatusForDataProvider(
    getInstitutionPlaidStatus(institution, 'balanceStatus'),
    credential.dataProvider,
  );

  const transactionsStatus = getStatusForDataProvider(
    getInstitutionPlaidStatus(institution, 'transactionsStatus'),
    credential.dataProvider,
  );

  const showTransactionSyncingStatus = !['zillow'].includes(credential.dataProvider ?? '');

  const statusColor = evaluateInstitutionStatusColor({
    transactionsStatus,
    balanceStatus,
    showTransactionSyncingStatus,
    updateRequired: isUpdateRequired,
    disconnected: isDisconnected,
    theme,
  });

  const showStatus = isDisconnected || isUpdateRequired || institution.hasIssuesReported;

  const component = (
    <ComboAvatar>
      {credential.institution.logo ? (
        <InstitutionLogo logo={credential.institution.logo} size={size} />
      ) : (
        <ManualAccountLogo icon="dollar-sign" size={size} />
      )}
      {showStatus && !!statusColor && <StatusColor $color={statusColor} />}
    </ComboAvatar>
  );
  if (isDisconnected) {
    return (
      <Tooltip content="We apologize, but we're unable to reconnect this bank. Please try re-adding the accounts.">
        {component}
      </Tooltip>
    );
  }

  return component;
};

InstitutionLogoWithStatus.fragments = {
  InstitutionLogoWithStatusFields: gql`
    fragment InstitutionLogoWithStatusFields on Credential {
      dataProvider
      updateRequired
      institution {
        hasIssuesReported
        logo
        status
        balanceStatus
        transactionsStatus
      }
    }
  `,
};

export default InstitutionLogoWithStatus;
