import { isNotNil, isPositive } from 'ramda-adjunct';

import type { BudgetAmounts } from 'common/lib/budget/Adapters';

import type { Maybe } from 'common/generated/graphql';
import { CategoryGroupType } from 'common/generated/graphql';
import type { Color, ThemeType } from 'common/types/Styles';

type MaybeAmountsLike = Partial<Omit<BudgetAmounts, 'rollover' | 'rolloverType'>> | undefined;
type ExtendedCategoryGroupType = CategoryGroupType | 'savings';

export const getBudgetedAmountWithRollover = (budgeted: Maybe<number>, rollover: Maybe<number>) =>
  (budgeted ?? 0) + (rollover ?? 0);

export const getAvailableDisplayAmount = (
  amount: number | null | undefined,
  categoryType: ExtendedCategoryGroupType | null | undefined,
) => {
  if (categoryType === 'savings') {
    return amount || 0;
  }

  const available = amount ?? 0;
  // We want to avoid showing negative remaining for income categories
  if (categoryType === CategoryGroupType.INCOME) {
    return Math.max(0, available);
  }
  return available;
};

export const getColorForAvailableAmount = (
  available: number,
  categoryType: CategoryGroupType,
): Color => {
  if (available === 0) {
    return 'grayFocus';
  } else if (available < 0 && categoryType === CategoryGroupType.EXPENSE) {
    return 'red';
  }

  return 'green';
};

export const getAmountColor = (amount: number | null | undefined, theme: ThemeType) => {
  if (amount === 0 || amount === undefined || amount === null) {
    return theme.color.grayDark;
  } else if (amount > 0) {
    return theme.color.green;
  } else {
    return theme.color.red;
  }
};

export const getDisplayProgressPercent = (actual: number, total: number) => {
  if (total !== 0) {
    if (actual * total < 0) {
      // actual and total have different signs
      return total > 0 ? 0 : 1;
    } else {
      return actual / total;
    }
  } else {
    return 0;
  }
};

export const getProgressBarColor = (type: string | undefined, isFlexBudgeting: boolean): Color => {
  switch (type) {
    case CategoryGroupType.INCOME:
      return 'green';
    case CategoryGroupType.EXPENSE:
      return isFlexBudgeting ? 'green' : 'red';
    default:
      return 'blue'; // Goals
  }
};

export const getRemainingTextColor = (
  type: string | undefined,
  remaining: number | null | undefined,
  exceedsMonthlyProgress: boolean,
): Color => {
  if (type === 'savings') {
    return 'blueText';
  } else if (exceedsMonthlyProgress) {
    return 'yellowText';
  } else if (isPositive(remaining)) {
    return 'greenText';
  }
  return 'textLight';
};

export const getGroupTypeActualAmountLabel = (type: string | undefined) => {
  switch (type) {
    case CategoryGroupType.INCOME:
      return 'earned';
    case CategoryGroupType.EXPENSE:
      return 'spent';
    default:
      return 'contributed'; // Goals
  }
};

export const getRemainingAmount = ({ actual, available, budgeted }: MaybeAmountsLike = {}) => {
  if (isNotNil(available)) {
    // Rollovers are enabled, so remaining amount comes from backend
    return available;
  } else {
    // Rollovers are disabled, so remaining amount is calculated from budgeted and actual (old way)
    return (budgeted ?? 0) - (actual ?? 0);
  }
};

export const getColorForRemainingAmount = (
  amounts: MaybeAmountsLike,
  sectionType: ExtendedCategoryGroupType,
) => {
  const remaining = Math.round(getRemainingAmount(amounts));
  const categoryType = sectionType === 'savings' ? CategoryGroupType.INCOME : sectionType;
  return getColorForAvailableAmount(remaining, categoryType);
};
