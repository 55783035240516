import { gql } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';

import InstitutionLogo from 'components/institutions/InstitutionLogo';
import EventPropagationBoundary from 'components/lib/higherOrder/EventPropagationBoundary';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import IconButton from 'components/lib/ui/button/IconButton';
import PremiumBadge from 'components/premium/PremiumBadge';
import PremiumFeatureOverlayTrigger from 'components/premium/PremiumFeatureOverlayTrigger';

import { getOrderedInstitutionDataProviders } from 'common/lib/institutions/fallback';

import { INSTITUTION_NAME_ASSOCIATED_FEATURE } from 'common/constants/premium';

import type { InstitutionRowFields } from 'common/generated/graphQlTypes/InstitutionRowFields';

const LOGO_SIZE_PX = 28;
const LOGO_PLACEHOLDER_SIZE_PX = 10;

const Root = styled(FlexContainer).attrs({ alignCenter: true })`
  min-height: 80px;
  cursor: pointer;
  transition: ${({ theme }) => theme.transition.default};
  padding: ${({ theme }) => theme.spacing.xsmall} 0;

  :hover {
    background: ${({ theme }) => theme.color.grayBackground};
  }

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grayFocus};
  }
`;

const StyledInstitutionLogo = styled(InstitutionLogo)`
  width: ${LOGO_SIZE_PX}px;
  height: ${LOGO_SIZE_PX}px;
  flex-shrink: 0;
`;

const LogoPlaceholder = styled.div`
  width: ${LOGO_PLACEHOLDER_SIZE_PX}px;
  height: ${LOGO_PLACEHOLDER_SIZE_PX}px;
  border-radius: ${({ theme }) => theme.radius.round};
  background: ${({ theme }) => theme.color.grayLight};
  margin: 0 ${(LOGO_SIZE_PX - LOGO_PLACEHOLDER_SIZE_PX) / 2}px;
  flex-shrink: 0;
`;

const TextContainer = styled(FlexContainer).attrs({ column: true })`
  flex: 1;
  margin-left: ${({ theme }) => theme.spacing.default};
  max-width: calc(100% - ${({ theme }) => LOGO_SIZE_PX + parseInt(theme.spacing.default, 10)}px);
`;

const Title = styled(Text)``;

const Subtitle = styled(Text).attrs({ size: 'xsmall', color: 'textLight' })`
  white-space: nowrap;
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type Props = {
  institution: InstitutionRowFields;
  onClick: () => void;
  onClickMoreButton?: () => void;
  loading?: boolean;
  className?: string;
};

const InstitutionRow = ({ institution, onClick, onClickMoreButton, className }: Props) => {
  const { name, logo, url, linkFlowWarningMessage } = institution;

  const showMoreButton =
    !!onClickMoreButton && getOrderedInstitutionDataProviders(institution).length > 1;

  return (
    <PremiumFeatureOverlayTrigger
      feature={INSTITUTION_NAME_ASSOCIATED_FEATURE[institution.name]}
      placement="bottom"
    >
      {({ hasAccess }) => (
        <Root onClick={hasAccess ? onClick : undefined} className={className}>
          {logo ? <StyledInstitutionLogo logo={logo} /> : <LogoPlaceholder />}

          <TextContainer>
            <Title>{name}</Title>
            <FlexContainer alignCenter>
              <Subtitle>{url}</Subtitle>
            </FlexContainer>
          </TextContainer>

          {(showMoreButton || !!linkFlowWarningMessage) && (
            <EventPropagationBoundary onClick>
              <FlexContainer alignEnd justifyEnd>
                <IconButton
                  icon={linkFlowWarningMessage ? 'alert-triangle' : 'more-horizontal'}
                  size="medium"
                  onClick={onClickMoreButton}
                />
              </FlexContainer>
            </EventPropagationBoundary>
          )}

          {!hasAccess && <PremiumBadge />}
        </Root>
      )}
    </PremiumFeatureOverlayTrigger>
  );
};

InstitutionRow.fragments = {
  InstitutionRowFields: gql`
    fragment InstitutionRowFields on Institution {
      id
      name
      logo
      largeLogo {
        url
      }
      url
      status
      hasIssuesReported
      hasIssuesReportedMessage
      linkFlowWarnBeforeConnecting
      linkFlowWarningMessage
      linkFlowWarningTitle
      preferredDataProvider
      firstBackupDataProvider
      plaidInstitutionId
      finicityInstitutionId
      mxInstitutionId
    }
  `,
};

export default InstitutionRow;
