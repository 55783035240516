import React from 'react';
import styled from 'styled-components';

import { MenuItem, MenuItemIcon, MenuSeparator } from 'components/lib/ui/Menu';
import NavLink from 'components/lib/ui/link/NavLink';
import { Popover, useOverlay } from 'components/lib/ui/popover';

/** Convenience export */
export const DropdownMenuSeparator = MenuSeparator;

export type ItemProps = React.ComponentProps<typeof MenuItem> & {
  icon?: string;
  linkTo?: string;
  keepMenuOpenOnClick?: boolean;
};

export const DropdownMenuItem = ({
  icon,
  linkTo,
  onClick,
  keepMenuOpenOnClick,
  children,
  ...props
}: ItemProps) => {
  const { close } = useOverlay();

  const handleMenuItemClicked = () => {
    if (props.disabled) {
      return;
    }
    onClick?.();
    if (!keepMenuOpenOnClick) {
      close();
    }
  };

  return (
    <MenuItem
      {...props}
      onClick={handleMenuItemClicked}
      as={linkTo ? NavLink : MenuItem}
      to={linkTo}
    >
      {!!icon && <MenuItemIcon name={icon} />}
      {children}
    </MenuItem>
  );
};

const Root = styled(Popover)`
  overflow: hidden;
  min-width: 240px;
  padding: ${({ theme }) => theme.spacing.xxsmall} 0;
`;

type Props = {
  children?: React.ReactNode;
  className?: string;
};

/**
 * For use with OverlayTrigger to display a dropdown menu that dismisses after an option is clicked.
 */
const DropdownMenu = ({ children, className }: Props) => (
  <Root className={className}>{children}</Root>
);

export default DropdownMenu;
