import type { DateTime } from 'luxon';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useRef } from 'react';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import CurrencyInput from 'components/lib/form/CurrencyInput';
import { maskClassProps } from 'components/lib/higherOrder/withSensitiveData';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import Tooltip from 'components/lib/ui/Tooltip';
import RecurringMenu from 'components/recurring/RecurringMenu';
import IconButtonBadge from 'components/transactions/drawer/IconButtonBadge';

import useCreditReportLiabilityStatementsOperations from 'common/lib/hooks/recurring/useCreditReportLiabilityStatementsOperations';
import { spacing } from 'common/lib/theme/dynamic';
import { formatCurrencyCentsOptional } from 'common/utils/Currency';
import formatTransactionAmount from 'common/utils/formatTransactionAmount';
import useKey from 'lib/hooks/useKey';
import useTheme from 'lib/hooks/useTheme';
import useToast from 'lib/hooks/useToast';

import { RECURRING } from 'common/constants/copy';

const CHECKMARK_ICON_SIZE_PX = 16;

type Props = {
  streamId: string;
  amount?: number;
  isPast?: boolean;
  isApproximate?: boolean;
  hasMerchant?: boolean;
  hasCreditReportLiabilityAccount?: boolean;
  liabilityType?: string;
  markedPaidAt?: string | null;
  accountId?: string | null | undefined;
  creditReportLiabilityStatementId?: string | null | undefined;
  merchantId?: string;
  name?: string;
  className?: string;
  startDate?: Maybe<DateTime>;
  isPaid?: boolean;
  setIsMenuOpen?: (open: boolean) => void;
  refetch?: () => void;
  isAmountDifferentThanOriginal?: boolean;
};

const Amount = styled(Text).attrs({ weight: 'medium', ...maskClassProps })<{ $isIncome: boolean }>`
  color: ${({ $isIncome, theme }) => ($isIncome ? theme.color.green : theme.color.text)};
  margin-right: ${({ theme }) => theme.spacing.default};
`;

const Container = styled(FlexContainer)`
  min-width: 180px;

  &:hover {
    cursor: default;
  }
`;

const IconWrapper = styled.div<{ $backgroundColor: string; $color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.radius.round};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  color: ${({ $color }) => $color};
  width: ${({ theme }) => theme.spacing.large};
  height: ${({ theme }) => theme.spacing.large};
  margin-right: ${({ theme }) => theme.spacing.default};
`;

const AmountInput = styled(CurrencyInput)`
  width: 120px;
  text-align: right;
  margin-right: ${({ theme }) => theme.spacing.default};
`;

const AwaitingBalance = styled(Text).attrs({
  weight: 'medium',
  size: 'small',
})`
  color: ${({ theme }) => theme.color.textLight};
  margin-right: ${({ theme }) => theme.spacing.default};
`;

const StyledRecurringMenu = styled(RecurringMenu)``;

const StyledIconButtonBadge = styled(IconButtonBadge)`
  margin-right: ${spacing.small};
  width: 32px;
  height: 32px;
`;

// Combined the Amount and RecurringMenu components into one
// so the Menu can take actions to affect the Amount and update the input
const RecurringAmountAndMenu = ({
  amount,
  isPast,
  isApproximate,
  hasMerchant,
  hasCreditReportLiabilityAccount,
  markedPaidAt,
  creditReportLiabilityStatementId,
  accountId,
  merchantId,
  name,
  startDate,
  refetch,
  streamId,
  liabilityType,
  isAmountDifferentThanOriginal,
}: Props) => {
  const { updateLiabilityUserBillAmount, updateLiabilityStatementPaymentStatus } =
    useCreditReportLiabilityStatementsOperations();
  const { openToast, openErrorToast } = useToast();

  const [amountValue, setAmountValue] = React.useState(amount);
  const [isEditStatementVisible, setIsEditStatementVisible] = React.useState(false);
  const inputRef = useRef<HTMLElement>(null);
  useKey('Enter', inputRef.current, { onKeyDown: () => inputRef.current?.blur() });
  const theme = useTheme();

  const isCreditCard = liabilityType === 'credit_card';

  const showAmountInput =
    hasCreditReportLiabilityAccount &&
    !R.isNil(amount) &&
    !!creditReportLiabilityStatementId &&
    isEditStatementVisible;

  const isPaid = !!markedPaidAt;

  const togglePaymentStatus = async () => {
    if (!creditReportLiabilityStatementId) {
      return;
    }

    await updateLiabilityStatementPaymentStatus({
      creditReportLiabilityStatementId,
      isPaid: !isPaid,
      onSuccess: () => {
        openToast({
          title: 'Success',
          description: 'Statement payment status updated.',
        });
        refetch?.();
      },
      onError: () => openErrorToast(),
    });
  };

  const [updatedAmount, setUpdatedAmount] = React.useState(amount);

  const isAwaitingBalance = hasCreditReportLiabilityAccount && R.isNil(amount);

  const showAmountValue = !isAwaitingBalance && !showAmountInput;
  const showCheckmark =
    (!!isPast && hasMerchant) || (!!markedPaidAt && hasCreditReportLiabilityAccount);

  const isIncome = Boolean(
    (amount && amount > 0 && hasMerchant) ||
      (updatedAmount && hasCreditReportLiabilityAccount && updatedAmount > 0),
  );

  const hasLiabilityStatement = creditReportLiabilityStatementId;
  const canBeMarkedAsPaid =
    hasCreditReportLiabilityAccount && hasLiabilityStatement && !markedPaidAt;

  return (
    <Container justifyEnd alignCenter>
      {showCheckmark && (
        <IconWrapper $backgroundColor={theme.color.greenFocus} $color={theme.color.greenText}>
          <Icon name="check" size={12} />
        </IconWrapper>
      )}

      {canBeMarkedAsPaid && (
        <StyledIconButtonBadge
          tooltip="Mark bill as paid"
          icon="check"
          iconSize={CHECKMARK_ICON_SIZE_PX}
          badge={false}
          onClick={togglePaymentStatus}
        />
      )}

      <Switch>
        <Case when={!!hasCreditReportLiabilityAccount}>
          {showAmountValue && (
            <Tooltip
              opacity={1}
              portal
              maxWidth={170}
              content={isCreditCard ? RECURRING.CREDIT_CARD_NO_CENTS : undefined}
            >
              <div>
                <Amount $isIncome={isIncome}>
                  {RA.isNotNil(updatedAmount) && formatCurrencyCentsOptional(updatedAmount * -1)}
                </Amount>
              </div>
            </Tooltip>
          )}
        </Case>

        <Case default>
          {showAmountValue && (
            <Tooltip
              opacity={1}
              content={isApproximate ? RECURRING.AMOUNT_ESTIMATE_TOOLTIP : undefined}
            >
              <div>
                <Amount $isIncome={isIncome}>
                  {isApproximate ? '≈' : ''}
                  {updatedAmount && formatTransactionAmount(updatedAmount)}
                </Amount>
              </div>
            </Tooltip>
          )}
        </Case>
      </Switch>

      {showAmountInput && (
        <div>
          <AmountInput
            ref={inputRef}
            autoFocus
            tabIndex={0}
            name="amount"
            selectOnFocus
            maskOptions={{ allowDecimal: true }}
            value={amountValue}
            onChange={setAmountValue}
            onBlur={async () => {
              // no need to update the statement if no changes were made
              if (amountValue === amount || !creditReportLiabilityStatementId) {
                setIsEditStatementVisible(false);
                return;
              }

              const invertedSignAmount = amountValue ? amountValue * -1 : 0;

              setUpdatedAmount(invertedSignAmount); // invert the sign before saving

              await updateLiabilityUserBillAmount({
                creditReportLiabilityStatementId,
                userBillAmount: invertedSignAmount,
                onSuccess: () => {
                  setIsEditStatementVisible(false);
                  openToast({
                    title: 'Success',
                    description: 'Amount updated.',
                  });
                  refetch?.();
                },
                onError: () =>
                  openErrorToast({
                    title: 'Error',
                    description: 'Something went wrong. Please try again later or submit a ticket.',
                  }),
              });
            }}
          />
        </div>
      )}

      {isAwaitingBalance && (
        <Tooltip opacity={1} content={isAwaitingBalance ? RECURRING.AWAITING_BALANCE : undefined}>
          <div>
            <AwaitingBalance>Awaiting balance</AwaitingBalance>
          </div>
        </Tooltip>
      )}
      {name && refetch && (
        <StyledRecurringMenu
          streamId={streamId}
          creditReportLiabilityStatementId={creditReportLiabilityStatementId}
          accountId={accountId}
          isPaid={isPaid}
          merchantId={merchantId}
          name={name}
          startDate={startDate}
          refetch={refetch}
          setAmountValue={setUpdatedAmount}
          setIsEditStatementVisible={setIsEditStatementVisible}
          isAmountDifferentThanOriginal={isAmountDifferentThanOriginal}
        />
      )}
    </Container>
  );
};

export default RecurringAmountAndMenu;
