import React from 'react';
import styled from 'styled-components';

import FeatureOnboardingPage from 'components/lib/layouts/FeatureOnboardingPage';
import CheckAnimation from 'components/lib/ui/CheckAnimation';
import FlexContainer from 'components/lib/ui/FlexContainer';

import { spacing } from 'common/lib/theme/dynamic';

const CHECK_ANIMATION_SIZE_PX = 140;

const HeaderContainer = styled(FlexContainer).attrs({ center: true })`
  margin-top: ${spacing.xxxxlarge};
  margin-bottom: -${spacing.large};
`;

type Props = {
  onBack: () => void;
  onCancel: () => void;
  onNext: () => void;
  progress: number;
};

const OnboardingSuccess = ({ onBack, onCancel, onNext, progress }: Props) => (
  <FeatureOnboardingPage
    header={
      <HeaderContainer>
        <CheckAnimation size={CHECK_ANIMATION_SIZE_PX} />
      </HeaderContainer>
    }
    pageName="Onboarding Success"
    title="You're all set!"
    description="You'll be able to customize it every month to fit your needs as life happens."
    progress={progress}
    onClickBack={onBack}
    onClickCancel={onCancel}
    onClickNext={onNext}
    nextButtonText="View my budget"
  />
);

export default OnboardingSuccess;
