import getColorPalette from 'common/lib/theme/getColorPalette';
import staticTheme from 'common/lib/theme/staticTheme';

import type { UiTheme } from 'common/types';

type ThemeContext = {
  uiTheme: UiTheme;
  isV2ThemeEnabled: boolean;
};

export const getTheme = ({ uiTheme, isV2ThemeEnabled }: ThemeContext) => {
  const color = getColorPalette(uiTheme, isV2ThemeEnabled);

  return {
    ...staticTheme,
    uiTheme,
    version: isV2ThemeEnabled ? 2 : 1,
    color,
  } as const;
};
