import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import SettingsCard from 'components/lib/layouts/SettingsCard';
import Badge from 'components/lib/ui/Badge';
import Banner from 'components/lib/ui/Banner';
import Column from 'components/lib/ui/Column';
import Flex from 'components/lib/ui/Flex';
import Modal from 'components/lib/ui/Modal';
import Text from 'components/lib/ui/Text';
import AsyncButton from 'components/lib/ui/button/AsyncButton';
import DefaultButton, { defaultButtonMixin } from 'components/lib/ui/button/DefaultButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import SettingsSection from 'components/settings/SettingsSection';
import SettingsTitle from 'components/settings/SettingsTitle';
import DisableMfaConfirmation from 'components/settings/security/DisableMfaConfirmation';
import DisconnectSSOConfirmation from 'components/settings/security/DisconnectSSOConfirmation';
import EditPasswordModalForm from 'components/settings/security/EditPasswordModal';
import EnableMfaFlow from 'components/settings/security/EnableMfaFlow';
import ProviderAuthButton from 'components/settings/security/ProviderAuthButton';
import SaveRecoveryCodesMfaModal from 'components/settings/security/SaveRecoveryCodesMfaModal';
import UpdateEmailFlow from 'components/settings/security/UpdateEmailFlow';
import UpdateEmailVerificationModal from 'components/settings/security/UpdateEmailVerificationModal';
import SupportSettingsCardContent from 'components/support/settings/SupportSettingsCardContent';

import { getProviderLabels } from 'common/lib/externalAuth/utils';
import useQuery from 'common/lib/hooks/useQuery';
import { capitalize } from 'common/utils/String';
import useDemoHousehold from 'lib/hooks/useDemoHousehold';
import useModal from 'lib/hooks/useModal';
import { sendCreatePasswordEmailAction } from 'state/user/thunks';

import { gql } from 'common/generated';
import type { AuthProvider } from 'common/types/auth';

const StyledText = styled(Text)`
  display: block;
  margin: -${({ theme }) => theme.spacing.xsmall} 0 ${({ theme }) => theme.spacing.default} 0;
  padding-top: ${({ theme }) => theme.spacing.xlarge};
`;

const StyledSettingsSection = styled(SettingsSection)`
  flex-wrap: wrap;
  row-gap: ${({ theme }) => theme.spacing.small};
`;

const StyledBanner = styled(Banner)`
  margin: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.xlarge} 0
    ${({ theme }) => theme.spacing.xlarge};
  font-size: ${({ theme }) => theme.fontSize.small};

  + ${SettingsSection} {
    padding-top: ${({ theme }) => theme.spacing.default};
  }
`;

const RECOVERY_CODES_DESCRIPTION_1 =
  'Recovery codes are used to access your account in the event you lose access to your device and ' +
  'cannot receive two-factor authentication codes.';

const RECOVERY_CODES_DESCRIPTION_2 =
  'Save your recovery codes in a safe place to keep access to your account. ' +
  'Monarch Support cannot restore access to accounts with two-factor authentication enabled for security reasons.';

const SecondaryLoadingButton = styled(AsyncButton)`
  ${defaultButtonMixin}
  width: 100%;
`;

const StyledSettingsCard = styled(SettingsCard)`
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
`;

const EmailActionButtonsContainer = styled(Flex)`
  max-height: 38px;

  > * + * {
    margin-left: 10px;
  }
`;

const SecuritySettings = () => {
  const dispatch = useDispatch();
  const { isDemoHousehold, blockActionForDemoHousehold } = useDemoHousehold();
  const { data, refetch, isLoadingInitialData, isNetworkRequestInFlight } =
    useQuery(SECURITY_SETTINGS_QUERY);

  const {
    email,
    hasMfaOn,
    hasPassword,
    externalAuthProviders = [],
    pendingEmailUpdateVerification = { email: null },
  } = data?.me ?? {};

  const [enablingMfa, setEnablingMfa] = useState(false);
  const [updatingEmail, setUpdatingEmail] = useState(false);
  const [verifyingEmail, setVerifyingEmail] = useState(false);
  const [confirmingDisableMfa, setConfirmingDisableMfa] = useState(false);
  const [confirmingDisconnectSSO, setConfirmingDisconnectSSO] = useState(false);

  const [passwordEmailSent, setPasswordEmailSent] = useState(false);
  const [sendingEmailPassword, setSendingEmailPassword] = useState(false);

  const [RecoveryCodesModal, { open: openRecoveryCodesModal, close: closeRecoveryCodesModal }] =
    useModal();
  const [EditPasswordModal, { open: openEditPasswordModal, close: closeEditPasswordModal }] =
    useModal();

  useEffect(() => {
    if (!updatingEmail) {
      // This causes an issue when live-reloading.
      // For more details, see https://github.com/apollographql/apollo-client/pull/7186#issuecomment-813975860
      refetch();
    }
  }, [updatingEmail]);

  const handleExternalAuthProviderButtonClicked = (_provider: string) => {
    setConfirmingDisconnectSSO(true);
  };
  const unverifiedEmail = pendingEmailUpdateVerification?.email || null;

  const hasExternalAuth = externalAuthProviders.length > 0;
  const providerLabels = getProviderLabels(externalAuthProviders.map((p) => p.provider));
  const providerName = externalAuthProviders[0]?.provider;
  const providerEmail = externalAuthProviders[0]?.email;
  const isUsingEmailFromProvider = providerEmail === email;

  const supportAccountAccessGrant = data?.me?.activeSupportAccountAccessGrant;
  const [toggleSupportAccountAccessMutation, { loading: isTogglingSupportAccountAccess }] =
    useMutation(TOGGLE_SUPPORT_ACCESS_MUTATION, {
      onCompleted: () => refetch(),
    });

  const toggleSupportAccountAccess = (isEnabled: boolean) => {
    toggleSupportAccountAccessMutation({ variables: { isEnabled } });
  };

  return (
    <Column md={9}>
      <Helmet>
        <title>Security Settings</title>
      </Helmet>
      <StyledSettingsCard
        title="Email & Password"
        loading={isLoadingInitialData}
        isDemo={isDemoHousehold}
      >
        {hasExternalAuth && !isUsingEmailFromProvider && (
          <SettingsSection>
            <span>
              <SettingsTitle
                title="Email"
                description={
                  <>
                    {providerEmail}
                    <Badge color="green">VERIFIED</Badge>
                  </>
                }
              />
            </span>
            {hasExternalAuth && providerName && (
              <ProviderAuthButton
                providerLabel={providerLabels[0]}
                provider={providerName as AuthProvider}
                disableTooltip // if this is shown, it means user has met the conditions to disconnect, so no need to show tooltip
                onClick={() => handleExternalAuthProviderButtonClicked(providerName)}
              />
            )}
            {pendingEmailUpdateVerification && (
              <PrimaryButton
                size="small"
                onClick={() => blockActionForDemoHousehold(() => setVerifyingEmail(true), false)}
              >
                Verify email
              </PrimaryButton>
            )}
          </SettingsSection>
        )}
        <StyledSettingsSection>
          <div>
            <SettingsTitle
              title="Email"
              description={
                <>
                  {unverifiedEmail || email}
                  {!unverifiedEmail ? (
                    <Badge color="green">VERIFIED</Badge>
                  ) : (
                    <Badge color="orange">NOT VERIFIED</Badge>
                  )}
                </>
              }
            />
          </div>
          <div>
            <EmailActionButtonsContainer>
              <DefaultButton
                disabled={!hasPassword && !unverifiedEmail}
                size="small"
                onClick={() => blockActionForDemoHousehold(() => setUpdatingEmail(true), false)}
              >
                Edit email
              </DefaultButton>
              {hasExternalAuth && providerName && isUsingEmailFromProvider && (
                <ProviderAuthButton
                  providerLabel={providerLabels[0]}
                  provider={providerName as AuthProvider}
                  disabled={!hasPassword || !!unverifiedEmail || isUsingEmailFromProvider}
                  onClick={() => handleExternalAuthProviderButtonClicked(providerName)}
                />
              )}
              {pendingEmailUpdateVerification && (
                <PrimaryButton
                  size="small"
                  onClick={() => blockActionForDemoHousehold(() => setVerifyingEmail(true), false)}
                >
                  Verify email
                </PrimaryButton>
              )}
            </EmailActionButtonsContainer>
          </div>
        </StyledSettingsSection>
        {passwordEmailSent && (
          <StyledBanner type="success">
            Check your email for instructions to create a Monarch account password.
          </StyledBanner>
        )}
        <SettingsSection>
          {hasPassword ? (
            <>
              <span>
                <SettingsTitle
                  title="Password"
                  description="Click the button to change your password"
                />
              </span>
              <span>
                <DefaultButton
                  onClick={() => blockActionForDemoHousehold(() => openEditPasswordModal(), false)}
                  size="small"
                >
                  Edit password
                </DefaultButton>
              </span>
            </>
          ) : (
            <>
              <span>
                <SettingsTitle
                  title="Create password"
                  description={
                    hasExternalAuth && providerName
                      ? `Currently using Log in with ${capitalize(providerName)}`
                      : 'Click the button to create a password'
                  }
                />
              </span>
              <span>
                <SecondaryLoadingButton
                  size="small"
                  pending={sendingEmailPassword}
                  onClick={() =>
                    blockActionForDemoHousehold(async () => {
                      setSendingEmailPassword(true);
                      dispatch(sendCreatePasswordEmailAction());
                      setSendingEmailPassword(false);
                      setPasswordEmailSent(true);
                    }, false)
                  }
                >
                  Create Password
                </SecondaryLoadingButton>
              </span>
            </>
          )}
        </SettingsSection>
      </StyledSettingsCard>

      {hasPassword && (
        <StyledSettingsCard
          title="Multi-Factor Authentication"
          loading={isLoadingInitialData}
          isDemo={isDemoHousehold}
        >
          <SettingsSection>
            <span>
              <SettingsTitle
                title={
                  <>
                    {'Multi-Factor Authentication'}
                    {hasMfaOn ? (
                      <Badge color="green">ENABLED</Badge>
                    ) : (
                      <Badge color="orange">DISABLED</Badge>
                    )}
                  </>
                }
                description="Protect your Monarch account with an extra security step"
              />
            </span>
            <span>
              {hasMfaOn ? (
                <DefaultButton size="small" onClick={() => setConfirmingDisableMfa(true)}>
                  Disable
                </DefaultButton>
              ) : (
                <DefaultButton
                  size="small"
                  onClick={() => blockActionForDemoHousehold(() => setEnablingMfa(true), false)}
                >
                  Enable MFA
                </DefaultButton>
              )}
            </span>
          </SettingsSection>
          {hasMfaOn && (
            <SettingsSection>
              <span style={{ paddingRight: '48px' }}>
                <SettingsTitle title="Recovery Codes" description={RECOVERY_CODES_DESCRIPTION_1} />
                <StyledText size="small">{RECOVERY_CODES_DESCRIPTION_2}</StyledText>
              </span>
              <span style={{ alignSelf: 'flex-start' }}>
                <DefaultButton size="small" onClick={openRecoveryCodesModal}>
                  Codes
                </DefaultButton>
              </span>
            </SettingsSection>
          )}
          <RecoveryCodesModal>
            <SaveRecoveryCodesMfaModal onDone={closeRecoveryCodesModal} />
          </RecoveryCodesModal>
          <EditPasswordModal>
            <EditPasswordModalForm
              onDone={closeEditPasswordModal}
              onCancel={closeEditPasswordModal}
            />
          </EditPasswordModal>

          {confirmingDisableMfa && (
            <Modal onClose={() => setConfirmingDisableMfa(false)}>
              {({ close }) => (
                <DisableMfaConfirmation
                  onCancel={close}
                  onDisable={() => {
                    close();
                    refetch();
                  }}
                />
              )}
            </Modal>
          )}
          {confirmingDisconnectSSO && (
            <Modal onClose={() => setConfirmingDisconnectSSO(false)}>
              {({ close }) => (
                <DisconnectSSOConfirmation
                  provider={providerName as AuthProvider}
                  providerLabel={providerLabels[0]}
                  providerEmail={providerEmail}
                  onCancel={close}
                  onDisconnect={() => {
                    close();
                    refetch();
                  }}
                />
              )}
            </Modal>
          )}
          {enablingMfa && (
            <Modal onClose={() => setEnablingMfa(false)}>
              {({ close }) => (
                <EnableMfaFlow
                  onComplete={() => {
                    close();
                    refetch();
                  }}
                />
              )}
            </Modal>
          )}
          {updatingEmail && (
            <Modal onClose={() => setUpdatingEmail(false)}>
              {({ close }) => (
                <UpdateEmailFlow
                  onComplete={() => {
                    close();
                    refetch();
                  }}
                />
              )}
            </Modal>
          )}
          {verifyingEmail && (
            <Modal onClose={() => setVerifyingEmail(false)}>
              {({ close }) => (
                <UpdateEmailVerificationModal
                  newEmail={unverifiedEmail!}
                  next={() => {
                    close();
                    refetch();
                  }}
                />
              )}
            </Modal>
          )}
        </StyledSettingsCard>
      )}

      <StyledSettingsCard
        title="Customer Support"
        loading={isLoadingInitialData}
        isDemo={isDemoHousehold}
      >
        <SupportSettingsCardContent
          supportAccountAccessGrant={supportAccountAccessGrant}
          toggleSupportAccountAccess={toggleSupportAccountAccess}
          isTogglingSupportAccountAccess={
            !isLoadingInitialData && (isTogglingSupportAccountAccess || isNetworkRequestInFlight)
          }
        />
      </StyledSettingsCard>
    </Column>
  );
};

export const SECURITY_SETTINGS_QUERY = gql(/* GraphQL */ `
  query Web_GetSecuritySettings {
    me {
      id
      email
      hasMfaOn
      isVerified
      hasPassword
      externalAuthProviders {
        provider
        email
      }
      pendingEmailUpdateVerification {
        email
      }
      activeSupportAccountAccessGrant {
        id
        createdAt
        expiresAt
      }
    }
  }
`);

const TOGGLE_SUPPORT_ACCESS_MUTATION = gql(/* GraphQL */ `
  mutation Web_ToggleSupportAccountAccess($isEnabled: Boolean!) {
    toggleSupportAccountAccess(isEnabled: $isEnabled) {
      grant {
        id
        expiresAt
      }
    }
  }
`);

export default SecuritySettings;
