import { sentenceCase } from 'change-case';
import { isNotNil } from 'ramda-adjunct';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import EditCategoryModal from 'components/categories/EditCategoryModal';
import GoalCustomizeModal from 'components/goalsV2/GoalCustomizeModal';
import ExpandMoreIconButton from 'components/lib/ui/ExpandMoreIconButton';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Modal from 'components/lib/ui/Modal';
import Text from 'components/lib/ui/Text';
import IconButton from 'components/lib/ui/button/IconButton';
import RouteLink from 'components/lib/ui/link/RouteLink';
import EditFlexibleGroupModalCard from 'components/plan/EditFlexibleGroupModalCard';
import {
  GroupHeaderRow,
  HeaderText,
  LeftColumn,
  PlanGridRow,
  PlanGroupDrawer,
} from 'components/plan/PlanGrid';
import PlanRowTitle from 'components/plan/PlanRowTitle';
import PlanRowTitleUnplanned from 'components/plan/PlanRowTitleUnplanned';
import { PlanUnallocatedBudgetRowTitle } from 'components/plan/PlanUnallocatedBudgetRow';

import { toggleExpandedGroupRow } from 'actions';
import useGetGoal from 'common/lib/hooks/goalsV2/useGetGoal';
import { radius, spacing } from 'common/lib/theme/dynamic';
import { usePlanContext } from 'lib/contexts/PlanContext';
import useModal from 'lib/hooks/useModal';
import { getSectionHasBottomPadding, SAVINGS_PLAN_SECTION_TYPE } from 'lib/plan';
import type { PlanGroupData, PlanRowData, PlanSectionType } from 'lib/plan/Adapters';
import { SettingsButtonAction } from 'lib/plan/Adapters';
import fakeCornersMixin from 'lib/styles/fakeCornersMixin';
import {
  getExpandedGroupRow,
  getPlanCollapsedGroups,
  getUnplannedExpandedByGroup,
} from 'selectors';

import { BudgetRolloverFrequency, BudgetSystem, BudgetVariability } from 'common/generated/graphql';

const SettingsButton = styled(IconButton).attrs({ icon: 'settings' })`
  opacity: 0;
  margin-left: auto;
  margin-right: -${spacing.xsmall};
  transition: opacity 0.1s ease-out;
  flex-shrink: 0;
`;

const GroupHeaderLeft = styled(GroupHeaderRow)`
  border-bottom-left-radius: ${radius.medium};
  padding-right: ${spacing.xlarge};
  ${({ theme }) => fakeCornersMixin({ theme, topLeft: true })};

  :hover {
    ${SettingsButton} {
      opacity: 1;
    }
  }
`;

const GridRow = styled(PlanGridRow)<{ $hasPadding: boolean }>`
  padding-right: ${spacing.xlarge};
  padding-left: ${({ theme, $hasPadding }) => ($hasPadding ? theme.spacing.xlarge : 0)};

  :hover ${SettingsButton} {
    opacity: 1;
  }
`;

type Props = {
  group: PlanGroupData;
  sectionType: PlanSectionType;
  unallocatedFlexibleBudgetAmount: number | null;
};

const PlanGroupLeft = ({
  group: {
    name,
    unplannedCount,
    rows,
    id,
    groupLevelBudgetingEnabled,
    showSettingsButton: showCategoryGroupSettings,
    href,
  },
  sectionType,
  unallocatedFlexibleBudgetAmount = 0,
}: Props) => {
  const dispatch = useDispatch();
  const onToggleExpandedRow = useCallback(
    (groupId: string) => dispatch(toggleExpandedGroupRow(groupId)),
    [dispatch],
  );

  const { actions, timeframe, householdBudgetSystem, openEditCategoryGroup, refetch } =
    usePlanContext();
  const householdUsesFlexibleBudgeting = householdBudgetSystem === BudgetSystem.FIXED_AND_FLEX;

  const isCollapsed = useSelector((state: any) => getPlanCollapsedGroups(state, id));
  const isUnplannedExpanded = useSelector((state: any) => getUnplannedExpandedByGroup(state, id));

  const toggleCollapsed = useCallback(() => actions.toggleIsGroupCollapsed(id), [id, actions]);
  const toggleUnplanned = useCallback(
    () => actions.toggleIsUnplannedExpandedForGroup(id),
    [id, actions],
  );

  const [editingCategoryId, setEditingCategoryId] = useState<string | undefined>(undefined);

  const [editingGoalId, setEditingGoalId] = useState<string | undefined>(undefined);

  const { goal: editingGoalV2 } = useGetGoal(editingGoalId, {
    skip: !editingGoalId,
  });

  const hasBottomPadding = useMemo(
    () =>
      getSectionHasBottomPadding(
        timeframe,
        unplannedCount,
        isCollapsed,
        groupLevelBudgetingEnabled,
      ),
    [isCollapsed, timeframe, unplannedCount],
  );

  const onSettingsAction = (settingsAction: SettingsButtonAction, itemId: string) => {
    if (sectionType === SAVINGS_PLAN_SECTION_TYPE) {
      setEditingGoalId(itemId);
    } else {
      switch (settingsAction) {
        case SettingsButtonAction.OPEN_CATEGORY_EDIT_MODAL:
          setEditingCategoryId(itemId);
          break;
        case SettingsButtonAction.OPEN_CATEGORY_GROUP_EDIT_MODAL:
          openEditCategoryGroup(itemId);
      }
    }
  };

  const hasAnyRows = rows.length > 0;
  const isFlexibleGroup = id === BudgetVariability.FLEXIBLE;
  const [
    EditFlexibleGroupModal,
    { open: openEditFlexibleGroupModal, close: closeEditFlexibleGroupModal },
  ] = useModal();

  return (
    <>
      <LeftColumn $hasBottomPadding={hasBottomPadding}>
        <GroupHeaderLeft>
          <PlanRowTitle
            icon={
              hasAnyRows && (
                <ExpandMoreIconButton onClick={toggleCollapsed} expanded={!isCollapsed} />
              )
            }
          >
            {href && (
              <RouteLink stealthy to={href}>
                <HeaderText>{name}</HeaderText>
              </RouteLink>
            )}
            {!href && <HeaderText>{name}</HeaderText>}
          </PlanRowTitle>
          {/* Right-hand side of the header */}
          <Switch>
            <Case when={isFlexibleGroup}>
              <SettingsButton onClick={openEditFlexibleGroupModal} />
            </Case>
            <Case when={showCategoryGroupSettings}>
              <SettingsButton onClick={() => openEditCategoryGroup(id)} />
            </Case>
          </Switch>
        </GroupHeaderLeft>
        <PlanGroupDrawer open={!isCollapsed} shouldAnimateOnMount={false}>
          {isNotNil(unallocatedFlexibleBudgetAmount) && <PlanUnallocatedBudgetRowTitle />}
          {rows.map((rowData, index) => (
            <PlanGroupLeftRow
              key={rowData.id}
              onClickIcon={() => (rowData.id ? onToggleExpandedRow(rowData.id) : null)}
              hasBottomBorder={hasBottomPadding && index === rows.length - 1}
              onClickSettings={() => onSettingsAction(rowData.settingsButtonAction, rowData.id)}
              isUnplannedExpanded={isUnplannedExpanded}
              isFlexibleGroup={isFlexibleGroup}
              householdUsesFlexibleBudgeting={householdUsesFlexibleBudgeting}
              rowData={rowData}
            />
          ))}
          {unplannedCount > 0 && (
            <PlanGridRow>
              <PlanRowTitleUnplanned
                unplannedCount={unplannedCount}
                isExpanded={isUnplannedExpanded}
                onClick={toggleUnplanned}
              />
            </PlanGridRow>
          )}
        </PlanGroupDrawer>
      </LeftColumn>
      {!!editingCategoryId && (
        <Modal onClose={() => setEditingCategoryId(undefined)}>
          {({ close }) => (
            <EditCategoryModal
              categoryId={editingCategoryId}
              onDone={() => {
                close();
                refetch();
              }}
            />
          )}
        </Modal>
      )}
      {editingGoalV2 && (
        <Modal onClose={() => setEditingGoalId(undefined)}>
          {() => <GoalCustomizeModal data={editingGoalV2} />}
        </Modal>
      )}

      <EditFlexibleGroupModal>
        <EditFlexibleGroupModalCard onClose={closeEditFlexibleGroupModal} />
      </EditFlexibleGroupModal>
    </>
  );
};

const PlanGroupLeftRow = ({
  rowData,
  isUnplannedExpanded,
  hasBottomBorder,
  isFlexibleGroup,
  householdUsesFlexibleBudgeting,
  onClickSettings,
  onClickIcon,
}: {
  isUnplannedExpanded: boolean;
  hasBottomBorder: boolean;
  onClickSettings?: () => void;
  onClickIcon?: () => void;
  rowData: PlanRowData;
  isFlexibleGroup: boolean;
  householdUsesFlexibleBudgeting: boolean;
}) => {
  const { id, parentGroupId, name, icon, href, height, isUnplanned, rolloverPeriod } = rowData;
  const rolloverPeriodFrequency = rolloverPeriod?.frequency;
  const showRolloverPeriodFrequency =
    householdUsesFlexibleBudgeting &&
    rolloverPeriodFrequency &&
    rolloverPeriodFrequency !== BudgetRolloverFrequency.MONTHLY;

  const isExpandedGroupRow = useSelector((state: any) =>
    getExpandedGroupRow(state, parentGroupId ?? ''),
  );
  const isSelfExpanded = useSelector((state: any) => getExpandedGroupRow(state, id ?? ''));

  if (parentGroupId) {
    if (!isExpandedGroupRow) {
      return null;
    }
  }

  if (isUnplanned && !isUnplannedExpanded) {
    return null;
  }

  return (
    <GridRow
      $grayBackground={isUnplanned || !!parentGroupId}
      $hasPadding={isFlexibleGroup && !!parentGroupId}
      $hasBottomBorder={hasBottomBorder}
      $height={height}
    >
      <PlanRowTitle
        icon={icon === 'chevron' ? <ExpandMoreIconButton expanded={isSelfExpanded} /> : icon}
        href={href}
        onClickIcon={onClickIcon}
      >
        <FlexContainer column>
          <span title={`${icon} ${name}`}>{name}</span>
          {showRolloverPeriodFrequency && rolloverPeriodFrequency && (
            <Text size="xsmall" color="textLight">
              {sentenceCase(rolloverPeriodFrequency)}
            </Text>
          )}
        </FlexContainer>
      </PlanRowTitle>
      {onClickSettings && <SettingsButton onClick={onClickSettings} />}
    </GridRow>
  );
};

export default React.memo(PlanGroupLeft);
