// THIS FILE IS GENERATED, DO NOT MODIFY MANUALLY. ##checksum##: 884687846
import { createAction } from 'typesafe-actions';

export const forceEmptyState = createAction('EMPLOYEE/FORCE_EMPTY_STATE')<boolean>();

export const forceRefreshEnabledStatus = createAction(
  'EMPLOYEE/FORCE_REFRESH_ENABLED_STATUS',
)<boolean>();

export const setDemoMode = createAction('EMPLOYEE/SET_DEMO_MODE')<boolean>();

export const setShowGoalsTour = createAction('EMPLOYEE/SET_SHOW_GOALS_TOUR')<boolean>();
