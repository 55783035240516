import { DateTime } from 'luxon';
import React from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import styled from 'styled-components';

import DividerLine from 'components/lib/ui/DividerLine';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import { RecurringView } from 'components/routes/recurring/RecurringUpcoming';

const Root = styled(FlexContainer).attrs({ alignCenter: true, justifyBetween: true })`
  padding: ${({ theme }) => theme.spacing.small} 0;
`;

const CurrentMonth = styled.h1`
  margin: 0;
  padding: 0;
  font-size: ${({ theme }) => theme.fontSize.xlarge};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const CalendarActions = styled(FlexContainer).attrs({ alignEnd: true, justifyEnd: true })`
  gap: ${({ theme }) => theme.spacing.small};
`;

const TodayButton = styled(DefaultButton)``;

const ButtonGroup = styled(FlexContainer)`
  ${DefaultButton} {
    height: 38px;

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    :first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    :last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    :not(:first-child) {
      margin-left: -1px;
    }
  }
`;

const Arrows = styled.div`
  height: 38px;
  display: flex;
  gap: 0;
`;

const ArrowButton = styled(Icon).attrs({
  size: 16,
})`
  cursor: pointer;
  padding: 10px;
  width: 36px;
  height: 36px;
`;

const ButtonGroupIcon = styled(Icon).attrs({
  size: 16,
})`
  margin-right: 5px;
`;

type Props = {
  startDate: DateTime;
  setStartDate: (date: string) => void;
  recurringView: RecurringView;
  setRecurringView: (view: RecurringView) => void;
  refetch: () => void;
};

const RecurringActions = ({
  startDate,
  setStartDate,
  recurringView,
  setRecurringView,
  refetch,
}: Props) => {
  const onClickToday = () => {
    setStartDate(DateTime.local().startOf('month').toISODate());
  };

  const onClickNext = () => {
    setStartDate(startDate.plus({ months: 1 }).toISODate());
  };

  const onClickPrev = () => {
    setStartDate(startDate.minus({ months: 1 }).toISODate());
  };

  const currentMonthLabel = startDate.toFormat('LLLL yyyy');

  return (
    <Root>
      <CurrentMonth>{currentMonthLabel}</CurrentMonth>
      <CalendarActions>
        <Arrows>
          <ArrowButton name="arrow-left" onClick={onClickPrev} />
          <ArrowButton name="arrow-right" onClick={onClickNext} />
        </Arrows>
        {/* Today Button */}
        <TodayButton onClick={onClickToday}>Today</TodayButton>

        <DividerLine />

        {/* View control */}
        <ButtonGroup>
          <DefaultButton
            onClick={() => setRecurringView(RecurringView.List)}
            active={recurringView === RecurringView.List}
          >
            <ButtonGroupIcon name="menu" />
            List
          </DefaultButton>
          <DefaultButton
            onClick={() => setRecurringView(RecurringView.Calendar)}
            active={recurringView === RecurringView.Calendar}
          >
            <ButtonGroupIcon name="calendar" />
            Calendar
          </DefaultButton>
        </ButtonGroup>
      </CalendarActions>
    </Root>
  );
};

export default RecurringActions;
