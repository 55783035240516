import React from 'react';
import styled from 'styled-components';

import DropdownMenu, { DropdownMenuItem } from 'components/lib/ui/DropdownMenu';
import FlexContainer from 'components/lib/ui/FlexContainer';
import IconButton from 'components/lib/ui/button/IconButton';
import OverlayTrigger from 'components/lib/ui/popover/OverlayTrigger';

const HoverIconButton = styled(IconButton)`
  opacity: 0;
  transition: opacity 0.1s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

type Props = {
  showGroupControls: boolean;
  className?: string;
  onAddCategory: () => void;
  onEditGroup: () => void;
  onDeleteGroup: () => void;
};

const BudgetCardControls = ({
  showGroupControls,
  onAddCategory,
  onEditGroup,
  onDeleteGroup,
}: Props) => (
  <FlexContainer gap="xxxsmall">
    {showGroupControls && (
      <OverlayTrigger
        overlay={
          <DropdownMenu>
            <DropdownMenuItem onClick={onEditGroup}>Edit group</DropdownMenuItem>
            <DropdownMenuItem onClick={onDeleteGroup} type="danger">
              Delete group
            </DropdownMenuItem>
          </DropdownMenu>
        }
        placement="bottom-start"
      >
        {({ toggleOpen, isOpen }) => (
          <HoverIconButton
            icon="more-horizontal"
            size="xsmall"
            onClick={toggleOpen}
            active={isOpen}
          />
        )}
      </OverlayTrigger>
    )}
    <IconButton icon="plus" size="xsmall" onClick={onAddCategory} />
  </FlexContainer>
);

export default BudgetCardControls;
