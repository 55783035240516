import { COINBASE_AUTH_URL } from 'common/constants/coinbase';

export const GUEST_PASS_URL = (code: string) => `www.monarchmoney.com/gp/${code}`;
export const getZillowURLForDataProviderId = (id: string) =>
  `https://www.zillow.com/homedetails/${id}_zpid`;
export const getCoinbaseOAuthURL = (
  clientId: string,
  redirectURL: string,
  scopes: string[],
  state: string,
) =>
  `${COINBASE_AUTH_URL}` +
  `?response_type=code` +
  `&client_id=${clientId}` +
  `&redirect_uri=${redirectURL}` +
  `&state=${state}` +
  `&account=all` +
  `&scope=${scopes.join(',')}`;

export const INVESTMENT_TRANSACTIONS_SURVEY_URL = (email: string, userId: string) =>
  `https://monarchmoney.typeform.com/to/uVipzwjp#email=${email}&user_id=${userId}`;

export const LIABILITIES_SURVEY_URL = (email: string, userId: string) =>
  `https://monarchmoney.typeform.com/to/ntmh5Uu2#email=${email}&user_id=${userId}`;

export const HELP_CENTER_URL = 'https://help.monarchmoney.com/hc/en-us';
export const HELP_CENTER_CSV_ARTICLE_URL =
  'https://help.monarchmoney.com/hc/en-us/articles/4409682789908';
export const HELP_CENTER_UPLOAD_BALANCE_HISTORY_ARTICLE_URL =
  'https://help.monarchmoney.com/hc/en-us/articles/14882425704212';
export const HELP_CENTER_ROLLOVER_ARTICLE_URL = `https://help.monarchmoney.com/hc/en-us/articles/4411119762196`;
export const HELP_CENTER_BUDGETS_ARTICLE_URL = `https://help.monarchmoney.com/hc/en-us/articles/360048883631`;
export const HELP_CENTER_SWITCH_BILLING_ARTICLE_URL =
  'https://help.monarchmoney.com/hc/en-us/articles/4414139810580';
export const HELP_CENTER_PRIVACY_SECURITY_URL =
  'https://help.monarchmoney.com/hc/en-us/articles/360048393572';
export const HELP_CENTER_CONNECTION_ISSUES_URL =
  'https://help.monarchmoney.com/hc/en-us/articles/360048393352';
export const HELP_CENTER_AI_ASSISTANT =
  'https://help.monarchmoney.com/hc/en-us/articles/16116906962452';
export const HELP_CENTER_MONEY_BACK_GUARANTEE_URL =
  'https://help.monarchmoney.com/hc/en-us/articles/30368325619732-Monarch-s-Money-back-Guarantee';

export const MINT_TRANSACTIONS_URL = 'https://mint.intuit.com/transactions';

export const WHATS_NEW_URL = 'https://www.monarchmoney.com/whats-new';

export const ADVISOR_ONBOARDING_VIDEO_URL =
  'https://www.loom.com/share/0b9e7f0dcdee47d9800ec60bb3fedc84';

export const ADVISOR_ONBOARDING_CALENDLY_URL =
  'https://calendly.com/d/4fc-gyj-79r/monarch-for-advisors-1-1-onboarding-call';

export const ADVISOR_FAQ_URL =
  'https://help.monarchmoney.com/hc/en-us/articles/11251085750292-Advisor-Program-Now-in-Beta';

export const MINT_DATA_EXPORT_CHROME_EXTENSION_URL =
  'https://chromewebstore.google.com/detail/mint-data-exporter-by-mon/doknkjpaacjheilodaibfpimamfgfhap';

export const SPINWHEEL_TERMS_OF_USE_URL = 'https://legal.spinwheel.io/business-terms-of-service/';

export const SPINWHEEL_PRIVACY_POLICY = 'https://legal.spinwheel.io/privacy/';

export const MONARCH_TERMS_OF_USE_URL = 'https://www.monarchmoney.com/terms';

export const MONARCH_PRIVACY_POLICY = 'https://www.monarchmoney.com/privacy';

export const HELP_CENTER_CONNECTING_TO_SPINWHEEL =
  'https://www.notion.so/monarchmoney/Connecting-to-Spinwheel-to-Track-your-Liability-Account-Bills-9c7e06360b9947d6af574a5e1339c064';

export const HELP_CENTER_GETTING_STARTED_WITH_BILL_SYNCING =
  'https://www.notion.so/monarchmoney/Getting-started-with-bill-syncing-c638df7c8f89404e9d09026937b67b2e';

export const BILL_SYNC_SURVEY_URL = (email: string, userId: string) =>
  `https://monarchmoney.typeform.com/to/Flw4M1q3#email=${email}&user_id=${userId}`;
