import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import React from 'react';
import styled from 'styled-components';

import isV2Theme from 'common/lib/theme/isV2Theme';
import variables from 'common/lib/theme/variables';
import useTheme from 'lib/hooks/useTheme';

const Root = styled.div`
  /* stylelint-disable-next-line selector-type-no-unknown */
  em-emoji-picker {
    --border-radius: ${({ theme }) => theme.radius.medium};
    --font-family: 'Graphik', sans-serif;
    --rgb-background: ${({ theme }) => hexToRgbString(variables.color.background.card({ theme }))};
    --color-border: ${({ theme }) => {
      const rgb = hexToRgbString(variables.color.background.base({ theme }));
      return rgb ? `rgb(${rgb})` : undefined;
    }};
    --rgb-color: ${({ theme }) => hexToRgbString(theme.color.text)};
    --rgb-accent: ${({ theme }) =>
      isV2Theme(hexToRgbString(theme.color.black), hexToRgbString(theme.color.blue))};
    --font-size: ${({ theme }) => theme.fontSize.small};
    --category-icon-size: ${({ theme }) => theme.fontSize.small};
    --category-icon-color: ${({ theme }) => hexToRgbString(variables.color.border.base({ theme }))};
  }
`;

type Emoji = {
  id: string;
  keywords: string[];
  name: string;
  native: string;
  shortcotes: string;
  unified: string;
};

type Props = {
  onClick: (emoji: Emoji) => void;
};

const EmojiPicker = ({ onClick }: Props) => {
  const theme = useTheme();
  const isDarkMode = theme.uiTheme === 'dark';

  return (
    <Root>
      <Picker
        data={data}
        onEmojiSelect={onClick}
        previewPosition="none"
        theme={isDarkMode ? 'dark' : 'light'}
        autoFocus
        native
      />
    </Root>
  );
};

/**
 * Convert a hex color to the format used by emoji-mart's CSS variables.
 *
 * Example: '#ff0000' -> '255,0,0'
 */
const hexToRgbString = (hex: string): string | undefined => {
  hex = hex.trim().replace(/#/g, '');

  if (hex.length !== 6) {
    return undefined;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `${r},${g},${b}`;
};

export default EmojiPicker;
