import pluralize from 'pluralize';
import * as React from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';

import Checkbox from 'components/lib/form/Checkbox';
import DividerLine from 'components/lib/ui/DividerLine';
import FlexContainer from 'components/lib/ui/FlexContainer';
import KeyboardShortcut from 'components/lib/ui/KeyboardShortcut';
import Text from 'components/lib/ui/Text';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import TransactionsListSortByMenu from 'components/reports/TransactionsListSortByMenu';
import ReviewAllTransactionsButton from 'components/transactions/ReviewAllTransactionsButton';
import ReviewSelect from 'components/transactions/review/ReviewSelect';

import { TransactionsDropdownFilter } from 'common/lib/transactions/review';

import type { TransactionOrdering } from 'common/generated/graphql';
import type { TransactionFilters } from 'types/filters';

export const HEADER_HEIGHT_PX = 60;

const ListHeader = styled.div`
  height: ${HEADER_HEIGHT_PX}px;
  display: flex;
  background: ${({ theme }) => theme.color.white};
  padding: ${({ theme }) => theme.spacing.default};
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.color.grayBackground};
  border-radius: ${({ theme }) => theme.radius.medium};
`;

const TransactionTitle = styled.div`
  display: flex;
  align-self: flex-start;
  font-size: ${({ theme }) => theme.fontSize.large};
  height: 100%;
  align-items: center;
`;

const HeaderText = styled(Text).attrs({ weight: 'medium' })``;

const HeaderActions = styled(FlexContainer).attrs({ alignCenter: true })`
  gap: ${({ theme }) => theme.spacing.small};

  & ${DefaultButton}:last-child {
    margin-right: 0;
  }
`;

const StyledReviewSelect = styled(ReviewSelect)`
  min-width: 200px;
`;

const StyledCheckbox = styled(Checkbox)`
  display: inline-block;
  margin-right: ${({ theme }) => theme.spacing.default};
  & ${DefaultButton} {
    margin: 0 ${({ theme }) => theme.spacing.large};
  }
`;

const StyledShortcut = styled(KeyboardShortcut)`
  margin-left: ${({ theme }) => theme.spacing.small};
  transform: translateY(1px);
`;

export type CustomEditButtonProps = {
  openBulkUpdateForm?: () => void;
  count: number;
};

export type Props = {
  isBulkUpdateActive: boolean;
  isAllSelected: boolean;
  totalSelected: number;
  filters: TransactionFilters;
  totalCount: number | undefined;
  overrideTitle?: React.ReactNode;
  extraControls?: React.ReactNode;
  sortBy?: Maybe<TransactionOrdering>;
  openBulkUpdateForm: (() => void) | undefined;
  toggleBulkUpdateActive: () => void;
  toggleIsAllSelected: () => void;
  deselectAll: () => void;
  setFilters: (filters: TransactionFilters, override?: boolean) => void;
  renderCustomEditButton?: (props: CustomEditButtonProps) => React.ReactNode;
  onChangeSortBy?: (sortBy: TransactionOrdering) => void;
};

const TransactionListHeader = ({
  isBulkUpdateActive,
  isAllSelected,
  totalSelected,
  filters,
  totalCount,
  overrideTitle,
  extraControls,
  sortBy,
  renderCustomEditButton,
  toggleIsAllSelected,
  toggleBulkUpdateActive,
  openBulkUpdateForm,
  deselectAll,
  setFilters,
  onChangeSortBy,
}: Props) => {
  const transactionsCount = totalCount ?? 0;

  const needsReviewSelectValue = useMemo(() => {
    if (filters.needsReviewByUser) {
      return filters.needsReviewByUser;
    }
    if (filters.needsReviewUnassigned) {
      return TransactionsDropdownFilter.NeedsReview;
    }

    return TransactionsDropdownFilter.All;
  }, [filters]);

  const getNeedsReviewFiltersFromSelectValue = (value: string) => {
    if (value === TransactionsDropdownFilter.All) {
      return {
        needsReview: undefined,
        needsReviewByUser: undefined,
        needsReviewUnassigned: undefined,
      };
    }

    if (value === TransactionsDropdownFilter.NeedsReview) {
      return {
        needsReview: true,
        needsReviewByUser: undefined,
        needsReviewUnassigned: true,
      };
    }

    return {
      needsReview: true,
      needsReviewByUser: value,
      needsReviewUnassigned: undefined,
    };
  };

  return (
    <ListHeader>
      <TransactionTitle>
        {isBulkUpdateActive && (
          <StyledCheckbox
            checked={isAllSelected}
            onChange={() => {
              toggleIsAllSelected();
              if (isAllSelected) {
                deselectAll();
              }
            }}
          />
        )}
        {totalSelected > 0 ? (
          <FlexContainer>
            <Text weight="medium">
              {totalSelected.toLocaleString()} {pluralize('transaction', totalSelected)} selected
            </Text>
            <StyledShortcut text="(Esc)" />
          </FlexContainer>
        ) : (
          <FlexContainer alignCenter>
            {!isBulkUpdateActive ? (
              overrideTitle ?? (
                <StyledReviewSelect
                  value={needsReviewSelectValue}
                  onChange={({ value }: { value: string }) => {
                    setFilters({
                      ...filters,
                      ...getNeedsReviewFiltersFromSelectValue(value),
                    });
                  }}
                />
              )
            ) : (
              <HeaderText weight="medium">All transactions</HeaderText>
            )}

            {isBulkUpdateActive && <StyledShortcut text="(modA)" />}
          </FlexContainer>
        )}
      </TransactionTitle>
      <HeaderActions>
        {isBulkUpdateActive ? (
          <>
            <DefaultButton
              onClick={() => {
                toggleBulkUpdateActive();
                deselectAll();
              }}
            >
              Cancel
            </DefaultButton>
            {renderCustomEditButton?.({ openBulkUpdateForm, count: totalSelected }) ?? (
              <PrimaryButton disabled={totalSelected < 1} onClick={openBulkUpdateForm}>
                Edit {totalSelected.toLocaleString() || null}
              </PrimaryButton>
            )}
          </>
        ) : (
          <>
            {transactionsCount > 0 && (
              <DefaultButton onClick={toggleBulkUpdateActive}>
                <ButtonIcon name="check" />
                <span>Edit multiple</span>
              </DefaultButton>
            )}
            {filters.needsReview && totalCount !== 0 && (
              <ReviewAllTransactionsButton totalCount={totalCount} />
            )}
            {extraControls}
          </>
        )}
        {transactionsCount > 0 && !!onChangeSortBy && (
          <>
            <DividerLine />
            <TransactionsListSortByMenu value={sortBy} onChangeValue={onChangeSortBy} />
          </>
        )}
      </HeaderActions>
    </ListHeader>
  );
};

export default React.memo(TransactionListHeader);
