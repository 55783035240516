// THIS FILE IS GENERATED, DO NOT MODIFY MANUALLY. ##checksum##: 2324066617
export default [
  'report-sync-issue',
  'block-app-with-message',
  'new-onboarding-connect-account',
  'web-cancel-discount-flow',
  'premium-upgrade-form-copy-position',
  'copy-balance-history',
  'ai-spending-insights',
  'new-enrichment-pipeline',
  'ab-test-annual-upsell-web',
  'sankey-diagram',
  'ab-test-invite-partner-onboarding',
  'needs-review-by',
  'ab-test-connect-single-account-before-paywall',
  'advisor-onboarding-web',
  'ab-test-cash-flow-sankey-default',
  'ab-test-onboarding-sankey',
  'fixed-flex-budgeting',
  'reports',
  'connect-mx-institutions',
  'connect-finicity-institutions',
  'investment-transactions-enabled',
  'support-chat-bubble',
  'block-signups-with-message',
  'reports-chart-share',
  'new-download-transactions',
  'filter-menu-other',
  'transactions-ui-v2-web',
  'hide-status-label-apps',
  'reports-drill-down',
  'liabilities',
  'liabilities-view-more',
  'transactions-ui-updates-walkthrough',
  'account-custom-logos',
  'referral-promo-test-copy-changes',
  'load-liabilities',
  'budget-changes-aug-2024',
  'ab-test-goal-oriented-signup',
  'ab-test-fake-experiment',
] as const;
