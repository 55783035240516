import clientPlatform from './clientPlatform';

type MiddlewareArgs = {
  payload: {
    obj: Record<string, any>;
    [key: string]: any;
  };
  integrations?: Record<string, boolean>;
  next: (payload: MiddlewareArgs['payload']) => void;
};

const clientPlatformMiddleware = ({ payload, next }: MiddlewareArgs) => {
  payload.obj.properties.client_platform = clientPlatform;
  next(payload);
};

export default clientPlatformMiddleware;
