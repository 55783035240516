import React from 'react';

import AddAccountsInstitutionSynced from 'components/accounts/AddAccountsInstitutionSynced';
import type { LinkAccountSuccessMetadata } from 'components/accounts/LinkAccountDataProviderModal';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ModalCard from 'components/lib/ui/ModalCard';

import { useStackContext } from 'common/lib/contexts/StackContext';

import { DataProviderLegacy } from 'common/generated/graphQlTypes/globalTypes';

const getDefaultNameForDataProvider = (dataProvider?: DataProviderLegacy) => {
  if (dataProvider === DataProviderLegacy.ZILLOW) {
    return 'Zillow';
  } else if (dataProvider === DataProviderLegacy.COINBASE) {
    return 'Coinbase';
  } else {
    return 'bank';
  }
};

type Props = {
  institutionName?: string;
  metadata: LinkAccountSuccessMetadata;
};

const LinkAccountSuccessModal = ({ institutionName, metadata }: Props) => {
  const { onComplete } = useStackContext();

  return (
    <ModalCard title="Success">
      <FlexContainer margin="large" alignCenter column>
        <AddAccountsInstitutionSynced
          institutionName={institutionName ?? getDefaultNameForDataProvider(metadata.dataProvider)}
          onClickContinue={() => onComplete?.(metadata)}
        />
      </FlexContainer>
    </ModalCard>
  );
};

export default LinkAccountSuccessModal;
