import React from 'react';

import Text from 'components/lib/ui/Text';
import OnboardingCard from 'components/plan/onboarding/OnboardingCard';
import OnboardingCategoryVariabilityCardRow from 'components/plan/onboarding/OnboardingCategoryVariabilityCardRow';

import type { BudgetVariability } from 'common/generated/graphql';

type Category = {
  id: string;
  name: string;
  icon: string;
  budgetVariability?: BudgetVariability | null;
};

type Group = {
  name: string;
  categories: Category[];
};

type Props = {
  group: Group;
  onChangeBudgetVariability: (categoryId: string, budgetVariability: BudgetVariability) => void;
};

const OnboardingCategoryVariabilityCard = ({ group, onChangeBudgetVariability }: Props) => (
  <OnboardingCard
    title={group.name}
    titleAccessory={
      <Text size="small" color="textLight" weight="medium">
        Category type
      </Text>
    }
  >
    {group.categories.map((category) => (
      <OnboardingCategoryVariabilityCardRow
        key={category.id}
        id={category.id}
        icon={category.icon}
        name={category.name}
        variability={category.budgetVariability}
        onChangeBudgetVariability={onChangeBudgetVariability}
      />
    ))}
  </OnboardingCard>
);

export default OnboardingCategoryVariabilityCard;
