import { DateTime } from 'luxon';
import { evolve, identity, negate } from 'ramda';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import type { BudgetHistoricalAmountBar } from 'components/budget/BudgetHistoricalAmountsBarChart';
import BudgetHistoricalAmountsBarChart from 'components/budget/BudgetHistoricalAmountsBarChart';
import Checkbox from 'components/lib/form/Checkbox';
import EventPropagationBoundary from 'components/lib/higherOrder/EventPropagationBoundary';
import Card from 'components/lib/ui/Card';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import { Skeleton, WithLoadingPlaceholder } from 'components/lib/ui/Skeleton';
import Text from 'components/lib/ui/Text';
import Currency from 'components/lib/ui/currency/Currency';

import useAggregatesGraphQuery from 'common/lib/hooks/budget/useAggregatesGraphQuery';
import { color, fontSize, fontWeight, radius, spacing } from 'common/lib/theme/dynamic';
import { meanBy } from 'common/utils/Math';
import useMeasure from 'lib/hooks/useMeasure';

import type { BudgetVariability } from 'common/generated/graphql';
import type { Id } from 'common/types';

const CARD_WIDTH_PX = 340;
const CHECKBOX_SIZE_PX = 16;

const Root = styled(Card)`
  width: ${CARD_WIDTH_PX}px;
  border: 1px solid ${color.grayLight};
`;

const Content = styled(FlexContainer).attrs({ column: true })`
  padding: ${spacing.small} ${spacing.default};
  gap: ${spacing.xsmall};
`;

const HeaderText = styled(Text).attrs({ weight: 'medium', size: 'small' })``;

const GrayRoundContainerRoot = styled.div<{
  $highlightOnHover?: boolean;
  $hasPadding?: boolean;
}>`
  display: flex;
  align-items: flex-start;
  flex-flow: column;
  background: ${color.grayBackground};
  border-radius: ${radius.medium};
  padding: ${({ $hasPadding = true, theme }) =>
    $hasPadding ? `${theme.spacing.xsmall} ${theme.spacing.small}` : 0};
  font-size: ${fontSize.xsmall};
  font-weight: ${fontWeight.medium};
  flex: 1;
  cursor: pointer;

  ${({ theme, $highlightOnHover = true }) =>
    $highlightOnHover &&
    css`
      &:hover {
        background: ${theme.color.grayFocus};
      }
    `}
`;

const StyledCheckbox = styled(Checkbox)`
  margin-top: ${spacing.xxsmall};

  ${Text} {
    display: inline-block;
    transform: translateY(-1px);
  }
`;

const LoadingContainer = styled(FlexContainer).attrs({
  center: true,
  paddingVertical: 'xxxlarge',
})`
  height: 100%;
  width: 100%;
`;

type Props = {
  appliedToFuture?: boolean;
  header?: React.ReactNode;
  isCategoryGroup?: boolean;
  isIncome?: boolean;
  /** This will be a BudgetVariability in case we're dealing with the top-level amount of one of the Fixed/Flex sections. */
  itemId: Id | BudgetVariability;
  onCheckApplyToFuture?: (applyToFuture: boolean) => void;
  onClickBar: (amount: number) => void;
};

const BudgetHistoricalChartPopover = ({
  itemId,
  header,
  isCategoryGroup,
  isIncome,
  appliedToFuture,
  onCheckApplyToFuture,
  onClickBar,
}: Props) => {
  const today = useMemo(() => DateTime.local(), []);
  const startDate = useMemo(() => today.minus({ months: 6 }).startOf('month'), [today]);
  const endDate = useMemo(() => today.minus({ month: 1 }).endOf('month'), [today]);
  const [data, { loading }] = useAggregatesGraphQuery(itemId, startDate, endDate, isCategoryGroup);

  const graphData: BudgetHistoricalAmountBar[] = useMemo(
    () =>
      data.map(
        evolve({
          sum: isIncome ? identity : negate,
          month: (date) => DateTime.fromISO(date).toFormat('MMM').toUpperCase(),
        }),
      ),
    [data, isIncome],
  );
  const average = useMemo(() => meanBy((datum) => datum.sum, graphData), [graphData]);
  const [spentLastMonth] = graphData.slice(-1);
  const isLoading = loading && !graphData.length;

  const [graphContainerRef, { width = 0 }] = useMeasure();

  const hideApplyToFuture = !onCheckApplyToFuture;

  return (
    <Root>
      <EventPropagationBoundary onMouseDown preventDefault>
        <Content>
          {header}
          <HeaderText>History</HeaderText>
          <FlexContainer gap="xsmall" alignCenter justifyBetween>
            <GrayRoundContainer
              label="Spent last month"
              onClick={() => onClickBar(spentLastMonth.sum)}
            >
              <WithLoadingPlaceholder
                isLoading={isLoading}
                placeholderComponent={<Skeleton height="20px" width="50%" />}
              >
                <Currency value={spentLastMonth?.sum} round />
              </WithLoadingPlaceholder>
            </GrayRoundContainer>
            <GrayRoundContainer label="Monthly average" onClick={() => onClickBar(average)}>
              <WithLoadingPlaceholder
                isLoading={isLoading}
                placeholderComponent={<Skeleton height="20px" width="50%" />}
              >
                <Currency value={average} round />
              </WithLoadingPlaceholder>
            </GrayRoundContainer>
          </FlexContainer>
          <GrayRoundContainer ref={graphContainerRef} highlightOnHover={false} hasPadding={false}>
            {isLoading ? (
              <LoadingContainer>
                <LoadingSpinner $size={24} />
              </LoadingContainer>
            ) : (
              <BudgetHistoricalAmountsBarChart
                data={graphData}
                width={width}
                color={isIncome ? 'green' : 'red'}
                onClickBar={onClickBar}
              />
            )}
          </GrayRoundContainer>
          {!hideApplyToFuture && (
            <StyledCheckbox
              size={CHECKBOX_SIZE_PX}
              checked={appliedToFuture}
              onChange={({ target: { checked } }) => onCheckApplyToFuture?.(checked)}
            >
              <Text weight="book">Apply budget changes to all future months</Text>
            </StyledCheckbox>
          )}
        </Content>
      </EventPropagationBoundary>
    </Root>
  );
};

const GrayRoundContainer = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<{
    label?: string;
    highlightOnHover?: boolean;
    hasPadding?: boolean;
    onClick?: () => void;
  }>
>(({ label, children, highlightOnHover, hasPadding, onClick }, ref) => (
  <GrayRoundContainerRoot
    ref={ref}
    role="button"
    $highlightOnHover={highlightOnHover}
    $hasPadding={hasPadding}
    onClick={onClick}
  >
    {children}
    {!!label && (
      <Text color="textLight" weight="book">
        {label}
      </Text>
    )}
  </GrayRoundContainerRoot>
));

export default BudgetHistoricalChartPopover;
