import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import type { Props as SelectFieldProps } from 'components/lib/form/SelectField';
import SelectField from 'components/lib/form/SelectField';

import { color, fontSize, fontWeight, spacing } from 'common/lib/theme/dynamic';

export const USE_STATEMENT_DUE_DATE_VALUE = 'statement_due_date';

const getSuffixForDay = (i: number) => {
  if (i === 1 || i === 21 || i === 31) {
    return 'st';
  } else if (i === 2 || i === 22) {
    return 'nd';
  } else if (i === 3 || i === 23) {
    return 'rd';
  }
  return 'th';
};

const SyncedLabel = styled.span`
  color: ${color.textLight};
  margin-left: ${spacing.xxxsmall};
`;

const getDayOfTheMonthOptions = ({
  lastStatementDueDateDay,
}: {
  lastStatementDueDateDay: number;
}) => {
  const overrideOptions = R.map(
    (i: number) => ({
      value: `${i}`,
      label: `${i}${getSuffixForDay(i)} of the month`,
    }),
    R.range(1, 32),
  );

  return [
    {
      label: 'Pulled monthly from Spinwheel',
      options: [
        {
          value: USE_STATEMENT_DUE_DATE_VALUE,
          label: (
            <>
              {`${lastStatementDueDateDay}${getSuffixForDay(lastStatementDueDateDay)}`} of the month
              <SyncedLabel>(synced)</SyncedLabel>
            </>
          ),
        },
      ],
    },
    {
      label: 'Custom due date',
      options: overrideOptions,
    },
  ];
};

const GroupHeading = styled.div`
  font-size: ${fontSize.small};
  color: ${color.textLight};
  text-align: left;
  padding: ${spacing.small};
  font-weight: ${fontWeight.medium};
`;

type Props = SelectFieldProps & {
  lastStatementDueDateDay: number;
};

const DayOfTheMonthField = ({ lastStatementDueDateDay, ...props }: Props) => {
  const options = getDayOfTheMonthOptions({ lastStatementDueDateDay });
  return (
    <SelectField
      hideLabel
      options={options}
      components={{
        GroupHeading: ({ children }: any) => <GroupHeading>{children}</GroupHeading>,
      }}
      {...props}
    />
  );
};

export default DayOfTheMonthField;
