import { gql } from '@apollo/client';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import GoalImage from 'components/goalsV2/GoalImage';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ProgressBar from 'components/lib/ui/ProgressBar';
import Text from 'components/lib/ui/Text';
import TimeframeTrendIndicator, { TimeframeText } from 'components/lib/ui/TimeframeTrendIndicator';
import { Currency } from 'components/lib/ui/currency/Currency';

import {
  getGoalDisplayAmount,
  getGoalDisplayProgress,
  invertAmountIfDebtGoal,
  sumCurrentMonthChangesForAllocations,
} from 'common/lib/goalsV2/adapters';

import { GoalType } from 'common/constants/goals';

import type { Web_GoalDashboardRowFields } from 'common/generated/graphQlTypes/Web_GoalDashboardRowFields';

const Root = styled(FlexContainer).attrs({ column: true })`
  padding: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.xlarge};
  border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
`;

const StyledGoalImage = styled(GoalImage)`
  width: 42px;
  height: 42px;
  border-radius: ${({ theme }) => theme.radius.xxsmall};
  margin-right: ${({ theme }) => theme.spacing.default};
  position: relative;
`;

const Title = styled(Text).attrs({ color: 'textLight', size: 'xsmall', weight: 'bold' })`
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.spacing.xxxsmall};
  letter-spacing: 0.1em;
`;

const Balance = styled(Currency).attrs({ size: 'large', weight: 'medium', color: 'text' })`
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.color.text};
`;

const StyledProgressBar = styled(ProgressBar)`
  margin-top: ${({ theme }) => theme.spacing.default};
  height: 8px;
`;

const StyledTimeframeTrendIndicator = styled(TimeframeTrendIndicator)`
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  font-size: ${({ theme }) => theme.fontSize.small};

  ${TimeframeText} {
    text-transform: none;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }
`;

type Props = {
  goal: Web_GoalDashboardRowFields;
  className?: string;
};

const GoalDashboardRow = ({ goal, className }: Props) => {
  const { name, imageStorageProvider, imageStorageProviderId, accountAllocations, type } = goal;

  const currentAmount = goal.currentAmount ?? 0;
  const amountChange = useMemo(() => sumCurrentMonthChangesForAllocations(accountAllocations), []);

  return (
    <Root className={className}>
      <FlexContainer alignCenter>
        <StyledGoalImage
          size="small"
          imageStorageProvider={imageStorageProvider}
          imageStorageProviderId={imageStorageProviderId}
          showGradient={false}
        />
        <FlexContainer column>
          <Title>{name}</Title>
          <Balance value={getGoalDisplayAmount(goal)} round />
        </FlexContainer>
      </FlexContainer>
      <StyledProgressBar color="green" value={getGoalDisplayProgress(goal)} roundAppearance />

      <StyledTimeframeTrendIndicator
        startAmount={currentAmount}
        timeframeDisplay="This month"
        endAmount={currentAmount + invertAmountIfDebtGoal(amountChange, type)}
        isAsset={type !== GoalType.Debt}
      />
    </Root>
  );
};

GoalDashboardRow.fragments = {
  GoalDashboardRowFields: gql`
    fragment Web_GoalDashboardRowFields on GoalV2 {
      id
      name
      imageStorageProvider
      imageStorageProviderId
      currentAmount
      completionPercent
      completedAt
      archivedAt
      type
      accountAllocations {
        id
        currentMonthChange {
          percent
          amount
        }
      }
    }
  `,
};

export default GoalDashboardRow;
