import React from 'react';

import { ButtonGroup } from 'components/lib/ui/ButtonGroup';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import PlanRowTitle from 'components/plan/PlanRowTitle';
import { CardRow, Left, Right } from 'components/plan/onboarding/OnboardingCard';

import { BudgetVariability } from 'common/generated/graphql';

type Props = {
  id: string;
  icon: string;
  name: string;
  variability?: BudgetVariability | null;
  onChangeBudgetVariability: (categoryId: string, budgetVariability: BudgetVariability) => void;
};

const OnboardingCategoryVariabilityCardRow = ({
  id,
  icon,
  name,
  variability,
  onChangeBudgetVariability,
}: Props) => {
  const selectedVariability = variability ?? BudgetVariability.FIXED;

  return (
    <CardRow $withTopBorder>
      <Left>
        <PlanRowTitle icon={icon}>
          <span>{name}</span>
        </PlanRowTitle>
      </Left>
      <Right>
        <ButtonGroup>
          <VariabilityButton
            active={selectedVariability === BudgetVariability.FIXED}
            onClick={() => onChangeBudgetVariability(id, BudgetVariability.FIXED)}
            text="Fixed"
          />
          <VariabilityButton
            active={selectedVariability === BudgetVariability.FLEXIBLE}
            onClick={() => onChangeBudgetVariability(id, BudgetVariability.FLEXIBLE)}
            text="Flexible"
          />
          <VariabilityButton
            active={selectedVariability === BudgetVariability.NON_MONTHLY}
            onClick={() => onChangeBudgetVariability(id, BudgetVariability.NON_MONTHLY)}
            text="Non-monthly"
          />
        </ButtonGroup>
      </Right>
    </CardRow>
  );
};

const VariabilityButton = ({
  active,
  onClick,
  text,
}: {
  active: boolean;
  onClick: () => void;
  text: string;
}) => (
  <DefaultButton size="xsmall" active={active} onClick={onClick}>
    <Text>{text}</Text>
  </DefaultButton>
);

export default OnboardingCategoryVariabilityCardRow;
