import React from 'react';
import styled, { css } from 'styled-components';

import type { ItemProps } from 'components/lib/ui/DropdownMenu';
import { DropdownMenuItem } from 'components/lib/ui/DropdownMenu';
import { MenuItemIcon } from 'components/lib/ui/Menu';
import Text from 'components/lib/ui/Text';

import { useStackContext } from 'common/lib/contexts/StackContext';

const ChevronIcon = styled(MenuItemIcon).attrs({ name: 'chevron-right', size: 14 })`
  margin-left: auto;
`;

const Title = styled.div`
  flex: 1;
  margin-right: ${({ theme }) => theme.spacing.default};
`;

const Right = styled.div`
  text-align: right;
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.color.grayBackground};
`;

const StyledDropdownMenuItem = styled(DropdownMenuItem)`
  ${({ onClick }) =>
    !onClick &&
    css`
      cursor: default;
      color: inherit;
    `}
  padding-right: ${({ theme }) => theme.spacing.xxsmall};
`;

const Description = styled(Text).attrs({ color: 'textLight', size: 'xsmall' })`
  display: block;
`;

export type MenuItem = ItemProps & {
  title?: string;
  right?: React.ReactNode;
  rightChevron?: React.ReactNode;
  onClick?: () => void;
  /** If provided, will push this component onto the Stack on click. */
  component?: React.FunctionComponent<any>;
  componentProps?: any;
  /** Allows contextual menu items, depending on some condition. */
  show?: boolean;
  description?: React.ReactNode;
  showDivider?: boolean;
  divider?: boolean;
};

type Props = {
  items: MenuItem[];
};

const NestedDropdownMenu = ({ items }: Props) => {
  const { push } = useStackContext();

  const mappedItems = items
    .filter((item) => item?.show ?? true)
    .map((item) => ({
      ...item,
      ...(item.component
        ? {
            rightChevron: true,
            keepMenuOpenOnClick: true,
            onClick: () => {
              item.onClick?.();
              item.component && push(item.component, item.componentProps || {});
            },
          }
        : {}),
    }));

  return (
    <>
      {mappedItems.map(
        ({ title, divider, right, rightChevron, description, showDivider, ...props }, idx) => {
          if (divider) {
            return <Divider key={idx} />;
          } else {
            return (
              <StyledDropdownMenuItem
                key={title}
                disabled={!props.onClick}
                $showDivider={showDivider}
                {...props}
              >
                <Title>
                  <Text>{title}</Text>
                  {description && <Description>{description}</Description>}
                </Title>
                <Right>{right ?? (rightChevron && <ChevronIcon />)}</Right>
              </StyledDropdownMenuItem>
            );
          }
        },
      )}
    </>
  );
};

export default NestedDropdownMenu;
