import React from 'react';

import useTheme from 'lib/hooks/useTheme';

import type { ReferenceLineLabelProps } from 'types/Recharts';

type Props = ReferenceLineLabelProps & {
  children: React.ReactNode;
  fill: string;
  textFill: string;
  grabberWidth: number;
  grabberHeight: number;
  chartMarginLeft: number;
  onClick?: () => void;
};

const GrabberReferenceLabel = ({
  viewBox: { x, y, width },
  children,
  fill,
  textFill,
  grabberWidth,
  grabberHeight,
  chartMarginLeft,
  onClick,
}: Props) => {
  const theme = useTheme();
  const rectLeft = x - chartMarginLeft;
  const rectTop = y - grabberHeight / 2;
  const radius = grabberHeight / 2;
  return (
    <g>
      <path
        d={`M${rectLeft},${rectTop}
          h${grabberWidth - radius}
          q${radius},0 ${radius},${radius}
          q0,${radius} -${radius},${radius}
          h-${grabberWidth}
          z`} // Rect with rounded top-right and bottom-right corners
        fill={fill}
        onClick={onClick}
        cursor="pointer"
      />
      <text
        x={rectLeft + grabberWidth / 2 - 2}
        y={rectTop + grabberHeight / 2}
        textAnchor="middle"
        alignmentBaseline="middle"
        fontSize={theme.fontSize.xsmall}
        fontWeight="bold"
        fill={textFill}
        onClick={onClick}
        cursor="pointer"
      >
        {children}
      </text>
      <line
        x1={rectLeft + grabberWidth}
        y1={rectTop + grabberHeight / 2}
        x2={width + chartMarginLeft}
        y2={rectTop + grabberHeight / 2}
        strokeDasharray="6 6"
        strokeWidth={1.5}
        stroke={fill}
      />
    </g>
  );
};

export default GrabberReferenceLabel;
