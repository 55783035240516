import React from 'react';

import CancelSubscriptionModal from 'components/settings/billing/CancelSubscriptionModal';
import type { Props as SubscriptionModalProps } from 'components/settings/billing/SubscriptionModal';
import SubscriptionModal from 'components/settings/billing/SubscriptionModal';
import CancelSubscriptionDiscountFlow from 'components/settings/billing/cancel/CancelSubscriptionDiscountFlow';
import Flow from 'components/utils/Flow';

import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';

const ManageSubscriptionFlow = (props: Omit<SubscriptionModalProps, 'next'>) => {
  const useNewCancelFlow = useIsFeatureFlagOn('web-cancel-discount-flow');

  return (
    <Flow
      initialProps={props}
      steps={[
        SubscriptionModal,
        useNewCancelFlow ? CancelSubscriptionDiscountFlow : CancelSubscriptionModal,
      ]}
    />
  );
};

export default ManageSubscriptionFlow;
