import { DateTime } from 'luxon';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useCallback, useMemo, useState, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import RotateAnimation from 'components/lib/animations/RotateAnimation';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Modal from 'components/lib/ui/Modal';
import PageWithNoAccountsEmptyState from 'components/lib/ui/PageWithNoAccountsEmptyState';
import MerchantSearchFlow from 'components/merchants/MerchantSearchFlow';
import AddRecurringModalCard from 'components/recurring/AddRecurringModalCard';
import RecurringEmptyCard from 'components/recurring/RecurringEmptyCard';
import RecurringHeaderControls from 'components/recurring/RecurringHeaderControls';
import RecurringHeaderTabs from 'components/recurring/RecurringHeaderTabs';
import ReviewStreamModalCard from 'components/recurring/ReviewStreamModalCard';
import RecurringEmptyPeriod from 'components/recurring/new/RecurringEmptyPeriod';
import NewRecurringHeaderTabs from 'components/recurring/new/RecurringHeaderTabs';
import RecurringAll from 'components/routes/recurring/RecurringAll';
import RecurringUpcoming from 'components/routes/recurring/RecurringUpcoming';
import EditMerchantModal from 'components/settings/merchants/EditMerchantModal';
import SpinwheelConnectionFlow from 'components/spinwheel/SpinwheelConnectionFlow';

import useRecurringSearch from 'common/lib/hooks/recurring/useRecurringSearch';
import { useRecurringStreams } from 'common/lib/hooks/recurring/useRecurringStreams';
import useSpinwheel from 'common/lib/hooks/recurring/useSpinwheel';
import useProfileFlag from 'common/lib/hooks/users/useProfileFlag';
import { getSearchDisabledTooltipText } from 'common/lib/recurring';
import { useRecurringActions } from 'lib/hooks/recurring/useRecurringActions';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import useMockDataWhenNoAccountsQuery from 'lib/hooks/useMockDataWhenNoAccountsQuery';
import useModal from 'lib/hooks/useModal';
import {
  useQueryParam,
  useUpdatableQueryParam,
  useUpdateQueryParam,
} from 'lib/hooks/useQueryParams';
import { useWhatsNew } from 'lib/hooks/useWhatsNew';
import toast, { dismissToast } from 'lib/ui/toast';
import { setRecurringFilters } from 'state/recurring/actions';
import { getFilters } from 'state/recurring/selectors';

import { RECURRING } from 'common/constants/copy';
import { RECURRING_SEARCH_TOAST_TIMEOUT_MS } from 'common/constants/recurringTransactions';
import routes from 'constants/routes';

import { gql } from 'common/generated/gql';

const LazyLoadedRecurringWalkthrough = React.lazy(
  () => import('components/recurring/new/RecurringWalkthrough'),
);

const DEFAULT_ROUTE = routes.recurring.upcoming.path;

const LoadingContainer = styled(Flex)`
  flex: 1;
  height: 100%;
  gap: ${({ theme }) => theme.spacing.default};
`;

const Recurring = () => {
  const dispatch = useDispatch();
  const filters = useSelector(getFilters);
  const { push, location } = useHistory();
  const reviewOpen = useQueryParam('reviewOpen') === 'true';
  const setReviewOpen = useUpdateQueryParam('reviewOpen');

  const isBillTrackingEnabled = useIsFeatureFlagOn('load-liabilities');

  useRecurringActions();

  const updateFilters = useCallback(
    (filters: any) => dispatch(setRecurringFilters(filters)),
    [dispatch],
  );
  const [AddRecurringModal, { open: openAddRecurringModal, close: closeAddRecurringModal }] =
    useModal();

  const [ReviewStreamModal, { open: openReviewStreamModal, close: closeReviewStreamModal }] =
    useModal(reviewOpen);

  const [AddManualRecurringModal, { open: openAddManualRecurringModal }] = useModal();
  const [ConnectionModal, { open: openConnectionModal, close: closeConnectionModal }] = useModal();

  const [editingMerchantId, setEditingMerchantId] = useState<string | null | undefined>(null);

  const {
    hasRecurringMerchants,
    pendingStreams,
    refetchHouseholdHasRecurring,
    isLoading: isLoadingInitialData,
  } = useRecurringStreams({
    includeLiabilities: isBillTrackingEnabled,
  });

  const [startDate, setStartDate] = useUpdatableQueryParam('startDate');
  const currentStartDate = useMemo(
    () => (startDate ? DateTime.fromISO(startDate) : DateTime.local().startOf('month')),
    [startDate],
  );
  const endDate = useMemo(() => currentStartDate.endOf('month'), [currentStartDate]);

  const { syncCreditReport, isSyncingCreditReport, resetTimeout, connectionStatus } =
    useSpinwheel();

  const {
    data: recurringItemsData,
    refetch: refetchRecurringItems,
    isLoadingInitialData: isLoadingRecurringCalendar,
  } = useMockDataWhenNoAccountsQuery(GET_RECURRING_UPCOMING_QUERY, {
    variables: {
      startDate: currentStartDate.toISODate(),
      endDate: endDate.toISODate(),
      filters: R.pick(['accounts', 'isCompleted'], {
        ...filters,
        accounts: filters?.accounts ?? undefined,
        isCompleted: undefined,
      }),
      includeLiabilities: isBillTrackingEnabled,
    },
  });

  const hasAlreadySyncedWithSpinwheel = connectionStatus.isConnected;
  const shouldSyncBeforeProceeding = connectionStatus.isDisconnected;

  const handleCloseConnectionModal = () => {
    resetTimeout();
    closeConnectionModal();
  };

  const onClickAddManual = useCallback(() => {
    closeAddRecurringModal();
    openAddManualRecurringModal();
  }, [closeAddRecurringModal, openAddManualRecurringModal]);

  const onClickAddBills = useCallback(() => {
    closeAddRecurringModal();
    if (shouldSyncBeforeProceeding) {
      syncCreditReport();
      openConnectionModal();
    } else {
      push(routes.recurring.mapLiabilities());
    }
  }, [closeAddRecurringModal, shouldSyncBeforeProceeding, syncCreditReport, openConnectionModal]);

  const refetchAll = () => Promise.all([refetchRecurringItems(), refetchHouseholdHasRecurring()]);
  const recurringItems = useMemo(
    () => recurringItemsData?.recurringTransactionItems ?? [],
    [recurringItemsData],
  );

  const [recurringSearchToastId, setRecurringSearchToastId] = useState<string | undefined>();
  const [
    startRecurringSearch,
    {
      isInProgress: isRecurringSearchInProgress,
      currentSearch: { searchStartedAt, searchNextAt },
    },
  ] = useRecurringSearch({
    onDidStartSearch: () => {
      closeAddRecurringModal();
      const toastId = toast({
        title: RECURRING.SEARCH.TOAST_TITLE,
        description: RECURRING.SEARCH.TOAST_SUBTITLE,
        duration: RECURRING_SEARCH_TOAST_TIMEOUT_MS,
        accessory: (
          <RotateAnimation>
            <Icon name="refresh" />
          </RotateAnimation>
        ),
      });
      setRecurringSearchToastId(toastId);
    },
    onDidFinishSearch: () => {
      dismissToast(recurringSearchToastId);
      refetchAll();
    },
    onTimeoutReached: () => {
      dismissToast(recurringSearchToastId);
      refetchAll();
    },
  });

  const searchDisabledTooltip = useMemo(
    () => getSearchDisabledTooltipText(searchStartedAt, searchNextAt),
    [searchStartedAt, searchNextAt],
  );

  // Profile flag for the recurring walkthrough
  const [dismissedWalkthroughAt, setDismissedWalkthroughAt, isLoadingDismissedWalkthroughAt] =
    useProfileFlag('dismissedRecurringWalkthroughAt');

  const { dismissWhatsNew } = useWhatsNew();

  if (isLoadingInitialData) {
    return (
      <LoadingContainer center column>
        <LoadingSpinner />
        Loading recurring data...
      </LoadingContainer>
    );
  }

  const shouldShowOverlayEmptyComponent =
    !isLoadingInitialData && !hasRecurringMerchants && !isBillTrackingEnabled;

  const showBillTrackingWalkthroughModal =
    isBillTrackingEnabled && !isLoadingDismissedWalkthroughAt && !dismissedWalkthroughAt;

  // TODO: remove duplicated component once we remove the feature flag load-liabilities
  return (
    <PageWithNoAccountsEmptyState
      name="Recurring"
      tabs={isBillTrackingEnabled ? <NewRecurringHeaderTabs /> : <RecurringHeaderTabs />}
      emptyIcon="calendar"
      emptyTitle={RECURRING.EMPTY_STATE_TITLE}
      emptyButtonText={RECURRING.EMPTY_ACCOUNTS_BUTTON_TEXT}
      controls={
        <RecurringHeaderControls
          // @ts-ignore mismatch between graphql type and redux type
          filters={filters}
          onChangeFilters={updateFilters}
          onClickAddRecurring={openAddRecurringModal}
        />
      }
      overlayEmptyComponent={
        shouldShowOverlayEmptyComponent ? (
          <RecurringEmptyCard
            onClickPrimary={startRecurringSearch}
            onClickSecondary={openAddManualRecurringModal}
            isLoading={isRecurringSearchInProgress}
          />
        ) : undefined
      }
    >
      <FlexContainer alignStart>
        <Switch>
          {/* This redirects the user to the default route if they try to access the page by
            the direct URL (/recurring). Without this we'd render a blank page. */}
          <Route
            exact
            path={routes.recurring.path}
            render={() => <Redirect to={{ pathname: DEFAULT_ROUTE, search: location.search }} />}
          />
          <Route path={routes.recurring.upcoming.path}>
            <RecurringUpcoming
              items={recurringItems}
              currentStartDate={currentStartDate}
              setStartDate={setStartDate}
              isLoading={isLoadingRecurringCalendar}
              refetch={refetchRecurringItems}
              streamsToReview={pendingStreams}
              onClickToReviewStreams={openReviewStreamModal}
              emptyStateComponent={<RecurringEmptyPeriod onClickPrimary={openAddRecurringModal} />}
            />
          </Route>
          <Route path={routes.recurring.all.path}>
            <RecurringAll filters={filters} startDate={currentStartDate} />
          </Route>
        </Switch>
      </FlexContainer>

      {RA.isNotNil(editingMerchantId) && (
        <Modal onClose={() => setEditingMerchantId(null)}>
          <EditMerchantModal merchantId={editingMerchantId} afterUpdate={refetchRecurringItems} />
        </Modal>
      )}

      <AddRecurringModal full={isBillTrackingEnabled}>
        <AddRecurringModalCard
          searchDisabledTooltip={searchDisabledTooltip}
          onClickSearch={startRecurringSearch}
          onClickAddManual={onClickAddManual}
          onClickAddBills={onClickAddBills}
          hasAlreadySyncedWithSpinwheel={hasAlreadySyncedWithSpinwheel}
        />
      </AddRecurringModal>

      <ReviewStreamModal onClose={() => setReviewOpen(undefined)}>
        <ReviewStreamModalCard streamsToReview={pendingStreams} close={closeReviewStreamModal} />
      </ReviewStreamModal>

      <AddManualRecurringModal>
        <MerchantSearchFlow onComplete={refetchAll} initialValues={{ isRecurring: true }} />
      </AddManualRecurringModal>

      <ConnectionModal onClose={handleCloseConnectionModal}>
        <SpinwheelConnectionFlow
          isLoading={isSyncingCreditReport}
          hasFraudError={connectionStatus.hasFraudError}
          hasGenericError={connectionStatus.hasGenericError}
          onComplete={handleCloseConnectionModal}
        />
      </ConnectionModal>

      {showBillTrackingWalkthroughModal && (
        <Suspense fallback={null}>
          <LazyLoadedRecurringWalkthrough
            onFinish={(wasSkipped) => {
              if (!wasSkipped) {
                push(routes.recurring.mapLiabilities());
              }
              setDismissedWalkthroughAt(new Date().toISOString());
            }}
            onStart={() => {
              dismissWhatsNew();
            }}
          />
        </Suspense>
      )}
    </PageWithNoAccountsEmptyState>
  );
};

export const GET_RECURRING_UPCOMING_QUERY = gql(/* GraphQL */ `
  query Web_GetUpcomingRecurringTransactionItems(
    $startDate: Date!
    $endDate: Date!
    $filters: RecurringTransactionFilter
    $includeLiabilities: Boolean
  ) {
    recurringTransactionItems(
      startDate: $startDate
      endDate: $endDate
      filters: $filters
      includeLiabilities: $includeLiabilities
    ) {
      stream {
        id
        frequency
        amount
        isApproximate
        name
        logoUrl
        merchant {
          id
        }
        creditReportLiabilityAccount {
          id
          liabilityType
          account {
            id
          }
        }
      }
      date
      isPast
      isLate
      markedPaidAt
      isAmountDifferentThanOriginal
      transactionId
      amount
      amountDiff
      creditReportLiabilityStatementId
      category {
        id
        name
        icon
      }
      account {
        id
        displayName
        icon
        logoUrl
      }
    }
  }
`);

export default Recurring;
