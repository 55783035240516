import React from 'react';

import Menu, { MenuItemLink, MenuSeparator } from 'components/lib/ui/Menu';

import { HOME_CATEGORY } from 'constants/advice';
import routes from 'constants/routes';

import type { AdviceQuery_Web_adviceItemCategories } from 'common/generated/graphQlTypes/AdviceQuery_Web';

type Props = {
  categories: AdviceQuery_Web_adviceItemCategories[];
};

const AdviceCategoriesMenu = ({ categories }: Props) => (
  <Menu>
    <MenuItemLink to={routes.advice({ category: HOME_CATEGORY })}>Recommendations</MenuItemLink>
    <MenuSeparator />
    {categories.map(({ name, displayName }) => (
      <MenuItemLink key={name} to={routes.advice({ category: name })}>
        {displayName}
      </MenuItemLink>
    ))}
  </Menu>
);

export default AdviceCategoriesMenu;
